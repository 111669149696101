import { httpApi } from '@app/api/http.api';

interface deletedItem {
  id: string;
}

type category_id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;

export const DeleteTradeExportTableData = (
  category_id: category_id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
  deleteItems: deletedItem[],
): Promise<undefined> => {
  return httpApi
    .delete<undefined>(
      `categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/tradeExport/tradeExportDelete`,
      {
        data: deleteItems, // This is where you pass your body data
      },
    )
    .then(({ data }) => data);
};
