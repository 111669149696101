import React, { useEffect, useState } from 'react';
import TradeDataForm from './TradeDataForm';
import TradeImportData from './TradeImportData';
import TradeExportData from './TradeExportData';
import { GetTradeFixedData } from '@app/api/categories/TradeDataFixed/GetTradeFixedData.api';
import { useLocation } from 'react-router-dom';

interface formData {
  source: string;
  importer_ranking: number;
  imported_value: number;
  year_range_short_term: {
    from: number;
    to: number;
  };
  year_range_long_term: {
    from: number;
    to: number;
  };
  date: Date;
}

function TradeData() {
  const [formData, setFromData] = useState<formData>();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const fetchTradeFormData = async () => {
    setLoading(true);
    try {
      const response = await GetTradeFixedData(state.category_id, state.market_id, state.sub_cat_id);
      setFromData(response.data);
      console.log('responseeee', response);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTradeFormData();
  }, []);
  return (
    <>
      <div></div>
      <TradeDataForm loading={loading} tradeFormData={formData as formData} fetchFormData={fetchTradeFormData} />
      <TradeImportData tradeFormData={formData as formData} />
      <TradeExportData tradeFormData={formData as formData} />
      <div></div>
    </>
  );
}

export default TradeData;
