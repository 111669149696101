import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/logo.png';
import logoDark from 'assets/logo-dark.png';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { GetLogoChange } from '@app/api/logoChange/GetLogoChange.api';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}

interface response {
  logoValue: string;
}

export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly } = useResponsive();
  const [logoImg, setLogoImg] = useState<response>();

  const theme = useAppSelector((state) => state.theme.theme);

  // const fetchImage = async () => {
  //   try {
  //     const response = await GetLogoChange();
  //     console.log('response', response);
  //     setLogoImg(response.data[0]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useLayoutEffect(() => {
  //   fetchImage();
  // }, []);

  // const img = theme === 'dark' ? logoDark : logo;

  const finalImage = localStorage.getItem('logo');

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <img src={finalImage} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
      </S.SiderLogoLink>
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
