import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MainHeader } from '@app/components/layouts/main/MainHeader/MainHeader';
// import { useLocation } from 'react-router-dom';
// import { useResponsive } from '@app/hooks/useResponsive';
// import { DASHBOARD } from '@app/components/router/AppRouter';
import CoverSection from '@app/components/landingPage/CoverSection/Cover';
import Navbar from '@app/components/landingPage/Navbar/Navbar';
import InfoSection from '@app/components/landingPage/InfoSection';
import Services from '@app/components/landingPage/Services';
import Footer from '@app/components/landingPage/Footer';
import Sidebar from '@app/components/landingPage/Sidebar';
import { useAppSelector } from '@app/hooks/reduxHooks';
import PriceAndFeatures from '@app/components/landingPage/PriceAndFeatures/PriceAndFeatures';
import SaveTimeAndResources from '@app/components/landingPage/SaveTimeAndResources';
import FinancialReview from '@app/components/landingPage/FinancialReview/FinancialReview';
import ExportAdvice from '@app/components/landingPage/ExportAdvice/ExportAdvice';
import NewMemberOffer from '@app/components/landingPage/NewMemberOffer/NewMemberOffer';
import Features from '@app/components/landingPage/features/Features';
import { useLocation, useNavigate } from 'react-router-dom';
import { assignPagePermission } from '@app/api/permissions/AllPagePermisson.api';
import { GetSubscriptionBasePlan } from '@app/api/Subscriptions/SubscriptionModules/GetSubscriptionBasePlan.api';
interface user {
  id?: string;
}
const LandingPage: React.FC = () => {
  const site = useLocation();
  const navigate = useNavigate();
  // const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);

  const user: user = useAppSelector((state) => state.user.user);
  // const [siderCollapsed, setSiderCollapsed] = useState(true);
  // const { isDesktop } = useResponsive();
  // const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   setIsTwoColumnsLayout([DASHBOARD].includes(location.pathname) && isDesktop);
  // }, [location.pathname, isDesktop]);
  const isMembershipActive = async () => {
    const res = await GetSubscriptionBasePlan(user?.id, 'Membershipsubscription');
    localStorage.setItem('MembershipStatus', JSON.stringify(res?.membership_active));
  };
  const assignPage = async () => {
    if (user?.id) {
      try {
        const type = 1;
        const PageData = await assignPagePermission(user?.id, type);
        localStorage.setItem('permissions', JSON.stringify(PageData.data));
      } catch (error) {}
    }
  };
  useEffect(() => {
    isMembershipActive();
    assignPage();
  }, []);
  useEffect(() => {
    if (site.state === 'Welcome to exportal connect portal' && user?.role === 'SUPERADMIN')
      navigate('/admin/superadminpanel');
  }, [user, navigate, site]);
  const desktopLayout = (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} contactToggle={toggle} userLoggedIn={user !== null} />
      <Navbar toggle={toggle} userLoggedIn={user !== null} />
      <CoverSection />
      <Features />
      <PriceAndFeatures />
      <InfoSection />
      <SaveTimeAndResources />
      <FinancialReview />
      <Services />
      <NewMemberOffer />
      <ExportAdvice />
      <Footer />
      {/* <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
        <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
      </MainHeader>
      <S.MainRow>
        <S.LeftSideCol xl={13} xxl={14} id="desktop-content">
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <S.Head>Your export journey is about to be transformed.</S.Head>
              <S.Content>
                Welcome to the Export Connect Portal, where actionable data, valuable market insights, and practical
                resources and tools are at your fingertips.
              </S.Content>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>

        <S.RightSideCol xl={11} xxl={10}>
          <BaseCol span={24}>
            <div style={{ textAlign: 'center' }}>
              <video
                id="myVideo"
                width="100%"
                height="280"
                controls
                poster="https://firebasestorage.googleapis.com/v0/b/export-connect-portal.appspot.com/o/pages%2Fimages%2Flanding_1665011368302.jpg?alt=media&token=09db7019-fd8c-416c-a2d2-c51e802e076b"
              >
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/export-connect-portal.appspot.com/o/pages%2Fvideos%2Flanding_1664845900104.mp4?alt=media&token=a45f99d1-2390-4711-92fd-b758e6214f0a"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </BaseCol>
        </S.RightSideCol>
      </S.MainRow>
      <Features />
      <BaseCol span={24}>
        <div style={{ textAlign: 'center' }}>
          <S.SubHead>Your Subscriptions </S.SubHead>
          <S.Content> Competitor reviews and trade data for</S.Content>
          <S.YourSubscriptionbutton>Add subscription</S.YourSubscriptionbutton>
          <S.Content> You do not have any subscriptions</S.Content>
        </div>
      </BaseCol> */}
    </>
  );

  //   const mobileAndTabletLayout = (
  //     <BaseRow gutter={[20, 24]}>
  //       <BaseCol span={24}>
  //         <TrendingCreators />
  //       </BaseCol>

  //       <BaseCol span={24}>
  //         <RecentlyAddedNft />
  //       </BaseCol>

  //       <BaseCol span={24}>
  //         <TrendingCollections />
  //       </BaseCol>

  //       <BaseCol span={24}>
  //         <RecentActivity />
  //       </BaseCol>
  //     </BaseRow>
  //   );

  return (
    <>
      {desktopLayout}

      {/* {isDesktop ? desktopLayout : mobileAndTabletLayout} */}
    </>
  );
};

export default LandingPage;
