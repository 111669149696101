import styled from 'styled-components';

export const MarketInsights = styled.div`
  margin: 2rem auto;
  padding: 20px;
  box-shadow: 0px 0px 15px 1px rgba(35, 78, 84, 0.61);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const MarketDetailsWrap = styled.div`
  width: 50%;
  margin: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const MarketInsightsText = styled.div`
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
  justify-content: center;
`;
