import { httpApi } from '@app/api/http.api';
interface response {
  status: boolean;
  message: string;
  data?: ConsumerBehaviourData;
}
interface ConsumerBehaviourData {
  key_trends: string;
  digital_adoption: string;
}
export const GetConsumerBehaviour = (id: string): Promise<response> => {
  return httpApi.get<response>(`/marketInsightsCbst/${id}`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
