/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';
import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import CompetitorData from '@app/pages/Admin/Categories/CategoryMarkets/CategoryMarketSubCategory/SubCategoryDetails/CompetitorData/CompetitorData';
// import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import YourSubcriptionPage from '@app/pages/YourSubscriptions/YourSubscriptions';
import MarketInsightsPage from '@app/pages/MarketInsights/MarketInsightsPage';
import LandingPage from '@app/pages/Landing/Landing';
//Importing admin pages
import UsersPage from '@app/pages/Admin/Users/UsersPage';
import MarketsPage from '@app/pages/Admin/Markets/MarketsPage';
import CategoriesPage from '@app/pages/Admin/Categories/CategoriesPage';
import VideosPage from '@app/pages/Admin/Videos/VideosPage';
import AdminResourcesPage from '@app/pages/Admin/Resources/AdminResourcesPage';
import PagesSectionPage from '@app/pages/Admin/Pages/PagesSectionPage';
// import CouponsPage from '@app/pages/Admin/Coupons/CouponsPage';
import AdminLayout from '../layouts/admin/AdminLayout/AdminLayout';
// import { readUser } from '@app/services/localStorage.service';
import MarketsDetails from '@app/pages/Admin/Markets/MarketDetails/MarketDetails';
import Insights from '@app/pages/Admin/Markets/MarketInsights/Insights';
import CategoriesDetails from '@app/pages/Admin/Categories/CategoriesDetails/CategoriesDetails';
import CatMarkSubCatDetails from '@app/pages/Admin/Categories/CategoryMarkets/CategoryMarketSubCategory/CatMarketSubCatDetails';
import SubCategoryDetails from '@app/pages/Admin/Categories/CategoryMarkets/CategoryMarketSubCategory/SubCategoryDetails/SubCategoryDetails';
import TradeData from '@app/pages/Admin/Categories/CategoryMarkets/CategoryMarketSubCategory/SubCategoryDetails/TradeData/TradeData';
import TradePage from '@app/pages/Trade/TradePage';
import AssignPermission from '@app/pages/Admin/Users/assignPermission/AssignPermission';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getAssignedPermissionsAPI } from '@app/api/permissions/GetAssignPermission.api';
import CategoryDataDetails from '@app/pages/Admin/Categories/CategoryMarkets/CategoryMarketSubCategory/SubCategoryDetails/CatagoryData/CategoryDataDetails';
import SuperAdminPanels from '@app/pages/Admin/SuperAdminPanel/SuperAdminPanel';
import LogoChange from '../profile/profileCard/ProfileNav/LogoChange';
import LogoChangeSelectionTab from '../profile/profileCard/ProfileNav/LogoChangeSlectionTab';
import DashboardPage from '@app/pages/DashboardPage';

interface AssignPermissionsResponse {
  status: boolean;
  message: string;
  data: {
    _id: string;
    UserID: string;
    AccessPermissions: {
      _id: string;
      permissionId: string;
      value: string;
    }[];
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
}

const CouponsPage = React.lazy(() => import('@app/pages/Admin/Coupons/CouponsPage'));
const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
// const MarketDetails = React.lazy(() => import('@app/pages/Admin/MarketDetails/MarketDetails'));
// const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ExportVideoPage = React.lazy(() => import('@app/pages/ExportVideo/ExportVideoPage'));
const CompetitorReviewsPage = React.lazy(() => import('@app/pages/CompetitorReviews/CompetitorReviewsPage'));
const AdvisoryServicePage = React.lazy(() => import('@app/pages/AdvisoryService/AdvisoryServicePage'));
const ResourcesPage = React.lazy(() => import('@app/pages/Resources/ResourcesPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const Successtransaction = React.lazy(() => import('@app/pages/Successtransaction'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));

const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));
// const user = readUser();

export const LANDING_PAGE = '/landing';
export const DASHBOARD = '/dashboard';
export const ADMIN = '/admin';

const Users = withLoading(UsersPage);
const AdminLayoutPage = withLoading(AdminLayout);
const Markets = withLoading(MarketsPage);
const MarketsDetailsPage = withLoading(MarketsDetails);
const InsightsPage = withLoading(Insights);
const Categories = withLoading(CategoriesPage);
const CategoriesDetailsPage = withLoading(CategoriesDetails);
const CatMarkSubCatDetailsPage = withLoading(CatMarkSubCatDetails);
const SubCategoryDetailsPage = withLoading(SubCategoryDetails);
const TradeDataPage = withLoading(TradeData);
const WrappedAssignPermission = withLoading(AssignPermission);
const Videos = withLoading(VideosPage);
const AdminResources = withLoading(AdminResourcesPage);
const PagesSection = withLoading(PagesSectionPage);
const Coupons = withLoading(CouponsPage);
const MarketInsights = withLoading(MarketInsightsPage);
const Landing = withLoading(LandingPage);
const YourSubcription = withLoading(YourSubcriptionPage);
// const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const AdvancedForm = withLoading(AdvancedFormsPage);
const CompetitorDataPage = withLoading(CompetitorData);
// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

// const DataTables = withLoading(DataTablesPage);
const ExportVideo = withLoading(ExportVideoPage);
const CompetitorReviews = withLoading(CompetitorReviewsPage);
const AdvisoryService = withLoading(AdvisoryServicePage);
const Resources = withLoading(ResourcesPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);
// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const userDataFromLocalStorage = localStorage.getItem('user');
  const userId = userDataFromLocalStorage ? JSON.parse(userDataFromLocalStorage)?.id : null;
  const [accessPage, setAccessPage] = useState<string[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          const response: AssignPermissionsResponse = await getAssignedPermissionsAPI(userId);
          setAccessPage(response?.data?.AccessPermissions?.map((e) => e.value));
        } else {
          console.error('UserID is undefined');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const user = useAppSelector((state) => state.user.user);
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );
  const protectedAdminLayout = (
    <RequireAuth>
      {user?.role === 'ADMIN' || user?.role === 'SUPERADMIN' ? <AdminLayoutPage /> : <Error404Page />}
    </RequireAuth>
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/landing" element={<Landing />} />
        <Route path="/" element={<Navigate to={LANDING_PAGE} />} />
        <Route path="*" element={<Error404Page />} />
        <Route path="/transactionsuccessful/:id" element={<Successtransaction />} />
        {/* <Route path={YOUR_SUBSCRIPTION} element={protectedLayout}> */}
        <Route path={ADMIN} element={protectedAdminLayout}>
          <Route index element={<Users />} />
          <Route path="users" element={<Users />} />
          <Route
            path="superadminpanel"
            element={user?.role === 'SUPERADMIN' ? <SuperAdminPanels /> : <Error404Page />}
          />
          <Route path="markets" element={<Markets />}></Route>
          <Route path="markets/:id" element={<MarketsDetailsPage />} />
          <Route path="markets/:id/:insights" element={<InsightsPage />} />

          <Route path="categories" element={<Categories />} />
          <Route path="categories/:id" element={<CategoriesDetailsPage />} />
          <Route path="categories/:id/:marketId" element={<CatMarkSubCatDetailsPage />} />
          <Route path="categories/:id/:marketId/:subcatId" element={<SubCategoryDetailsPage />} />
          <Route path="categories/:id/:marketId/:subcatId/tradeData" element={<TradeDataPage />} />
          <Route path="categories/:id/:marketId/:subcatId/competitordata" element={<CompetitorDataPage />} />
          <Route path="categories/:id/:marketId/:subcatId/categorydata" element={<CategoryDataDetails />} />
          <Route path="categories/:id/categoriesDetailsPage/:marketId" element={<CatMarkSubCatDetailsPage />} />
          {/* <Route path="/:id" element={<CatMarkSubCatDetails />} /> */}
          <Route path="videos" element={<Videos />} />
          <Route path="resources" element={<AdminResources />} />
          <Route path="pages" element={<PagesSection />} />
          <Route path="coupons" element={<Coupons />} />
          <Route path="allpermission/:id" element={<WrappedAssignPermission />} />
        </Route>
        <Route path={DASHBOARD} element={protectedLayout}>
          {/* <Route index element={<NftDashboard />} /> */}
          <Route index element={<DashboardPage />} />
          {!accessPage?.includes('your-subscriptions') && (
            <Route path="your-subscriptions" element={<YourSubcription />} />
          )}
          {/* <Route path="TradeDetailsPage" element={<TradePage />} /> */}
          {!accessPage?.includes('your-subscriptions') && (
            <Route path="your-subscriptions/TradeDetailsPage" element={<TradePage />} />
          )}

          {!accessPage?.includes('market-insights') && <Route path="market-insights" element={<MarketInsights />} />}
          <Route path="apps">
            <Route path="feed" element={<NewsFeed />} />
          </Route>
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>
          {/* <Route path="data-tables" element={<DataTables />} /> */}
          {!accessPage?.includes('export-video') && <Route path="export-video" element={<ExportVideo />} />}
          {!accessPage?.includes('competitor-reviews') && (
            <Route path="competitor-reviews" element={<CompetitorReviews />} />
          )}
          {!accessPage?.includes('advisory-service') && <Route path="advisory-service" element={<AdvisoryService />} />}
          {!accessPage?.includes('resources') && <Route path="resources" element={<Resources />} />}
          <Route path="maps">
            <Route path="google-maps" element={<Google />} />
            <Route path="leaflet-maps" element={<Leaflet />} />
            <Route path="react-simple-maps" element={<ReactSimple />} />
            <Route path="pigeon-maps" element={<Pigeons />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            {user?.role === 'SUPERADMIN' && <Route path="logo-change" element={<LogoChangeSelectionTab />} />}
            <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} />
          </Route>
          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="varifymail" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
