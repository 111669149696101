/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';
interface response {
  status?: boolean;
  message?: string;
  data?: [];
}
// interface data {
//   email: string;
//   password: string;
// }
export const ViewUsers = (): Promise<response> => {
  return httpApi.get<response>(`/user`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
export const MakeAdmin = (id: string, status: any): Promise<response> => {
  return httpApi.put<response>(`/user/${id}/userToAdmin`, status).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
export const MakeAdminAvocado = (id: string, status: any): Promise<response> => {
  return httpApi.put<response>(`/user/${id}/userToAdminAvocado`, status).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
export const LoginUser = (id: string, data: any): Promise<any> => {
  return httpApi.post<any>(`/user/${id}/newUserLogin`, data).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
