import { httpApi } from '@app/api/http.api';
interface response {
  status: boolean;
  message: string;
  data?: marketAccess;
}
interface marketAccess {
  key_regulators: string;
  import_procedure: string;
  documentation_required: string;
  certificates_required: string;
  labelling_requirements: string;
  packaging_requirements: string;
  tariffs_levied: string;
  non_tarrif_barriers: string;
}
export const GetMarketAccessReq = (id: string): Promise<response> => {
  return httpApi.get<response>(`/marketInsightsMar/${id}`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
