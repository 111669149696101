import React from 'react';
import UsersTabsPage from './component/Tabs/UsersTabPage';
import { Userpage } from './UsersPage.styles';

const UsersPage: React.FC = () => {
  const desktopLayout = (
    <>
      <UsersTabsPage />
    </>
  );

  //   const mobileAndTabletLayout = (
  //     <BaseRow gutter={[20, 24]}>
  //       <BaseCol span={24}>
  //         <TrendingCreators />
  //       </BaseCol>

  //       <BaseCol span={24}>
  //         <RecentlyAddedNft />
  //       </BaseCol>

  //       <BaseCol span={24}>
  //         <TrendingCollections />
  //       </BaseCol>

  //       <BaseCol span={24}>
  //         <RecentActivity />
  //       </BaseCol>
  //     </BaseRow>
  //   );

  return (
    <Userpage>
      {desktopLayout}
      {/* {isDesktop ? desktopLayout : mobileAndTabletLayout} */}
    </Userpage>
  );
};

export default UsersPage;
