import React, { useEffect, useState } from 'react';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { useNavigate } from 'react-router-dom';
import { readUser } from '@app/services/localStorage.service';
import { assignPagePermission } from '@app/api/permissions/AllPagePermisson.api';
import { Badge } from 'antd';
import { IoCartOutline } from 'react-icons/io5';
import { useAppSelector } from '@app/hooks/reduxHooks';
import Model from '@app/components/Model';
import ProductCard from '@app/components/landingPage/Addtocart/addToCart';
import { selectCartItems } from '@app/store/slices/addToCartSlice';
import ContactUs from '@app/pages/ContactUs/ContactUs';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}
interface ModalState {
  isModalOpen: boolean;
  isModalOpenCart: boolean;
}
export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const cartItems = useAppSelector(selectCartItems);
  const cart = localStorage.getItem('subscribeplan');
  const subscribeplan = cart ? JSON.parse(cart) : null;
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, isModalOpenCart: false });
  const { isModalOpen, isModalOpenCart } = modalState;
  // const assignPage = async () => {
  //   if (user?.id) {
  //     try {
  //       const type = 1;
  //       const PageData = await assignPagePermission(user?.id, type);
  //       localStorage.setItem('permissions', JSON.stringify(PageData.data));
  //     } catch (error) {}
  //   }
  // };
  // useEffect(() => {
  //   assignPage();
  // }, []);
  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true, isModalOpenCart: false });
  };
  const showModalCart = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: true });
  };
  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };
  const product = { id: cartItems.length + 1, name: 'dsdf', price: 99 };
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={12} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          {/* <HeaderSearch /> */}
        </BaseCol>
        {/* <BaseCol>
          <S.GHButton />
        </BaseCol> */}
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={10} xxl={8}>
        {/* <HeaderSearch /> */}
      </BaseCol>
      {/* <BaseCol>
        <S.GHButton />
      </BaseCol> */}
    </>
  );

  return (
    <BaseRow justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={12} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[5, 5]}>
          <BaseCol>
            <BaseRow style={{ alignItems: 'end' }}>
              {/* <BaseCol>
                <ThemePicker />
              </BaseCol> */}
              <BaseCol>
                <BasePopover>
                  <BaseButton onClick={showModal}>
                    <S.HeaderActionWrapper>Contact Us</S.HeaderActionWrapper>
                  </BaseButton>
                </BasePopover>
              </BaseCol>
              <BaseCol style={{ marginLeft: '10px' }}>
                <BasePopover>
                  <BaseButton onClick={() => navigate('/')}>
                    <S.HeaderActionWrapper>Home</S.HeaderActionWrapper>
                  </BaseButton>
                </BasePopover>
              </BaseCol>
              {/* <BaseCol>
                <HeaderFullscreen />
              </BaseCol>

              <BaseCol>
                <NotificationsDropdown />
              </BaseCol> */}

              <BaseCol style={{ marginLeft: '30px' }}>
                <SettingsDropdown />
              </BaseCol>
              {user && (
                <BaseCol>
                  <Badge style={{ marginLeft: '30px' }} color="#00B4A0" count={subscribeplan?.length} showZero>
                    <IoCartOutline onClick={showModalCart} fontSize="30px" />
                  </Badge>
                </BaseCol>
              )}
            </BaseRow>
          </BaseCol>
          {/* 
          <BaseCol>
            <ProfileDropdown />
          </BaseCol> */}
        </BaseRow>
      </S.ProfileColumn>
      <Model
        title="Contact"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<ContactUs handleCancel={handleCancel} />}
      />
      <Model
        title="Cart"
        open={isModalOpenCart}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<ProductCard product={product} handleClose={handleCancel} handleOpen={showModalCart} />}
      />
    </BaseRow>
  );
};
