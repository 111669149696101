import React, { useEffect, useState } from 'react';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Option } from 'antd/lib/mentions';
import { GetCategoryType } from '@app/api/categories/GetCategoryType.api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as S from '../Categories/CategoriesPage.styles';
import { useTranslation } from 'react-i18next';
import { CategoryUpdate } from '@app/api/categories/CategoryDetails/CategoryUpdate.api';
import { notificationController } from '@app/controllers/notificationController';
import { Form } from 'antd';

interface categoryType {
  _id: string;
  name: string;
  category_type_id: string;
  status: boolean;
}

const CategoriesEdit: React.FC = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [categoryTypeData, setCategoryTypeData] = useState<categoryType[]>([]);
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const categoryPermissions =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Categories');
  const defaultCategory = categoryTypeData?.find((e) => e?._id === state?.category_type_id);
  const initValue = {
    name: state.name,
    status: state.status,
  };

  useEffect(() => {
    const getCategoryType = async () => {
      const response = await GetCategoryType();
      setCategoryTypeData(response?.data as []);
    };
    getCategoryType();
  }, []);
  const handleSubmit = async (values: categoryType) => {
    setLoading(true);
    try {
      // Simulate a delay, replace this with your actual API call
      const EditCategoryData = {
        category_type_id: values.category_type_id,
        name: values.name,
        status: values.status,
      };
      // Assuming ChangePassword is an asynchronous function, use await here
      await CategoryUpdate(id as string, EditCategoryData);
      notificationController.success({ message: t('common.success') });
      navigate('/admin/categories', { state: { update: id } });
    } catch (error) {
      console.error('Error while adding market.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setLoading(false);
    }
  };

  const category_type_id = Form.useWatch('category_type_id', form);
  const name = Form.useWatch('name', form);
  const status = Form.useWatch('status', form);
  const checkDefaultvalue =
    category_type_id === defaultCategory?._id && name === state.name && status === state.status ? true : false;

  return (
    <S.CategoryDetailsWrap>
      <div
        style={{
          padding: '20px',
          boxShadow: '0px 0px 15px 1px rgba(35, 78, 84, 0.61)',
          borderRadius: '5px',
          margin: '2rem auto',
          paddingBottom: '1px',
        }}
      >
        <BaseForm
          form={form}
          initialValues={initValue}
          onFinish={handleSubmit}
          disabled={!categoryPermissions?.permissions?.edit_update_categories}
        >
          <Auth.FormItem
            name="name"
            label="Category Name"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder="Category Name" />
          </Auth.FormItem>
          {defaultCategory && (
            <Auth.FormItem
              name="category_type_id"
              label={t('common.category-type')}
              rules={[{ required: true, message: t('common.requiredField') }]}
              initialValue={defaultCategory?._id}
            >
              <Auth.FormBaseSelect placeholder="Category" defaultValue={defaultCategory?.name}>
                {categoryTypeData?.map((e) => (
                  <Option value={e._id} key={e._id}>
                    {e.name}
                  </Option>
                ))}
              </Auth.FormBaseSelect>
            </Auth.FormItem>
          )}
          <div
            style={{
              border: '1px solid rgba(67, 115, 109, 0.82)',
              borderRadius: '8px',
              padding: '10px',
              marginBottom: '10px',
            }}
          >
            <div style={{ display: 'flex', padding: '12px 0px' }}>
              <BaseForm.Item name="status" valuePropName="checked" noStyle>
                <Auth.FormCheckbox>
                  <Auth.Text>Active</Auth.Text>
                </Auth.FormCheckbox>
              </BaseForm.Item>
            </div>

            {!Form.useWatch('status', form) && (
              <p style={{ background: '#dbdbdb5c', padding: '10px', borderRadius: '4px' }}>
                Category must be activated before it is visible user-side. Add at least one category market with one
                subcategory before activating.
              </p>
            )}
          </div>

          <BaseForm.Item style={{ marginBottom: '2rem' }}>
            <Auth.SubmitButton disabled={checkDefaultvalue || loading} type="primary" htmlType="submit">
              {t('common.submit')}
            </Auth.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      </div>
    </S.CategoryDetailsWrap>
  );
};

export default CategoriesEdit;
