import { httpApi } from '@app/api/http.api';
interface response {
  membership_active(membership_active: any): unknown;
  status: boolean;
  message: string;
  data: Subscription[];
}

interface Subscription {
  _id: string;
  category_id: any;
  market_id: any;
  cat_market_sabcat_id: any;
  matching_subcategory: any;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export const GetSubscriptionBasePlan = (userId: string, name: string): Promise<response> => {
  return httpApi.get<response>(`/subscription/getMembershipPlane/${userId}/${name}`).then(({ data }) => data);
};
// This API is fetching all the Resource Modules(Execute,Examine, etc) and all the Resources in that folder
