/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Model from '@app/components/Model';
import * as S from '../../CategoriesPage.styles';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddCatMarkSubCatForm from './AddCatMarkSubCatForm';
import { DeleteOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ListWrapper } from '@app/pages/Admin/Markets/MarketsPage.styles';
import { GetCatMarketSubCategory } from '@app/api/categories/CategoryMarkets/CategoryMarketSubCategory/GetCatMarketSubCategory.api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { AddCategoryMarkets } from '@app/api/categories/CategoryMarkets/AddCategoryMarkets.api';
import { Form } from 'antd';
import { DeleteCatMarkSubCategory } from '@app/api/categories/CategoryMarkets/CategoryMarketSubCategory/DeleteCatMarkSubCategory.api';
import { GetCategoryMarkets } from '@app/api/categories/CategoryMarkets/GetCategoryMarkets.api';

interface ShowModel {
  name: string;
  _id: string;
}
interface ModalState {
  isModalOpen: boolean;
  name?: string;
  _id?: string;
}

interface folderData {
  sub_category_name: string;
  sub_cat_id: string;
  category_id: string;
  category_name: string;
  createdAt: Date;
  market_id: string;
  market_name: string;
  updatedAt: Date;
  _id: string;
}
interface marketData {
  status: boolean;
  category_id: string;
  createdAt: Date;
  market_id: string;
  market_name: string;
  updatedAt: Date;
  _id: string;
}

interface values {
  status: boolean;
}

interface errorMessage {
  message: string;
}

const CatMarkSubCatData = (): JSX.Element => {
  const [isAddCatMarketModalOpen, SetIsAddCatMarketModalOpen] = useState(false);

  const [folders, setFolders] = useState<folderData[]>([]);
  const [marketData, setMarketData] = useState<marketData[]>();
  const [marketLoad, setMarketLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const { t } = useTranslation();
  const { id, marketId } = useParams();
  const { state } = useLocation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const { isModalOpen } = modalState;
  const showModal = ({ _id, name }: ShowModel) => {
    setModalState({ ...modalState, isModalOpen: true, _id, name });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };
  const deleteAlert = ({ _id, name }: ShowModel) => {
    showModal({ _id, name });
  };

  const handleAddCategoryMarket = async () => {
    SetIsAddCatMarketModalOpen(true);
  };
  const handleAddCatMarkOk = () => {
    SetIsAddCatMarketModalOpen(false);
  };
  const handleAddCatMarkCancel = () => {
    SetIsAddCatMarketModalOpen(false);
  };

  // to get and Map sub cats
  const fetchCatmarketSubCategoryData = async () => {
    setLoading(true);
    try {
      const response = await GetCatMarketSubCategory(state.category_id, state.market_id);
      response?.data?.length >= 1 && setFolders(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchCatMarkData = async () => {
    setMarketLoad(true);
    try {
      const response = await GetCategoryMarkets(id as string);
      response?.data?.length >= 1 && setMarketData(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setMarketLoad(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCatmarketSubCategoryData();
    fetchCatMarkData();
  }, []);

  const handleSubmit = async (values: values) => {
    setStatusLoading(true);
    try {
      const submitData = {
        market_id: state.market_id,
        _id: state._id,
        status: values.status,
      };

      // this is to manage and update the Active status of catgeory market
      await AddCategoryMarkets(state.category_id, submitData);
      notificationController.success({ message: t('common.success') });
      fetchCatMarkData();
    } catch (error) {
      console.error(error);
    } finally {
      setStatusLoading(false);
    }
  };
  const handleDeleteCategory = async () => {
    setDelLoading(true);
    try {
      await DeleteCatMarkSubCategory(modalState._id as string);
      notificationController.success({ message: t('common.success') });
      const updatedFolders = folders.filter((folder) => folder?._id !== modalState._id); // Filter out the deleted item
      setFolders(updatedFolders);
      if (updatedFolders.length === 0) {
        // If no items left, reset folders array to an empty array
        setFolders([]);
      }
      fetchCatmarketSubCategoryData();
      handleCancel();
    } catch (error) {
      console.error('Error deleting category market:', error);
      notificationController.error(error as errorMessage);
    } finally {
      setDelLoading(false);
    }
  };

  const DeleteMsg = () => (
    <div>
      <div>Are you sure you want to delete {modalState.name}?</div>
      <S.ButtonContainer>
        <S.Button disabled={delLoading} onClick={handleCancel}>
          Cancel
        </S.Button>
        <S.Button disabled={delLoading} type="primary" onClick={handleDeleteCategory} loading={delLoading}>
          Submit
        </S.Button>
      </S.ButtonContainer>
    </div>
  );

  const navigateToDifferentRoute = (element: folderData) => {
    navigate(`${pathname}/${element.sub_cat_id}`, { state: element });
  };
  const status = Form.useWatch('status', form);
  const disableButton = marketData?.find((e) => e?.market_id === marketId)?.status === status;
  return (
    <>
      <S.CategoryMarket>
        <div
          style={{
            display: 'flex',
            marginBottom: '10px',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{state.market_name}</p>
          {!marketLoad && (
            <BaseForm
              onFinish={handleSubmit}
              form={form}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '14px',
                border: '1px solid #43736dd1',
                margin: '10px',
                borderRadius: '8px',
                flexDirection: 'column',
                minWidth: '100%',
                paddingBottom: '0px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <BaseForm.Item
                  name="status"
                  valuePropName="checked"
                  initialValue={marketData?.find((e) => e?.market_id === marketId)?.status}
                >
                  <Auth.FormCheckbox onChange={(e) => setCheckboxChecked(e.target.checked)}>
                    <Auth.Text>Active</Auth.Text>
                  </Auth.FormCheckbox>
                </BaseForm.Item>
                <div>
                  <Auth.SubmitButton
                    type="primary"
                    disabled={disableButton || statusLoading}
                    loading={statusLoading}
                    htmlType="submit"
                  >
                    Save
                  </Auth.SubmitButton>
                </div>
              </div>

              {!status && (
                <p style={{ background: 'rgba(219, 219, 219, 0.36)', padding: '10px', borderRadius: '4px' }}>
                  Category market must be activated before it is visible user-side. Add at least one subcategory before
                  activating.
                </p>
              )}
            </BaseForm>
          )}

          <S.Button onClick={handleAddCategoryMarket}>ADD SUBCATEGORY</S.Button>
        </div>

        <div>
          <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Subcategories</p>
          {!loading ? (
            <div>
              {folders?.map((element: folderData) => (
                <ListWrapper
                  style={{
                    display: 'flex',
                    boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 15px 1px',
                    margin: '10px 0px',
                    borderRadius: '3px',
                  }}
                  key={element?._id}
                >
                  <div className="hoverDiv" onClick={() => navigateToDifferentRoute(element)} key={element._id}>
                    <S.BaseRowBox gutter={[16, 16]}>
                      <div>{element?.sub_category_name}</div>
                    </S.BaseRowBox>
                  </div>
                  <BaseButton
                    style={{ margin: '7px 10px' }}
                    onClick={() => deleteAlert({ _id: element._id, name: element?.sub_category_name as string })}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </ListWrapper>
              ))}
              {folders?.length === 0 && <p style={{ color: 'rgb(211, 125, 12)' }}>No subcategories added</p>}
            </div>
          ) : (
            <S.CustomSpin />
          )}
        </div>

        <Model
          title="Delete Category"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          content={<DeleteMsg />}
        />

        <Model
          title="Add Subcategory"
          open={isAddCatMarketModalOpen}
          onOk={handleAddCatMarkOk}
          onCancel={handleAddCatMarkCancel}
          content={
            <AddCatMarkSubCatForm
              handleOk={handleAddCatMarkOk}
              handleCancel={handleAddCatMarkCancel}
              catID={state.category_id}
              marketID={state.market_id}
              fetchData={fetchCatmarketSubCategoryData}
              existingData={folders}
            />
          }
        />
      </S.CategoryMarket>
    </>
  );
};

export default CatMarkSubCatData;
