import { httpApi } from '@app/api/http.api';
interface response {
  data: categoryMarketsSubcatgory[];
}
interface categoryMarketsSubcatgory {
  sub_category_name: string;
  sub_cat_id: string;
  category_id: string;
  category_name: string;
  market_id: string;
  market_name: string;
  updatedAt: Date;
  _id: string;
  createdAt: Date;
}

export const GetCatMarketSubCategory = (category_id: string, market_id: string): Promise<response> => {
  return httpApi
    .get<response>(`/categories/market/${category_id}/${market_id}/marketSubCatList`)
    .then(({ data }) => data);

  // This API is fetching markest data for Category > category markets > market sub categories
};
export const GetCatMarketSubCategoryDasboard = (market_id: string, category_id: string): Promise<response> => {
  return httpApi
    .get<response>(`/categories/market/${category_id}/${market_id}/marketSubCatList`)
    .then(({ data }) => data);

  // This API is fetching markest data for Category > category markets > market sub categories
};

// http://localhost:3000/localhost/categories/market/65f294d8a85b1949f49ce97e/660e399ebacb0f3fd474c39c/marketSubCatList
