import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { GetRef, InputRef } from 'antd';
import { Button, DatePicker, Form, Input, Modal, Table } from 'antd';
import '../../../../../../../assets/common.css';
import { DeleteOutlined, FilterFilled } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useLocation, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { Loading } from '@app/components/common/Loading/Loading';
import Model from '@app/components/Model';
import AddFromCsvModel from '../AddFromCsvModel/AddFromCsvModel';
import { AddCompetitorData } from '@app/api/categories/Competitor Data/AddCompetitorData.api';
import { GetCompetitorData } from '@app/api/categories/Competitor Data/GetCompetitorData.api';
import { DeleteCompetitorData } from '@app/api/categories/Competitor Data/DeleteCompetitorData.api';
import { TbCameraPlus } from 'react-icons/tb';
import UploadImage from './UploadImage';
import { Image } from 'antd';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { deleteImage } from '@app/api/categories/Competitor Data/ImageUpload.api';
import moment from 'moment';
import currenciesList from '../CompetitorData/currencies.json';
type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}
const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [dateValue, setDateValue] = useState<string | null>(record && record[dataIndex]); // State to hold the date value

  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  const handleBlur = () => {
    save();
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: false,
            message: `${title} is required.`,
          },
        ]}
      >
        {dataIndex === 'date' ? ( // Render date picker if dataIndex is 'date'
          <DatePicker
            ref={inputRef}
            onBlur={handleBlur}
            value={dateValue ? moment(dateValue) : null}
            onChange={(date, dateString) => setDateValue(dateString)}
          />
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={handleBlur} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          padding: '6px',
          height: '100%',
          // minWidth: '300px',
          fontSize: '13px',
          // width: 'auto',
          // background: '#e0eaf5',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  outlet: string;
  product_name: string;
  type: string;
  weight: string;
  price: string;
  country_manufacture: string;
  product_claim: string;
  date: string;
  _id: string;
}
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
interface formData {
  outlet: string;
  product_name: string;
  type: string;
  weight: string;
  price: string;
  country_manufacture: string;
  product_claim: string;
  date: string;
}
interface props {
  tradeFormData: formData;
}
interface ModalState {
  isModalOpen: boolean;
  isImageModel: boolean;
}

const CompetitorData = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { id, marketId, subcatId } = useParams();
  const [btnLoading, setBtnLoading] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [DeletebtnLoading, setDeleteBtnLoading] = useState(false);
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, isImageModel: false });
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [initialData, setInitialData] = useState<DataType[]>([]);
  const [searchQuery, setsearchQuery] = useState('');

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true, isImageModel: false });
  };
  const showModalNew = () => {
    setModalState({ ...modalState, isModalOpen: false, isImageModel: true });
  };
  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  const handleOkNew = () => {
    setModalState({ ...modalState, isModalOpen: false, isImageModel: true });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, isImageModel: false });
  };

  // function to get currency according to Country(category market) and show it in price local column
  const countrycurrency = () => {
    const currencies = currenciesList.find((item) => item.country === state.market_name)?.currency_code;

    return currencies;
    // setCurrency(currencies)
  };
  useEffect(() => {
    countrycurrency();
  }, []);
  // console.log(currenciesList.find((item) => item.country === state.market_name)?.currency_code);

  const [newselectedRows, setSelectedRows] = useState<DataType[]>([]);
  const [count, setCount] = useState(2);
  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Outlet',
      dataIndex: 'outlet',
      width: 10,
      editable: true,
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => {
        return JSON.stringify(initialData) === JSON.stringify(dataSource) && a.outlet.localeCompare(b.outlet);
      },
      sortDirections: JSON.stringify(initialData) === JSON.stringify(dataSource) ? ['ascend', 'descend'] : [],
    },
    {
      title: 'Product name',
      dataIndex: 'product_name',
      width: 30,
      editable: true,
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => {
        return (
          JSON.stringify(initialData) === JSON.stringify(dataSource) && a.product_name.localeCompare(b.product_name)
        );
      },
      sortDirections: JSON.stringify(initialData) === JSON.stringify(dataSource) ? ['ascend', 'descend'] : [],
    },
    {
      title: 'Product image',
      dataIndex: 'product_image',
      width: 10,
      editable: false,
      render: (image, data: any) => {
        return (
          <div style={{ display: 'inline-grid', justifyItems: 'center', padding: '10px' }}>
            {image?.product_image && (
              <>
                <Image width={50} src={image?.product_image} style={{ marginBottom: '10px' }} />
                <BaseButton
                  style={{ border: 'none', backgroundColor: 'inherit', cursor: 'pointer' }}
                  onClick={async () => {
                    setdeleteLoading(true);
                    await deleteImage(id || '', marketId || '', subcatId || '', image?.image_id || '');
                    fetchTableData();
                    setdeleteLoading(false);
                  }}
                  loading={deleteLoading}
                >
                  <RiDeleteBin6Fill />
                </BaseButton>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 10,
      editable: true,
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => {
        return JSON.stringify(initialData) === JSON.stringify(dataSource) && a.type.localeCompare(b.type);
      },
      sortDirections: JSON.stringify(initialData) === JSON.stringify(dataSource) ? ['ascend', 'descend'] : [],
    },
    {
      title: 'Weight(g/ml)',
      dataIndex: 'weight',
      width: '20px',
      editable: true,
      sorter: (a, b) => {
        return JSON.stringify(initialData) === JSON.stringify(dataSource) && a.weight - b.outlet;
      },
      sortDirections: JSON.stringify(initialData) === JSON.stringify(dataSource) ? ['ascend', 'descend'] : [],
    },
    {
      title: `Price local ${countrycurrency()}`,
      dataIndex: 'price',
      width: 5,
      editable: true,
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => {
        return JSON.stringify(initialData) === JSON.stringify(dataSource) && a.price - b.price;
      },
      sortDirections: JSON.stringify(initialData) === JSON.stringify(dataSource) ? ['ascend', 'descend'] : [],
    },
    {
      title: 'Country of manufacture',
      dataIndex: 'country_manufacture',
      width: 8,
      editable: true,
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => {
        return (
          JSON.stringify(initialData) === JSON.stringify(dataSource) &&
          a.country_manufacture.localeCompare(b.country_manufacture)
        );
      },
      sortDirections: JSON.stringify(initialData) === JSON.stringify(dataSource) ? ['ascend', 'descend'] : [],
    },
    {
      title: 'Product claims',
      dataIndex: 'product_claim',
      editable: true,
      width: 200,
    },
    {
      title: 'Date sourced',
      dataIndex: 'date',
      width: 180,
      editable: true,
      showSorterTooltip: { target: 'full-header' },
      render: (item) => {
        return item && moment(item).format('DD MMMM YYYY');
      },

      sorter: (a, b) => {
        if (JSON.stringify(initialData) === JSON.stringify(dataSource)) {
          if (moment(a.date).isBefore(b.date)) {
            return -1;
          }
          if (moment(a.date).isAfter(b.date)) {
            return 1;
          }
          return 0;
        } else {
          return 0;
        }
      },
      sortDirections: JSON.stringify(initialData) === JSON.stringify(dataSource) ? ['ascend', 'descend'] : [],
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[] | undefined) => {
      if (selectedRows && Array.isArray(selectedRows)) {
        setSelectedRows(selectedRows);
      } else {
        console.error('selectedRows is not an array:', selectedRows);
      }
    },
  };

  const fetchTableData = async () => {
    try {
      const response = await GetCompetitorData(id as string, marketId as string, subcatId as string);
      // Assign _id as key for each item in response.data
      const dataWithKeys = response?.data?.map((item: DataType) => ({ ...item, key: item?._id }));

      // Convert date strings to moment objects
      const dataWithMomentDates = dataWithKeys?.map((item) => ({
        ...item,
        date: item.date ? moment(item.date) : null,
      }));
      setDataSource(dataWithMomentDates);
      setInitialData(dataWithMomentDates);
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    fetchTableData();
  }, []);

  const handleDelete = async (selectedRows: DataType[]) => {
    setDeleteBtnLoading(true);
    if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
      console.log('No rows selected for deletion');
      return;
    }

    // Separate saved rows (with _id) and unsaved rows
    const savedRows: DataType[] = [];
    const unsavedRows: DataType[] = [];
    selectedRows.forEach((row) => {
      if (row._id) {
        savedRows.push(row);
      } else {
        unsavedRows.push(row);
      }
    });

    // Delete saved rows via API
    if (savedRows.length > 0) {
      const DeletedIds = savedRows.map((item) => ({ _id: item._id }));
      try {
        await DeleteCompetitorData(id as string, marketId as string, subcatId as string, DeletedIds);
      } catch (error) {
        notificationController.error(error);
      } finally {
        setDeleteBtnLoading(false);
      }
    }

    // Remove selected rows from the data source array
    const updatedDataSource = dataSource.filter(
      (item) => !selectedRows.some((selectedItem) => selectedItem.key === item.key),
    );

    // Update state
    setDataSource(updatedDataSource);
    setDeleteBtnLoading(false);

    // Handle unsaved changes (if any)
    if (unsavedRows.length > 0) {
      console.log('Handling unsaved changes:', unsavedRows);
      // Handle unsaved changes (e.g., show a message to the user)
    }
  };

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      outlet: '',
      product_name: '',
      type: '',
      weight: '',
      price: '',
      country_manufacture: '',
      product_claim: '',
      date: '',
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const handleSaveTest = async () => {
    setBtnLoading(true);
    try {
      const newData = dataSource.map((e) => ({
        ...e,
        date: moment(e.date, 'YYYY-MM-DD'), // Formatting date with moment.js
      }));

      await AddCompetitorData(id as string, marketId as string, subcatId as string, newData);
      notificationController.success({ message: t('common.success') });
      fetchTableData();
    } catch (error) {
      notificationController.error(error);
    } finally {
      setBtnLoading(false);
    }
  };

  const handlerFilterSearch = (searchInput) => {
    setsearchQuery(searchInput);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  console.log('dataSource', dataSource);
  console.log('initialData', initialData);
  return (
    <div style={{ padding: '20px 10px', background: '#F5F5F5' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#f5f5f5',
          padding: '18px',
          borderBottom: '1px solid #b3cbe1',
          borderTop: '1px solid #b3cbe1',
        }}
      >
        {' '}
        <p>Products</p>
        <Input
          style={{ width: '30%' }}
          placeholder="Name filter"
          onChange={(e) => {
            handlerFilterSearch(e.target.value);
          }}
        ></Input>
        <BaseButton
          onClick={() => {
            showModal();
          }}
          type="text"
        >
          ADD FROM CSV
        </BaseButton>
      </div>
      <Table
        loading={Loading}
        // rowKey={(params) => console.log(params._id)}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource.filter((e) => e.product_name.toLowerCase().includes(searchQuery.toLowerCase()))}
        pagination={false}
        columns={columns as ColumnTypes}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#f5f5f5',
          padding: '18px',
          borderBottom: '1px solid #b3cbe1',
          borderRight: '1px solid #b3cbe1',
          borderLeft: '1px solid #b3cbe1',
        }}
      >
        <BaseButton
          onClick={() => handleDelete(newselectedRows)}
          loading={DeletebtnLoading}
          disabled={DeletebtnLoading || btnLoading || newselectedRows.length === 0}
        >
          <DeleteOutlined style={{ fontSize: '22px' }} />
        </BaseButton>
        <BaseButton
          style={{ marginLeft: '10px' }}
          disabled={newselectedRows.length === 0}
          onClick={() => {
            showModalNew();
          }}
        >
          <TbCameraPlus style={{ fontSize: '22px' }} />
        </BaseButton>

        <Button onClick={handleAdd} className="addrowBtnTable">
          Add a row
        </Button>

        <Button onClick={handleSaveTest} disabled={DeletebtnLoading || btnLoading} loading={btnLoading}>
          Save
        </Button>
      </div>
      <Model
        title="Input CSV"
        open={modalState.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<AddFromCsvModel type="competitor" />}
      />
      <Model
        title="Upload images"
        open={modalState.isImageModel}
        onOk={handleOkNew}
        onCancel={handleCancel}
        content={
          <UploadImage
            newselectedRows={newselectedRows}
            state={state}
            fetch={fetchTableData}
            modelClose={handleCancel}
          />
        }
      />
    </div>
  );
};

export default CompetitorData;
