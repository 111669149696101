/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { DeltaStatic } from 'quill';
import { TextAreaQuill } from '@app/pages/Admin/Categories/CategoriesPage.styles';
import { useTranslation } from 'react-i18next';
import { AddCategoryContent } from '@app/api/categories/CategoryContents/AddCategoryContent.api';
import { Input } from 'antd';
import { useParams } from 'react-router-dom';

interface Props {
  handleOk: () => void;
  type: string;
  title: string;
  description: string;
  fetchData: () => void;
  contentID: string;
}

interface formData {
  _id?: string;
  title: string;
  description: string;
}

const CategoryContentsQuill = ({ handleOk, type, title, description, fetchData, contentID }: Props): JSX.Element => {
  const modules = {
    toolbar: [['bold', 'italic', 'underline'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]],
  };
  const { id, marketId, subcatId } = useParams();
  const [value, setValue] = useState(description); // State for editor content
  const [html, setHtml] = useState(description);
  const quillRef = useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [ContentTitle, setContentTitle] = useState(title);

  const handleChange = (content: string, _delta: DeltaStatic, source: string) => {
    setValue(content); // Update state with the latest content (text or HTML)
    if (source === 'user') setHtml(content);
  };
  // Function to handle HTML input and update both editor and state
  const handleHtmlChange = (e: string) => {
    console.log('html content', e);
    setHtml(e);
    setValue(e);
  };
  console.log('title ===>', title, 'description==>', description);
  // To Post-Put category contents data

  const handleAdd = async () => {
    setLoading(true);
    const editFormData: formData = {
      _id: contentID,
      title: ContentTitle,
      description: value,
    };
    const addFormData: formData = {
      title: ContentTitle,
      description: value,
    };
    try {
      await AddCategoryContent(
        id as string,
        marketId as string,
        subcatId as string,
        type === 'Add' ? addFormData : editFormData,
      );
      notificationController.success({ message: t('common.success') });

      fetchData();
      handleOk();
      setHtml('');
      setValue('');
      setContentTitle('');
    } catch (error) {
      notificationController.error({ message: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const handleReset = () => {
    setValue(description ?? '');
    setHtml(description ?? '');
    setContentTitle(title);
  };

  useEffect(() => {
    setContentTitle(title);
    setValue(description);
    setHtml(description);
  }, [title, description]);

  return (
    <div>
      <div style={{ display: 'flex', padding: '5px', justifyContent: 'space-between', marginBottom: '15px' }}>
        <BaseButton
          disabled={(value === description && title === ContentTitle) || loading}
          severity="warning"
          onClick={() => handleReset()}
        >
          Reset
        </BaseButton>
        <BaseButton type="default">
          <a target="" href="https://www.prepostseo.com/tool/online-text-editor">
            Editor
          </a>
        </BaseButton>
        <BaseButton
          severity="success"
          disabled={loading || (value === description && title === ContentTitle)}
          loading={loading}
          onClick={handleAdd}
        >
          Apply
        </BaseButton>
      </div>
      <div style={{ margin: '10px 0px' }}>
        <Input
          value={ContentTitle}
          onChange={(titleValue) => {
            setContentTitle(titleValue.target.value);
          }}
          placeholder="Title"
        />{' '}
      </div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(value, delta, source) => handleChange(value, delta, source)}
        modules={modules}
        ref={quillRef} // Assign reference to the editor instance
      />
      <div>
        <TextAreaQuill
          value={html}
          onChange={(e) => handleHtmlChange(e.target.value)}
          autoSize={{ minRows: 2, maxRows: 5 }}
          placeholder="Enter your HTML here"
        />
      </div>
    </div>
  );
};
export default CategoryContentsQuill;
