import React from 'react';

const CompetitorIcon = ({ style }: { style: React.CSSProperties }) => {
  return (
    <div className="mx-auto" style={{ display: 'flex', justifyContent: 'center', ...style }}>
      <svg
        aria-hidden="true"
        role="presentation"
        focusable="false"
        viewBox="0 0 24 24"
        style={{ width: '29px', height: '29px' }} // Adjust size as needed
        className="q-tab__icon q-icon notranslate"
      >
        <path d="M0 0h24v24H0z" style={{ fill: 'none' }}></path>
        <path d="M21 2H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7v2H8v2h8v-2h-2v-2h7c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H3V4h18v12z"></path>
      </svg>
    </div>
  );
};

export default CompetitorIcon;
