import React, { useEffect, useState } from 'react';
import * as S from './CatagoryData.styles';
import { GetCategoryContents } from '@app/api/categories/CategoryContents/GetCategoryContents.api';
import { useParams } from 'react-router-dom';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { DeleteOutlined } from '@ant-design/icons';
import { DeleteCategoryContent } from '@app/api/categories/CategoryContents/DeleteCategoryContent.api';
import Model from '@app/components/Model';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import CategoryContentsQuill from './CategoryContentsQuill';
import CustomModal from '@app/components/Model';

interface categoryContent {
  name: string;
  _id?: string;
}

interface ModalState {
  isModalOpen: boolean;
  name?: string;
  _id?: string;
}

function CategoryContents() {
  const [contentsData, setContentsData] = useState([]);
  const { id, marketId, subcatId } = useParams();
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [contentmodalState, setContentModalState] = useState<ModalState>({ isModalOpen: false });
  const [EditcontentmodalState, setEditContentModalState] = useState<ModalState>({ isModalOpen: false });
  const { t } = useTranslation();
  const [editTile, setEditTitle] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [contentID, setContentId] = useState('');
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);

  const showModal = ({ _id, name }: categoryContent) => {
    setModalState({ ...modalState, isModalOpen: true, _id, name });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const deleteAlert = async ({ _id, name }: categoryContent) => {
    showModal({ _id, name });
  };

  const handleContentOk = () => {
    setContentModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleEditContentOk = () => {
    setEditContentModalState({ ...EditcontentmodalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleContentCancel = () => {
    setContentModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleEditContentCancel = async () => {
    await setEditContentModalState({ ...EditcontentmodalState, isModalOpen: false });
    await setEditTitle('');
    await setEditDescription('');
  };

  const showContentModal = ({ _id, name }: categoryContent) => {
    setContentModalState({ ...contentmodalState, isModalOpen: true, _id, name });
  };
  const showEditContentModal = () => {
    setEditContentModalState({ ...EditcontentmodalState, isModalOpen: true });
  };

  const fetchCategoryContent = async () => {
    setLoading(true);
    try {
      const response = await GetCategoryContents(id, marketId, subcatId);
      console.log(response);
      setContentsData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoryContent();
  }, []);

  const DeleteMsg = () => (
    <div>
      <div>
        Are you sure you want to delete <span style={{ color: '#fa7462', fontSize: '18px' }}>{modalState.name}</span> ?
      </div>
      <S.ButtonContainer>
        <S.Button onClick={handleCancel} disabled={delLoading}>
          Cancel
        </S.Button>
        <S.Button type="primary" onClick={handleDelete} disabled={delLoading} loading={delLoading}>
          Submit
        </S.Button>
      </S.ButtonContainer>
    </div>
  );
  const handleDelete = async () => {
    setDelLoading(true);
    try {
      await DeleteCategoryContent(id, marketId, subcatId, modalState._id as string);
      notificationController.success({ message: t('common.success') });
      fetchCategoryContent();
      handleCancel();
    } catch (error) {
      notificationController.error(error);
    } finally {
      setDelLoading(false);
    }
  };

  const handleEditModal = async (title, description, documentID) => {
    await setEditTitle(title);
    await setEditDescription(description);
    await showEditContentModal();
    await setContentId(documentID);
  };

  // console.log(contentsData);

  return (
    <>
      <S.CategoryDetailsWrap>
        <div
          style={{
            marginTop: '2rem',
            margin: '0 auto',
            padding: '20px',
            boxShadow: '0px 0px 15px 1px rgba(35, 78, 84, 0.61)',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {' '}
          <p> Category Contents</p>{' '}
          {!loading ? (
            <div>
              {contentsData.length > 0 ? (
                <div>
                  {contentsData.map((element) => (
                    <S.ListWrapper
                      style={{
                        display: 'flex',
                        boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 15px 1px',
                        margin: '10px 0px',
                        borderRadius: '3px',
                      }}
                      key={element._id}
                    >
                      <div
                        className="hoverDiv"
                        onClick={() => handleEditModal(element.title, element.description, element._id)}
                      >
                        <S.BaseRowBox gutter={[16, 16]}>
                          <div>{element?.title}</div>
                        </S.BaseRowBox>
                      </div>
                      <BaseButton
                        style={{ margin: '7px 10px' }}
                        onClick={() => deleteAlert({ _id: element._id, name: element.title })}
                      >
                        <DeleteOutlined />
                      </BaseButton>
                    </S.ListWrapper>
                  ))}
                </div>
              ) : (
                <div>
                  {' '}
                  <p>No category content</p>
                </div>
              )}
            </div>
          ) : (
            <S.CustomSpin />
          )}
          <div style={{ margin: '0 auto' }}>
            <S.Button disabled={loading} onClick={showContentModal}>
              Add Content
            </S.Button>
          </div>
        </div>
      </S.CategoryDetailsWrap>

      <Model
        title="Delete Content"
        open={modalState.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<DeleteMsg />}
      />

      <CustomModal
        title="Add Content"
        open={contentmodalState.isModalOpen}
        onOk={handleContentOk}
        onCancel={handleContentCancel}
        content={
          <CategoryContentsQuill
            handleOk={handleContentOk}
            type="Add"
            fetchData={fetchCategoryContent}
            title=""
            description=""
            contentID=""
          />
        }
      />

      <CustomModal
        title="Edit Content"
        open={EditcontentmodalState.isModalOpen}
        onOk={handleEditContentOk}
        onCancel={handleEditContentCancel}
        content={
          <CategoryContentsQuill
            handleOk={handleEditContentOk}
            type="edit"
            title={editTile}
            fetchData={fetchCategoryContent}
            description={editDescription}
            contentID={contentID}
          />
        }
      />
    </>
  );
}

export default CategoryContents;
