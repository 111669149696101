import styled from 'styled-components';
import { LAYOUT, media } from '@app/styles/themes/constants';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
export const Head = styled.h1`
  font-size: 30px;
  text-align: center;
  margin: 30px 10px;
  font-weight: bold;
`;
export const SubHead = styled.h2`
  font-size: 25px;
  font-weight: bold;
`;
export const Content = styled.p`
  font-size: 20px;
`;
export const YourSubscriptionbutton = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
`;
export const MainRow = styled(BaseRow)`
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  @media only screen and ${media.xl} {
    jusjustify-content: space-between;
  }
`;
export const RightSideCol = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--sider-background-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(BaseCol)`
  @media only screen and ${media.xl} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    overflow: auto;
    jus
  }
`;

export const Space = styled.div`
  margin: 1rem 0;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;
export const Dropdownpage = styled.div`
  width: 100%;
  margin: 0px auto 80px;
`;
