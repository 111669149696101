import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Form, Input, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { GetCategoryTableData } from '@app/api/categories/CategoryDataTableNEW/GetCategoryTableData.api';
import { DeleteCategoryTableData } from '@app/api/categories/CategoryDataTableNEW/DeleteCategoryTableData.api';
import { AddCategoryTableData } from '@app/api/categories/CategoryDataTableNEW/AddCategoryTableData.api';
import { GetCategoryTableFields } from '@app/api/categories/CategoryTableFields/GetCategoryTableFields.api';

type FormInstance<T> = typeof Form<T>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleBlur = () => {
    save();
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: false,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={handleBlur} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          padding: '6px',
          height: '100%',
          // fontSize: '13px',
          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'center',
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  _id: string;
  rank: string;
  imported_value: string;
  imported_quantity: string;
  annual_growth_short_term: string;
  annual_growth_long_term: string;
  annual_growth_quantity: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
interface formData {
  rank: string;
  imported_value: string;
  imported_quantity: string;
  annual_growth_short_term: string;
  annual_growth_long_term: string;
  annual_growth_quantity: string;
  year_range_short_term?: {
    from: number;
    to: number;
  };
  year_range_long_term?: {
    from: number;
    to: number;
  };
}
interface props {
  tradeFormData: formData;
  fetchFormData: () => void;
}

const CategoryDataTable = ({ columnsData, tableDataSource, fetchCatTableData, setCatTableDataSource }: props) => {
  const { t } = useTranslation();
  const { id, marketId, subcatId } = useParams();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [DeletebtnLoading, setDeleteBtnLoading] = useState(false);

  const [newselectedRows, setSelectedRows] = useState<DataType[]>([]);
  const [count, setCount] = useState(2);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[] | undefined) => {
      if (selectedRows && Array.isArray(selectedRows)) {
        setSelectedRows(selectedRows);
      } else {
        console.error('selectedRows is not an array:', selectedRows);
      }
    },
  };

  //API to get column names(Table Fields)
  // const fetchTableFieldsData = async () => {
  //   try {
  //     const response = await GetCategoryTableFields(id, marketId, subcatId);
  //     setTableFieldsData(response.data.reverse()); // Reverse the columns here
  //     console.log('tableFieldsData', response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTableFieldsData();
  // }, []);

  // APi to get table data

  // const fetchTableData = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await GetCategoryTableData(id as string, marketId as string, subcatId as string);
  //     const dataWithKeys = response?.data?.rowsData?.map((item: DataType) => ({ ...item, key: item._id }));
  //     setDataSource(dataWithKeys.reverse(``)); // Reverse the data here
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchTableData();
  // }, []);

  const handleDelete = async (selectedRows: DataType[]) => {
    setDeleteBtnLoading(true);
    if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
      console.log('No rows selected for deletion');
      return;
    }

    const savedRows: DataType[] = [];
    const unsavedRows: DataType[] = [];
    selectedRows.forEach((row) => {
      if (row._id) {
        savedRows.push(row);
      } else {
        unsavedRows.push(row);
      }
    });

    if (savedRows.length > 0) {
      const DeletedIds = savedRows.map((item) => ({ rowId: item._id }));
      try {
        await DeleteCategoryTableData(id as string, marketId as string, subcatId as string, DeletedIds);
        notificationController.success({ message: 'Row Deleted Successfully' });
      } catch (error) {
        notificationController.error(error);
      } finally {
        setDeleteBtnLoading(false);
      }
    }

    const updatedDataSource = tableDataSource.filter(
      (item) => !selectedRows.some((selectedItem) => selectedItem.key === item.key),
    );

    setCatTableDataSource(updatedDataSource);
    setSelectedRows([]);
    setDeleteBtnLoading(false);

    if (unsavedRows.length > 0) {
      console.log('Handling unsaved changes:', unsavedRows);
    }
  };

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
    };
    setCatTableDataSource([...tableDataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...tableDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setCatTableDataSource(newData);
  };

  const handleSaveTest = async () => {
    setBtnLoading(true);
    try {
      const newData: formData[] = [...tableDataSource];
      await AddCategoryTableData(id as string, marketId as string, subcatId as string, newData);
      fetchCatTableData();
      notificationController.success({ message: t('common.success') });
    } catch (error) {
      notificationController.error(error);
    } finally {
      setBtnLoading(false);
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = columnsData?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
      title: () => (
        <>
          <div>{col.lable}</div>
          <div style={{ fontSize: '12px', color: '#888' }}>({col.info})</div>
        </>
      ),
      render: (text: any) => {
        const numericValue = parseFloat(text);
        if (!isNaN(numericValue)) {
          return <span style={{ color: numericValue >= 0 ? 'green' : 'red' }}>{text}</span>;
        }
        return text || 'N/A';
      },
    };
  });
  console.log('columnsData', columnsData);

  return (
    <div style={{ padding: '20px 10px', background: '#F5F5F5' }}>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#f5f5f5',
          padding: '18px',
          borderBottom: '1px solid #b3cbe1',
          borderTop: '1px solid #b3cbe1',
        }}
      >
        <p>
          <span style={{ color: '#fa7462' }}>Note:</span> For rows of the same group field, you can put the value in the
          first row, and in the following rows put a pair of double quotes (&quot;&quot;)
        </p>
      </div> */}
      <Table
        className="categoryDataTable"
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={tableDataSource}
        pagination={false}
        columns={columns as ColumnTypes}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#f5f5f5',
          padding: '18px',
          borderBottom: '1px solid #b3cbe1',
          borderRight: '1px solid #b3cbe1',
          borderLeft: '1px solid #b3cbe1',
        }}
      >
        <BaseButton
          onClick={() => handleDelete(newselectedRows)}
          loading={DeletebtnLoading}
          disabled={DeletebtnLoading || btnLoading || newselectedRows.length === 0}
        >
          <DeleteOutlined style={{ fontSize: '22px' }} />
        </BaseButton>
        <Button onClick={handleAdd} className="addrowBtnTable">
          Add a row
        </Button>
        <Button onClick={handleSaveTest} disabled={DeletebtnLoading || btnLoading} loading={btnLoading}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default CategoryDataTable;
