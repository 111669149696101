import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
  logout,
} from '@app/api/auth.api';
import { setUser } from '@app/store/slices/userSlice';
import { deletePermission, deleteToken, deleteUser, persistToken, readToken } from '@app/services/localStorage.service';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((res) => {
    dispatch(setUser(res?.data?.user));
    persistToken(res?.data?.token);

    return res?.data?.token;
  }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

// export const doVerifySecurityCode = createAsyncThunk(
//   'auth/doVerifySecurityCode',
//   async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
// );

export const doVerifySecurityCode = createAsyncThunk('auth/doVerifySecurityCode', async () => {
  try {
    await verifySecurityCode();
  } catch (error) {
    // Handle logout error, if needed
    console.error('verify error:', error);
  }
});

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

// export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
//   deleteToken();
//   deleteUser();
//   dispatch(setUser(null));
// });
export const doLogout = createAsyncThunk('auth/doLogout', async (payload, { dispatch }) => {
  try {
    await logout();
    deleteToken();
    deleteUser();
    deletePermission();
    dispatch(setUser(null));
  } catch (error) {
    // Handle logout error, if needed
    console.error('Logout error:', error);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
