/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import * as S from '../../../CategoriesPage.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AddCatMarketSubcategory } from '@app/api/categories/CategoryMarkets/CategoryMarketSubCategory/AddCatMarketSubcategory.api';
import { GetCatMarketSubCategory } from '@app/api/categories/CategoryMarkets/CategoryMarketSubCategory/GetCatMarketSubCategory.api';
import '../../../../../../assets/common.css';
interface values {
  status: boolean;
}
interface subcategory {
  sub_category_name: string;
  status: boolean;
  sub_cat_id: string;
  category_id: string;
  category_name: string;
  createdAt: Date;
  market_id: string;
  market_name: string;
  updatedAt: Date;
  _id: string;
}

const SubCategoryDetails = (): JSX.Element => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { pathname } = useLocation();
  const { id, marketId, subcatId } = useParams();
  const [subcategoryLoad, setSubcategoryLoad] = useState(false);
  const [subcategory, setSubcategory] = useState<subcategory[]>();
  const navigate = useNavigate();
  const [statusLoading, setStatusLoading] = useState(false);
  const [form] = Form.useForm();
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const fetchCatmarketSubCategoryData = async () => {
    setSubcategoryLoad(true);
    try {
      const response = await GetCatMarketSubCategory(id as string, marketId as string);
      response?.data?.length >= 1 && setSubcategory(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setSubcategoryLoad(false);
    }
  };
  useEffect(() => {
    fetchCatmarketSubCategoryData();
  }, []);
  const handleSubmit = async (values: values) => {
    setStatusLoading(true);
    try {
      const submitData = {
        _id: state._id,
        sub_cat_id: state.sub_cat_id,
        status: values.status,
      };
      // this is to manage and update the Active status of catgeory market
      await AddCatMarketSubcategory(state.category_id, state.market_id, submitData);
      notificationController.success({ message: t('common.success') });
      fetchCatmarketSubCategoryData();
    } catch (error) {
      console.error(error);
    } finally {
      setStatusLoading(false);
    }
  };
  const status = Form.useWatch('status', form);
  const disableButton = subcategory?.find((e) => e?.sub_cat_id === subcatId)?.status === status;
  return (
    <>
      <div>
        <p style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>{state.sub_category_name}</p>
      </div>
      <S.SubCatForm>
        <div
          style={{
            display: 'flex',
            marginBottom: '10px',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {!subcategoryLoad && (
            <BaseForm
              onFinish={handleSubmit}
              form={form}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '14px',
                border: '1px solid #43736dd1',
                margin: '10px',
                borderRadius: '8px',
                flexDirection: 'column',
                marginBottom: '20px',
                minWidth: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                  // marginBottom: '-10px',
                  // padding: '10px',
                }}
              >
                <BaseForm.Item
                  name="status"
                  valuePropName="checked"
                  noStyle
                  initialValue={subcategory?.find((e) => e?.sub_cat_id === subcatId)?.status}
                >
                  <Auth.FormCheckbox onChange={(e) => setCheckboxChecked(e.target.checked)}>
                    <Auth.Text>Active</Auth.Text>
                  </Auth.FormCheckbox>
                </BaseForm.Item>
                <div>
                  <Auth.SubmitButton
                    type="primary"
                    htmlType="submit"
                    disabled={disableButton || statusLoading}
                    loading={statusLoading}
                  >
                    Save
                  </Auth.SubmitButton>
                </div>
              </div>
              {!status && (
                <p
                  style={{
                    background: '#dbdbdb5c',
                    padding: '10px',
                    borderRadius: '4px',
                    marginBottom: '0px',
                    marginTop: '15px',
                  }}
                >
                  When activated, subcategory will not show a waiting time in the cart. Add the trade and competitor
                  data before activating
                </p>
              )}
            </BaseForm>
          )}

          <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', alignItems: 'center' }}>
            <label className="customLabel">Stripe Price ID : </label>
            <Auth.FormItem label="">
              <Input disabled={true} placeholder="f56fgsgfd" />
            </Auth.FormItem>
          </div>

          <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', alignItems: 'center' }}>
            <label className="customLabel">Stripe Product ID : </label>
            <Auth.FormItem label="">
              <Input disabled={true} placeholder="Z56f6r54ergsgfd" />
            </Auth.FormItem>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            margin: '10px 0px',
            flexDirection: 'column',
            // boxShadow: '0px 0px 15px 1px rgba(35, 78, 84, 0.61)',
            justifyContent: 'space-between',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          <Button
            className="subcategorytablesButton"
            onClick={() => {
              navigate(`${pathname}/tradeData`, { state: state });
            }}
          >
            Trade Data
          </Button>
          <Button
            className="subcategorytablesButton"
            onClick={() => {
              navigate(`${pathname}/competitordata`, { state: state });
            }}
          >
            Competitor Data
          </Button>
          <Button
            className="subcategorytablesButton"
            onClick={() => {
              navigate(`${pathname}/categorydata`, { state: state });
            }}
          >
            Category Data
          </Button>
        </div>
      </S.SubCatForm>
    </>
  );
};

export default SubCategoryDetails;
