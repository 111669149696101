import { httpApi } from '@app/api/http.api';
interface response {
  data: pages[];
}
interface pages {
  status: boolean;
  _id: string;
  name: string;
}
export const PageGet = (): Promise<response> => {
  return httpApi.get<response>(`/pages`).then(({ data }) => data);
};
