import React, { useEffect, useMemo } from 'react';
import { Overlay } from '../../../common/Overlay/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './AdminSider.styles';
import { SiderLogo } from '../../main/sider/SiderLogo';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import AdminSiderMenu from './AdminSiderMenu/AdminSiderMenu';
import AdminSidebarSettingsMenu from './AdminSiderMenu/AdminSiderSettingsMenu';

interface AdminSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const AdminSider: React.FC<AdminSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();

  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  const toggleSider = () => setCollapsed(!isCollapsed);
  useEffect(() => {
    if (user == null) {
      navigate('/auth/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={!isDesktop && isCollapsed}
        collapsedWidth={tabletOnly ? 80 : 0}
        collapsible={isCollapsible}
        width={260}
        {...props}
      >
        <SiderLogo isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />
        <S.SiderContent>
          <S.SidebarHeading>Admin</S.SidebarHeading>
          <AdminSiderMenu setCollapsed={setCollapsed} />
          <S.SidebarHeading>Settings</S.SidebarHeading>
          <AdminSidebarSettingsMenu setCollapsed={setCollapsed} />
        </S.SiderContent>
      </S.Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default AdminSider;
