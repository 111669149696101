import React, { useEffect, useState } from 'react';
import * as S from './MarketsPage.styles';
import Model from '@app/components/Model';
import { ViewMarkets } from '@app/api/markets/ViewMarkets.api';
import MarketsData from './MarketsData';
import AddMarketForm from './AddMarketForm';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import '../../../assets/common.css';
interface response {
  status: boolean;
  message: string;
  data?: [];
}
interface ModalState {
  isModalOpen: boolean;
}
const MarketsPage: React.FC = () => {
  const [item, setitem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const { isModalOpen } = modalState;
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const marketPermissions =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Markets');
  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const AddMarket = () => {
    showModal();
  };
  const loadMarkets = async () => {
    setLoading(true);
    try {
      const response: response = await ViewMarkets();
      setitem(() => response?.data as []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadMarkets();
  }, []);
  const desktopLayout = (
    <S.Container>
      {!loading ? (
        <>
          {marketPermissions?.permissions?.add_market_button && (
            <>
              <S.Button onClick={AddMarket}>Add Market</S.Button>
              <Model
                title="Add Market"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                content={<AddMarketForm loadMarkets={loadMarkets} handleOk={handleOk} />}
              />
            </>
          )}
          {marketPermissions?.permissions?.list_of_markets && (
            <MarketsData items={item} loadMarkets={loadMarkets} permissions={marketPermissions} />
          )}
        </>
      ) : (
        <div>
          <BaseSpin size="large" />
        </div>
      )}
    </S.Container>
  );

  return (
    <>
      {/* <BreadcrumbsPage /> */}
      {desktopLayout}
      {/* {isDesktop ? desktopLayout : mobileAndTabletLayout} */}
    </>
  );
};

export default MarketsPage;
