import { httpApi } from '@app/api/http.api';

export const DeleteCategoryContent = (
  category_id: string,
  category_market_id: string,
  cat_market_sabcat_id: string,
  content_id: string,
) => {
  return httpApi
    .delete(
      `categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/categoryContents/${content_id}/categoryContentDelete`,
    )
    .then(({ data }) => data);
};

// this api is to call the category contents in category data table
