import { httpApi } from '@app/api/http.api';
interface response {
  status: boolean;
  message: string;
  data?: FSData;
}
interface FSData {
  full_service_source: string;
  limited_service_source: string;
  full_service_restaurants: [];
  limited_service_restaurants: [];
  key_trends: string;
}
export const GetFoodService = (id: string): Promise<response> => {
  return httpApi.get<response>(`/marketInsightsFcd/${id}`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
