/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';
interface response {
  status?: boolean;
  message?: string;
  data?: [];
}
// interface data {
//   email: string;
//   password: string;
// }

export const ViewEcportalUsers = (): Promise<response> => {
  return httpApi.get<response>(`/user/ecportal`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
export const ViewAvocadosUsers = (): Promise<response> => {
  return httpApi.get<response>(`/user/avocado`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
export const GetAUser = (portal: string, searchParam: string): Promise<response> => {
  return httpApi.get<response>(`/user/${portal}/${portal}-user?search=${searchParam}`).then(({ data }) => data);
};
