import React, { useEffect, useState } from 'react';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { DatePicker, Form, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { AddTradeFixedData } from '@app/api/categories/TradeDataFixed/AddTradeFixedData.api';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import CustomModal from '@app/components/Model';
import DescriptionQuill from './DescriptionQuill';
import { MdEdit } from 'react-icons/md';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import '../../../../../../../assets/common.css';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import _ from 'lodash'; // Import lodash for deep comparison

interface Props {
  tradeFormData: FormData;
  loading: boolean;
  fetchFormData: () => void;
}

interface FormData {
  source: string;
  importer_ranking: number;
  imported_value: number;
  year_range_short_term: {
    from: number;
    to: number;
  };
  year_range_long_term: {
    from: number;
    to: number;
  };
  date: Date;
  import_description?: string;
}

interface ValuesData {
  source: string;
  importer_ranking: number;
  imported_value: number;
  shortTermFrom: number;
  shortTermTo: number;
  longTermFrom: number;
  longTermTo: number;
  DateSourced: Date;
}

const TradeDataForm = ({ tradeFormData, loading, fetchFormData }: Props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    setIsModified(false);
    form.setFieldsValue({
      DateSourced: tradeFormData?.date && moment(tradeFormData?.date),
      source: tradeFormData?.source,
      importer_ranking: tradeFormData?.importer_ranking,
      imported_value: tradeFormData?.imported_value,
      shortTermFrom: tradeFormData?.year_range_short_term?.from,
      shortTermTo: tradeFormData?.year_range_short_term?.to,
      longTermFrom: tradeFormData?.year_range_long_term?.from,
      longTermTo: tradeFormData?.year_range_long_term?.to,
    });
  }, [tradeFormData]);

  const handleSubmit = async (values: ValuesData) => {
    setFormLoading(true);
    const formData: FormData = {
      source: values.source,
      importer_ranking: values.importer_ranking,
      imported_value: values.imported_value,
      year_range_short_term: {
        from: values.shortTermFrom,
        to: values.shortTermTo,
      },
      year_range_long_term: {
        from: values.longTermFrom,
        to: values.longTermTo,
      },
      date: values.DateSourced,
    };

    try {
      await AddTradeFixedData(state.category_id, state.market_id, state.sub_cat_id, formData);
      notificationController.success({ message: t('common.success') });
      await fetchFormData();
    } catch (error) {
      console.error('error', error);
    } finally {
      setFormLoading(false);
    }
  };

  const [modalState, setModalState] = useState({ isModalOpen: false });
  const { isModalOpen } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleFieldsChange = () => {
    const currentValues = form.getFieldsValue();
    const formattedValues = {
      ...currentValues,
      year_range_short_term: {
        from: currentValues.shortTermFrom,
        to: currentValues.shortTermTo,
      },
      year_range_long_term: {
        from: currentValues.longTermFrom,
        to: currentValues.longTermTo,
      },
      date: currentValues.DateSourced,
    };

    const isSame = _.isEqual(formattedValues, tradeFormData);
    setIsModified(!isSame);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '50%',
          minWidth: '360px',
          margin: '15px auto',
          boxShadow: '0px 0px 15px 1px rgba(35, 78, 84, 0.61)',
          borderRadius: '5px',
        }}
      >
        <div style={{ width: '100%', padding: '20px' }}>
          <Auth.FormItem>
            <BaseButton className="QuillDescription" style={{ color: '#234E54' }} onClick={showModal} type="text">
              <MdEdit /> DESCRIPTION
            </BaseButton>
            {loading && <BaseSpin />}
          </Auth.FormItem>
          {!loading && (
            <BaseForm
              onFinish={handleSubmit}
              initialValues={tradeFormData}
              form={form}
              onFieldsChange={handleFieldsChange}
            >
              <Auth.FormItem name="DateSourced" label=" Date sourced:" className="form_wrap">
                <DatePicker defaultValue={moment(tradeFormData?.date)} placeholder="Date Sourced" />
              </Auth.FormItem>

              <Auth.FormItem name="source" label="Source:">
                <TextArea className="labelFloat" autoSize={{ minRows: 2, maxRows: 5 }} />
              </Auth.FormItem>

              <Auth.FormItem name="importer_ranking" label="Importer ranking:">
                <InputNumber maxLength={8} controls={false} />
              </Auth.FormItem>

              <Auth.FormItem name="imported_value" label="Imported value (millions):">
                <InputNumber maxLength={8} controls={false} />
              </Auth.FormItem>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  padding: '12px 0px',
                }}
              >
                <p className="customLabel">Year range (short term):</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <label className="customLabel" style={{ marginTop: '15px', paddingRight: '12px' }}>
                    {' '}
                    From
                  </label>
                  <Auth.FormItem
                    name="shortTermFrom"
                    initialValue={tradeFormData?.year_range_short_term?.from}
                    style={{ marginRight: '10px' }}
                  >
                    <InputNumber maxLength={4} controls={false} />
                  </Auth.FormItem>
                  <label className="customLabel" style={{ marginTop: '15px', paddingRight: '12px' }}>
                    To
                  </label>
                  <Auth.FormItem name="shortTermTo" initialValue={tradeFormData?.year_range_short_term?.to}>
                    <InputNumber maxLength={4} controls={false} />
                  </Auth.FormItem>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  padding: '12px 0px',
                }}
              >
                <p className="customLabel">Year range (long term):</p>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <label className="customLabel" style={{ marginTop: '15px', paddingRight: '12px' }}>
                    From
                  </label>
                  <Auth.FormItem
                    name="longTermFrom"
                    initialValue={tradeFormData?.year_range_long_term?.from}
                    style={{ marginRight: '10px' }}
                  >
                    <InputNumber maxLength={4} controls={false} />
                  </Auth.FormItem>

                  <label className="customLabel" style={{ marginTop: '15px', paddingRight: '12px' }}>
                    To
                  </label>
                  <Auth.FormItem name="longTermTo" initialValue={tradeFormData?.year_range_long_term?.to}>
                    <InputNumber maxLength={4} controls={false} />
                  </Auth.FormItem>
                </div>
              </div>

              <BaseForm.Item noStyle>
                <Auth.SubmitButton
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isModified || formLoading}
                >
                  Submit
                </Auth.SubmitButton>
              </BaseForm.Item>
            </BaseForm>
          )}
        </div>
      </div>
      <CustomModal
        title="Description"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={
          <DescriptionQuill
            handleOk={handleCancel}
            catID={state.category_id}
            marketID={state.market_id}
            subCatID={state.sub_cat_id}
            data={tradeFormData?.import_description as string}
            type="import"
          />
        }
      />
    </>
  );
};

export default TradeDataForm;
