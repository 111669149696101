import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import DownloadCsv from '../../Download.Csv';
import ViewUser from '../../ViewUsers';
import SearchUsers from '../../SearchUsers';
import { readUser } from '@app/services/localStorage.service';

const UsersTabsPage: React.FC = () => {
  const { t } = useTranslation();
  const user = readUser();
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const usersPermissions =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'User');
  const commonTabs = useMemo(
    () => [
      {
        key: '1',
        label: `${t('tabs.search')} User`,
        children: <SearchUsers type="user" />,
        identity: 'access_permission',
      },
      // {
      //   key: '2',
      //   label: `${t('tabs.view')} User`,
      //   children: <ViewUser type="user" />,
      // },
      {
        key: '2',
        label: `${t('tabs.export')}`,
        children: <DownloadCsv />,
        identity: 'export_download',
      },
      {
        key: '3',
        label: `${t('tabs.permission')}`,
        children: <ViewUser />,
        identity: 'access_permission',
      },
    ],
    [t],
  );
  // console.log('userPermissions', usersPermissions);
  // const filteredAdminSideBar = commonTabs.filter((e) =>
  //   usersPermissions?.permissions?.find((event) => event?.key === e?.key),
  // );
  const filteredTabs = useMemo(() => {
    return commonTabs.filter((tab) => usersPermissions?.permissions[tab.identity]);
  }, [commonTabs]);
  // console.log('filteredAdminSideBar', filteredAdminSideBar);
  return (
    <>
      <BaseCol>
        <BaseTabs defaultActiveKey="1" items={user?.role === 'SUPERADMIN' ? commonTabs.slice(0, 2) : filteredTabs} />
      </BaseCol>
    </>
  );
};

export default UsersTabsPage;
