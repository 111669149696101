import React from 'react';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesH2,
  ServicesP,
} from './FinancialReview.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

const FinancialReview: React.FC = () => {
  return (
    <ServicesContainer id="features">
      <ServicesWrapper>
        <ServicesH2>As featured in the Australian Financial Review</ServicesH2>
        <ServicesH1>Financial Review</ServicesH1>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default FinancialReview;
