import { httpApi } from '@app/api/http.api';

export interface CategoryData {
  newcsvdata: string;
}

export const PostExportCSV = (
  id: string,
  marketId: string,
  subCatId: string,
  type: string,
  CategoryData: CategoryData,
): Promise<undefined> => {
  return httpApi
    .post<undefined>(`/categories/categoryMarket/${id}/${marketId}/${subCatId}/csvFiles/tradeExportCsv?type=${type}`, {
      ...CategoryData,
    })
    .then(({ data }) => data);
};
