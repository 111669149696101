import { httpApi } from '@app/api/http.api';
interface response {
  status: boolean;
  message: string;
  data?: GRData;
}
interface GRData {
  grocery_retailing_source: string;
  petro_convenience_source: string;
  petro_convenience: [];
  grocery_retailing: [];
  key_trends: string;
}
export const GetGroceryRetail = (id: string): Promise<response> => {
  return httpApi.get<response>(`/marketInsightsGrcd/${id}`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
