import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: foldersData[];
}

interface foldersData {
  ID: string;
  name: string;
}
export const EditVideoDetails = (folderID: string, videoID: string, formData: FormData): Promise<response> => {
  return httpApi
    .put<response>(`videoFolders/${folderID}/videos/${videoID}/videoUpdate`, formData)
    .then(({ data }) => data);

  // This API is fetching Video details(name,descprtion etc) with help of folder > clicked video id
};
