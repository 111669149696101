import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
import { useEffect, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import Model from '@app/components/Model';
import * as S from '../YourSubscriptionsPage.styles';
import { Popconfirm } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
interface props {
  subscriptionModules?: response;
  GetSubscription: () => void;
}
interface response {
  status: boolean;
  message: string;
  data: Subscription[];
}

interface Subscription {
  _id: string;
  category_id: any;
  market_id: any;
  cat_market_sabcat_id: any;
  matching_subcategory: any;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const SubscriptionsModules = ({ subscriptionModules, GetSubscription }: props): JSX.Element => {
  const [isResourceEditModalOpen, setIsEditResourceModalOpen] = useState({
    state: false,
    countryID: '',
    subscriptionData: {} as response,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navigateToDifferentRoute = (element: Subscription) => {
    navigate(
      pathname === '/dashboard' ? `${pathname}/your-subscriptions/TradeDetailsPage` : `${pathname}/TradeDetailsPage`,
      { state: element },
    );
    // console.log(e);
  };
  // console.log(folders);
  console.log('subscriptionmodule', subscriptionModules);

  return (
    <S.SubscriptionsModule>
      {subscriptionModules &&
        subscriptionModules.data?.map((subscription, index) => {
          return (
            <>
              {subscription.market_id ? (
                <S.YourSubscriptionbutton
                  onClick={() => navigateToDifferentRoute(subscription)}
                  key={subscription?._id}
                >
                  <BaseCollapse.Panel
                    header={`${subscription?.market_id?.name || ''} - ${subscription?.category_id?.name || ''} - ${
                      subscription?.matching_subcategory[0]?.sub_category_name || ''
                    }`}
                    key={index}
                  >
                    {' '}
                    {
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          margin: '5px',
                        }}
                      ></div>
                    }
                  </BaseCollapse.Panel>
                </S.YourSubscriptionbutton>
              ) : (
                ''
              )}
            </>
          );
        })}
    </S.SubscriptionsModule>
  );
};

export default SubscriptionsModules;
