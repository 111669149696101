import { httpApi } from '@app/api/http.api';

export interface CategoryData {
  name: string;
  category_type_id: string;
  status: boolean;
}

interface ReadUser {
  id: string; // assuming 'id' is a property of your user type
}

const readUserString: string | null = localStorage.getItem('user');
const readUser: ReadUser | null = readUserString ? JSON.parse(readUserString) : null;

console.log(readUser);

export const AddCategory = (CategoryData: CategoryData): Promise<undefined> => {
  if (readUser) {
    return httpApi.post<undefined>(`/categories/categoryAdd`, { ...CategoryData }).then(({ data }) => data);
  } else {
    // Handle the case when readUser is null, perhaps throw an error or handle it appropriately.
    throw new Error('User data not found in local storage');
  }
};
