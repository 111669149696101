import React, { useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import styled from 'styled-components';
import { notificationController } from '@app/controllers/notificationController';
import { ViewUsers } from '@app/api/users/ViewUsers.api';
import { ActivityStory } from './activityStory/ActivityStory';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
const YourSubscriptionbutton = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
`;
interface response {
  status?: boolean;
  message?: string;
  data?: [];
}
const ViewUser = (): JSX.Element => {
  const [loading, setloading] = useState<boolean>(false);
  const [show, setshow] = useState<boolean>(false);
  const [item, setitem] = useState<response[]>([]);
  const handleDownloadClick = async () => {
    setloading(true);
    try {
      const response: response = await ViewUsers();
      setitem(response.data as []);
      notificationController.success({ message: 'Success Fetching Users' });
      setshow(true);
    } catch (error) {
      console.error('Error·downloading·users:', error);
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <YourSubscriptionbutton disabled={loading} onClick={handleDownloadClick}>
        Load Users
      </YourSubscriptionbutton>
      {loading && <BaseSpin />}
      {show ? <ActivityStory item={item as []} /> : ''}
    </>
  );
};

export default ViewUser;
