import { Table } from 'antd';
import * as S from './TradePage.styles';
import Model from '@app/components/Model';
import { useEffect, useMemo, useState } from 'react';
import { PageGet } from '@app/api/pages/pages.api';
import { pageDetail } from '@app/api/pages/page.Details.api';
import { useLocation } from 'react-router-dom';
import { GetCategoryTableData } from '@app/api/categories/CategoryDataTableNEW/GetCategoryTableData.api';

interface response {
  status: boolean;
  message: string;
  data?: any[];
}
interface props {
  categoryTableFields?: response;
  categoryContents?: response;
}

interface ModalState {
  isModalOpen: boolean;
}

const CategoryData = ({ categoryTableFields, categoryContents }: props): JSX.Element => {
  const site = useLocation();

  const url = site.pathname;
  const urlName = url.split('/');
  const [pagename, setPageName] = useState<any>('');
  const [pagedetails, setPagedetails] = useState<any>('');
  const [rowData, setRowData] = useState();

  const PagenameId = pagename && pagename.find((page: any) => page.slug === 'category-data');
  const memoPagenameId = useMemo(() => PagenameId, [PagenameId]);

  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });

  const { isModalOpen } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  useEffect(() => {
    PageGet()
      .then((data: any) => setPageName(data.data))
      .catch((err: any) => console.log('err', err));
  }, []);
  useEffect(() => {
    if (urlName[2] && memoPagenameId?._id) {
      pageDetail(memoPagenameId?._id)
        .then((data: any) => setPagedetails(data.data))

        .catch((err: any) => console.log('err', err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoPagenameId?._id]);
  const columns = categoryTableFields?.data?.map((col) => {
    return {
      ...col,
      title: () => (
        <>
          <div>{col.lable}</div>
          <div style={{ fontSize: '12px', color: '#888' }}>({col.info})</div>
        </>
      ),
      render: (text: any) => {
        const numericValue = parseFloat(text);
        if (!isNaN(numericValue)) {
          return <span style={{ color: numericValue >= 0 ? 'green' : 'red' }}>{text}</span>;
        }
        return text || 'N/A';
      },
    };
  });
  const fetchTableCells = async () => {
    try {
      const response = await GetCategoryTableData(
        site.state.category_id._id,
        site.state.market_id._id as string,
        site.state.cat_market_sabcat_id as string,
      );
      console.log('response', response);
      // Assign _id as key for each item in response.data
      const dataWithKeys = response?.data?.rowsData?.map((item: DataType) => ({ ...item, key: item._id }));
      setRowData(dataWithKeys);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchTableCells();
  }, []);
  return (
    <>
      {pagedetails && pagedetails[0]?.status && (
        <div style={{ width: '370px', height: '230px', margin: '10px auto' }} onClick={showModal}>
          <img src={pagedetails[0]?.image} alt="pagedetails" height="100%" width="100%" />
        </div>
      )}

      {pagedetails[0]?.video && (
        <Model
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          content={
            <>
              <video width="100%" controls autoPlay>
                <source src={pagedetails[0]?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          }
        />
      )}
      {categoryContents &&
        categoryContents.data?.map((item, index) => {
          return (
            <>
              <S.Content key={index}>
                <S.Heading>{item.title}</S.Heading>
                <S.Heading2 dangerouslySetInnerHTML={{ __html: item.description }} />
              </S.Content>
            </>
          );
        })}
      <Table columns={columns} dataSource={rowData} bordered pagination={false} scroll={{ x: 'max-content' }} />
    </>
  );
};

export default CategoryData;
