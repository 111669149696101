/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../../Categories/CategoriesPage.styles';
import { useLocation } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Model from '@app/components/Model';
import { notificationController } from '@app/controllers/notificationController';
import { DeleteSubCategory } from '@app/api/categories/SubCategoryOptions/DeleteSubCategory.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ListWrapper } from '../../Markets/MarketsPage.styles';
import { Input } from 'antd';
import { BasePopconfirm } from '@app/components/common/BasePopconfirm/BasePopconfirm';
import { AddSubCatFolders } from '@app/api/categories/SubCategoryOptions/AddSubCatFolders.api';

interface ModalState {
  isModalOpen: boolean;
  _id: string;
}
interface DelModalState {
  isDelModalOpen: boolean;
  sub_category_name: string;
  _id: string;
}
interface folderData {
  sub_category_name?: string;
  _id?: string;
}
interface showModal {
  sub_category_name: string;
  _id: string;
}

interface errorMessage {
  message: string;
}

interface props {
  subCatOptionsData: folderData[];
  fetchData: () => void;
  loading: boolean;
}

const SubCatPage = ({ subCatOptionsData, fetchData, loading }: props): JSX.Element => {
  // const [folders, setFolders] = useState<folderData[]>([]);
  const [backup, setBackup] = useState<folderData[]>([]);
  const [delLoading, setDelLoading] = useState(false);
  // const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);

  // const fetchData = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await GetSubCategoryOptions(state._id);
  //     response?.data.length >= 1 && setFolders(response?.data[0]?.subcategory_options as folderData[]);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  console.log('stateeeee', state._id);

  const InnerPopUpSubmit = async () => {
    try {
      await AddSubCatFolders(state._id, backup);
    } catch (error) {
      notificationController.error(error as errorMessage);
    }
    fetchData();
    setOpen(false);
  };

  async function closeAllModal() {
    await setOpen(false);
    handleCancel();
  }
  useEffect(() => {
    setBackup([...subCatOptionsData]);
  }, [subCatOptionsData]);

  const handleReset = () => {
    setBackup([...subCatOptionsData]);
  };

  const handleDelete = async () => {
    setDelLoading(true);

    try {
      await DeleteSubCategory(state._id, delModalState?._id);
      notificationController.success({ message: t('common.success') });
      await fetchData();
      handleDelOk();
    } catch (error) {
      notificationController.error(error as errorMessage);
      // Handle errors appropriately
    } finally {
      setDelLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...backup])); // Deep clone the backup array
    newInputs[index].sub_category_name = event.target.value;
    setBackup(newInputs);
  };

  const addItem = () => {
    setBackup([...backup, { sub_category_name: '' }]);
  };
  const deleteItem = (i: number) => {
    setBackup((prevArray) => {
      // Create a copy of the array without the element at the specified index
      const newArray = [...prevArray.slice(0, i), ...prevArray.slice(i + 1)];
      return newArray;
    });
  };

  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, _id: '' });
  const [delModalState, setDelModalState] = useState<DelModalState>({
    isDelModalOpen: false,
    sub_category_name: '',
    _id: '',
  });

  const { isModalOpen } = modalState;
  const { isDelModalOpen } = delModalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const showDelModal = ({ _id, sub_category_name }: showModal) => {
    setDelModalState({ ...modalState, isDelModalOpen: true, _id, sub_category_name });
  };

  const handleDelOk = () => {
    setDelModalState({ ...modalState, isDelModalOpen: false, _id: '', sub_category_name: '' });
  };

  const handleDelCancel = () => {
    setDelModalState({ ...modalState, isDelModalOpen: false, _id: '', sub_category_name: '' });
  };
  const deleteAlert = async ({ _id, sub_category_name }: showModal) => {
    showDelModal({ _id, sub_category_name });
  };

  const DeleteMsg = () => (
    <div>
      <div>
        Are you sure you want to delete {}
        {delModalState?.sub_category_name}?
      </div>
      <S.ButtonContainer>
        <S.Button disabled={delLoading} onClick={handleDelCancel}>
          Cancel
        </S.Button>
        <S.Button disabled={delLoading} type="primary" onClick={handleDelete} loading={delLoading}>
          Submit
        </S.Button>
      </S.ButtonContainer>
    </div>
  );
  return (
    <>
      <S.CategoryDetailsWrap>
        <div
          style={{
            marginTop: '2rem',
            margin: '0 auto',
            padding: '20px',
            boxShadow: '0px 0px 15px 1px rgba(35, 78, 84, 0.61)',
            borderRadius: '5px',
          }}
        >
          <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
            <p style={{ margin: '0' }}>Subcategory Options</p>
            <EditOutlined onClick={showModal} />
          </div>
          {!loading ? (
            <div>
              {subCatOptionsData?.length === 0 ? ( // Moved the condition outside map
                <div>
                  <p style={{ border: '1px solid black', borderRadius: '3px', padding: '10px' }}>
                    No items have been added
                  </p>
                  <p style={{ color: '#D37D0C' }}>Please add a subcategory option</p>
                </div>
              ) : (
                subCatOptionsData?.map((element: folderData) => (
                  <ListWrapper
                    style={{
                      display: 'flex',
                      boxShadow: '0px 0px 15px 1px rgba(35, 78, 84, 0.61)',
                      margin: '10px 0px',
                      justifyContent: 'space-between',
                      borderRadius: '3px',
                    }}
                    key={element._id}
                  >
                    <div>
                      <S.BaseRowBox key={element?.sub_category_name} gutter={[16, 16]}>
                        {element?.sub_category_name}
                      </S.BaseRowBox>
                    </div>
                    <BaseButton
                      style={{ margin: '7px 10px' }}
                      onClick={() =>
                        deleteAlert({
                          _id: element?._id as string,
                          sub_category_name: element?.sub_category_name as string,
                        })
                      }
                    >
                      <DeleteOutlined />
                    </BaseButton>
                  </ListWrapper>
                ))
              )}
            </div>
          ) : (
            <S.CustomSpin />
          )}
        </div>
      </S.CategoryDetailsWrap>
      <Model
        title="Delete "
        open={isDelModalOpen}
        onOk={handleDelOk}
        onCancel={handleDelCancel}
        content={<DeleteMsg />}
      />
      <Model
        title="Sub Category options"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={closeAllModal}
        content={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <S.Button severity="warning" onClick={handleReset}>
                Reset
              </S.Button>
              <BasePopconfirm
                placement="bottom"
                title={'Are you sure you want to make these changes?'}
                onConfirm={InnerPopUpSubmit}
                okText={t('popconfirm.yes')}
                cancelText={t('popconfirm.no')}
                onCancel={() => setOpen(false)}
                open={open}
              >
                <BaseButton onClick={() => setOpen(true)}>Apply</BaseButton>
              </BasePopconfirm>
            </div>

            <S.Scroll_box>
              {backup.map((element, index) => (
                <div
                  key={element._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Input
                    type="text"
                    value={backup[index].sub_category_name}
                    onChange={(event) => handleInputChange(event, index)}
                  />
                  <BaseButton
                    style={{ marginLeft: '10px' }}
                    disabled={'_id' in element}
                    onClick={() => deleteItem(index)}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </div>
              ))}
            </S.Scroll_box>
            <S.Button type="primary" onClick={addItem}>
              Add item
            </S.Button>
          </>
        }
      />
    </>
  );
};

export default SubCatPage;
