/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import Model from '@app/components/Model';
import * as S from '../CategoriesPage.styles';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetCategoryMarkets } from '@app/api/categories/CategoryMarkets/GetCategoryMarkets.api';
import { DeleteCategoryMarkets } from '@app/api/categories/CategoryMarkets/DeleteCategoryMarktes.api';
import AddCategoryMarketForm from './AddCategoryMarketForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ListWrapper } from '../../Markets/MarketsPage.styles';

interface categories {
  name: string;
  _id?: string;
}
interface ModalState {
  isModalOpen: boolean;
  name?: string;
  _id?: string;
}
interface folderData {
  status: boolean;
  category_id: string;
  createdAt: Date;
  market_id: string;
  market_name: string;
  updatedAt: Date;
  _id: string;
}

interface subCatOptionsData {
  sub_category_name?: string;
  _id?: string;
}

interface props {
  subCatOptionsData: subCatOptionsData[];
  fetchData: () => void;
}

interface errorMessage {
  message: string;
}

const CategoryMarketData = ({ subCatOptionsData }: props): JSX.Element => {
  const [isAddCatMarketModalOpen, SetIsAddCatMarketModalOpen] = useState(false);
  const { state } = useLocation();
  const [folders, setFolders] = useState<folderData[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  // const [subCatOptions, setsubcatOptionsFolders] = useState<subCatOptionsData[]>([]);

  const { isModalOpen } = modalState;

  // console.log('subCatOptionsss', subCatOptionsData);

  // called this api to get the array of sub cat options and check length to show category market or not
  // const fetchSubCatOption = async () => {
  //   try {
  //     const response = await GetSubCategoryOptions(state._id);
  //     response?.data.length >= 1 &&
  //       setsubcatOptionsFolders(response?.data[0]?.subcategory_options as subCatOptionsData[]);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //   }
  // };
  // useEffect(() => {
  //   fetchSubCatOption();
  // }, []);

  // console.log('sanjayyyyyyyyyyyy', subCatOptions);

  const fetchCatMarkData = async () => {
    setLoading(true);
    try {
      const response = await GetCategoryMarkets(state._id);
      response?.data?.length >= 1 && setFolders(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCatMarkData();
  }, []);

  const showModal = ({ _id, name }: categories) => {
    setModalState({ ...modalState, isModalOpen: true, _id, name });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };
  const deleteAlert = async ({ _id, name }: categories) => {
    showModal({ _id, name });
  };

  const handleAddCategoryMarket = async () => {
    SetIsAddCatMarketModalOpen(true);
  };
  const handleAddCatMarkOk = () => {
    SetIsAddCatMarketModalOpen(false);
  };
  const handleAddCatMarkCancel = () => {
    SetIsAddCatMarketModalOpen(false);
  };

  const handleDeleteCategory = async () => {
    setDelLoading(true);
    try {
      await DeleteCategoryMarkets(modalState._id as string);
      notificationController.success({ message: t('common.success') });
      const updatedFolders = folders.filter((folder) => folder._id !== modalState._id); // Filter out the deleted item
      setFolders(updatedFolders);
      if (updatedFolders.length === 0) {
        // If no items left, reset folders array to an empty array
        setFolders([]);
      }
      handleCancel();
    } catch (error) {
      // console.error('Error deleting category market:', error);
      notificationController.error(error as errorMessage);

      // Handle errors appropriately
    } finally {
      setDelLoading(false);
    }
  };

  const DeleteMsg = () => (
    <div>
      <div>Are you sure you want to delete {modalState.name}?</div>
      <S.ButtonContainer>
        <S.Button disabled={delLoading} onClick={handleCancel}>
          Cancel
        </S.Button>
        <S.Button disabled={delLoading} type="primary" onClick={handleDeleteCategory} loading={delLoading}>
          Submit
        </S.Button>
      </S.ButtonContainer>
    </div>
  );

  const navigateToDifferentRoute = (element: folderData) => {
    navigate(`${pathname}/${element.market_id}`, { state: element });
    // console.log(e);
  };
  return (
    <>
      {subCatOptionsData.length > 0 && (
        <S.CategoryMarket>
          <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
            <p style={{ margin: '0' }}>Category Markets</p>
            <S.Button onClick={handleAddCategoryMarket}>ADD CATEGORY MARKET</S.Button>
          </div>
          {!loading ? (
            <div>
              {folders?.map((element: folderData) => (
                <ListWrapper
                  style={{
                    display: 'flex',
                    boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 15px 1px',
                    margin: '10px 0px',
                    borderRadius: '3px',
                  }}
                  key={element._id}
                >
                  <div className="hoverDiv">
                    <S.BaseRowBox
                      key={element?.market_name}
                      gutter={[16, 16]}
                      onClick={() => navigateToDifferentRoute(element)}
                    >
                      <div>{element?.market_name}</div>
                    </S.BaseRowBox>
                  </div>
                  <BaseButton
                    style={{ margin: '7px 10px' }}
                    onClick={() => deleteAlert({ _id: element._id, name: element.market_name })}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </ListWrapper>
              ))}
            </div>
          ) : (
            <S.CustomSpin />
          )}
        </S.CategoryMarket>
      )}
      <Model
        title="Delete Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<DeleteMsg />}
      />
      <Model
        title="Add Category Market"
        open={isAddCatMarketModalOpen}
        onOk={handleAddCatMarkOk}
        onCancel={handleAddCatMarkCancel}
        content={
          <AddCategoryMarketForm
            fetchCatMarkData={fetchCatMarkData}
            handleOk={handleAddCatMarkOk}
            handleCancel={handleAddCatMarkCancel}
            catID={state._id}
            existingData={folders}
          />
        }
      />
    </>
  );
};

export default CategoryMarketData;
