import { httpApi } from '@app/api/http.api';
interface response {
  data: subCategories[];
}
interface subCategories {
  subcategory_options: subCatOption[];
  name: string;
}
interface SubCategoryOption {
  _id: string;
  sub_category_name: string;
}
interface subCatOption {
  sub_category_name: string;
  _id: string;
}
interface SubCategory {
  _id: string;
  category_id: string;
  subcategory_options: SubCategoryOption[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface SubCategoryResponse {
  status: boolean;
  message: string;
  data: SubCategory[];
}
export const GetSubCategoryOptions = (ID: string): Promise<SubCategoryResponse> => {
  return httpApi.get<SubCategoryResponse>(`/categories/subCategories/${ID}`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
