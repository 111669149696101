import React, { useEffect, useState } from 'react';
import * as S from './CatagoryData.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { DeleteOutlined } from '@ant-design/icons';
import CustomModal from '@app/components/Model';
import TableFieldsModal from './TableFieldsModal';
import { GetCategoryTableFields } from '@app/api/categories/CategoryTableFields/GetCategoryTableFields.api';
import { useParams } from 'react-router-dom';
import Model from '@app/components/Model';
import { notificationController } from '@app/controllers/notificationController';
import { DeleteCategoryTableFields } from '@app/api/categories/CategoryTableFields/DeleteCategoryTableFields.api';
import { useTranslation } from 'react-i18next';

interface ModalState {
  isModalOpen: boolean;
  name?: string;
  _id?: string;
}

interface categoryContent {
  name: string;
  _id?: string;
}

interface props {
  fetchCatTableData: () => void;
  fetchData: () => void;
  columnsData: [];
}

function CategoryTableFields({ fetchData, columnsData, fetchCatTableData }: props) {
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [TableFieldmodalState, setTableFieldModalState] = useState<ModalState>({ isModalOpen: false });
  const [EditTableFieldmodalState, setEditTableFieldModalState] = useState<ModalState>({ isModalOpen: false });
  const { id, marketId, subcatId } = useParams();
  // const [tableFieldsData, setTableFieldsData] = useState([]);
  const [editableData, setEditableData] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);

  const showModal = ({ _id, name }: categoryContent) => {
    setModalState({ ...modalState, isModalOpen: true, _id, name });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const deleteAlert = async ({ _id, name }: categoryContent) => {
    showModal({ _id, name });
  };

  const handleTableFieldOk = () => {
    setTableFieldModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
    setEditableData([]);
  };

  const handleEditTableFieldOk = () => {
    setEditTableFieldModalState({ ...EditTableFieldmodalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleTableFieldCancel = () => {
    setTableFieldModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleEditTableFieldCancel = async () => {
    setEditableData([]);

    setEditTableFieldModalState({ ...EditTableFieldmodalState, isModalOpen: false });
  };

  const showTableFieldModal = ({ _id, name }: categoryContent) => {
    setTableFieldModalState({ ...TableFieldmodalState, isModalOpen: true, _id, name });
  };
  const showEditTableFieldModal = () => {
    setEditTableFieldModalState({ ...EditTableFieldmodalState, isModalOpen: true });
  };

  // const fetchTableFieldsData = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await GetCategoryTableFields(id, marketId, subcatId);
  //     setTableFieldsData(response.data.reverse());
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchTableFieldsData();
  // }, []);

  const DeleteMsg = () => (
    <div>
      <div>
        Are you sure you want to delete <span style={{ color: '#fa7462', fontSize: '18px' }}>{modalState.name}</span>?
      </div>
      <S.ButtonContainer>
        <S.Button onClick={handleCancel} disabled={delLoading}>
          Cancel
        </S.Button>
        <S.Button type="primary" onClick={handleDelete} disabled={delLoading} loading={delLoading}>
          Submit
        </S.Button>
      </S.ButtonContainer>
    </div>
  );
  const handleDelete = async () => {
    setDelLoading(true);
    try {
      await DeleteCategoryTableFields(id, marketId, subcatId, modalState._id as string);
      notificationController.success({ message: t('common.success') });
      // fetchTableFieldsData();
      fetchCatTableData();
      fetchData();
      handleCancel();
    } catch (error) {
      notificationController.error(error);
    } finally {
      setDelLoading(false);
    }
  };

  const handleEditTableField = async (element) => {
    console.log(element);
    setEditableData(element);
    showEditTableFieldModal();
  };
  return (
    <>
      <S.CategoryDetailsWrap>
        <div
          style={{
            marginTop: '2rem',
            margin: '0 auto',
            padding: '20px',
            boxShadow: '0px 0px 15px 1px rgba(35, 78, 84, 0.61)',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          Table Fields
          {!loading ? (
            <div>
              {columnsData?.map((element) => (
                <S.ListWrapper
                  style={{
                    display: 'flex',
                    boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 15px 1px',
                    margin: '10px 0px',
                    borderRadius: '3px',
                  }}
                  key={element._id}
                >
                  <div
                    className="hoverDiv"
                    onClick={() => {
                      handleEditTableField(element);
                    }}
                  >
                    <S.BaseRowBox gutter={[16, 16]}>
                      <div>
                        {' '}
                        FIELD : {element?.field_id} LABEL: {element.lable}
                      </div>
                    </S.BaseRowBox>
                  </div>
                  <BaseButton
                    style={{ margin: '7px 10px' }}
                    onClick={() => deleteAlert({ _id: element._id, name: element.lable })}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </S.ListWrapper>
              ))}
            </div>
          ) : (
            <S.CustomSpin />
          )}
          <div style={{ margin: '0 auto' }}>
            <S.Button onClick={showTableFieldModal}>Add Table Field</S.Button>
          </div>
        </div>
      </S.CategoryDetailsWrap>

      <Model
        title="Delete Table Field"
        open={modalState.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<DeleteMsg />}
      />

      <CustomModal
        title="Add Table Field"
        open={TableFieldmodalState.isModalOpen}
        onOk={handleTableFieldOk}
        onCancel={handleTableFieldCancel}
        content={<TableFieldsModal handleOk={handleTableFieldOk} fetchData={fetchData} type="Add" />}
      />

      <CustomModal
        title="Edit Table Field"
        open={EditTableFieldmodalState.isModalOpen}
        onOk={handleEditTableFieldOk}
        onCancel={handleEditTableFieldCancel}
        content={
          <TableFieldsModal
            handleOk={handleEditTableFieldOk}
            fetchData={fetchData}
            ediTableData={editableData}
            type="Edit"
          />
        }
      />
    </>
  );
}

export default CategoryTableFields;
