import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ProfileNav } from '@app/components/profile/profileCard/ProfileNav/ProfileNav';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';

const ProfileLayout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('profile.title')}</PageTitle>

      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} md={24} xl={24}>
          <ProfileCard>
            <BaseRow gutter={[30, 30]}>
              {/* <BaseCol xs={24} md={12} xl={24}>
                  <ProfileInfo profileData={user} />
                </BaseCol> */}

              <BaseCol xs={24} md={12} xl={24}>
                <ProfileNav />
              </BaseCol>
            </BaseRow>
          </ProfileCard>
        </BaseCol>

        <BaseCol xs={24} md={24} xl={24}>
          <Outlet />
        </BaseCol>
      </BaseRow>
    </>
  );
};

const ProfileCard = styled(BaseCard)`
  height: unset;
`;

export default ProfileLayout;
