import React from 'react';
import { Card, Button } from 'antd';
import styled from 'styled-components';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { NavBtnLink } from '../Navbar/NavbarElements';

const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const StyledCard = styled(Card)`
  width: 700px;
  padding: 20px;
  .ant-card-head-title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }
`;

const Price = styled.div`
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
`;

const FeaturesList = styled.ul`
  padding: 0;
`;

const Feature = styled.li`
  margin-bottom: 10px;
`;
const SbHead = styled.h3`
  font-size: 26px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const NavBtn = styled(NavBtnLink)`
  padding: 21px 61px;
  font-size: larger;
  color: white;
`;
const NewMemberOffer: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  return (
    <PricingContainer id="NewMemberOffer">
      <StyledCard title="Pricing & Features">
        {/* extra={<Button type="primary">New Member Special Offer</Button> */}
        <Button
          type="primary"
          style={{
            cursor: 'auto',
            width: '100%',
            height: '50px',
            borderRadius: '15px 15px 0px 0px',
            background: '#00b4a0',
            border: 'none',
          }}
        >
          New Member Special Offer
        </Button>
        <Price>
          <span style={{ fontSize: '50px', fontWeight: '600' }}>$99 </span>/first 6 months
        </Price>
        <p style={{ fontSize: '28px', textAlign: 'center' }}>
          RRP $299 p.a. <span style={{ fontSize: '28px', color: '#00b4a0' }}>Save $50</span>
        </p>

        <SbHead>Your membership includes:</SbHead>
        <FeaturesList>
          <Feature>
            Access to succinct and actionable market insight summaries, including key demographic data and consumer
            trends.
          </Feature>
          <Feature>Downloadable export resources, tools, and checklists to help you plan.</Feature>
          <Feature>
            Access to export tutorials, masterclasses, and forum videos to develop your export market capability.
          </Feature>
        </FeaturesList>
        <SbHead>Plus, access to:</SbHead>
        <FeaturesList>
          <Feature>Competitor insights, including product claims, pricing, images, and more.</Feature>
          <Feature>Trade data for your product category for your target markets.</Feature>
          <Feature>One-on-one export advisory session with industry leaders in export market development.</Feature>
        </FeaturesList>
        {!user && (
          <div style={{ textAlign: 'center', margin: '50px 0' }}>
            <NavBtn to="/auth/sign-up">Create your account</NavBtn>
            <Feature style={{ fontWeight: 'bold', marginTop: '50px' }}>
              By creating an account, you will be granted access to the Export Connect Portal and subscribed to future
              Export Connect correspondence and the latest industry news. You can unsubscribe at any time.
            </Feature>
          </div>
        )}
      </StyledCard>
    </PricingContainer>
  );
};

export default NewMemberOffer;
