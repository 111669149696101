import { useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import styled from 'styled-components';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { ActivityStory } from './activityStory/ActivityStory';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { SearchUser, SearchUserSub } from '@app/api/users/SearchUser.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import moment from 'moment';

const YourSearchbutton = styled(BaseButton)`
  margin: 0;
  height: 57px;
  border: left; // Fix the typo here
  border-left: 0;
  border-radius: inherit;
`;

const WrapperBox = styled.div`
  display: flex;
`;

const SearchInputNew = styled(SearchInput)`
  .ant-input-search-button {
    display: none;
  }
  .ant-input-group-addon {
    display: none;
  }
`;
interface response {
  status?: boolean;
  message?: string;
  data?: [];
}
interface Props {
  type: 'user' | 'permission';
}
const ViewUser = (props: Props): JSX.Element => {
  const { type } = props;
  const [loading, setloading] = useState<boolean>(false);
  const [show, setshow] = useState<boolean>(false);
  const [email, setemail] = useState<string>('');
  const [item, setitem] = useState([]);
  const [sub, setSub] = useState([]);
  const { t } = useTranslation();

  const handleDownloadClick = async () => {
    setloading(true);

    try {
      const data = {
        search: email || '',
      };
      const response: response = await SearchUser(data?.search);
      setitem(response.data as []);
      notificationController.success({ message: t('common.success') });
      setloading(false);
      setshow(true);
    } catch (error) {
      console.error('Error downloading users:', error);
      // Handle errors appropriately
    }

    setloading(false);
  };
  const handleSubClick = async () => {
    setloading(true);

    try {
      const data = {
        search: email || '',
      };
      const response: response = await SearchUserSub(data?.search);
      setSub(response.data as []);
      setloading(false);
      setshow(true);
    } catch (error) {
      console.error('Error downloading users:', error);
      // Handle errors appropriately
    }

    setloading(false);
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: 'Product',
      dataIndex: 'Product',
      key: 'name',
      align: 'center',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'percent',
      align: 'center',
    },

    {
      title: 'PeriodStart',
      key: 'PeriodStart',
      dataIndex: 'PeriodStart',
      align: 'center',
      render: (item) => {
        return item && moment(item).format('DD MMMM YYYY HH:mm:ss');
      },
    },
    {
      title: 'PeriodEnd',
      key: 'PeriodEnd',
      dataIndex: 'PeriodEnd',
      align: 'center',
      render: (item) => {
        return item && moment(item).format('DD MMMM YYYY HH:mm:ss');
      },
    },
  ];

  return (
    <>
      <WrapperBox>
        <SearchInputNew
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e) => setemail(e.target.value as any)}
          placeholder="Enter Email or UID"
          allowClear
        />
        <YourSearchbutton
          disabled={loading || email.trim() === ''}
          onClick={() => (handleDownloadClick(), handleSubClick())}
        >
          search
        </YourSearchbutton>
      </WrapperBox>
      {show ? <ActivityStory item={item as []} /> : ''}
      {item.length > 0 && (
        <div
          style={{
            boxShadow: '-3px -1px 9px 0px rgba(5, 5, 5, 0.48)',
            padding: '20px',
            marginTop: '10px',
          }}
        >
          <BaseTable
            dataSource={sub}
            columns={columns}
            rowKey={(record) => record._id}
            // onChange={handleTableChange}
            scroll={{ x: 800 }}
            bordered
          />
        </div>
      )}
    </>
  );
};

export default ViewUser;
