import React, { useEffect, useState } from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  SidebarContact,
  SidebarLinkRoute,
} from './SidebarElements';
import Model from '@app/components/Model';
import ContactUs from '@app/pages/ContactUs/ContactUs';
import { readUser } from '@app/services/localStorage.service';
import { IoCartOutline } from 'react-icons/io5';
import { Badge } from 'antd';
import ProductCard from '../Addtocart/addToCart';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { selectCartItems } from '@app/store/slices/addToCartSlice';

interface SidebarProps {
  isOpen: boolean;
  toggle: () => void;
  userLoggedIn: boolean;
  contactToggle: () => void;
}
interface ModalState {
  isModalOpen: boolean;
  isModalOpenCart: boolean;
}
const Sidebar: React.FC<SidebarProps> = ({ userLoggedIn, isOpen, toggle }) => {
  const user = readUser();
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, isModalOpenCart: false });
  const cartItems = useAppSelector(selectCartItems);
  const cart = localStorage.getItem('subscribeplan');
  const subscribeplan = cart ? JSON.parse(cart) : null;
  const { isModalOpen, isModalOpenCart } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true, isModalOpenCart: false });
  };
  const showModalCart = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: true });
  };
  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };
  const [scrollNav, setScrollNav] = useState<boolean>(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  const product = { id: cartItems.length + 1, name: 'dsdf', price: 99 };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);
  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink to="about" onClick={toggle} smooth={true} duration={500} spy={true} offset={-140}>
              About
            </SidebarLink>
            <SidebarLink to="features" onClick={toggle} smooth={true} duration={500} spy={true} offset={-140}>
              Features
            </SidebarLink>
            <SidebarLink to="services" onClick={toggle} smooth={true} duration={500} spy={true} offset={-140}>
              Testimonials
            </SidebarLink>
            {userLoggedIn && (
              <SidebarLinkRoute to="/dashboard" onClick={toggle}>
                Dashboard
              </SidebarLinkRoute>
            )}
            {(user?.role === 'ADMIN' || user?.role === 'SUPERADMIN') && (
              <SidebarLinkRoute to="/admin">Admin</SidebarLinkRoute>
            )}
            <SidebarContact onClick={showModal}>Contact Us</SidebarContact>
            {!userLoggedIn && (
              <SidebarLink to="signup" onClick={toggle}>
                Sign Up
              </SidebarLink>
            )}
          </SidebarMenu>
          {userLoggedIn && (
            <SideBtnWrap>
              <div style={{ margin: '10px 0px' }}>
                <Badge color="#00B4A0" count={subscribeplan?.length} showZero>
                  <span style={{ color: 'white' }}>
                    <IoCartOutline onClick={showModalCart} fontSize="30px" />
                  </span>
                </Badge>
              </div>
            </SideBtnWrap>
          )}
          <SideBtnWrap>
            {!userLoggedIn ? (
              <SidebarRoute to="/auth/login">Sign In</SidebarRoute>
            ) : (
              <SidebarRoute to="/logout">Logout</SidebarRoute>
            )}
          </SideBtnWrap>
        </SidebarWrapper>
      </SidebarContainer>
      <Model
        title="Contact"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<ContactUs handleCancel={handleCancel} />}
      />
      <Model
        title="Cart"
        open={isModalOpenCart}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<ProductCard product={product} handleClose={handleCancel} handleOpen={showModalCart} />}
      />
    </>
  );
};

export default Sidebar;
