import React from 'react';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesH2,
  ServicesP,
  Img,
} from './ExportAdvicestyles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

const ExportAdvice: React.FC = () => {
  return (
    <ServicesContainer id="features">
      <ServicesWrapper>
        <ServicesH2>Export Connect is your link to global markets and trusted export advice.</ServicesH2>
        <Img src="https://exportconnectportal.com/img/imgpsh_.d1e315f2.png" />
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default ExportAdvice;
