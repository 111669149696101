import CustomModal from '@app/components/Model';
import * as S from '../ConsumerBehaviour/ConsumerBehaviourButton.styles';
import { useEffect, useState } from 'react';
import QuillEditor from './QuillEditor';
import { GetGroceryRetail } from '@app/api/markets/MarketDetails/GroceryRetail/GetGroceryRetail.api';
import { useParams } from 'react-router-dom';
import Model from '@app/components/Model';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRowBox, ListWrapper } from '../../MarketsPage.styles';
import { Button, Input } from 'antd';
import { GroceryRetailStyle } from './GroceryRetail.styles';
import { notificationController } from '@app/controllers/notificationController';
import { GRDelete } from '@app/api/markets/MarketDetails/GroceryRetail/GRDelete.api';
import { PutGroceryRetail } from '@app/api/markets/MarketDetails/GroceryRetail/PutGroceryRetail.api';
import { PCDelete } from '@app/api/markets/MarketDetails/GroceryRetail/PCDelete.api';
import { BasePopconfirm } from '@app/components/common/BasePopconfirm/BasePopconfirm';
import { Scroll_box } from '../DemographicFactors/DemographicFactors.styles';

interface GRData {
  grocery_retailing_source: string;
  petro_convenience_source: string;
  petro_convenience: [];
  grocery_retailing: [];
  key_trends: string;
}
interface GR {
  name?: string;
  percentage: number;
  _id?: string;
}
interface ModalState {
  _id: string;
  isModalOpen: boolean;
}
const GroceryRetail = (): JSX.Element => {
  const [dataLoading, setDataLoading] = useState(false);
  const [GRFolders, setGRFolders] = useState<GR[]>([]);
  const [GRBackup, setGRBackup] = useState<GR[]>([]);
  const [PCFolders, setPCFolders] = useState<GR[]>([]);
  const [PCBackup, setPCBackup] = useState<GR[]>([]);
  const [formData, setFormData] = useState<GRData>();
  const [GRLoading, setGRLoading] = useState(false);
  const [GRDelLoading, setGRDelLoading] = useState('');
  const [PCLoading, setPCLoading] = useState(false);
  const [PCDelLoading, setPCDelLoading] = useState('');
  const [GRSource, setGRSource] = useState('');
  const [PCSource, setPCSource] = useState('');
  const [GROpen, setGROpen] = useState(false);
  const [PCOpen, setPCOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [modalState, setModalState] = useState({ id: '', isModalOpen: false });
  const [GRmodalState, setGRModalState] = useState<ModalState>({ isModalOpen: false, _id: '' });
  const [PCmodalState, setPCModalState] = useState<ModalState>({ isModalOpen: false, _id: '' });
  const fetchData = async () => {
    setDataLoading(true);
    try {
      const response = await GetGroceryRetail(id as string);
      setFormData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setGRSource(formData?.grocery_retailing_source as string);
    setPCSource(formData?.petro_convenience_source as string);
    setGRFolders((formData?.grocery_retailing as GR[]) ?? []);
    setGRBackup((formData?.grocery_retailing as GR[]) ?? []);
    setPCFolders((formData?.petro_convenience as GR[]) ?? []);
    setPCBackup((formData?.petro_convenience as GR[]) ?? []);
  }, [formData]);
  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  const showGRModal = () => {
    setGRModalState({ ...GRmodalState, isModalOpen: true });
  };

  const handleGROk = () => {
    setGRModalState({ ...GRmodalState, isModalOpen: false });
  };

  const handleGRCancel = () => {
    setGRModalState({ ...GRmodalState, isModalOpen: false });
  };
  const handleGRReset = () => {
    setGRBackup([...GRFolders]);
  };
  const handleGRNameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...GRBackup])); // Deep clone the backup array
    newInputs[index].name = event.target.value;
    setGRBackup(newInputs);
  };
  const handleGRValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...GRBackup])); // Deep clone the backup array
    newInputs[index].percentage = Number(event.target.value);
    setGRBackup(newInputs);
  };

  const addGRItem = () => {
    if (!GRBackup || !Array.isArray(GRBackup)) {
      setGRBackup([{ name: '', percentage: 0 }]);
    } else {
      setGRBackup([...GRBackup, { name: '', percentage: 0 }]);
    }
  };

  const deleteGRItem = (i: number) => {
    setGRBackup((prevArray) => {
      // Create a copy of the array without the element at the specified index
      const newArray = [...prevArray.slice(0, i), ...prevArray.slice(i + 1)];
      return newArray;
    });
  };
  const handleGRDelete = async (deleteId: string) => {
    setGRDelLoading(deleteId);
    try {
      // Simulate a delay, replace this with your actual API call
      // Assuming ChangePassword is an asynchronous function, use await here
      await GRDelete(id as string, deleteId);
      notificationController.success({ message: 'Success' });
      fetchData();
    } catch (error) {
      console.error('Error while deleting data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setGRDelLoading('');
    }
  };
  const saveGRsource = async () => {
    setGRLoading(true);
    try {
      // Simulate a delay, replace this with your actual API cal
      // Assuming ChangePassword is an asynchronous function, use await here
      await PutGroceryRetail(id as string, { grocery_retailing_source: GRSource });
      fetchData();
      notificationController.success({ message: 'success' });
    } catch (error) {
      console.error(error);
    } finally {
      setGRLoading(false);
    }
  };
  const showPCModal = () => {
    setPCModalState({ ...GRmodalState, isModalOpen: true });
  };

  const handlePCOk = () => {
    setPCModalState({ ...PCmodalState, isModalOpen: false });
  };

  const handlePCCancel = () => {
    setPCModalState({ ...PCmodalState, isModalOpen: false });
  };
  const handlePCReset = () => {
    setPCBackup([...PCFolders]);
  };
  const handlePCNameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...PCBackup])); // Deep clone the backup array
    newInputs[index].name = event.target.value;
    setPCBackup(newInputs);
  };
  const handlePCValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...PCBackup])); // Deep clone the backup array
    newInputs[index].percentage = Number(event.target.value);
    setPCBackup(newInputs);
  };

  const addPCItem = () => {
    if (!PCBackup || !Array.isArray(PCBackup)) {
      setPCBackup([{ name: '', percentage: 0 }]);
    } else {
      setPCBackup([...PCBackup, { name: '', percentage: 0 }]);
    }
  };

  const deletePCItem = (i: number) => {
    setPCBackup((prevArray) => {
      // Create a copy of the array without the element at the specified index
      const newArray = [...prevArray.slice(0, i), ...prevArray.slice(i + 1)];
      return newArray;
    });
  };
  const handlePCDelete = async (deleteId: string) => {
    setPCDelLoading(deleteId);
    try {
      // Simulate a delay, replace this with your actual API call
      // Assuming ChangePassword is an asynchronous function, use await here
      await PCDelete(id as string, deleteId);
      notificationController.success({ message: 'Success' });
      fetchData();
    } catch (error) {
      console.error('Error while deleting data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setPCDelLoading('');
    }
  };
  const savePCsource = async () => {
    setPCLoading(true);
    try {
      // Simulate a delay, replace this with your actual API cal
      // Assuming ChangePassword is an asynchronous function, use await here
      await PutGroceryRetail(id as string, { petro_convenience_source: PCSource });
      fetchData();
      notificationController.success({ message: 'success' });
    } catch (error) {
      console.error(error);
    } finally {
      setPCLoading(false);
    }
  };
  const handleGRSubmit = async () => {
    setLoading(true);
    try {
      // Assuming ChangePassword is an asynchronous function, use await here
      await PutGroceryRetail(id as string, { grocery_retailing: { type: GRBackup } });
      fetchData();
      notificationController.success({ message: 'Success' });
      setGROpen(false);
    } catch (err) {
      notificationController.error({ message: 'Error Updating Market' });
      setGROpen(false);
    } finally {
      setLoading(false);
    }
  };
  const handlePCSubmit = async () => {
    setLoading(true);
    try {
      // Assuming ChangePassword is an asynchronous function, use await here
      await PutGroceryRetail(id as string, { petro_convenience: { type: PCBackup } });
      notificationController.success({ message: 'Success' });
      setPCOpen(false);
      fetchData();
    } catch (err) {
      notificationController.error({ message: 'Error Updating Market' });
      setPCOpen(false);
    } finally {
      setLoading(false);
    }
  };
  async function closeAllModal() {
    await setGROpen(false);
    await setPCOpen(false);
    handleGRCancel();
    handlePCCancel();
  }
  const { isModalOpen } = modalState;
  const GRhasZeroPercentageOrEmptyName = GRBackup?.some((item) => item.percentage === 0 || item?.name?.trim() === '');
  const PChasZeroPercentageOrEmptyName = GRBackup?.some((item) => item.percentage === 0 || item?.name?.trim() === '');
  const disableGRButton = JSON.stringify(GRBackup) === JSON.stringify(GRFolders);
  const disablePCButton = JSON.stringify(PCBackup) === JSON.stringify(PCFolders);
  return (
    <>
      <S.ButtonRowWrap>
        <S.BaseRowButton onClick={showModal}>Key Trends</S.BaseRowButton>
      </S.ButtonRowWrap>
      <GroceryRetailStyle>
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ margin: '0' }}>Grocery Retailing</p>
          <EditOutlined onClick={showGRModal} />
        </div>
        <div>
          <p style={{ margin: '0', fontSize: 'small' }}>Number of stores</p>
          {GRFolders?.length === 0 ? (
            <div>
              <p style={{ border: '1px solid black', padding: '10px' }}>No items have been added</p>
            </div>
          ) : (
            <div>
              {GRFolders?.map((element) => (
                <ListWrapper key={element?._id}>
                  <div className="hoverDiv">
                    <BaseRowBox key={element?._id} gutter={[16, 16]}>
                      <div>{element?.name}</div>
                      <div>{element?.percentage}</div>
                    </BaseRowBox>
                  </div>
                  <BaseButton
                    style={{ margin: '7px 10px' }}
                    onClick={() => handleGRDelete(element?._id as string)}
                    disabled={GRDelLoading === element?._id}
                    loading={GRDelLoading === element?._id}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </ListWrapper>
              ))}
            </div>
          )}
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Input placeholder="source" value={GRSource} onChange={(e) => setGRSource(e.target.value)} />
        </div>
        <Button type="primary" disabled={GRLoading} loading={GRLoading} onClick={() => saveGRsource()}>
          Save
        </Button>
      </GroceryRetailStyle>
      <GroceryRetailStyle>
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ margin: '0' }}>Convenience/Petro-Convenience</p>
          <EditOutlined onClick={showPCModal} />
        </div>
        <div>
          <p style={{ margin: '0', fontSize: 'small' }}>Number of stores</p>
          {PCFolders?.length === 0 ? (
            <div>
              <p style={{ border: '1px solid black', padding: '10px' }}>No items have been added</p>
            </div>
          ) : (
            <div style={{ boxShadow: 'rgba(35, 78, 84, 0.61)', borderRadius: '3px' }}>
              {PCFolders?.map((element) => (
                <ListWrapper key={element?._id}>
                  <div className="hoverDiv">
                    <BaseRowBox key={element?._id} gutter={[16, 16]}>
                      <div>{element?.name}</div>
                      <div>{element?.percentage}</div>
                    </BaseRowBox>
                  </div>
                  <BaseButton
                    style={{ margin: '7px 10px' }}
                    onClick={() => handlePCDelete(element?._id as string)}
                    disabled={PCDelLoading === element?._id}
                    loading={PCDelLoading === element?._id}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </ListWrapper>
              ))}
            </div>
          )}
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Input placeholder="source" value={PCSource} onChange={(e) => setPCSource(e.target.value)} />
        </div>
        <Button type="primary" disabled={PCLoading} loading={PCLoading} onClick={() => savePCsource()}>
          Save
        </Button>
      </GroceryRetailStyle>
      <CustomModal
        title="Key Trends"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<QuillEditor handleOk={handleCancel} data={formData?.key_trends as string} fetchData={fetchData} />}
      />
      <Model
        title="Grocery Retailing"
        open={GRmodalState.isModalOpen}
        onOk={handleGROk}
        onCancel={closeAllModal}
        content={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 0px 10px' }}>
              <BaseButton severity="warning" disabled={disableGRButton} onClick={handleGRReset}>
                Reset
              </BaseButton>
              <BasePopconfirm
                placement="bottom"
                title={'Are you sure you want to make these changes?'}
                onConfirm={handleGRSubmit}
                okText="Yes"
                cancelText="No"
                onCancel={() => setGROpen(false)}
                open={GROpen}
              >
                <BaseButton
                  onClick={() => setGROpen(true)}
                  disabled={loading || GRhasZeroPercentageOrEmptyName || disableGRButton}
                  loading={loading}
                >
                  Apply
                </BaseButton>
              </BasePopconfirm>
            </div>

            <Scroll_box>
              {GRBackup?.map((element, index) => (
                <div
                  key={element._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    gap: '10px',
                  }}
                >
                  <Input
                    type="text"
                    value={GRBackup[index].name}
                    onChange={(event) => handleGRNameChange(event, index)}
                  />
                  <Input
                    type="number"
                    value={GRBackup[index].percentage}
                    onChange={(event) => handleGRValueChange(event, index)}
                  />
                  <BaseButton disabled={'_id' in element} onClick={() => deleteGRItem(index)}>
                    <DeleteOutlined />
                  </BaseButton>
                </div>
              ))}
            </Scroll_box>
            <Button type="primary" onClick={addGRItem}>
              Add item
            </Button>
          </>
        }
      />
      <Model
        title="Convenience/Petro-Convenience"
        open={PCmodalState.isModalOpen}
        onOk={handlePCOk}
        onCancel={closeAllModal}
        content={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 0px 10px' }}>
              <BaseButton severity="warning" disabled={disablePCButton} onClick={handlePCReset}>
                Reset
              </BaseButton>
              <BasePopconfirm
                placement="bottom"
                title={'Are you sure you want to make these changes?'}
                onConfirm={handlePCSubmit}
                okText="Yes"
                cancelText="No"
                onCancel={() => setPCOpen(false)}
                open={PCOpen}
              >
                <BaseButton
                  onClick={() => setPCOpen(true)}
                  disabled={loading || PChasZeroPercentageOrEmptyName || disablePCButton}
                  loading={loading}
                >
                  Apply
                </BaseButton>
              </BasePopconfirm>
            </div>

            <Scroll_box>
              {PCBackup?.map((element, index) => (
                <div
                  key={element._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    gap: '10px',
                  }}
                >
                  <Input
                    type="text"
                    value={PCBackup[index].name}
                    onChange={(event) => handlePCNameChange(event, index)}
                  />
                  <Input
                    type="number"
                    value={PCBackup[index].percentage}
                    onChange={(event) => handlePCValueChange(event, index)}
                  />
                  <BaseButton disabled={'_id' in element} onClick={() => deletePCItem(index)}>
                    <DeleteOutlined />
                  </BaseButton>
                </div>
              ))}
            </Scroll_box>
            <Button type="primary" onClick={addPCItem}>
              Add item
            </Button>
          </>
        }
      />
    </>
  );
};
export default GroceryRetail;
