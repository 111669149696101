import { httpApi } from '@app/api/http.api';

type _id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;

export const AddCategoryTableData = (
  _id: _id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
  formData,
): Promise<undefined> => {
  return httpApi
    .post<undefined>(
      `/categories/categoryMarket/${_id}/${category_market_id}/${cat_market_sabcat_id}/categoryData/tableDataAdd`,
      formData,
    )
    .then(({ data }) => data);
};

// Post
// localhost:3000/localhost/categories/categoryMarket/65f294e4a85b1949f49ce9ed/65f295eea85b1949f49cebae/65f296a9a85b1949f49cec8d/categoryData/tableDataAdd
