import { useEffect, useState } from 'react';
import * as S from '../ConsumerBehaviour/ConsumerBehaviourButton.styles';
import CustomModal from '@app/components/Model';
import { GetMarketAccessReq } from '@app/api/markets/MarketDetails/MarketAccessReq/GetMarketAccessReq.api';
import { useParams } from 'react-router-dom';
import MarketAccessQuill from './MarketAccessQuill';

interface marketAccess {
  key_regulators: string;
  import_procedure: string;
  documentation_required: string;
  certificates_required: string;
  labelling_requirements: string;
  packaging_requirements: string;
  tariffs_levied: string;
  non_tarrif_barriers: string;
}

const MarketAccess = (): JSX.Element => {
  const [formData, setFormData] = useState<marketAccess>();
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState({ id: '', isModalOpen: false, name: '', text: '' });
  const { id } = useParams();
  const showModal = (name: string, text: string) => {
    setModalState({ ...modalState, isModalOpen: true, name: name, text: text ?? '' });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, name: '', text: '' });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, name: '', text: '' });
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetMarketAccessReq(id as string);
      setFormData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { isModalOpen } = modalState;
  return (
    <>
      <S.ButtonRowWrap>
        <S.BaseRowButton onClick={() => showModal('Key Regulators', formData?.key_regulators as string)}>
          Key Regulators
        </S.BaseRowButton>
        <S.BaseRowButton onClick={() => showModal('Import Procedure', formData?.import_procedure as string)}>
          Import Procedure
        </S.BaseRowButton>
        <S.BaseRowButton
          onClick={() => showModal('Documentation Required', formData?.documentation_required as string)}
        >
          Documentation Required
        </S.BaseRowButton>
        <S.BaseRowButton onClick={() => showModal('Certificates Required', formData?.certificates_required as string)}>
          Certificates Required
        </S.BaseRowButton>
        <S.BaseRowButton
          onClick={() => showModal('General Labelling Requirements', formData?.labelling_requirements as string)}
        >
          General Labelling Requirements
        </S.BaseRowButton>
        <S.BaseRowButton
          onClick={() => showModal('Packaging Requirements', formData?.packaging_requirements as string)}
        >
          Packaging Requirements
        </S.BaseRowButton>
        <S.BaseRowButton onClick={() => showModal('Tarrifs Levied', formData?.tariffs_levied as string)}>
          Tarrifs Levied
        </S.BaseRowButton>
        <S.BaseRowButton onClick={() => showModal('Non-Tarrif Barriers', formData?.non_tarrif_barriers as string)}>
          Non-Tarrif Barriers
        </S.BaseRowButton>
      </S.ButtonRowWrap>
      <CustomModal
        title={modalState.name}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={
          <MarketAccessQuill
            handleOk={handleCancel}
            data={modalState.text}
            fetchData={fetchData}
            type={modalState.name}
          />
        }
      />
    </>
  );
};
export default MarketAccess;
