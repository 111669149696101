import { httpApi } from '@app/api/http.api';

export const AddVideoModule = (pageDetail: FormData, Id: string): Promise<undefined> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return httpApi.post<undefined>(`videoFolders/${Id}/videos/addVideos`, pageDetail, config).then(({ data }) => data);
};
