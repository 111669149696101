// import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { useEffect, useState } from 'react';
import { Button } from '../../MarketInsights/Insights.styles';
import { useParams } from 'react-router-dom';
import { EIGet } from '@app/api/markets/MarketDetails/EconomicIndicator/EIGet.api';
import { EIPut } from '@app/api/markets/MarketDetails/EconomicIndicator/EIPut.api';
import CustomModal from '@app/components/Model';
import QuillEditor from './QuillEditor';
import { Form } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import dayjs from 'dayjs';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';

interface EIData {
  ease_ranking: string;
  gdb_billions: string;
  gdb_per_capita: string;
  human_ranking: string;
  logistics_ranking: string;
  market_id: string;
  quality_ranking: string;
  source_date: Date;
  trade_agreement: string;
}
const EIForm = (): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<EIData>();
  const [initialFormData, setInitialFormData] = useState<EIData>();
  const [EIloading, setEILoading] = useState(false);
  const { id } = useParams();
  const [form] = Form.useForm();
  const fetchData = async () => {
    setEILoading(true);
    try {
      const response = await EIGet(id as string);
      setFormData(response?.data);
      setInitialFormData(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setEILoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (values: EIData) => {
    console.log('values', values);
    setLoading(true);
    try {
      await EIPut(id as string, values);
      fetchData();
      notificationController.success({
        message: 'Data Saved Successfully',
      });
    } catch (error) {
      console.error('Error while submitting data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setLoading(false);
    }
  };
  const [modalState, setModalState] = useState({ id: '', isModalOpen: false });
  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  const { isModalOpen } = modalState;
  return (
    <>
      {!EIloading ? (
        (formData === null || formData) && (
          <div style={{ padding: '20px', boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 15px 1px', borderRadius: '5px' }}>
            {' '}
            <BaseForm
              name="EIForm"
              form={form}
              initialValues={{
                ...formData,
                source_date: formData?.source_date ? dayjs(formData.source_date) : null,
              }}
              onFinish={handleSubmit}
            >
              <Auth.FormItem
                name="source_date"
                label="Source Date"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <DayjsDatePicker />
              </Auth.FormItem>

              <Auth.FormItem
                name="gdb_billions"
                label="GDP (billions):"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Auth.FormInput type="number" />
              </Auth.FormItem>
              <Auth.FormItem
                name="gdb_per_capita"
                label="GDP per capita:"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Auth.FormInput type="number" />
              </Auth.FormItem>
              <Auth.FormItem
                name="quality_ranking"
                label="Quality of living ranking:"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Auth.FormInput type="number" />
              </Auth.FormItem>
              <Auth.FormItem
                name="human_ranking"
                label="Human development index ranking:"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Auth.FormInput type="number" />
              </Auth.FormItem>
              <Auth.FormItem
                name="logistics_ranking"
                label="Logistics performance index ranking:"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Auth.FormInput type="number" />
              </Auth.FormItem>
              <Auth.FormItem
                name="ease_ranking"
                label="Ease of doing business ranking:"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Auth.FormInput type="number" />
              </Auth.FormItem>
              {/* <BaseForm.Item name="status" valuePropName="checked" noStyle>
        <Auth.FormCheckbox>
          <Auth.Text>Active</Auth.Text>
        </Auth.FormCheckbox>
      </BaseForm.Item> */}
              {/* Market must be activated before it is visible in Market Insights. Complete market insights before activating. */}
              <BaseForm.Item noStyle>
                <Auth.SubmitButton type="primary" disabled={loading} loading={loading} htmlType="submit">
                  Save Details
                </Auth.SubmitButton>
              </BaseForm.Item>
            </BaseForm>
            <CustomModal
              title="Trade Agreements"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              content={
                <QuillEditor handleOk={handleCancel} data={formData?.trade_agreement as string} fetchData={fetchData} />
              }
            />
            <Button style={{ width: '100%' }} onClick={showModal}>
              Trade Agreements
            </Button>
          </div>
        )
      ) : (
        <BaseSpin />
      )}
    </>
  );
};
export default EIForm;
