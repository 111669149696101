import { httpApi } from '@app/api/http.api';
interface formData {
  _id?: string;
  title: string;
  description: string;
}
export const AddCategoryContent = (
  id: string,
  category_market_id: string,
  cat_market_sabcat_id: string,
  formData: formData,
) => {
  return httpApi
    .post(
      `categories/categoryMarket/${id}/${category_market_id}/${cat_market_sabcat_id}/categoryContents/categoryContentAdd`,
      formData,
    )
    .then(({ data }) => data);
};

// this api is to Add the category contents in category data table
