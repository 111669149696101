/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from './TradePage.styles';
import { BasicTableRow } from '@app/api/table.api';
import { ColumnsType } from 'antd/lib/table';
import { pageDetail } from '@app/api/pages/page.Details.api';
import { PageGet } from '@app/api/pages/pages.api';
import { useLocation } from 'react-router-dom';
import Model from '@app/components/Model';
import { GetTradeFixedData } from '@app/api/categories/TradeDataFixed/GetTradeFixedData.api';
import '../../assets/common.css';
import moment from 'moment';

interface Response {
  status: boolean;
  message: string;
  data?: any[];
}

interface Props {
  tradeData?: Response;
  exportData?: Response;
}
interface ModalState {
  isModalOpen: boolean;
}
interface formData {
  source: string;
  importer_ranking: number;
  imported_value: number;
  year_range_short_term: {
    from: number;
    to: number;
  };
  year_range_long_term: {
    from: number;
    to: number;
  };
  date: Date;
  import_description: string;
  export_description: string;
}
const TradeData: React.FC<Props> = ({ tradeData, exportData }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const site = useLocation();

  const url = site.pathname;
  const urlName = url.split('/');
  const [pagename, setPageName] = useState<any>('');
  const [pagedetails, setPagedetails] = useState<any>('');
  const [formData, setFromData] = useState<formData>();

  const PagenameId = pagename && pagename.find((page: any) => page.slug === 'trade-data');
  const memoPagenameId = useMemo(() => PagenameId, [PagenameId]);

  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });

  const { isModalOpen } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  useEffect(() => {
    PageGet()
      .then((data: any) => setPageName(data.data))
      .catch((err: any) => console.log('err', err));
  }, []);
  console.log('site', site);
  const fetchTradeFormData = async () => {
    setLoading(true);
    try {
      const response = await GetTradeFixedData(
        site.state.category_id._id,
        site.state.market_id._id,
        site.state.cat_market_sabcat_id,
      );
      setFromData(response.data);
      console.log('responseeee', response);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTradeFormData();
  }, []);
  console.log('tradeData', tradeData);
  useEffect(() => {
    if (urlName[2] && memoPagenameId?._id) {
      pageDetail(memoPagenameId?._id)
        .then((data: any) => setPagedetails(data.data))

        .catch((err: any) => console.log('err', err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoPagenameId?._id]);

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('Rank'),
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a, b) => a.rank - b.rank, // Define custom sorter function
      defaultSortOrder: 'ascend', // Set default sort order
      showSorterTooltip: false,
    },
    {
      title: 'Exporter',
      dataIndex: 'exporter',
      showSorterTooltip: false,
      key: 'exporter',
    },
    {
      title: 'Value imported (USD thousands)',
      dataIndex: 'imported_value',
      key: 'imported_value',
    },
    {
      title: 'Quantity imported (tons)',
      key: 'imported_quantity',
      dataIndex: 'imported_quantity',
    },
    {
      title:
        formData?.year_range_long_term?.from && formData?.year_range_long_term?.to ? (
          <>
            Annual growth in imported value %<br />
            {formData?.year_range_long_term?.from} - {formData?.year_range_long_term?.to}
          </>
        ) : (
          'Annual growth in imported value %'
        ),
      key: 'annual_growth_long_term',
      dataIndex: 'annual_growth_long_term',
    },
    {
      title:
        formData?.year_range_short_term?.from && formData?.year_range_short_term?.to ? (
          <>
            Annual growth in imported value %<br />
            {formData?.year_range_short_term?.from} - {formData?.year_range_short_term?.to}
          </>
        ) : (
          'Annual growth in imported value %'
        ),
      key: 'annual_growth_short_term',
      dataIndex: 'annual_growth_short_term',
    },
    {
      title:
        formData?.year_range_long_term?.from && formData?.year_range_long_term?.to ? (
          <>
            Annual growth in imported quantity %<br />
            {formData?.year_range_long_term?.from} - {formData?.year_range_long_term?.to}
          </>
        ) : (
          'Annual growth in imported quantity %'
        ),
      key: 'imported_quantity',
      dataIndex: 'imported_quantity',
    },
  ];
  const renderTable = (dataSource: any[], title: string) => (
    <S.TradeDataTable padding={[10, 10]}>
      <h3>{title}</h3>
      <BaseTable
        columns={columns.map((column) => ({
          ...column,
          render: (text: any) => {
            const numericValue = parseFloat(text);
            if (!isNaN(numericValue)) {
              return <span style={{ color: numericValue >= 0 ? 'green' : 'red' }}>{text}</span>;
            }
            return text || 'N/A';
          },
        }))}
        dataSource={dataSource}
        loading={loading}
        rowKey={(record) => record._id}
        scroll={{ x: 800 }}
        bordered
        pagination={false}
      />
    </S.TradeDataTable>
  );

  return (
    <>
      {pagedetails && pagedetails[0]?.status && (
        <div style={{ width: '370px', height: '230px', margin: '0 auto' }} onClick={showModal}>
          <img src={pagedetails[0]?.image} height="100%" width="100%" />
        </div>
      )}

      {pagedetails[0]?.video && (
        <Model
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          content={
            <>
              <video width="100%" controls autoPlay>
                <source src={pagedetails[0]?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          }
        />
      )}

      <div>
        {
          <div style={{ padding: '10px', backgroundColor: '#F5F5F5' }}>
            <div>
              {
                <>
                  <div>
                    <div style={{ margin: '10px 0px', textAlign: 'center' }}>
                      {site?.state?.market_id?.name} is the world&apos;s {formData?.importer_ranking} largest importer
                      of {site?.state?.matching_subcategory[0]?.sub_category_name}. As of{' '}
                      {moment(formData?.date).format('YYYY')} imported value has surpassed US $
                      {formData?.imported_value} million.
                    </div>

                    <span
                      style={{ margin: '10px 0px', textAlign: 'center' }}
                      dangerouslySetInnerHTML={{ __html: formData?.import_description }}
                    ></span>
                  </div>
                  <p style={{ textAlign: 'end' }}>Source: {formData?.source}</p>
                </>
              }
            </div>
            <div>{renderTable(tradeData?.data || [], 'Top 10 Countries your Market Imports from.')}</div>
          </div>
        }
      </div>
      <div style={{ marginTop: '20px' }}>
        {
          <div style={{ padding: '10px', backgroundColor: '#F5F5F5' }}>
            <div>
              <p style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: formData?.export_description }} />
            </div>
            <div>{renderTable(exportData?.data || [], 'Top 10 Countries Australia Export to.')}</div>
          </div>
        }
      </div>
    </>
  );
};

export default TradeData;
