import { PostCompetitorCSV } from '@app/api/categories/Competitor Data/PostCompetitorCSV.api';
import { PostExportCSV } from '@app/api/categories/TradeExportTableData/PostExportCSV.api';
import { PostImportCSV } from '@app/api/categories/TradeImportTableData/PostImportCSV.api';
import { notificationController } from '@app/controllers/notificationController';
import { Button, Radio, RadioChangeEvent } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { ChangeEvent, useState } from 'react';
import Model from '@app/components/Model';
import { useParams } from 'react-router-dom';
interface ErrorType {
  message: string;
}
interface ModalState {
  isModalOpen: boolean;
}
const AddFromCsvModel = ({ type }: { type: string }) => {
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const { id, marketId, subcatId } = useParams();
  const [value, setValue] = useState('replace');
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState<string[]>();
  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (type === 'export')
        await PostExportCSV(id as string, marketId as string, subcatId as string, value, { newcsvdata: input });
      if (type === 'import')
        await PostImportCSV(id as string, marketId as string, subcatId as string, value, { newcsvdata: input });
      if (type === 'competitor')
        await PostCompetitorCSV(id as string, marketId as string, subcatId as string, value, { newcsvdata: input });
      notificationController.success({ message: 'success' });
    } catch (error) {
      const Error: ErrorType = error as ErrorType;
      setErrorText(Error?.message?.split(',')?.slice(1));
      setModalState({ isModalOpen: true });
    } finally {
      setLoading(false);
    }
  };
  const UserGuide = () => (
    <div>
      {errorText?.map((element) => {
        return (
          <>
            <span>{element}</span>
            <br />
          </>
        );
      })}
      <Button onClick={handleCancel}>OK</Button>
    </div>
  );
  console.log('errorText', errorText);
  return (
    <>
      <div>
        <TextArea
          placeholder="Place CSV text here"
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChange(event)}
          autoSize={{ minRows: 4 }}
        />
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="replace">Replace</Radio>
            <Radio value="add">Add</Radio>
          </Radio.Group>
        </div>
      </div>

      <Button style={{ display: 'flex', margin: '0 auto' }} disabled={loading} onClick={() => handleSubmit()}>
        Apply
      </Button>
      <Model
        title="The correct headers are"
        open={modalState.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<UserGuide />}
      />
    </>
  );
};

export default AddFromCsvModel;
