import React from 'react';
import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';

interface CustomTabBarProps {
  activeKey: string;
  onChange: (key: string) => void;
  tabs: { key: string; label: string; icon: React.ReactElement }[];
}

const CustomTabBar: React.FC<CustomTabBarProps> = ({ tabs, activeKey, onChange }) => {
  return (
    <Tabs activeKey={activeKey} onChange={onChange}>
      {tabs.map((tab) => (
        <Tabs.TabPane
          key={tab.key}
          tab={
            <span style={{ color: tab.key === activeKey ? '#294d4a' : 'black' }}>
              {tab.icon}
              {tab.label}
            </span>
          }
        />
      ))}
    </Tabs>
  );
};

export default CustomTabBar;
