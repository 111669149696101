import { httpApi } from '@app/api/http.api';
interface response {
  status: boolean;
  message: string;
  data?: dfData;
}
interface dfData {
  dominant_religious_groups?: PE[];
  expatriate_population?: number;
  market_id?: number;
  median_age?: number;
  population_ethnicity?: PE[];
  population_growth?: number;
  total_population?: number;
  urban_population?: number;
  population_source?: string;
  dominant_source?: string;
}
interface PE {
  name?: string;
  percentage: number;
  _id?: string;
}
export const DFGet = (id: string): Promise<response> => {
  return httpApi.get<response>(`/marketInsightsDf/${id}`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
