import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { UploadOutlined } from '@ant-design/icons';
import { Select as AntSelect, Dropdown, Image, Modal, Space } from 'antd';
// import { notificationController } from '@app/controllers/notificationController';
import styled from 'styled-components';
import { pageDetailAdd } from '@app/api/pages/Page.Update.api';
import { notificationController } from '@app/controllers/notificationController';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { pageDetail } from '@app/api/pages/page.Details.api';

import Removeicon from '@app/components/common/icons/RemoveIcon';
import { pageMediaDelete } from '@app/api/pages/page.MediaDelete.api';
import { useParams } from 'react-router-dom';
import { AddImage } from '@app/api/categories/Competitor Data/ImageUpload.api';

const FormInput = styled(Auth.FormItem)`
  .ant-row.ant-form-item-row {
  }
  .ant-col.ant-form-item-control {
    width: 100% !important;
  }
  .ant-form-item-control-input {
    position: inherit !important;
    display: inherit !important;
    align-items: inherit !important;
    min-height: inherit !important;
  }
  .ant-form-item-control {
    flex-grow: inherit !important;
  }
  .ant-select-dropdown {
    overflow-y: auto; /* or overflow-y: scroll; */
  }
  .ant-upload {
    width: 100%;
  }
  .ant-upload-list-picture-container {
    width: 95%;
    margin: 0 auto;
  }
`;
interface pageData {
  status: boolean;
  _id: string;
  name: string;
}

interface formData {
  status: boolean;
  imageid: string;
  Image: {
    fileList: {
      length: number;
      forEach(arg0: (file: any) => void): unknown;
      originFileObj: File;
    };
    file: File;
  };
  Video: {
    file: {
      originFileObj: File;
    };
  };
}

interface DataType {
  outlet: string;
  product_name: string;
  type: string;
  weight: string;
  price: string;
  country_manufacture: string;
  product_claim: string;
  date: string;
  _id: string;
}
interface DataTypeNew {
  state: any;
  fetch: () => void;
  modelClose: () => void;
  newselectedRows: DataType[];
}
type SingleStringArray = string[];
const UploadImage: React.FC<DataTypeNew> = ({ newselectedRows, state, fetch, modelClose }) => {
  const { Option } = AntSelect;
  const { t } = useTranslation();
  const [pagename, setPageName] = useState<pageData[]>([]);
  const [pageid, setPageid] = useState<string>('');
  const [loading, setloading] = useState<boolean>(false);
  const { id, marketId, subcatId } = useParams();

  const initialValues: any = {
    status: true,
  };

  const ImageId =
    newselectedRows &&
    newselectedRows.map((page: DataType) => {
      return page._id;
    });
  const memoImageId: SingleStringArray = useMemo(() => ImageId, [ImageId]);
  console.log(JSON.stringify(memoImageId), 'memoImageId');
  const handleSubmit = async (values: formData) => {
    setloading(true);
    const formdata = new FormData();

    // memoImageId.forEach((id) => formdata.append('id', id));
    if (values.Image.fileList.length > 1) {
      // Handle each file separately in case of bulk upload
      values.Image.fileList.forEach((file: any) => {
        formdata.append(`product_image`, file.originFileObj);
      });
    } else {
      // Single image upload
      formdata.append('product_image', values?.Image?.file);
    }

    formdata.append('selected_id', JSON.stringify(memoImageId) || '');
    formdata.append('category_name', state.sub_category_name || '');
    try {
      await AddImage(id || '', marketId || '', subcatId || '', formdata);
      setloading(false);
      fetch();
      modelClose();
    } catch (err: any) {
      notificationController.error({ message: err.message || 'An error occurred!' });
    } finally {
      setloading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload: () => {
      return false;
    },
    onChange(info: any) {
      const { file } = info;
      if (file && file.status !== 'uploading') {
        if (file instanceof File || file instanceof Blob) {
        } else {
        }
      }
    },
  };

  // action:'#',
  // onChange: (info: any) => {
  //   const { status } = info.file;
  //   if (status !== 'uploading') {
  //     console.log(info.file, info.fileList);
  //   }
  //   if (status === 'done') {
  //     notificationController.success({ message: t('uploads.successUpload', { name: info.file.name }) });
  //   } else if (status === 'error') {
  //     notificationController.error({ message: t('uploads.failedUpload', { name: info.file.name }) });
  //   }
  // },
  const uploadVideoProps = {
    name: 'file',
    multiple: true,
    beforeUpload: (file: File) => {
      const isVideo = file.type.startsWith('video/');
      if (!isVideo) {
        notificationController.error({ message: 'You can only upload video files!' });
      }
      return false;
    },
    onChange(info: any) {
      const { file } = info;
      if (file && file.status !== 'uploading') {
        if (file instanceof File || file instanceof Blob) {
        }
      }
    },
  };

  return (
    <>
      {loading ? (
        <div style={{ width: '50%', margin: '0 auto', textAlign: 'center' }}>
          <BaseSpin size="large" />
        </div>
      ) : (
        <>
          <div style={{ width: '50%', margin: '0 auto' }}>
            <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initialValues}>
              <FormInput
                name="Image"
                // label="Image-Video"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <BaseUpload listType="picture" {...uploadProps} accept="image/*" style={{ width: '100%' }}>
                  <BaseButton style={{ width: '100%' }} icon={<UploadOutlined />}>
                    Select images
                  </BaseButton>
                </BaseUpload>
              </FormInput>
              <BaseForm.Item noStyle>
                <Auth.SubmitButton type="primary" htmlType="submit">
                  Upload files
                </Auth.SubmitButton>
              </BaseForm.Item>
            </BaseForm>
          </div>
        </>
      )}
    </>
  );
};

export default UploadImage;
