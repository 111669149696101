import { httpApi } from '@app/api/http.api';
interface response {
  MarketInsightsEI: {
    gdb_billions: number;
    gdb_per_capita: number;
    quality_ranking: number;
    human_ranking: number;
    logistics_ranking: number;
    ease_ranking: number;
    source_date: Date;
    trade_agreement: string;
  };
  MarketInsightsDf: {
    total_population: number;
    expatriate_population: number;
    population_growth: number;
    median_age: number;
    urban_population: number;
    population_ethnicity: ArrayData[];
    dominant_religious_groups: ArrayData[];
  };
  MarketInsightsCbst: {
    key_trends: string;
    digital_adoption: string;
  };
  MarketInsightsGrcd: {
    key_trends: string;
    grocery_retailing: ArrayData[];
    petro_convenience: ArrayData[];
  };
  MarketInsightsFcd: {
    key_trends: string;
    full_service_restaurants: ArrayData[];
    limited_service_restaurants: ArrayData[];
  };
  MarketInsightsEccd: {
    key_trends: string;
    Key_e_tailers: string;
  };
  MarketInsightsMar: {
    key_regulators: string;
    import_procedure: string;
    documentation_required: string;
    certificates_required: string;
    labelling_requirements: string;
    packaging_requirements: string;
    tariffs_levied: string;
    non_tarrif_barriers: string;
  };
}
interface ArrayData {
  _id: string;
  name: string;
  percentage: string;
}
export const GetMarketInsights = (marketId: string): Promise<response> => {
  return httpApi.get<response>(`/markets/${marketId}/marketInsights`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
