import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: foldersData[];
}
interface foldersData {
  ID: string;
  name: string;
}
export const DeleteResource = (folderID: string, resourceID: string): Promise<response> => {
  return httpApi
    .delete<response>(`/resourceFolders/${folderID}/resources/${resourceID}/resourceDelete `)

    .then(({ data }) => data);
};
