import React, { useState, useEffect } from 'react';
import { Modal, Select } from 'antd';
import * as S from './CompetitorReviews.styles';
import { ViewCategories } from '@app/api/categories/ViewCategories.api';
import { GetCategoryMarkets } from '@app/api/categories/CategoryMarkets/GetCategoryMarkets.api';
import { MainRow } from './CompetitorReviewsPage';
import SubCategoriesComponent from './MarketSubcategoriesList';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';

const { Option } = Select;

interface Category {
  _id: string;
  name: string;
  status: boolean;
}

interface categoryMarkets {
  category_markets_name: string;
  category_id: string;
  market_id: string;
  market_name: string;
  _id: string;
}

interface categoryMarketsSubcatgory {
  sub_category_name: string;
  sub_cat_id: string;
  category_id: string;
  category_name: string;
  market_id: string;
  market_name: string;
  _id: string;
}

interface SelectedItem {
  categoryId: string;
  category_name: string;
  marketId: string;
  market_name: string;
  subcategoryId: string;
  sub_category_name: string;
}

interface Props {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  onSelect: (selectedItems: SelectedItem[]) => void;
  toggleCompetitorReviewModal: () => void;
}

interface Select {
  onChange: (event: any) => void;
}

const MarketBrowserModal: React.FC<Props> = ({ open, onCancel, onOk, onSelect, toggleCompetitorReviewModal }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<{ id: string; name: string } | null>(null);
  const [markets, setMarkets] = useState<categoryMarkets[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<{ id: string; name: string } | null>(null);
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ViewCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleCategoryChange = async (value: string, option: React.ReactElement) => {
    setLoading(true);
    setSelectedMarket(null);
    setSelectedCategory({ id: value, name: option.props.children });
    GetCategoryMarkets(value)
      .then((response) => {
        setMarkets(response?.data?.filter((e) => e?.status));
      })
      .catch((error) => {
        console.error('Error fetching markets:', error);
      })
      .finally(() => setLoading(false));
  };

  const handleSelectedItemsChange = (items: SelectedItem[]) => {
    setSelectedItems(items);
  };

  const handleSelection = () => {
    onSelect(selectedItems);
    onOk();
    toggleCompetitorReviewModal();
  };
  const filteredCategories = categories?.filter((e) => e?.status);
  return (
    <Modal
      title="Market Browser"
      open={open}
      onOk={handleSelection}
      onCancel={onCancel}
      width={300}
      okText="Go to Cart"
      footer={false}
    >
      <MainRow>
        <S.SelectCategory
          placeholder="Select Category"
          onChange={handleCategoryChange}
          value={selectedCategory ? selectedCategory.id : undefined}
        >
          {filteredCategories.map((category) => (
            <Option key={category._id} value={category._id}>
              {category.name}
            </Option>
          ))}
        </S.SelectCategory>

        {selectedCategory && (
          <SubCategoriesComponent
            markets={markets}
            selectedCategory={selectedCategory}
            onSelectedItemsChange={handleSelectedItemsChange}
          />
        )}
        {loading && <BaseSpin />}
      </MainRow>
      <S.CartButton shape="round" type="primary" block onClick={handleSelection}>
        <ShoppingCartOutlined />
        Go to Cart
      </S.CartButton>
    </Modal>
  );
};

export default MarketBrowserModal;
