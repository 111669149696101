import React, { useEffect, useState } from 'react';
import SubCatPage from '../SubCategoryOptions/SubCatPage';
import CategoryMarketData from '../CategoryMarkets/CategoryMarketsData';
import CategoriesEdit from '../CategoriesEdit';
import { GetSubCategoryOptions } from '@app/api/categories/SubCategoryOptions/GetSubCategoryOptions.api';
import { useLocation } from 'react-router-dom';

interface folderData {
  sub_category_name?: string;
  _id?: string;
}
const CategoriesDetails: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState<folderData[]>([]);
  const { state } = useLocation();
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetSubCategoryOptions(state._id);
      response?.data.length >= 1 && setFolders(response?.data[0]?.subcategory_options as folderData[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // console.log('folders ---->', folders);
  return (
    <>
      <CategoriesEdit />
      <SubCatPage subCatOptionsData={folders} fetchData={fetchData} loading={loading} />
      <CategoryMarketData subCatOptionsData={folders} fetchData={fetchData} />
    </>
  );
};

export default CategoriesDetails;
