import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: marketAccess;
}

interface marketAccess {
  key_regulators?: string;
  import_procedure?: string;
  documentation_required?: string;
  certificates_required?: string;
  labelling_requirements?: string;
  packaging_requirements?: string;
  tariffs_levied?: string;
  non_tarrif_barriers?: string;
}
export const PutMarketAccessReq = (id: string, formData: marketAccess): Promise<response> => {
  return httpApi.put<response>(`/marketInsightsMar/${id}/marketMarUpdate `, formData).then(({ data }) => data);

  // This API is fetching Video details(name,descprtion etc) with help of folder > clicked video id
};
