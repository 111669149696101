import { httpApi } from '@app/api/http.api';

// This Api is for Adding(POST) the Table Data for --> Competitor table data in category module

interface formData {
  outlet: string;
  product_name: string;
  type: string;
  weight: string;
  price: string;
  country_manufacture: string;
  product_claim: string;
  date: string;
}

type _id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;

export const AddCompetitorData = (
  _id: _id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
  formData: formData[],
): Promise<undefined> => {
  return httpApi
    .post<undefined>(
      `categories/categoryMarket/${_id}/${category_market_id}/${cat_market_sabcat_id}/competitorData/competitorDataAdd`,
      formData,
    )
    .then(({ data }) => data);
};
