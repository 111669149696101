import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: foldersData[];
}

interface foldersData {
  full_service_source?: string;
  limited_service_source?: string;
  full_service_restaurants?: { type: inputData[] };
  limited_service_restaurants?: { type: inputData[] };
  key_trends?: string;
}
interface inputData {
  name?: string;
  percentage: number;
  _id?: string;
}
export const PutFoodService = (id: string, formData: foldersData): Promise<response> => {
  return httpApi.put<response>(`/marketInsightsFcd/${id}/marketFcdUpdate `, formData).then(({ data }) => data);

  // This API is fetching Video details(name,descprtion etc) with help of folder > clicked video id
};
