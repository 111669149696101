/* eslint-disable react-hooks/exhaustive-deps */
import CustomModal from '@app/components/Model';
import { useEffect, useState } from 'react';
import { GetFoodService } from '@app/api/markets/MarketDetails/FoodService/GetFoodService.api';
import { useParams } from 'react-router-dom';
import { DeleteFS } from '@app/api/markets/MarketDetails/FoodService/DeleteFS.api';
import { notificationController } from '@app/controllers/notificationController';
import Model from '@app/components/Model';
import { DeleteLS } from '@app/api/markets/MarketDetails/FoodService/DeleteLS.api';
import { BaseRowButton, ButtonRowWrap, FSButton, FoodServiceStyle, Scroll_box } from './FoodService.styles';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BaseRowBox, ListWrapper } from '../../MarketsPage.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import QuillEditor from './QuillEditor';
import { BasePopconfirm } from '@app/components/common/BasePopconfirm/BasePopconfirm';
import { Input } from 'antd';
import { PutFoodService } from '@app/api/markets/MarketDetails/FoodService/PutFoodService.api';
interface FSData {
  full_service_source: string;
  limited_service_source: string;
  full_service_restaurants: [];
  limited_service_restaurants: [];
  key_trends: string;
}
interface foodService {
  name?: string;
  percentage: number;
  _id?: string;
}
interface ModalState {
  _id: string;
  isModalOpen: boolean;
}
const FoodService = (): JSX.Element => {
  const { id } = useParams();
  const [modalState, setModalState] = useState({ id: '', isModalOpen: false });
  const [foodServiceData, setFoodServiceData] = useState<FSData>();
  const [FSFolders, setFSFolders] = useState<foodService[]>([]);
  const [LSFolders, setLSFolders] = useState<foodService[]>([]);
  const [FSBackup, setFSBackup] = useState<foodService[]>([]);
  const [LSBackup, setLSBackup] = useState<foodService[]>([]);
  const [FSOpen, setFSOpen] = useState(false);
  const [LSOpen, setLSOpen] = useState(false);
  const [FSmodalState, setFSModalState] = useState<ModalState>({ isModalOpen: false, _id: '' });
  const [LSmodalState, setLSModalState] = useState<ModalState>({ isModalOpen: false, _id: '' });
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [FSDelLoading, setFSDelLoading] = useState('');
  const [LSDelLoading, setLSDelLoading] = useState('');
  const fetchData = async () => {
    setDataLoading(true);
    try {
      const response = await GetFoodService(id as string);
      setFoodServiceData(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    setFSFolders((foodServiceData?.full_service_restaurants as foodService[]) ?? []);
    setFSBackup((foodServiceData?.full_service_restaurants as foodService[]) ?? []);
    setLSFolders((foodServiceData?.limited_service_restaurants as foodService[]) ?? []);
    setLSBackup((foodServiceData?.limited_service_restaurants as foodService[]) ?? []);
  }, [foodServiceData]);
  const handleFSReset = () => {
    setFSBackup([...FSFolders]);
  };
  const handleFSNameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...FSBackup])); // Deep clone the backup array
    newInputs[index].name = event.target.value;
    setFSBackup(newInputs);
  };
  const handleFSValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...FSBackup])); // Deep clone the backup array
    newInputs[index].percentage = Number(event.target.value);
    setFSBackup(newInputs);
  };

  const addFSItem = () => {
    if (!FSBackup || !Array.isArray(FSBackup)) {
      setFSBackup([{ name: '', percentage: 0 }]);
    } else {
      setFSBackup([...FSBackup, { name: '', percentage: 0 }]);
    }
  };

  const deleteFSItem = (i: number) => {
    setFSBackup((prevArray) => {
      // Create a copy of the array without the element at the specified index
      const newArray = [...prevArray.slice(0, i), ...prevArray.slice(i + 1)];
      return newArray;
    });
  };
  const handleLSReset = () => {
    setLSBackup([...LSFolders]);
  };
  const handleLSNameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...LSBackup])); // Deep clone the backup array
    newInputs[index].name = event.target.value;
    setLSBackup(newInputs);
  };
  const handleLSValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...LSBackup])); // Deep clone the backup array
    newInputs[index].percentage = Number(event.target.value);
    setLSBackup(newInputs);
  };

  const addLSItem = () => {
    if (!LSBackup || !Array.isArray(LSBackup)) {
      setLSBackup([{ name: '', percentage: 0 }]);
    } else {
      setLSBackup([...LSBackup, { name: '', percentage: 0 }]);
    }
  };

  const deleteLSItem = (i: number) => {
    setLSBackup((prevArray) => {
      // Create a copy of the array without the element at the specified index
      const newArray = [...prevArray.slice(0, i), ...prevArray.slice(i + 1)];
      return newArray;
    });
  };
  const showFSModal = () => {
    setFSModalState({ ...FSmodalState, isModalOpen: true });
  };

  const handleFSOk = () => {
    setFSModalState({ ...FSmodalState, isModalOpen: false });
  };

  const handleFSCancel = () => {
    setFSModalState({ ...FSmodalState, isModalOpen: false });
  };

  const showLSModal = () => {
    setLSModalState({ ...LSmodalState, isModalOpen: true });
  };

  const handleLSOk = () => {
    setLSModalState({ ...LSmodalState, isModalOpen: false });
  };

  const handleLSCancel = () => {
    setLSModalState({ ...LSmodalState, isModalOpen: false });
  };
  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  const { isModalOpen } = modalState;
  const handleFSDelete = async (deleteId: string) => {
    setFSDelLoading(deleteId);
    try {
      // Simulate a delay, replace this with your actual API call
      // Assuming ChangePassword is an asynchronous function, use await here
      await DeleteFS(id as string, deleteId);
      notificationController.success({ message: 'Success' });
      fetchData();
    } catch (error) {
      console.error('Error while deleting data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setFSDelLoading(deleteId);
    }
  };
  const handleLSDelete = async (deleteId: string) => {
    setLSDelLoading(deleteId);
    try {
      // Simulate a delay, replace this with your actual API call
      // Assuming ChangePassword is an asynchronous function, use await here
      await DeleteLS(id as string, deleteId);
      notificationController.success({ message: 'Success' });
      fetchData();
    } catch (error) {
      console.error('Error while deleting data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setLSDelLoading(deleteId);
    }
  };
  const handleFSSubmit = async () => {
    setLoading(true);
    try {
      // Assuming ChangePassword is an asynchronous function, use await here
      await PutFoodService(id as string, { full_service_restaurants: { type: FSBackup } });
      notificationController.success({
        message: 'Success',
      });
      setFSOpen(false);
      fetchData();
    } catch (err) {
      notificationController.error({ message: 'Error Updating Market' });
      setFSOpen(false);
    } finally {
      setLoading(false);
    }
  };
  const handleLSSubmit = async () => {
    setLoading(true);
    try {
      // Assuming ChangePassword is an asynchronous function, use await here
      await PutFoodService(id as string, { limited_service_restaurants: { type: LSBackup } });
      notificationController.success({
        message: 'Success',
      });
      setLSOpen(false);
      fetchData();
    } catch (err) {
      notificationController.error({ message: 'Error Updating Market' });
      setLSOpen(false);
    } finally {
      setLoading(false);
    }
  };
  async function closeAllModal() {
    await setFSOpen(false);
    await setLSOpen(false);
    handleFSCancel();
    handleLSCancel();
  }
  const FShasZeroPercentageOrEmptyName = FSBackup?.some((item) => item.percentage === 0 || item?.name?.trim() === '');
  const LShasZeroPercentageOrEmptyName = LSBackup?.some((item) => item.percentage === 0 || item?.name?.trim() === '');
  const FSSum = FSBackup?.reduce((accumulator, current) => accumulator + current?.percentage, 0);
  const LSSum = LSBackup?.reduce((accumulator, current) => accumulator + current?.percentage, 0);
  const disableFSButton = JSON.stringify(FSBackup) === JSON.stringify(FSFolders);
  const disableLSButton = JSON.stringify(LSBackup) === JSON.stringify(LSFolders);
  return (
    <>
      <ButtonRowWrap>
        <BaseRowButton onClick={showModal}>Key Trends</BaseRowButton>
      </ButtonRowWrap>
      <FoodServiceStyle>
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ margin: '0' }}>Full-Service Restaurants</p>
          <EditOutlined onClick={showFSModal} />
        </div>
        <div>
          {FSFolders?.length === 0 ? (
            <div>
              <p style={{ border: '1px solid black', padding: '10px' }}>No items have been added</p>
            </div>
          ) : (
            <div>
              {FSFolders?.map((element) => (
                <ListWrapper key={element?._id}>
                  <div className="hoverDiv">
                    <BaseRowBox key={element?._id} gutter={[16, 16]}>
                      <div>{element?.name}</div>
                      <div>{element?.percentage}%</div>
                    </BaseRowBox>
                  </div>
                  <BaseButton
                    style={{ margin: '7px 10px' }}
                    onClick={() => handleFSDelete(element?._id as string)}
                    disabled={FSDelLoading === element?._id}
                    loading={FSDelLoading === element?._id}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </ListWrapper>
              ))}
            </div>
          )}
        </div>
      </FoodServiceStyle>
      <FoodServiceStyle>
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ marginTop: '3 rem' }}>Limited-Service Restaurants</p>
          <EditOutlined onClick={showLSModal} />
        </div>
        <div>
          {LSFolders?.length === 0 ? (
            <div>
              <p style={{ border: '1px solid black', padding: '10px' }}>No items have been added</p>
            </div>
          ) : (
            <div>
              {LSFolders?.map((element) => (
                <ListWrapper key={element?._id}>
                  <div className="hoverDiv">
                    <BaseRowBox key={element?._id} gutter={[16, 16]}>
                      <div>{element?.name}</div>
                      <div>{element?.percentage}%</div>
                    </BaseRowBox>
                  </div>
                  <BaseButton
                    style={{ margin: '7px 10px' }}
                    onClick={() => handleLSDelete(element?._id as string)}
                    disabled={LSDelLoading === element?._id}
                    loading={LSDelLoading === element?._id}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </ListWrapper>
              ))}
            </div>
          )}
        </div>
      </FoodServiceStyle>
      <CustomModal
        title="Key Trends"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={
          <QuillEditor handleOk={handleCancel} data={foodServiceData?.key_trends as string} fetchData={fetchData} />
        }
      />
      <Model
        title="Full-Service Restaurants"
        open={FSmodalState.isModalOpen}
        onOk={handleFSOk}
        onCancel={closeAllModal}
        content={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FSButton severity="warning" disabled={disableFSButton} onClick={handleFSReset}>
                Reset
              </FSButton>
              <div>{FSSum}/100</div>
              <BasePopconfirm
                placement="bottom"
                title={'Are you sure you want to make these changes?'}
                onConfirm={handleFSSubmit}
                okText="Yes"
                cancelText="No"
                onCancel={() => setFSOpen(false)}
                open={FSOpen}
              >
                <BaseButton
                  onClick={() => setFSOpen(true)}
                  disabled={loading || FShasZeroPercentageOrEmptyName || disableFSButton}
                  loading={loading}
                >
                  Apply
                </BaseButton>
              </BasePopconfirm>
            </div>

            <Scroll_box>
              {FSBackup?.map((element, index) => (
                <div
                  key={element._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    gap: '10px',
                  }}
                >
                  <Input
                    type="text"
                    value={FSBackup[index].name}
                    onChange={(event) => handleFSNameChange(event, index)}
                  />
                  <Input
                    type="number"
                    value={FSBackup[index].percentage}
                    onChange={(event) => handleFSValueChange(event, index)}
                  />
                  <BaseButton disabled={'_id' in element} onClick={() => deleteFSItem(index)}>
                    <DeleteOutlined />
                  </BaseButton>
                </div>
              ))}
            </Scroll_box>
            <FSButton type="primary" onClick={addFSItem}>
              Add item
            </FSButton>
          </>
        }
      />
      <Model
        title="Limited-Service Restaurants"
        open={LSmodalState.isModalOpen}
        onOk={handleLSOk}
        onCancel={closeAllModal}
        content={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FSButton severity="warning" disabled={disableLSButton} onClick={handleLSReset}>
                Reset
              </FSButton>
              <div>{LSSum}/100</div>
              <BasePopconfirm
                placement="bottom"
                title={'Are you sure you want to make these changes?'}
                onConfirm={handleLSSubmit}
                okText="Yes"
                cancelText="No"
                onCancel={() => setLSOpen(false)}
                open={LSOpen}
              >
                <BaseButton
                  onClick={() => setLSOpen(true)}
                  disabled={loading || LShasZeroPercentageOrEmptyName || disableLSButton}
                  loading={loading}
                >
                  Apply
                </BaseButton>
              </BasePopconfirm>
            </div>

            <Scroll_box>
              {LSBackup?.map((element, index) => (
                <div
                  key={element._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    gap: '10px',
                  }}
                >
                  <Input
                    type="text"
                    value={LSBackup[index].name}
                    onChange={(event) => handleLSNameChange(event, index)}
                  />
                  <Input
                    type="number"
                    value={LSBackup[index].percentage}
                    onChange={(event) => handleLSValueChange(event, index)}
                  />
                  <BaseButton disabled={'_id' in element} onClick={() => deleteLSItem(index)}>
                    <DeleteOutlined />
                  </BaseButton>
                </div>
              ))}
            </Scroll_box>
            <FSButton type="primary" onClick={addLSItem}>
              Add item
            </FSButton>
          </>
        }
      />
    </>
  );
};
export default FoodService;
