import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

export const GroceryRetailStyle = styled.div`
  margintop: 2rem;
  margin: 15px 0px;
  padding: 20px;
  box-shadow: rgba(35, 78, 84, 0.61) 0px 0px 15px 1px;
  border-radius: 5px;
`;
export const Button = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
`;
export const Scroll_box = styled.div`
  height: 200px;
  overflow-y: auto;
`;
export const TextAreaQuill = styled(TextArea)`
  margin: 10px 0px;
`;
