import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/lib/mentions';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { GetCountry } from '@app/api/markets/GetCountry.api';
import { AddMarket } from '@app/api/markets/AddMarket.api';
import { Form } from 'antd';

interface props {
  loadMarkets: () => void;
  handleOk: () => void;
}
interface marketType {
  _id: string;
  name: string;
}
interface response {
  status: boolean;
  message: string;
  data?: [];
}
interface addMarket {
  country_id: string;
  name: string;
  status: boolean;
}

const AddMarketForm = ({ loadMarkets, handleOk }: props): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [countryData, setCountryData] = useState<marketType[]>([]);
  const [loading, setLoading] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  useEffect(() => {
    const getCountry = async () => {
      const response: response = await GetCountry();
      setCountryData(response?.data as []);
    };
    getCountry();
  }, []);
  const handleSubmit = async (values: addMarket) => {
    setLoading(true);
    try {
      // Simulate a delay, replace this with your actual API call
      const AddMarketData = {
        country_id: values.country_id,
        name: values.name,
        status: values.status ?? false,
      };
      // Assuming ChangePassword is an asynchronous function, use await here
      await AddMarket(AddMarketData);
      notificationController.success({ message: t('common.success') });
      await loadMarkets();
      handleOk();
      form.resetFields();
    } catch (error) {
      console.error('Error while adding market.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setLoading(false);
    }
  };
  return (
    <BaseForm onFinish={handleSubmit} form={form}>
      <Auth.FormItem
        name="country_id"
        label={t('common.country')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <Auth.FormBaseSelect placeholder="Country">
          {countryData
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => (
              <Option value={e._id} key={e._id}>
                {e.name}
              </Option>
            ))}
        </Auth.FormBaseSelect>
      </Auth.FormItem>
      <Auth.FormItem name="name" label="Market Name" rules={[{ required: true, message: t('common.requiredField') }]}>
        <Auth.FormInput placeholder="Market Name" />
      </Auth.FormItem>
      <div style={{ display: 'flex', padding: '12px 0px' }}>
        <BaseForm.Item name="status" valuePropName="checked" noStyle>
          <Auth.FormCheckbox onChange={(e) => setCheckboxChecked(e.target.checked)}>
            <Auth.Text>Active</Auth.Text>
          </Auth.FormCheckbox>
        </BaseForm.Item>
      </div>

      {!checkboxChecked && (
        <p style={{ background: '#dbdbdb5c', padding: '10px', borderRadius: '4px' }}>
          Market must be activated before it is visible in Market Insights. Complete market insights before activating.
        </p>
      )}
      <BaseForm.Item noStyle>
        <Auth.SubmitButton type="primary" disabled={loading} htmlType="submit" loading={loading}>
          {!loading ? `${t('common.submit')}` : ''}
        </Auth.SubmitButton>
      </BaseForm.Item>
    </BaseForm>
  );
};
export default AddMarketForm;
