/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useTranslation } from 'react-i18next';
import * as S from './DemographicFactors.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import Model from '@app/components/Model';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { BaseRowBox, Button, DemographicFactors } from './DemographicFactors.styles';
import { DFPut } from '@app/api/markets/MarketDetails/DemographicFactors/DFPut.api';
import { useParams } from 'react-router-dom';
import { DFGet } from '@app/api/markets/MarketDetails/DemographicFactors/DFGet.api';
import { notificationController } from '@app/controllers/notificationController';
import { ListWrapper } from '../../MarketsPage.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { PEDelete } from '@app/api/markets/MarketDetails/DemographicFactors/PEDelete.api';
import { DRGDelete } from '@app/api/markets/MarketDetails/DemographicFactors/DRGDelete.api';
import { BasePopconfirm } from '@app/components/common/BasePopconfirm/BasePopconfirm';
interface formData {
  dominant_religious_groups?: PE[];
  expatriate_population?: number;
  market_id?: number;
  median_age?: number;
  population_ethnicity?: PE[];
  population_growth?: number;
  total_population?: number;
  urban_population?: number;
  population_source?: string;
  dominant_source?: string;
}
interface ValuesData {
  dominant_religious_groups?: { type: PE[] };
  expatriate_population?: number;
  market_id?: number;
  median_age?: number;
  population_ethnicity?: { type: PE[] };
  population_growth?: number;
  total_population?: number;
  urban_population?: number;
  population_source?: string;
  dominant_source?: string;
}
interface PE {
  name?: string;
  percentage: number;
  _id?: string;
}
interface ModalState {
  _id: string;
  isModalOpen: boolean;
}
interface ErrorType {
  message: string;
}
const DemographicFactorsForm = (): JSX.Element => {
  const [PEFolders, setPEFolders] = useState<PE[]>([]);
  const [DRGFolders, setDRGFolders] = useState<PE[]>([]);
  const [PEBackup, setPEBackup] = useState<PE[]>([]);
  const [DRGBackup, setDRGBackup] = useState<PE[]>([]);
  const [dfData, setDfData] = useState<formData>();

  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [PELoading, setPELoading] = useState(false);
  const [DRGLoading, setDRGLoading] = useState(false);
  const [PEmodalState, setPEModalState] = useState<ModalState>({ isModalOpen: false, _id: '' });
  const [DRGmodalState, setDRGModalState] = useState<ModalState>({ isModalOpen: false, _id: '' });
  const [PESource, setPESource] = useState('');
  const [DRGSource, setDRGSource] = useState('');
  const [PEDelLoading, setPEDelLoading] = useState('');
  const [DRGDelLoading, setDRGDelLoading] = useState('');
  const { t } = useTranslation();
  const { id } = useParams();
  const [PEOpen, setPEOpen] = useState(false);
  const [DRGOpen, setDRGOpen] = useState(false);
  const PESum = PEBackup?.reduce((accumulator, current) => accumulator + current?.percentage, 0);
  const DRGSum = DRGBackup?.reduce((accumulator, current) => accumulator + current?.percentage, 0);
  const handlePESubmit = async () => {
    setLoading(true);
    try {
      // Assuming ChangePassword is an asynchronous function, use await here
      await DFPut(id as string, { population_ethnicity: { type: PEBackup } });
      setPEOpen(false);
      fetchData();
      notificationController.success({ message: 'Market Updated' });
    } catch (err) {
      notificationController.error({ message: 'Error Updating Market' });
      setPEOpen(false);
    } finally {
      setLoading(false);
    }
  };
  const handleDRGSubmit = async () => {
    setLoading(true);
    try {
      // Assuming ChangePassword is an asynchronous function, use await here
      await DFPut(id as string, { dominant_religious_groups: { type: DRGBackup } });
      setDRGOpen(false);
      fetchData();
      notificationController.success({ message: 'Market Updated' });
    } catch (err) {
      notificationController.error({ message: 'Error Updating Market' });
      setDRGOpen(false);
    } finally {
      setLoading(false);
    }
  };
  const hasZeroPercentageOrEmptyName = PEBackup?.some((item) => item.percentage === 0 || item?.name?.trim() === '');

  const fetchData = async () => {
    setDataLoading(true);
    try {
      const response = await DFGet(id as string);
      setDfData(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    setPESource(dfData?.population_source as string);
    setDRGSource(dfData?.dominant_source as string);
    setPEFolders((dfData?.population_ethnicity as PE[]) ?? []);
    setPEBackup((dfData?.population_ethnicity as PE[]) ?? []);
    setDRGFolders((dfData?.dominant_religious_groups as PE[]) ?? []);
    setDRGBackup((dfData?.dominant_religious_groups as PE[]) ?? []);
  }, [dfData]);
  const initValues = {
    total_population: dfData?.total_population,
    expatriate_population: dfData?.expatriate_population,
    population_growth: dfData?.population_growth,
    median_age: dfData?.median_age,
    urban_population: dfData?.urban_population,
  };
  const handlePEReset = () => {
    setPEBackup([...PEFolders]);
  };
  const handlePENameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...PEBackup])); // Deep clone the backup array
    newInputs[index].name = event.target.value;
    setPEBackup(newInputs);
  };
  const handlePEValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...PEBackup])); // Deep clone the backup array
    newInputs[index].percentage = Number(event.target.value);
    setPEBackup(newInputs);
  };

  const addPEItem = () => {
    if (!PEBackup || !Array.isArray(PEBackup)) {
      setPEBackup([{ name: '', percentage: 0 }]);
    } else {
      setPEBackup([...PEBackup, { name: '', percentage: 0 }]);
    }
  };

  const deletePEItem = (i: number) => {
    setPEBackup((prevArray) => {
      // Create a copy of the array without the element at the specified index
      const newArray = [...prevArray.slice(0, i), ...prevArray.slice(i + 1)];
      return newArray;
    });
  };
  const handleDRGReset = () => {
    setDRGBackup([...DRGFolders]);
  };
  const handleDRGNameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...DRGBackup])); // Deep clone the backup array
    newInputs[index].name = event.target.value;
    setDRGBackup(newInputs);
  };
  const handleDRGValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...DRGBackup])); // Deep clone the backup array
    newInputs[index].percentage = Number(event.target.value);
    setDRGBackup(newInputs);
  };

  const addDRGItem = () => {
    if (!DRGBackup || !Array.isArray(DRGBackup)) {
      setDRGBackup([{ name: '', percentage: 0 }]);
    } else {
      setDRGBackup([...DRGBackup, { name: '', percentage: 0 }]);
    }
  };
  async function closeAllModal() {
    await setDRGOpen(false);
    await setPEOpen(false);
    handleDRGCancel();
    handlePECancel();
  }
  const deleteDRGItem = (i: number) => {
    setDRGBackup((prevArray) => {
      // Create a copy of the array without the element at the specified index
      const newArray = [...prevArray.slice(0, i), ...prevArray.slice(i + 1)];
      return newArray;
    });
  };

  const showPEModal = () => {
    setPEModalState({ ...PEmodalState, isModalOpen: true });
  };

  const handlePEOk = () => {
    setPEModalState({ ...PEmodalState, isModalOpen: false });
  };

  const handlePECancel = () => {
    setPEModalState({ ...PEmodalState, isModalOpen: false });
  };

  const showDRGModal = () => {
    setDRGModalState({ ...DRGmodalState, isModalOpen: true });
  };

  const handleDRGOk = () => {
    setDRGModalState({ ...DRGmodalState, isModalOpen: false });
  };

  const handleDRGCancel = () => {
    setDRGModalState({ ...DRGmodalState, isModalOpen: false });
  };
  const savePEsource = async () => {
    setPELoading(true);
    try {
      // Simulate a delay, replace this with your actual API cal
      // Assuming ChangePassword is an asynchronous function, use await here
      await DFPut(id as string, { population_source: PESource });
      fetchData();
      notificationController.success({ message: t('common.success') });
    } catch (error) {
      const err: ErrorType = error as ErrorType;
      notificationController.error({ message: err?.message as string });
    } finally {
      setPELoading(false);
    }
  };
  const saveDRGsource = async () => {
    setDRGLoading(true);
    try {
      // Simulate a delay, replace this with your actual API call
      // Assuming ChangePassword is an asynchronous function, use await here
      await DFPut(id as string, { dominant_source: DRGSource });
      fetchData();
      notificationController.success({ message: t('common.success') });
    } catch (error) {
      const err: ErrorType = error as ErrorType;
      notificationController.error({ message: err?.message as string });
    } finally {
      setDRGLoading(false);
    }
  };
  const handleSubmits = async (values: ValuesData) => {
    setLoading(true);
    try {
      // Simulate a delay, replace this with your actual API call
      // Assuming ChangePassword is an asynchronous function, use await here
      await DFPut(id as string, values);
      notificationController.success({ message: t('common.success') });
    } catch (error) {
      console.error('Error while saving data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setLoading(false);
    }
  };
  const handlePEDelete = async (deleteId: string) => {
    setPEDelLoading(deleteId);
    try {
      // Simulate a delay, replace this with your actual API call
      // Assuming ChangePassword is an asynchronous function, use await here
      await PEDelete(id as string, deleteId);
      notificationController.success({ message: t('common.success') });
      fetchData();
    } catch (error) {
      console.error('Error while deleting data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setPEDelLoading(deleteId);
    }
  };
  const handleDRGDelete = async (deleteId: string) => {
    setDRGDelLoading(deleteId);
    try {
      // Simulate a delay, replace this with your actual API call
      // Assuming ChangePassword is an asynchronous function, use await here
      await DRGDelete(id as string, deleteId);
      notificationController.success({ message: t('common.success') });
      fetchData();
    } catch (error) {
      console.error('Error while deleting data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setDRGDelLoading(deleteId);
    }
  };
  const disablePEButton = JSON.stringify(PEBackup) === JSON.stringify(PEFolders);
  const disableDRGButton = JSON.stringify(DRGBackup) === JSON.stringify(DRGFolders);
  return (
    <>
      {!dataLoading && (
        <DemographicFactors>
          <BaseForm onFinish={handleSubmits} initialValues={initValues}>
            <Auth.FormItem
              name="total_population"
              label="Total population (millions):"
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput type="number" />
            </Auth.FormItem>
            <Auth.FormItem
              name="expatriate_population"
              label="Expatriate population (millions):"
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput type="number" />
            </Auth.FormItem>
            <Auth.FormItem
              name="population_growth"
              label="Population growth (%):"
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput type="number" />
            </Auth.FormItem>
            <Auth.FormItem
              name="median_age"
              label="Median age:"
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput type="number" />
            </Auth.FormItem>
            <Auth.FormItem
              name="urban_population"
              label="Urban population (%):"
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput type="number" />
            </Auth.FormItem>
            {/* <BaseForm.Item name="status" valuePropName="checked" noStyle>
        <Auth.FormCheckbox>
          <Auth.Text>Active</Auth.Text>
        </Auth.FormCheckbox>
      </BaseForm.Item> */}
            {/* Market must be activated before it is visible in Market Insights. Complete market insights before activating. */}
            <BaseForm.Item noStyle>
              <Auth.SubmitButton type="primary" disabled={loading} htmlType="submit" loading={loading}>
                Save Details
              </Auth.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </DemographicFactors>
      )}
      <DemographicFactors>
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ margin: '0' }}>Population Ethnicity</p>
          <EditOutlined onClick={showPEModal} />
        </div>
        <div>
          {PEFolders?.length === 0 ? (
            <div>
              <p style={{ border: '1px solid black', padding: '10px' }}>No items have been added</p>
            </div>
          ) : (
            <div>
              {PEFolders?.map((element) => (
                <ListWrapper key={element?._id}>
                  <div className="hoverDiv">
                    <BaseRowBox key={element?._id} gutter={[16, 16]}>
                      <div>{element?.name}</div>
                      <div>{element?.percentage}%</div>
                    </BaseRowBox>
                  </div>
                  <BaseButton
                    style={{ margin: '7px 10px' }}
                    onClick={() => handlePEDelete(element?._id as string)}
                    disabled={PEDelLoading === element?._id}
                    loading={PEDelLoading === element?._id}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </ListWrapper>
              ))}
            </div>
          )}
        </div>
        <Input placeholder="source" value={PESource} onChange={(e) => setPESource(e.target.value)} />
        <Button type="primary" disabled={PELoading} loading={PELoading} onClick={() => savePEsource()}>
          Save
        </Button>
      </DemographicFactors>
      <DemographicFactors>
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ margin: '0' }}>Dominant Religious Groups</p>
          <EditOutlined onClick={showDRGModal} />
        </div>
        <div>
          {DRGFolders?.length === 0 ? (
            <div>
              <p style={{ border: '1px solid black', padding: '10px' }}>No items have been added</p>
            </div>
          ) : (
            <div>
              {DRGFolders?.map((element) => (
                <ListWrapper key={element?._id}>
                  <div className="hoverDiv">
                    <BaseRowBox key={element?._id} gutter={[16, 16]}>
                      <div>{element?.name}</div>
                      <div>{element?.percentage}%</div>
                    </BaseRowBox>
                  </div>
                  <BaseButton
                    style={{ margin: '7px 10px' }}
                    onClick={() => handleDRGDelete(element?._id as string)}
                    disabled={DRGDelLoading === element?._id}
                    loading={DRGDelLoading === element?._id}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </ListWrapper>
              ))}
            </div>
          )}
        </div>
        <Input placeholder="source" value={DRGSource} onChange={(e) => setDRGSource(e.target.value)} />
        <Button type="primary" disabled={DRGLoading} loading={DRGLoading} onClick={() => saveDRGsource()}>
          Save
        </Button>
      </DemographicFactors>
      <Model
        title="Population Ethnicity"
        open={PEmodalState.isModalOpen}
        onOk={handlePEOk}
        onCancel={closeAllModal}
        content={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <S.Button severity="warning" disabled={disablePEButton} onClick={handlePEReset}>
                Reset
              </S.Button>
              <div>{PESum}/100</div>
              <BasePopconfirm
                placement="bottom"
                title={'Are you sure you want to make these changes?'}
                onConfirm={handlePESubmit}
                okText={t('popconfirm.yes')}
                // onVisibleChange={handleVisibleChange}
                cancelText={t('popconfirm.no')}
                onCancel={() => setPEOpen(false)}
                open={PEOpen}
              >
                <BaseButton
                  onClick={() => setPEOpen(true)}
                  disabled={loading || hasZeroPercentageOrEmptyName || disablePEButton}
                  loading={loading}
                >
                  Apply
                </BaseButton>
              </BasePopconfirm>
            </div>

            <S.Scroll_box>
              {PEBackup?.map((element, index) => (
                <div
                  key={element._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    gap: '10px',
                  }}
                >
                  <Input
                    type="text"
                    value={PEBackup[index]?.name}
                    onChange={(event) => handlePENameChange(event, index)}
                  />
                  <Input
                    type="number"
                    value={PEBackup[index]?.percentage}
                    onChange={(event) => handlePEValueChange(event, index)}
                  />
                  <BaseButton disabled={'_id' in element} onClick={() => deletePEItem(index)}>
                    <DeleteOutlined />
                  </BaseButton>
                </div>
              ))}
            </S.Scroll_box>
            <S.Button type="primary" onClick={addPEItem}>
              Add item
            </S.Button>
          </>
        }
      />
      <Model
        title="Dominant Religious Groups"
        open={DRGmodalState.isModalOpen}
        onOk={handleDRGOk}
        onCancel={closeAllModal}
        content={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <S.Button severity="warning" disabled={disableDRGButton} onClick={handleDRGReset}>
                Reset
              </S.Button>
              <div>{DRGSum}/100</div>
              <BasePopconfirm
                placement="bottom"
                title={'Are you sure you want to make these changes?'}
                onConfirm={handleDRGSubmit}
                okText={t('popconfirm.yes')}
                // onVisibleChange={handleVisibleChange}
                cancelText={t('popconfirm.no')}
                onCancel={() => setDRGOpen(false)}
                open={DRGOpen}
              >
                <BaseButton
                  onClick={() => setDRGOpen(true)}
                  disabled={loading || hasZeroPercentageOrEmptyName || disableDRGButton}
                  loading={loading}
                >
                  Apply
                </BaseButton>
              </BasePopconfirm>
            </div>

            <S.Scroll_box>
              {DRGBackup?.map((element, index) => (
                <div
                  key={element._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    gap: '10px',
                  }}
                >
                  <Input
                    type="text"
                    value={DRGBackup[index]?.name}
                    onChange={(event) => handleDRGNameChange(event, index)}
                  />
                  <Input
                    type="number"
                    value={DRGBackup[index]?.percentage}
                    onChange={(event) => handleDRGValueChange(event, index)}
                  />
                  <BaseButton disabled={'_id' in element} onClick={() => deleteDRGItem(index)}>
                    <DeleteOutlined />
                  </BaseButton>
                </div>
              ))}
            </S.Scroll_box>
            <S.Button type="primary" onClick={addDRGItem}>
              Add item
            </S.Button>
          </>
        }
      />
    </>
  );
};
export default DemographicFactorsForm;
