/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './YourSubscriptionsPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useLocation, useNavigate } from 'react-router-dom';
import { pageDetail } from '@app/api/pages/page.Details.api';
import { PageGet } from '@app/api/pages/pages.api';
import { GetSubscriptionModules } from '@app/api/Subscriptions/SubscriptionModules/GetSubscriptionModules.api';
import SubscriptionsModules from './SubscriptionsModules/SubscriptionsModules';
import { notificationController } from '@app/controllers/notificationController';
import Model from '@app/components/Model';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import ProductCard from '@app/components/landingPage/Addtocart/addToCart';
import { readUser } from '@app/services/localStorage.service';

interface response {
  status: boolean;
  message: string;
  data: Subscription[];
}

interface Subscription {
  _id: string;
  category_id: string;
  market_id: string;
  cat_market_sabcat_id: string;
  matching_subcategory: any;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
interface ModalState {
  isModalOpen: boolean;
  isModalOpenCart: boolean;
}

const YourSubcriptionPage: React.FC = () => {
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const yourSubPerms =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Your Subscriptions');
  const site = useLocation();
  const navigate = useNavigate();
  const url = site.pathname;
  const urlName = url.split('/');
  const { state } = useLocation();
  const [pagename, setPageName] = useState<any>('');
  const [pagedetails, setPagedetails] = useState<any>('');
  const [modLoading, setModLoading] = useState(false);
  const [subscriptionModules, setSubscriptionModules] = useState<response>();
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, isModalOpenCart: false });
  // const memoRisepagename = useMemo(() => pagename, [pagename]);
  const PagenameId = pagename && pagename.find((page: any) => page.slug === urlName[2]);
  const memoPagenameId = useMemo(() => PagenameId, [PagenameId]);
  const user = readUser();

  const { isModalOpen, isModalOpenCart } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true, isModalOpenCart: false });
  };

  const showModalCart = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: true });
  };
  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };

  console.log(PagenameId);

  const GetSubscription = async () => {
    setModLoading(true);
    try {
      const response = await GetSubscriptionModules();
      setSubscriptionModules(response);
    } catch (err) {
      console.log(err);
    } finally {
      setModLoading(false);
    }
  };

  useEffect(() => {
    GetSubscription();
  }, []);

  useEffect(() => {
    PageGet()
      .then((data: any) => setPageName(data.data))
      .catch((err: any) => console.log('err', err));
  }, []);
  useEffect(() => {
    if (urlName[2] && memoPagenameId?._id) {
      pageDetail(memoPagenameId?._id)
        .then((data: any) => setPagedetails(data.data))

        .catch((err: any) => console.log('err', err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoPagenameId?._id]);

  useEffect(() => {
    if (state) {
      notificationController.success({ message: state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  const product = { id: 1, name: 'dsdf', price: 99 };
  const desktopLayout = (
    <>
      <S.MainRow>
        <S.LeftSideCol xl={13} xxl={14} id="desktop-content">
          <BaseRow gutter={[0, 60]}>
            <BaseCol span={24}>
              <S.Content>
                Tactical data and export insights are now available at your fingertips! Explore our platform’s features
                and access export market insights, resources, masterclasses, and key data. Our bespoke solutions are
                practical and tailored to your unique business needs to give you confidence regardless of where you are
                on your export journey.
              </S.Content>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
        {pagedetails && pagedetails[0]?.status && (
          <S.RightSideCol xl={11} xxl={10}>
            <BaseCol span={24}>
              {/* <div style={{ textAlign: 'center' }}>
                <video id="myVideo" width="100%" height="280" controls poster={pagedetails[0]?.image}>
                  <source src={pagedetails[0]?.video} />
                  Your browser does not support the video tag.
                </video> */}

              {/* <img src={pagedetails[0]?.image} alt="pagedetails" width="100%" /> */}
              {/* </div> */}

              {pagedetails[0]?.image && (
                <div onClick={showModal}>
                  <img src={pagedetails[0]?.image} alt="pagedetails" width="100%" />
                </div>
              )}
            </BaseCol>
          </S.RightSideCol>
        )}
      </S.MainRow>

      {yourSubPerms?.permissions?.add_subscription_button && (
        <BaseCol span={24}>
          <div style={{ textAlign: 'center' }}>
            <S.SubHead>Your Subscriptions</S.SubHead>

            <S.Content>Competitor reviews and trade data for specific markets</S.Content>
            <S.YourSubscriptionbutton onClick={() => navigate('/dashboard/competitor-reviews')}>
              Add subscription
            </S.YourSubscriptionbutton>

            {!modLoading ? (
              <SubscriptionsModules subscriptionModules={subscriptionModules} GetSubscription={GetSubscription} />
            ) : (
              <S.Content> You do not have any subscriptions</S.Content>
            )}
          </div>
        </BaseCol>
      )}
    </>
  );

  //   const mobileAndTabletLayout = (
  //     <BaseRow gutter={[20, 24]}>
  //       <BaseCol span={24}>
  //         <TrendingCreators />
  //       </BaseCol>

  //       <BaseCol span={24}>
  //         <RecentlyAddedNft />
  //       </BaseCol>

  //       <BaseCol span={24}>
  //         <TrendingCollections />
  //       </BaseCol>

  //       <BaseCol span={24}>
  //         <RecentActivity />
  //       </BaseCol>
  //     </BaseRow>
  //   );

  return (
    <>
      {subscriptionModules?.data?.length === 0 && user?.role !== 'SUPERADMIN' && user?.role !== 'ADMIN' && (
        <S.MembershipSection>
          <h1 style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>Register your membership now</h1>
          <p>Get full access to the Export Connect Portal and its premium services.</p>
          <Button onClick={showModalCart} type="primary">
            Purchase ($99 for the first year)
          </Button>
          <br />
          <br />
          <Link to={''} style={{ textDecoration: 'underline' }}>
            See all inclusions and membership terms
          </Link>
        </S.MembershipSection>
      )}
      <PageTitle>Your Subscriptions</PageTitle>
      <S.Head>Welcome to the Export Connect Portal</S.Head>
      {desktopLayout}
      {/* {isDesktop ? desktopLayout : mobileAndTabletLayout} */}
      {pagedetails[0]?.video && (
        <Model
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          content={
            <>
              <video width="100%" controls autoPlay>
                <source src={pagedetails[0]?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          }
        />
      )}
      <Model
        title="Cart"
        open={isModalOpenCart}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<ProductCard product={product} handleClose={handleCancel} handleOpen={showModalCart} />}
      />
    </>
  );
};

export default YourSubcriptionPage;
