import { httpApi } from '@app/api/http.api';

type category_id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;

export const DeleteCategoryTableData = (
  category_id: category_id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
  deleteItems: any,
): Promise<undefined> => {
  return httpApi
    .delete<undefined>(
      `categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/categoryData/tableDataDelete`,
      { data: deleteItems },
    )
    .then(({ data }) => data);
};
