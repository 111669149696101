import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: foldersData[];
}

interface foldersData {
  id?: string;
  name?: string;
}
export const CreateEditResourceFolders = (data: foldersData[]): Promise<response> => {
  return httpApi.post<response>(`resourceFolders/resourceFolderAdd`, data).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
