import React from 'react';
import { useTranslation } from 'react-i18next';
import { profileNavData } from '@app/constants/profileNavData';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './ProfileNav.styles';
import { readUser } from '@app/services/localStorage.service';

export const ProfileNav: React.FC = () => {
  const { t } = useTranslation();
  const user = readUser();
  const navigate = useNavigate();
  const location = useLocation();
  console.log('user', user);
  const filteredNavData =
    user?.role !== 'SUPERADMIN'
      ? profileNavData.slice(0, 2).map((item) => (
          <S.Btn
            key={item.id}
            icon={item.icon}
            type="text"
            color={item.color}
            onClick={() => navigate(item.href)}
            $isActive={`/profile/${item.href}` === location.pathname}
          >
            {t(item.name)}
          </S.Btn>
        ))
      : profileNavData.map((item) => (
          <S.Btn
            key={item.id}
            icon={item.icon}
            type="text"
            color={item.color}
            onClick={() => navigate(item.href)}
            $isActive={`/profile/${item.href}` === location.pathname}
          >
            {t(item.name)}
          </S.Btn>
        ));
  return <S.Wrapper>{filteredNavData}</S.Wrapper>;
};
