import React, { useEffect, useMemo, useState } from 'react';
import * as S from './PagesSectionPage.styles';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { UploadOutlined } from '@ant-design/icons';
import { Select as AntSelect, Dropdown, Image, Modal, Space } from 'antd';
// import { notificationController } from '@app/controllers/notificationController';
import styled from 'styled-components';
import { PageGet } from '@app/api/pages/pages.api';
import { pageDetailAdd } from '@app/api/pages/Page.Update.api';
import { notificationController } from '@app/controllers/notificationController';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { pageDetail } from '@app/api/pages/page.Details.api';
import { FormWrapper } from '../Videos/VideosPage.styles';
import Removeicon from '@app/components/common/icons/RemoveIcon';
import { pageMediaDelete } from '@app/api/pages/page.MediaDelete.api';

const FormInput = styled(Auth.FormItem)`
  flex-direction: row !important;
  justify-content: space-between;
  .ant-row.ant-form-item-row {
    flex-direction: row !important;
    justify-content: space-between;
  }
  .ant-col.ant-form-item-control {
    width: inherit !important;
  }
  .ant-form-item-control-input {
    position: inherit !important;
    display: inherit !important;
    align-items: inherit !important;
    min-height: inherit !important;
  }
  .ant-form-item-control {
    flex-grow: inherit !important;
  }
  .ant-select-dropdown {
    overflow-y: auto; /* or overflow-y: scroll; */
  }
`;
interface pageData {
  status: boolean;
  _id: string;
  name: string;
}
interface formData {
  status: boolean;
  Image: {
    file: {
      originFileObj: File;
    };
  };
  Video: {
    file: {
      originFileObj: File;
    };
  };
}
const PagesSectionPage: React.FC = () => {
  const { Option } = AntSelect;
  const { t } = useTranslation();
  const [pagename, setPageName] = useState<pageData[]>([]);
  const [pageid, setPageid] = useState<string>('');
  const [loading, setloading] = useState<boolean>(false);
  const [pagedetails, setPagedetails] = useState<any>('');
  const [uploadimage, setUploadedImage] = useState<any>(null);
  const [uploadvideo, setUploadedVideo] = useState<any>(null);
  const [disabled, setdisabled] = useState<any>(true);
  const [showModal, setShowModal] = useState(false);
  const initialValues: any = {
    status: pagedetails[0]?.status,
  };
  const getPage = () => {
    PageGet()
      .then((data) => setPageName(data?.data))
      .catch((err) => console.log('err', err));
  };
  useEffect(() => {
    getPage();
  }, []);
  const memoRisepagename = useMemo(() => pagename, [pagename]);
  const PagenameId = pagename && pagename.find((page: pageData) => page?._id === pageid);
  const memoPagenameId = useMemo(() => PagenameId, [PagenameId]);
  const getPageDetail = () => {
    if (memoPagenameId?._id) {
      pageDetail(memoPagenameId?._id)
        .then((data) => setPagedetails(data?.data))
        .catch((err) => console.log('err', err));
    }

    setUploadedImage(null);
    setUploadedVideo(null);
  };
  useEffect(() => {
    getPageDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoPagenameId]);

  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const pagesPermissions =
    permissionFromLocalStorage && userPermissions?.pagePermissions?.find((e) => e?.page_name === 'Pages');
  const desktopLayout = (
    <>
      <FormWrapper>
        <AntSelect
          disabled={!pagesPermissions?.permissions?.dropdown_list_of_pages}
          style={{ width: '100%', overflowY: 'auto' }}
          placeholder="Select Page"
          onChange={(value: string) => setPageid(value)}
          maxTagCount={5}
          className="custom-dropdown"
        >
          {memoRisepagename &&
            memoRisepagename
              ?.filter((e) => e?.name !== 'Advisory Service')
              .map((page) => {
                return (
                  <Option key={page._id} value={page._id}>
                    {page.name}
                  </Option>
                );
              })}
        </AntSelect>
      </FormWrapper>
    </>
  );
  const handleSubmit = async (values: formData) => {
    setloading(true);
    const formdata = new FormData();
    formdata.append('status', values?.status === undefined ? false : values?.status);
    formdata.append('image', uploadimage);
    formdata.append('video', uploadvideo);

    try {
      await pageDetailAdd(formdata as any, pageid);
      notificationController.success({ message: `${memoPagenameId.name} successfully updated` });
      if (memoPagenameId?._id) {
        pageDetail(memoPagenameId?._id)
          .then((data: any) => setPagedetails(data.data))
          .catch((err: any) => console.log('err', err));
      }
      setUploadedImage(null);
      setUploadedVideo(null);
      setloading(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleRemoveMedia = async (type: string) => {
    setloading(true);
    try {
      await pageMediaDelete(type, pageid);
      notificationController.success({ message: `${memoPagenameId.name} Page ${type} removed` });
      if (memoPagenameId?._id) {
        pageDetail(memoPagenameId?._id)
          .then((data) => setPagedetails(data.data))
          .catch((err) => console.log('err', err));
      }
      setloading(false);
    } catch (err) {
      console.log('err', err);
    }
  };
  const uploadProps = {
    name: 'file',
    multiple: false,
    beforeUpload: () => {
      return false;
    },
    onChange(info: any) {
      const { file } = info;
      if (file && file.status !== 'uploading') {
        if (file instanceof File || file instanceof Blob) {
          setUploadedImage(file);
        } else {
          setUploadedImage(null);
        }
      }
    },
  };

  // action:'#',
  // onChange: (info: any) => {
  //   const { status } = info.file;
  //   if (status !== 'uploading') {
  //     console.log(info.file, info.fileList);
  //   }
  //   if (status === 'done') {
  //     notificationController.success({ message: t('uploads.successUpload', { name: info.file.name }) });
  //   } else if (status === 'error') {
  //     notificationController.error({ message: t('uploads.failedUpload', { name: info.file.name }) });
  //   }
  // },
  const uploadVideoProps = {
    name: 'file',
    multiple: false,
    beforeUpload: (file: File) => {
      const isVideo = file.type.startsWith('video/');
      if (!isVideo) {
        notificationController.error({ message: 'You can only upload video files!' });
      }
      return false;
    },
    onChange(info: any) {
      const { file } = info;
      if (file && file.status !== 'uploading') {
        if (file instanceof File || file instanceof Blob) {
          setUploadedVideo(file);
        } else {
          setUploadedVideo(null);
        }
      }
    },
  };

  const handleToggleModal: () => void = () => {
    setShowModal((prevState) => !prevState);
  };

  const items = [
    {
      key: '1',
      label: <a onClick={() => handleRemoveMedia('video')}>Remove</a>,
    },
    {
      key: '2',
      label: 'Cancel',
    },
  ];

  const itemsImage = [
    {
      key: '1',
      label: <a onClick={() => handleRemoveMedia('image')}>Remove</a>,
    },
    {
      key: '2',
      label: 'Cancel',
    },
  ];
  const handleValuesChange = async (changedValues: any, allValues: any) => {
    if (changedValues.status === true || changedValues.status === false || changedValues.Image || changedValues.Video) {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
  };
  return (
    <>
      {showModal && (
        <Modal open={showModal} onCancel={handleToggleModal} footer={null} destroyOnClose>
          <video muted width="100%" controls src={pagedetails[0]?.video} />
        </Modal>
      )}
      {desktopLayout}
      {loading ? (
        <div style={{ width: '50%', margin: '0 auto', textAlign: 'center' }}>
          <BaseSpin size="large" />
        </div>
      ) : (
        <>
          {pagedetails && (
            <>
              <S.Head>Page: {memoPagenameId?.name}</S.Head>
              <div style={{ width: '50%', margin: '0 auto' }}>
                <BaseForm
                  layout="vertical"
                  onFinish={handleSubmit}
                  requiredMark="optional"
                  initialValues={initialValues}
                  onValuesChange={handleValuesChange}
                >
                  <FormInput
                    name="status"
                    label="Image-Video"
                    rules={[{ required: true, message: t('common.requiredField') }]}
                  >
                    <BaseSwitch
                      disabled={!pagesPermissions?.permissions?.active_inactive_icon_image_video}
                      checked={pagedetails[0]?.status}
                      onChange={(checked: boolean) => {
                        setPagedetails((prevState: any) => ({
                          ...prevState,
                          0: {
                            ...prevState[0],
                            status: checked,
                          },
                        }));
                      }}
                    />
                  </FormInput>
                  <FormInput
                    name="Image"
                    label="Image"
                    rules={[{ required: false, message: t('common.requiredField') }]}
                  >
                    {pagedetails[0]?.image ? (
                      <>
                        {' '}
                        <Image
                          width={30}
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Picture_icon_BLACK.svg/1156px-Picture_icon_BLACK.svg.png"
                          preview={{ src: pagedetails[0].image }}
                        />
                        <Dropdown
                          menu={{ items: itemsImage }}
                          trigger={['click']}
                          placement="topRight"
                          disabled={!pagesPermissions?.permissions?.delete_image_video}
                        >
                          <Space>
                            <Removeicon />
                          </Space>
                        </Dropdown>
                      </>
                    ) : (
                      <BaseUpload listType="picture" {...uploadProps} maxCount={1} accept="image/*">
                        <BaseButton
                          style={{ width: '200px' }}
                          icon={<UploadOutlined />}
                          disabled={!pagesPermissions?.permissions?.edit_update_image_video}
                        >
                          Upload image
                        </BaseButton>
                      </BaseUpload>
                    )}
                  </FormInput>
                  <FormInput
                    name="Video"
                    label="Video"
                    rules={[{ required: false, message: t('common.requiredField') }]}
                  >
                    {pagedetails[0]?.video ? (
                      <>
                        {' '}
                        <Image
                          onClick={handleToggleModal}
                          preview={false}
                          width={30}
                          src="https://www.svgrepo.com/show/478883/video-playback-mark.svg"
                        />
                        <Dropdown
                          className="dropdown1"
                          menu={{ items }}
                          trigger={['click']}
                          placement="topRight"
                          disabled={!pagesPermissions?.permissions?.delete_image_video}
                        >
                          <Space>
                            <Removeicon />
                          </Space>
                        </Dropdown>
                      </>
                    ) : (
                      <BaseUpload {...uploadVideoProps} maxCount={1} accept="video/*">
                        <BaseButton
                          style={{ width: '200px' }}
                          icon={<UploadOutlined />}
                          disabled={!pagesPermissions?.permissions?.edit_update_image_video}
                        >
                          Upload video{' '}
                        </BaseButton>
                      </BaseUpload>
                    )}
                  </FormInput>
                  <BaseForm.Item noStyle>
                    <Auth.SubmitButton type="primary" htmlType="submit" disabled={disabled}>
                      Save Settings
                    </Auth.SubmitButton>
                  </BaseForm.Item>
                </BaseForm>
              </div>
            </>
          )}
        </>
      )}

      {/* {desktopLayout} */}
      {/* {isDesktop ? desktopLayout : mobileAndTabletLayout} */}
    </>
  );
};

export default PagesSectionPage;
