import * as S from '../VideosPage.styles';
import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
import { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import EditVideo from './EditVideo';
import Model from '@app/components/Model';
import { Popconfirm } from 'antd';
import { Delete } from '@app/api/Videos/Modules/DeleteVideo.api';
import { notificationController } from '@app/controllers/notificationController';
interface props {
  videoModules?: folder;
  GetVideos: () => void;
}
type folder = [
  {
    name: string;
    id: string;
    data: [
      {
        name: string;
        _id: string;
        description: string;
        free: boolean;
        media_file: string;
        position_in_folder: number;
      },
    ];
  },
];
interface videoData {
  name: string;
  _id: string;
  description: string;
  free: boolean;
  position_in_folder: number;
}
interface ModalState {
  isModalOpen: boolean;
}
const VideoModules = ({ videoModules, GetVideos }: props): JSX.Element => {
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const videosPermissions =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Videos');
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [medieurl, setMedieUrl] = useState<string>();
  const { isModalOpen } = modalState;

  const showModal = (url: string) => {
    setMedieUrl(url);
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  const [isVideoEditModalOpen, setIsEditVideoModalOpen] = useState({
    state: false,
    countryID: '',
    videoData: {} as videoData,
  });
  const handleVideoEdit = (country_id: string, e: videoData) => {
    setIsEditVideoModalOpen({ state: true, countryID: country_id, videoData: e });
  };

  const handleEditOk = () => {
    setIsEditVideoModalOpen({ state: false, countryID: '', videoData: {} as videoData });
  };
  const confirm = async (folderID: string, videoID: string) => {
    await Delete(folderID, videoID);
    notificationController.success({ message: 'Video Deleted Successfully' });
    GetVideos();
  };
  // const handleCancel = () => {

  // };
  return (
    <S.FormWrapper>
      {videosPermissions?.permissions?.list_of_folder_videos &&
        videoModules &&
        videoModules?.map((folder, index) => {
          return (
            <S.CollapseWrapper key={folder.id} style={{ margin: '0 auto', marginBottom: '10px', width: '100%' }}>
              <BaseCollapse.Panel header={folder.name} key={index}>
                {folder?.data
                  ?.sort((a, b) => a?.position_in_folder - b?.position_in_folder)
                  .map((e, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '10px 5px',
                        paddingBottom: '18px',
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'justify',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        onClick={() => handleVideoEdit(folder?.id as string, e)}
                      >
                        {e?.position_in_folder}. {e?.name}
                      </div>
                      <Popconfirm
                        title="This is a one time operation be careful before clicking."
                        onConfirm={() => confirm(folder?.id as string, e?._id as string)}
                        okText={'Delete'}
                        cancelText={
                          <button
                            style={{ border: 'none', backgroundColor: 'inherit' }}
                            onClick={() => showModal(e?.media_file)}
                          >
                            Watch
                          </button>
                        }
                        // onCancel={handleCancel}
                      >
                        <BaseButton
                          disabled={!videosPermissions?.permissions?.delete_button_of_videos}
                          style={{ marginLeft: '5px' }}
                        >
                          <SettingOutlined />
                        </BaseButton>
                      </Popconfirm>
                    </div>
                  ))}
              </BaseCollapse.Panel>
            </S.CollapseWrapper>
          );
        })}

      <Model
        title="Edit Video"
        open={isVideoEditModalOpen.state}
        onOk={handleEditOk}
        onCancel={handleEditOk}
        content={<EditVideo handleEditOk={handleEditOk} data={isVideoEditModalOpen} fetchData={GetVideos} />}
      />
      <Model
        title="Watch Video"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={
          <>
            {medieurl && (
              <>
                <video key={medieurl} width="100%" controls autoPlay>
                  <source src={medieurl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </>
            )}
          </>
        }
      />
    </S.FormWrapper>
  );
};

export default VideoModules;
