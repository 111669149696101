import { httpApi } from '@app/api/http.api';

export interface ContactUsData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  enquiry: string;
}

// interface ReadUser {
//   id: string; // assuming 'id' is a property of your user type
// }

// const readUserString: string | null = localStorage.getItem('user');
// const readUser: ReadUser | null = readUserString ? JSON.parse(readUserString) : null;

// console.log(readUser);

// export const ContactUsForm = (ContactUsData: ContactUsData): Promise<undefined> => {
//   if (readUser) {
//     return httpApi.post<undefined>(`/contactus`, { ...ContactUsData }).then(({ data }) => data);
//   } else {
//     // Handle the case when readUser is null, perhaps throw an error or handle it appropriately.
//     throw new Error('User data not found in local storage');
//   }
// };
export const ContactUsForm = (ContactUsData: ContactUsData): Promise<undefined> => {
  return httpApi.post<undefined>(`/contactus`, { ...ContactUsData }).then(({ data }) => data);
};
