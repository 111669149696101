import React, { FC } from 'react';

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  NavBtnLink,
  Subtitle,
  Img,
  ImgWrap,
} from './saveTimeAndResources';
// interface InfoSectionProps {
//   id: string;
//   headline: string;
//   description: string;
//   img: string;
//   topLine: string; // Assuming 'img' is a string representing the image source
// }
const adArray = [
  {
    id: 1,
    title: 'SAVE TIME AND RESOURCES',
    subtitle: 'A platform offering valuable export insights right at your fingertips!',
    description:
      'Select the best markets for your products and improve decision-making by analysing relevant market insights and comprehensive market and trade data trends for your market and category of choice. Explore our export resources, tutorials, and masterclasses, all in one place.',
    image: 'https://exportconnectportal.com/images/home-banner-dashboard-450.png',
  },
  {
    id: 2,
    title: 'TACTICAL INSIGHTS FROM TRUSTED SOURCES',
    subtitle:
      'Discuss and define your export strategy with trusted advisors with over 40 years of export market development experience.',
    description:
      ' Gain tactical export insights relevant to the market and category of your choice during your one-on-one advisory sessions directly with the Founding Director of Export Connect, Najib Lawand, and Market Development Director, David Leevers.',
    image: 'https://exportconnectportal.com/images/dashboard-2-450.png',
  },
  {
    id: 3,
    title: 'ON-THE-GROUND DATA, A CLICK AWAY',
    subtitle: 'Review your in-market competitors to identify your best opportunities & develop an evidence-based USP.',
    description:
      'Analyse detailed competitor reviews for your product category in international markets including competitor product claims, pricing, product images, country of manufacture, and more!',
    image: 'https://exportconnectportal.com/images/dashboard-3-450.png',
  },
];
const SaveTimeAndResources: FC = () => {
  return (
    <>
      <InfoContainer id="SaveTime">
        <InfoWrapper>
          {adArray.map((data) => (
            <InfoRow key={data.id}>
              <Column1>
                <TextWrapper>
                  {/* <TopLine>fdf</TopLine> */}
                  <TopLine>{data.title}</TopLine>
                  <Heading>{data.subtitle}</Heading>
                  <Subtitle>{data.description}</Subtitle>
                  {/* <NavBtnLink to="/">Hello</NavBtnLink> */}
                </TextWrapper>
              </Column1>
              <Column2>
                <ImgWrap>
                  <Img src={data.image} />
                </ImgWrap>
              </Column2>
            </InfoRow>
          ))}
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default SaveTimeAndResources;
