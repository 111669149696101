import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: foldersData[];
}

interface foldersData {
  _id: string;
  name: string;
}
export const GetResourceFolders = (): Promise<response> => {
  return httpApi.get<response>(`resourceFolders`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
