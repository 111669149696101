import React, { useEffect, useState } from 'react';
import { Card, Button, Typography, Checkbox, Input, message } from 'antd';
import { addItemToCart, removeItemToCart } from '@app/store/slices/addToCartSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { selectCartItems } from '@app/store/slices/addToCartSlice';
import { FaRegCreditCard } from 'react-icons/fa';
import { BsCartCheckFill } from 'react-icons/bs';
import MarketBrowserModal from '@app/pages/CompetitorReviews/MarketBrowserModal';
import * as S from './addToCart.style';
import { loadStripe } from '@stripe/stripe-js';
import { buySubscription } from '@app/api/Subscriptions/SubscriptionModules/PostSubscriptionModulus.api';
import { Select as AntSelect } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import CoupanCodeModel from '../CoupanCodeModel.tsx/CoupanCodeModel';
import { CheckCoupons } from '@app/api/coupons/CheckCoupan.api';
import { GetSubscriptionBasePlan } from '@app/api/Subscriptions/SubscriptionModules/GetSubscriptionBasePlan.api';
import { notificationController } from '@app/controllers/notificationController';
import styled from 'styled-components';
interface Product {
  id: number;
  name: string;
  price: number;
}

const AllCoupanBotton = styled(Button)`
  background: #10c593;
  color: white;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 8px;

  @media (max-width: 446px) {
    font-size: 10px;
  }
`;
const AllCheckbox = styled(Checkbox)`
  @media (max-width: 446px) {
    margin-left: 10px;
  }
`;

interface SelectedItem {
  categoryId: string;
  category_name: string;
  marketId: string;
  market_name: string;
  subcategoryId: string;
  sub_category_name: string;
}

interface ProductCardProps {
  product: Product;
  handleClose: () => void;
  handleOpen: () => void;
}

interface Service {
  category: { id: string; name: string };
  market: { marketId: string; marketName: string };
  subCategory: { subcategoryId: string; subcategoryName: string };
  price: number;
}

interface response {
  sessionId?: string;
}

interface ModalState {
  isModalOpen: boolean;
}

interface errorMessage {
  message: string;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, handleClose, handleOpen }) => {
  const { Title } = Typography;
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector(selectCartItems);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [marketModal, setMarketModal] = useState(false);
  const [serviceCart, setServiceCart] = useState<Service[]>([]);
  const [membership, setMembership] = useState();
  const [discount, setdiscount] = useState();
  const [membershipactive, setmembershipactive] = useState(false);
  const userDataFromLocalStorage = localStorage.getItem('user');
  const userId = userDataFromLocalStorage ? JSON.parse(userDataFromLocalStorage)?.id : null;
  const [coupon, setCoupon] = useState('');
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [prePurchaseModal, setPrePurchaseModal] = useState(false);
  const [cartModal, setCartModal] = useState(false);
  let couponJson = {};
  const [couponStatus, setcouponStatus] = useState(true);

  const handleCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);

    try {
      if (e.target.checked) {
        const response = await CheckCoupons({ code: coupon || '' });
        const discountData = response?.data;

        if (discountData) {
          setdiscount(discountData.percent);
          couponJson = { _id: discountData._id, code: discountData.code, coupon_id: discountData.coupon_id };
          localStorage.setItem('couponDetails', JSON.stringify(couponJson));
          message.success('Coupon code applied!');
        }
      } else {
        message.info('Coupon code removed.');
        setdiscount(undefined);
      }
    } catch (error: any) {
      console.error('Error applying coupon:', error);

      notificationController.error({
        message: 'Error',
        description: error?.message || 'An error occurred while applying the coupon code.',
      });
      setcouponStatus(false);
      setCoupon(''); // Clear the coupon input when the status is false
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAddToCart = () => {
    dispatch(addItemToCart(product));
  };
  const handleRemoveToCart = (id: number) => {
    dispatch(removeItemToCart(id));
  };

  const browseMarketModal = () => {
    setMarketModal(true);
  };

  const handleMarketBrowserCloseModal = () => {
    setMarketModal(false);
  };
  const toggleCartModal = () => {
    setCartModal(true);
    setPrePurchaseModal(false);
  };
  const toggleCompetitorReviewModal = () => {
    setPrePurchaseModal(true);
  };
  const handleMarketSelection = (selectedItems: SelectedItem[]) => {
    const output = selectedItems.map((item) => {
      return {
        category: { id: item.categoryId, name: item.category_name },
        market: { marketId: item.marketId, marketName: item.market_name },
        subCategory: { subcategoryId: item.subcategoryId, subcategoryName: item.sub_category_name },
        price: 150,
      };
    });

    const uniqueOutput = output.filter(
      (item) =>
        !serviceCart.some(
          (existingItem) =>
            existingItem.category.id === item.category.id &&
            existingItem.market.marketId === item.market.marketId &&
            existingItem.subCategory.subcategoryId === item.subCategory.subcategoryId,
        ),
    );
    setServiceCart((prevServiceCart) => [...prevServiceCart, ...uniqueOutput]);
  };

  const handleModalOk = () => {
    handleMarketBrowserCloseModal();
  };

  const removeSubscription = (index: number) => {
    const updatedServiceCart = [...serviceCart];
    updatedServiceCart.splice(index, 1);
    setServiceCart(updatedServiceCart);
  };
  const updatedServiceCart = serviceCart?.map((item) => {
    const price = discount ? (item.price * (100 - discount)) / 100 : item.price;
    return {
      ...item,
      price: price,
    };
  });
  useEffect(() => {
    GetSubscriptionBasePlan(userId, 'Membershipsubscription')
      .then((data) => {
        setmembershipactive(data.membership_active);
      })
      .catch((err: string) => {
        console.log('err', err);
      });
  }, []);
  const subscribeplan = membershipactive
    ? [...updatedServiceCart]
    : [
        ...updatedServiceCart,
        {
          user_id: userId,
          subscriptionprice: discount ? (product.price * (100 - discount)) / 100 : 99,
          membership: 'Membershipsubscription',
        },
      ];
  localStorage.setItem('subscribeplan', JSON.stringify(subscribeplan));
  const handlePayment = async () => {
    setLoading(true);
    setMembership({ user_id: userId, subscriptionprice: 99 });
    const stripePromise = loadStripe(
      'pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3',
    );
    try {
      const response: response = await buySubscription('/subscription/buySubscription', {
        // cart: updatedServiceCart.length <= 0 ? { user_id: userId, subscriptionprice: 99 } : updatedServiceCart,
        cart: subscribeplan,
      });
      const sessionId: string = response?.sessionId ?? '';
      const stripe = await stripePromise;
      // localStorage.setItem('cart', JSON.stringify(subscribeplan));
      await stripe?.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setLoading(false);
    }
  };
  console.log('updatedCart', updatedServiceCart);
  console.log('subscribeplan', subscribeplan);
  return (
    <Card title={'Membership'} style={{ width: '100%', textAlign: 'center' }}>
      {!membershipactive && (
        <S.ServiceCardContainer style={{ border: '1px solid black', borderRadius: '0px' }}>
          <S.ServiceCard>
            <div>
              <S.ServiceName>Export Connect Portal Membership</S.ServiceName>
            </div>
            <div>
              <S.ServiceName>$ {discount ? (product.price * (100 - discount)) / 100 : product.price}</S.ServiceName>
            </div>
          </S.ServiceCard>
        </S.ServiceCardContainer>
      )}
      {cartItems.map((item) => (
        <Title level={5} key={item.id}>
          {item.id}
          {item.name}: ${item.price} <Button onClick={() => handleRemoveToCart(item.id)}>remove</Button>
        </Title>
      ))}
      {serviceCart.length > 0 &&
        serviceCart.map((service, index) => (
          <React.Fragment key={index}>
            <S.ServiceCardContainer style={{ border: '1px solid black', borderRadius: '0px' }}>
              <S.ServiceCard>
                <div>
                  <S.ServiceName>{service.category.name}</S.ServiceName>
                  <S.ServiceName>{service.market.marketName}</S.ServiceName>
                  <S.ServiceName>{service.subCategory.subcategoryName}</S.ServiceName>
                </div>
                <div>
                  <S.ServiceName>{discount ? (service.price * (100 - discount)) / 100 : service.price} $</S.ServiceName>
                  <S.RemoveButton onClick={() => removeSubscription(index)}>Remove</S.RemoveButton>
                </div>
              </S.ServiceCard>
            </S.ServiceCardContainer>
          </React.Fragment>
        ))}
      <S.ServiceCardContainer style={{ border: '1px solid black', borderRadius: '0px', position: 'relative' }}>
        <S.ServiceCard>
          <AllCoupanBotton onClick={() => showModal()} size="small">
            All Coupons
          </AllCoupanBotton>
          <div>
            <S.ServiceName>Optional Promo Code</S.ServiceName>
            <Input
              value={coupon}
              onChange={(e: any) => setCoupon(e.target.value)}
              style={{ marginRight: 8, backgroundColor: '#f5f5f5', border: '2px dotted #10c593' }}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AllCheckbox style={{ marginTop: '22px' }} onChange={handleCheckboxChange}>
              Apply
            </AllCheckbox>
          </div>
        </S.ServiceCard>
      </S.ServiceCardContainer>
      {loading && (
        <S.LoadingCard title="Taking you to checkout page">
          <S.LoaderSpin tip="Taking you to checkout page" />
        </S.LoadingCard>
      )}
      <div style={{ width: '100%', margin: '0 auto', display: 'grid', justifyContent: 'center' }}>
        <Button
          style={{
            borderRadius: '30px',
            background: '#00B4A0',
            marginBottom: '20px',
            width: '270px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
          type="primary"
          icon={<BsCartCheckFill />}
          onClick={browseMarketModal}
          size="large"
        >
          Browse markets
        </Button>
        <Button
          disabled={subscribeplan.length < 1 || loading}
          style={{
            borderRadius: '30px',
            background: '#C33C32',
            marginBottom: '20px',
            width: '270px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
          type="primary"
          onClick={handlePayment}
          icon={<FaRegCreditCard />}
          loading={loading}
          size="large"
        >
          Complete purchase
        </Button>
        <MarketBrowserModal
          open={marketModal}
          onCancel={handleMarketBrowserCloseModal}
          onOk={handleModalOk}
          onSelect={handleMarketSelection}
          toggleCompetitorReviewModal={toggleCartModal}
        />
        <CoupanCodeModel open={isModalOpen} onCancel={handleCancel} onOk={handleOk} />
      </div>
    </Card>
  );
};

export default ProductCard;
