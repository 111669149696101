import { httpApi } from '@app/api/http.api';

interface foldersData {
  sub_category_name?: string;
  _id?: string;
}
export const AddSubCatFolders = (id: string, data: foldersData[]): Promise<undefined> => {
  return httpApi
    .post<undefined>(`/categories/subCategories/${id}/subCategoryUpdate`, { subcategory_options: { type: data } })
    .then(({ data }) => data);
};
