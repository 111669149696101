// http://localhost:3000/localhost/logo

interface response {
  logoValue: string;
}

import { httpApi } from '@app/api/http.api';

export const GetLogoChange = (): Promise<response> => {
  return httpApi.get<response>(`/logo`).then(({ data }) => data);
};
