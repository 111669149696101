import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: backup;
}

type backup = [
  {
    name: string;
    id: string;
    data: [
      {
        name: string;
        _id: string;
        description: string;
        free: boolean;
        media_file: string;
        position_in_folder: number;
      },
    ];
  },
];
export const GetVideoModules = (): Promise<response> => {
  return httpApi.get<response>(`/videoData`).then(({ data }) => data);

  // This API is fetching all the folders(country name generally) and all the videos in that folder
};
