import { httpApi } from '@app/api/http.api';

// POST: Add a new retail price audit report
export interface FormDatas {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status: boolean;
  image: {
    file: {
      originFileObj: File;
    };
  };
  video: {
    file: {
      originFileObj: File;
    };
  };
}
export const pageDetailAdd = (pageDetail: FormDatas, pageId: string): Promise<undefined> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return httpApi
    .post<undefined>(`/pages/${pageId}/pageDetail/addPageDetail   `, pageDetail, config)
    .then(({ data }) => data);
};
