import React from 'react';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements.styles';

const Services: React.FC = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Client Testimonials</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon
            src={
              'https://mazid1.github.io/react-landing-page-with-styled-component-and-smooth-scroll/static/media/svg-4.715313cd.svg'
            }
          />
          {/* <ServicesH2>Reduce expenses</ServicesH2> */}
          <ServicesP>
            I recommend the services that Export Connect provide with utmost confidence and enthusiasm. With only our
            first market exercise in Singapore, Export Connect delivered exceptional support and performance on time and
            with expertise throughout the entire process. Assisting with all details required from beginning to customer
            introductions and beyond. We at Jasper Coffee are extremely pleased with the outcome, the unlimited support
            provided by the Export Connect team at all times and would highly recommend the services of Export Connect
            so much so that we will definitely be utilising their services on our next market exploration.
          </ServicesP>
          <ServicesH2> Steve Janevski | Jasper Coffee</ServicesH2>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon
            src={
              'https://mazid1.github.io/react-landing-page-with-styled-component-and-smooth-scroll/static/media/svg-4.715313cd.svg'
            }
          />
          <ServicesP>
            The webinars and support that the staff from Export Connect provided has proven to be invaluable in
            assisting our preparations to begin exporting our products. The pitch deck was well thought through and
            presented and the coverage of all facets involved in exporting was very insightful. Access to the recordings
            and presentations means this information will be easily accessible for us as we enter discussions with
            potential distributors.
          </ServicesP>
          <ServicesH2> Bruno Rossitt | Nutraviva</ServicesH2>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon
            src={
              'https://mazid1.github.io/react-landing-page-with-styled-component-and-smooth-scroll/static/media/svg-4.715313cd.svg'
            }
          />
          {/* <ServicesH2>Premium Benefits</ServicesH2> */}
          <ServicesP>
            The Export Connect masterclass was highly relevant to our current needs as we expand our seafood export
            business. The webinar series was engaging, informative and well presented and delivered. I would highly
            recommend Export Connect to anyone considering exporting, or anyone currently in the export business and
            wanting to drive their export business forward.
          </ServicesP>
          <ServicesH2> Lachlan McKinnon | Southern Eels</ServicesH2>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
