/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './MarketInsightsPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Select } from 'antd';
import { ViewMarkets } from '@app/api/markets/ViewMarkets.api';
import styled from 'styled-components';
import { LAYOUT, media } from '@app/styles/themes/constants';
import { pageDetail } from '@app/api/pages/page.Details.api';
import { PageGet } from '@app/api/pages/pages.api';
import { useLocation } from 'react-router-dom';
import Model from '@app/components/Model';
import { GetMarketInsights } from '@app/api/markets/GetMarketInsights.api';
import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
import { FileTextOutlined, LaptopOutlined, RiseOutlined } from '@ant-design/icons';
import '../../assets/common.css';
import moment from 'moment';

interface market {
  name: string;
  _id: string;
  status: boolean;
}
interface marketInsightsData {
  MarketInsightsEI: {
    gdb_billions: number;
    gdb_per_capita: number;
    quality_ranking: number;
    human_ranking: number;
    logistics_ranking: number;
    ease_ranking: number;
    source_date: Date;
    trade_agreement: string;
  };
  MarketInsightsDf: {
    total_population: number;
    expatriate_population: number;
    population_growth: number;
    median_age: number;
    urban_population: number;
    population_ethnicity: ArrayData[];
    dominant_religious_groups: ArrayData[];
    population_source: string;
    dominant_source: string;
  };
  MarketInsightsCbst: {
    key_trends: string;
    digital_adoption: string;
  };
  MarketInsightsGrcd: {
    key_trends: string;
    grocery_retailing_source: string;
    petro_convenience_source: string;
    grocery_retailing: ArrayData[];
    petro_convenience: ArrayData[];
  };
  MarketInsightsFcd: {
    key_trends: string;
    full_service_restaurants: ArrayData[];
    limited_service_restaurants: ArrayData[];
  };
  MarketInsightsEccd: {
    key_trends: string;
    Key_e_tailers: string;
  };
  MarketInsightsMar: {
    key_regulators: string;
    import_procedure: string;
    documentation_required: string;
    certificates_required: string;
    labelling_requirements: string;
    packaging_requirements: string;
    tariffs_levied: string;
    non_tarrif_barriers: string;
  };
}
interface ArrayData {
  _id: string;
  name: string;
  percentage: string;
}
interface ModalState {
  isModalOpen: boolean;
}
interface AccessModalState {
  isModalOpen: boolean;
  title: string;
  content: string;
}
export const MainRow = styled(BaseRow)`
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  @media only screen and ${media.xl} {
    jusjustify-content: space-between;
  }
`;
export const RightSideCol = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--sider-background-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(BaseCol)`
  @media only screen and ${media.xl} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    overflow: auto;
    jus
  }
`;
const MarketInsightsPage: React.FC = () => {
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const marketPerms =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Maket Insights');
  const MembershipStatus: string = localStorage.getItem('MembershipStatus') as string;
  const usersubscribeplan: boolean = JSON.parse(MembershipStatus) as boolean;
  const [market, setMarket] = useState<market[]>([]);
  const [marketInsights, setMarketInsights] = useState<marketInsightsData>();
  const [selectedMarket, setSelectedMarket] = useState('');
  const { t } = useTranslation();
  const { Option } = Select;
  const site = useLocation();

  const url = site.pathname;
  const urlName = url.split('/');
  const [pagename, setPageName] = useState<any>('');
  const [pagedetails, setPagedetails] = useState<any>('');

  const PagenameId = pagename && pagename.find((page: any) => page.slug === urlName[2]);
  const memoPagenameId = useMemo(() => PagenameId, [PagenameId]);

  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [accessModal, setAccessModal] = useState<AccessModalState>({ isModalOpen: false, title: '', content: '' });

  const { isModalOpen } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  const showAccessModal = (title: string, content: string) => {
    setAccessModal({ ...modalState, isModalOpen: true, title: title, content: content });
  };

  const handleAccessModalOk = () => {
    setAccessModal({ ...modalState, isModalOpen: false, title: '', content: '' });
  };

  const handleAccessModalCancel = () => {
    setAccessModal({ ...modalState, isModalOpen: false, title: '', content: '' });
  };
  const fetchMarketInsights = async () => {
    const res = await GetMarketInsights(selectedMarket);
    setMarketInsights(res);
  };
  useEffect(() => {
    const fetchMarket = async () => {
      const res = await ViewMarkets();
      setMarket(res?.data as market[]);
    };
    fetchMarket();
  }, []);

  useEffect(() => {
    PageGet()
      .then((data: any) => setPageName(data.data))
      .catch((err: any) => console.log('err', err));
  }, []);
  useEffect(() => {
    if (urlName[2] && memoPagenameId?._id) {
      pageDetail(memoPagenameId?._id)
        .then((data: any) => setPagedetails(data.data))

        .catch((err: any) => console.log('err', err));
    }
  }, [memoPagenameId?._id]);
  function handleChange(value: string) {
    setSelectedMarket(value);
  }
  useEffect(() => {
    selectedMarket !== '' && fetchMarketInsights();
  }, [selectedMarket]);
  const Description = () => <div dangerouslySetInnerHTML={{ __html: accessModal.content }}></div>;
  console.log(accessModal.content);
  const desktopLayout = (
    <>
      <S.Title>Market Insights</S.Title>
      <S.SubTitle>Powerful global insights are now just a click away.</S.SubTitle>
      <S.BlockWrapper>
        <MainRow>
          <RightSideCol xl={13} xxl={14}>
            Enrich your export knowledge and identify the most lucrative and attractive markets for your products by
            analysing key insights and data, including economic indicators, demographic and consumer trends, channel
            developments, and market access requirements. Select your country of choice, then navigate through each
            heading to read more about each indicator relating to your market.
          </RightSideCol>
          {pagedetails && pagedetails[0]?.status && (
            <LeftSideCol xl={11} xxl={10}>
              <div onClick={showModal}>
                <img src={pagedetails[0]?.image} alt="pagedetails" width="100%" />
              </div>
            </LeftSideCol>
          )}
        </MainRow>
        {marketPerms?.permissions?.markets_dropdown_box && usersubscribeplan && (
          <S.ItemFlex>
            <Select style={{ width: 250 }} onChange={handleChange} placeholder="Markets">
              {market
                .filter((e) => e.status)
                .map((e) => (
                  <Option value={e?._id} key={e?._id}>
                    {e?.name}
                  </Option>
                ))}
            </Select>
          </S.ItemFlex>
        )}
        {selectedMarket !== '' && (
          <S.CollapseWrapper style={{ margin: ' 0 auto' }}>
            <BaseCollapse.Panel header="Economic Indicators" key="Economic Indicators">
              <div className="marketsECDiv">
                GDP (Billions): <div>{marketInsights?.MarketInsightsEI?.gdb_billions ?? 'No Data'}</div>{' '}
              </div>
              <div className="marketsECDiv">
                GDP per capita:<div> {marketInsights?.MarketInsightsEI?.gdb_per_capita ?? 'No Data'}</div>
              </div>
              <div className="marketsECDiv">
                Quality of living ranking: <div>{marketInsights?.MarketInsightsEI?.quality_ranking ?? 'No Data'}</div>
              </div>
              <div className="marketsECDiv">
                Human development index ranking:{' '}
                <div>{marketInsights?.MarketInsightsEI?.human_ranking ?? 'No Data'}</div>
              </div>
              <div className="marketsECDiv">
                Logistics performance index ranking:{' '}
                <div>{marketInsights?.MarketInsightsEI?.logistics_ranking ?? 'No Data'}</div>
              </div>
              <div className="marketsECDiv">
                Ease of doing business ranking: <div>{marketInsights?.MarketInsightsEI?.ease_ranking ?? 'No Data'}</div>
              </div>
              {marketInsights?.MarketInsightsEI?.source_date && (
                <div className="marketsECDiv">
                  Sourced: <div>{moment(marketInsights?.MarketInsightsEI?.source_date).format('YYYY-MM-DD')}</div>
                </div>
              )}
              <S.DescDiv>
                <FileTextOutlined /> Trade agreements:
              </S.DescDiv>
              <S.EditorData
                className="tradeAgreementsData"
                dangerouslySetInnerHTML={{ __html: marketInsights?.MarketInsightsEI?.trade_agreement ?? 'No Data' }}
              ></S.EditorData>
            </BaseCollapse.Panel>
            <BaseCollapse.Panel header="Demographic Factors" key="Demographic Factors">
              <div className="marketsECDiv">
                Total population (millions):{' '}
                <div>{marketInsights?.MarketInsightsDf?.total_population ?? 'No Data'}</div>{' '}
              </div>
              <div className="marketsECDiv">
                Expatriate population (millions):
                <div> {marketInsights?.MarketInsightsDf?.expatriate_population ?? 'No Data'}</div>
              </div>
              <div className="marketsECDiv">
                Population growth (%): <div>{marketInsights?.MarketInsightsDf?.population_growth ?? 'No Data'}</div>
              </div>
              <div className="marketsECDiv">
                Median age: <div>{marketInsights?.MarketInsightsDf?.median_age ?? 'No Data'}</div>
              </div>
              <div className="marketsECDiv">
                Urban population (%):
                <div>{marketInsights?.MarketInsightsDf?.urban_population ?? 'No Data'}</div>
              </div>
              {(marketInsights?.MarketInsightsDf?.population_ethnicity as ArrayData[])?.length > 0 && (
                <>
                  <S.ListHeader>Population Ethnicity:</S.ListHeader>
                  <S.ListData>
                    {marketInsights?.MarketInsightsDf?.population_ethnicity?.map((e) => (
                      <S.ListDiv key={e?._id}>
                        <div>{e?.name}</div>
                        <div>{e?.percentage}%</div>
                      </S.ListDiv>
                    ))}
                  </S.ListData>
                  <div style={{ textAlign: 'center', fontSize: '1em', color: '#00b9f3', marginTop: '-10px' }}>
                    {marketInsights?.MarketInsightsDf?.population_source &&
                      `Source: ${marketInsights?.MarketInsightsDf?.population_source}`}
                  </div>
                </>
              )}
              {(marketInsights?.MarketInsightsDf?.dominant_religious_groups as ArrayData[])?.length > 0 && (
                <div style={{ margin: '2rem 0px' }}>
                  <S.ListHeader>Dominant Religious Groups:</S.ListHeader>
                  <S.ListData>
                    {marketInsights?.MarketInsightsDf?.dominant_religious_groups?.map((e) => (
                      <S.ListDiv key={e?._id}>
                        <div>{e?.name}</div>
                        <div>{e?.percentage}%</div>
                      </S.ListDiv>
                    ))}
                  </S.ListData>
                  <div style={{ textAlign: 'center', fontSize: '1em', color: '#00b9f3', marginTop: '-10px' }}>
                    {marketInsights?.MarketInsightsDf?.dominant_source &&
                      `Source: ${marketInsights?.MarketInsightsDf?.dominant_source}`}
                  </div>
                </div>
              )}
            </BaseCollapse.Panel>
            <BaseCollapse.Panel
              header="Consumer Behaviour and Societal Trends"
              key="Consumer Behaviour and Societal Trends"
            >
              <S.DescDiv>
                <RiseOutlined /> Key Trends:
              </S.DescDiv>{' '}
              <S.EditorData
                dangerouslySetInnerHTML={{ __html: marketInsights?.MarketInsightsCbst?.key_trends ?? 'No Data' }}
              ></S.EditorData>
              <S.DescDiv>
                <LaptopOutlined /> Digital Adoption:
              </S.DescDiv>{' '}
              <S.EditorData
                dangerouslySetInnerHTML={{ __html: marketInsights?.MarketInsightsCbst?.digital_adoption ?? 'No Data' }}
              ></S.EditorData>
            </BaseCollapse.Panel>
            <BaseCollapse.Panel header="Grocery Retail Channel Developments" key="Grocery Retail Channel Developments">
              <S.DescDiv>
                <RiseOutlined /> Key Trends:{' '}
              </S.DescDiv>
              <S.EditorData
                dangerouslySetInnerHTML={{ __html: marketInsights?.MarketInsightsGrcd?.key_trends ?? 'No Data' }}
              ></S.EditorData>
              {(marketInsights?.MarketInsightsGrcd?.grocery_retailing as ArrayData[])?.length > 0 && (
                <div>
                  <S.ListHeader>Grocery Retailing:</S.ListHeader>
                  <S.ListSubheader>Number of Establishments</S.ListSubheader>
                  <S.ListData>
                    {marketInsights?.MarketInsightsGrcd?.grocery_retailing?.map((e) => (
                      <S.ListDiv key={e?._id}>
                        <div>{e?.name}</div>
                        <div>{e?.percentage}</div>
                      </S.ListDiv>
                    ))}
                  </S.ListData>
                  <div style={{ textAlign: 'center', fontSize: '1em', color: '#00b9f3', marginTop: '-10px' }}>
                    {marketInsights?.MarketInsightsGrcd?.grocery_retailing_source &&
                      `Source: ${marketInsights?.MarketInsightsGrcd?.grocery_retailing_source}`}
                  </div>
                </div>
              )}
              {(marketInsights?.MarketInsightsGrcd?.petro_convenience as ArrayData[])?.length > 0 && (
                <div style={{ margin: '2rem 0px' }}>
                  <S.ListHeader>Convenience/Petro-Convenience:</S.ListHeader>
                  <S.ListSubheader>Number of establishments</S.ListSubheader>
                  <S.ListData>
                    {marketInsights?.MarketInsightsGrcd?.petro_convenience?.map((e) => (
                      <S.ListDiv key={e?._id}>
                        <div>{e?.name}</div>
                        <div>{e?.percentage}</div>
                      </S.ListDiv>
                    ))}
                  </S.ListData>
                  <div style={{ textAlign: 'center', fontSize: '1em', color: '#00b9f3', marginTop: '-10px' }}>
                    {marketInsights?.MarketInsightsGrcd?.petro_convenience_source &&
                      `Source: ${marketInsights?.MarketInsightsGrcd?.petro_convenience_source}`}
                  </div>
                </div>
              )}
            </BaseCollapse.Panel>
            <BaseCollapse.Panel header="Foodservice Channel Developments" key="Foodservice Channel Developments">
              <S.DescDiv>
                <RiseOutlined /> Key Trends:{' '}
              </S.DescDiv>
              <S.EditorData
                dangerouslySetInnerHTML={{ __html: marketInsights?.MarketInsightsFcd?.key_trends ?? 'No Data' }}
              ></S.EditorData>
              {(marketInsights?.MarketInsightsFcd?.full_service_restaurants as ArrayData[])?.length > 0 && (
                <div>
                  <S.ListHeader>Full-Service Restaurants:</S.ListHeader>
                  <S.ListSubheader>Category value share</S.ListSubheader>
                  <S.ListData>
                    {marketInsights?.MarketInsightsFcd?.full_service_restaurants?.map((e) => (
                      <S.ListDiv key={e?._id}>
                        <div>{e?.name}</div>
                        <div>{e?.percentage}%</div>
                      </S.ListDiv>
                    ))}
                  </S.ListData>
                </div>
              )}
              {(marketInsights?.MarketInsightsFcd?.limited_service_restaurants as ArrayData[])?.length > 0 && (
                <div style={{ margin: '2rem 0px' }}>
                  <S.ListHeader>Limited-Service Restaurants:</S.ListHeader>
                  <S.ListSubheader>Category value share</S.ListSubheader>
                  <S.ListData>
                    {marketInsights?.MarketInsightsFcd?.limited_service_restaurants?.map((e) => (
                      <S.ListDiv key={e?._id}>
                        <div>{e?.name}</div>
                        <div>{e?.percentage}%</div>
                      </S.ListDiv>
                    ))}
                  </S.ListData>
                </div>
              )}
            </BaseCollapse.Panel>
            <BaseCollapse.Panel header="e-Commerce Channel Developments" key="e-Commerce Channel Developments">
              <S.DescDiv>
                <RiseOutlined /> Key Trends:
              </S.DescDiv>
              <S.EditorData
                dangerouslySetInnerHTML={{ __html: marketInsights?.MarketInsightsEccd?.key_trends ?? 'No Data' }}
              ></S.EditorData>
              <S.DescDiv>
                <LaptopOutlined /> Key e-tailers:
              </S.DescDiv>
              <S.EditorData
                dangerouslySetInnerHTML={{ __html: marketInsights?.MarketInsightsEccd?.Key_e_tailers ?? 'No Data' }}
              ></S.EditorData>
            </BaseCollapse.Panel>
            <BaseCollapse.Panel header="Market Access Requirements" key="Market Access Requirements">
              <div>
                {marketInsights?.MarketInsightsMar?.key_regulators && (
                  <S.ModalButton
                    onClick={() =>
                      showAccessModal('Key Regulators', marketInsights?.MarketInsightsMar?.key_regulators as string)
                    }
                  >
                    KEY REGULATORS
                  </S.ModalButton>
                )}
                {marketInsights?.MarketInsightsMar?.import_procedure && (
                  <S.ModalButton
                    onClick={() =>
                      showAccessModal('Import Procedure', marketInsights?.MarketInsightsMar?.import_procedure as string)
                    }
                  >
                    IMPORT PROCEDURE
                  </S.ModalButton>
                )}
                {marketInsights?.MarketInsightsMar?.documentation_required && (
                  <S.ModalButton
                    onClick={() =>
                      showAccessModal(
                        'Documentation Required',
                        marketInsights?.MarketInsightsMar?.documentation_required as string,
                      )
                    }
                  >
                    DOCUMENTATION REQUIRED
                  </S.ModalButton>
                )}
                {marketInsights?.MarketInsightsMar?.certificates_required && (
                  <S.ModalButton
                    onClick={() =>
                      showAccessModal(
                        'Certificates Required',
                        marketInsights?.MarketInsightsMar?.certificates_required as string,
                      )
                    }
                  >
                    CERTIFICATES REQUIRED
                  </S.ModalButton>
                )}
                {marketInsights?.MarketInsightsMar?.labelling_requirements && (
                  <S.ModalButton
                    onClick={() =>
                      showAccessModal(
                        'General Labelling Requirements',
                        marketInsights?.MarketInsightsMar?.labelling_requirements as string,
                      )
                    }
                  >
                    GENERAL LABELLING REQUIREMENTS
                  </S.ModalButton>
                )}
                {marketInsights?.MarketInsightsMar?.packaging_requirements && (
                  <S.ModalButton
                    onClick={() =>
                      showAccessModal(
                        'Packaging Requirement',
                        marketInsights?.MarketInsightsMar?.packaging_requirements as string,
                      )
                    }
                  >
                    PACKAGING REQUIREMENTS
                  </S.ModalButton>
                )}
                {marketInsights?.MarketInsightsMar?.tariffs_levied && (
                  <S.ModalButton
                    onClick={() =>
                      showAccessModal('Tariffs Levied', marketInsights?.MarketInsightsMar?.tariffs_levied as string)
                    }
                  >
                    TARIFFS LEVIED
                  </S.ModalButton>
                )}
                {marketInsights?.MarketInsightsMar?.non_tarrif_barriers && (
                  <S.ModalButton
                    onClick={() =>
                      showAccessModal(
                        'Non-Tariff Barriers',
                        marketInsights?.MarketInsightsMar?.non_tarrif_barriers as string,
                      )
                    }
                  >
                    NON-TARIFF BARRIERS
                  </S.ModalButton>
                )}
              </div>
            </BaseCollapse.Panel>
          </S.CollapseWrapper>
        )}
      </S.BlockWrapper>
      <Model
        title={accessModal.title}
        open={accessModal.isModalOpen}
        onOk={handleAccessModalOk}
        onCancel={handleAccessModalCancel}
        content={<Description />}
      />
    </>
  );

  return (
    <>
      <PageTitle>{t('common.market-insights')}</PageTitle>
      {desktopLayout}
      {pagedetails[0]?.video && (
        <Model
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          content={
            <>
              <video width="100%" controls autoPlay>
                <source src={pagedetails[0]?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          }
        />
      )}
    </>
  );
};
export default MarketInsightsPage;
