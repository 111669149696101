import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: backup;
}

type backup = [
  {
    name: string;
    id: string;
    data: [
      {
        name: string;
        _id: string;
        description: string;
        free: boolean;
        media_file: string;
        position_in_folder: number;
        thumbnail: string;
        media_file_url: string;
        thumbnail_url: string;
      },
    ];
  },
];
export const GetResourceModules = (): Promise<response> => {
  return httpApi.get<response>(`/resourceData`).then(({ data }) => data);
};
// This API is fetching all the Resource Modules(Execute,Examine, etc) and all the Resources in that folder
