import { httpApi } from '@app/api/http.api';
interface response {
  data: categoryMarkets[];
}
interface categoryMarkets {
  status: boolean;
  category_id: string;
  createdAt: Date;
  market_id: string;
  market_name: string;
  updatedAt: Date;
  _id: string;
}
export const GetCategoryMarkets = (id: string): Promise<response> => {
  return httpApi.get<response>(`categories/categoryMarket/${id}/categoryMarketList`).then(({ data }) => data);

  // This API is fetching markest data for Category > category markets
};
