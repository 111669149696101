/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/lib/mentions';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { Form } from 'antd';
import { AddCatMarketSubcategory } from '@app/api/categories/CategoryMarkets/CategoryMarketSubCategory/AddCatMarketSubcategory.api';
import { GetSubCategoryOptions } from '@app/api/categories/SubCategoryOptions/GetSubCategoryOptions.api';
import { useLocation } from 'react-router-dom';
import { CustomSpin } from '../../CategoriesPage.styles';

interface props {
  fetchData: () => void;
  handleOk: () => void;
  handleCancel: () => void;
  catID: string;
  marketID: string;
  existingData: MyType[];
}
interface subCatOption {
  sub_category_name: string;
  _id: string;
}
interface values {
  sub_cat_id: string;
}
type MyType = {
  sub_category_name: string;
  sub_cat_id: string;
  category_id: string;
  category_name: string;
  createdAt: Date;
  market_id: string;
  market_name: string;
  updatedAt: Date;
  _id: string;
};
const AddCatMarkSubCatForm = ({
  handleOk,
  handleCancel,
  catID,
  marketID,
  existingData,
  fetchData,
}: props): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [Dropdownloading, setDropdownLoading] = useState(false);
  const [subCatFolders, setSubCatFolders] = useState<subCatOption[]>([]);

  //This is called to map sub category options in select of add form
  const fetchSubCatData = async () => {
    setDropdownLoading(true);
    try {
      const response = await GetSubCategoryOptions(state.category_id);
      // response?.data.length >= 1 && setSubCatFolders(response?.data[0]?.subcategory_options);
      console.log('sanjayyy', response.data);
      const markets = response?.data[0]?.subcategory_options;
      let filteredData; // Define filteredData outside the condition

      if (existingData?.length > 0) {
        filteredData = markets.filter((item) => !existingData?.map((bItem) => bItem?.sub_cat_id).includes(item._id));
      } else {
        filteredData = markets;
      }

      setSubCatFolders(filteredData);
    } catch (error) {
      console.error('Error fetching market data:', error);
    } finally {
      setDropdownLoading(false);
    }
  };

  useEffect(() => {
    fetchSubCatData();
  }, [existingData]);

  const handleSubmit = async (values: values) => {
    setLoading(true);
    try {
      //Post API Is running Here
      await AddCatMarketSubcategory(catID, marketID, values);
      notificationController.success({ message: t('common.success') });
      handleOk();
      handleCancel();
      fetchData();
      form.resetFields();
    } catch (error) {
      console.error('Error while adding category.', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {!Dropdownloading ? (
        <BaseForm form={form} onFinish={handleSubmit} style={{ display: 'flex', justifyContent: 'center' }}>
          <Auth.FormItem name="sub_cat_id" rules={[{ required: true, message: t('common.requiredField') }]}>
            <Auth.FormBaseSelect placeholder="Subcategory" style={{ width: 'calc(250px + 0.5vw)' }}>
              {subCatFolders?.map((e) => (
                <Option value={e._id} key={e._id}>
                  {e.sub_category_name}
                </Option>
              ))}
            </Auth.FormBaseSelect>
          </Auth.FormItem>

          <BaseForm.Item style={{ margin: '0px 5px' }}>
            <Auth.SubmitButton type="primary" disabled={loading} htmlType="submit" loading={loading}>
              ADD
            </Auth.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      ) : (
        <CustomSpin />
      )}
    </div>
  );
};
export default AddCatMarkSubCatForm;
