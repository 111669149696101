import { httpApi } from '@app/api/http.api';
interface data {
  code: string;
}

export const GetAllCoupan = (): Promise<any> => {
  return httpApi.get<any>(`/coupons/getallcode`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
