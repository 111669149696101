import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
import { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import Model from '@app/components/Model';
import * as S from '../AdminResourcesPage.styles';
import EditResource from './EditResource';
import { Popconfirm } from 'antd';
import { DeleteResource } from '@app/api/Resources/ResourceModules/DeleteResource.api';
import { notificationController } from '@app/controllers/notificationController';
interface props {
  resourceModules?: folder;
  GetResource: () => void;
}
type folder = [
  {
    name: string;
    id: string;
    data: [
      {
        name: string;
        _id: string;
        description: string;
        free: boolean;
        media_file: string;
        position_in_folder: number;
        thumbnail: string;
        media_file_url: string;
        thumbnail_url: string;
      },
    ];
  },
];
interface resourceData {
  name: string;
  _id: string;
  description: string;
  free: boolean;
  media_file_url: string;
  media_file: string;
  thumbnail: string;
  thumbnail_url: string;
  position_in_folder: number;
}

const ResourceModules = ({ resourceModules, GetResource }: props): JSX.Element => {
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const resourcesPermissions =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Resource');
  const [isResourceEditModalOpen, setIsEditResourceModalOpen] = useState({
    state: false,
    countryID: '',
    resourceData: {} as resourceData,
  });
  const handleResourceEdit = (country_id: string, e: resourceData) => {
    setIsEditResourceModalOpen({ state: true, countryID: country_id, resourceData: e });
  };

  const handleEditOk = () => {
    setIsEditResourceModalOpen({ state: false, countryID: '', resourceData: {} as resourceData });
  };

  const confirm = async (folderID: string, videoID: string) => {
    await DeleteResource(folderID, videoID);
    notificationController.success({ message: 'Resource Deleted Successfully' });
    GetResource();
  };

  const handleDownload = (id: string, fileUrl: string) => {
    window.open(fileUrl, '_blank');
  };

  // console.log(folders);

  return (
    <S.FormWrapper>
      {resourcesPermissions?.permissions?.list_of_folder_resources &&
        resourceModules &&
        resourceModules?.map((folder, index) => {
          return (
            <S.CollapseWrapper key={folder?.id} style={{ margin: '0 auto', marginBottom: '10px', width: '80%' }}>
              <BaseCollapse.Panel header={folder.name} key={index}>
                {folder?.data
                  ?.sort((a, b) => a?.position_in_folder - b?.position_in_folder)
                  .map((e, index) => (
                    <div
                      key={index}
                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '5px' }}
                    >
                      <div onClick={() => handleResourceEdit(folder?.id as string, e)}>
                        {' '}
                        {e?.position_in_folder}. {e?.name}
                      </div>
                      <Popconfirm
                        title="This is a one time operation be careful before clicking."
                        onConfirm={() => confirm(folder?.id as string, e?._id as string)}
                        okText={'Delete'}
                        cancelText={'Download'}
                        onCancel={() => handleDownload(e?._id, e?.media_file)}
                      >
                        <BaseButton disabled={!resourcesPermissions?.permissions?.delete_button_of_resources}>
                          <SettingOutlined />
                        </BaseButton>
                      </Popconfirm>
                    </div>
                  ))}
              </BaseCollapse.Panel>
            </S.CollapseWrapper>
          );
        })}
      <Model
        title="Edit Resource"
        open={isResourceEditModalOpen.state}
        onOk={handleEditOk}
        onCancel={handleEditOk}
        content={<EditResource handleEditOk={handleEditOk} data={isResourceEditModalOpen} fetchData={GetResource} />}
      />
    </S.FormWrapper>
  );
};

export default ResourceModules;
