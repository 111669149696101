import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Head } from './Landing/LandingPage.styles';

const DashboardPage: React.FC = () => {
  const desktopLayout = (
    <div>
      <BaseRow gutter={[0, 60]}>
        <BaseCol span={24}>
          Dive into a world of boundless opportunities with our revolutionary platform! Harness the power of tactical
          data and export insights right at your fingertips! Whether you are a seasoned exporter or just dipping your
          toes into the global market, we have got the tools, resources, and expertise to elevate your journey. Unlock
          exclusive access to export market insights, invaluable resources, enriching masterclasses, and crucial data
          that will propel your business forward. Our bespoke solutions are meticulously crafted to cater to your unique
          business needs, ensuring you stride confidently towards international success. Embark on your export journey
          with unwavering confidence, armed with the knowledge and support you need to conquer new horizons. Let&apos;s
          transform your aspirations into achievements, together! Welcome aboard to a world of endless possibilities!
        </BaseCol>
      </BaseRow>
    </div>
  );

  return (
    <div style={{ width: '70%', margin: '0 auto', textAlign: 'justify' }}>
      <PageTitle>Your Subscriptions</PageTitle>
      <Head>Welcome to the Export Connect Portal</Head>
      {desktopLayout}
      {/* {isDesktop ? desktopLayout : mobileAndTabletLayout} */}
    </div>
  );
};

export default DashboardPage;
