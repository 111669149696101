import styled from 'styled-components';
import { BaseCard, BaseCard as CommonCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Collapse } from 'antd';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';

export const TablesWrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
  width: 35%;
  margin: auto;
`;
export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  // background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;

export const ItemFlex = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
  font-size: 20px;
`;
export const Title = styled.h1`
  font-size: 2rem;
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin: 0;
`;

export const Total = styled.h3`
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  color: green;
`;

export const SubTitle = styled.p`
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
`;

export const SubcriptionTitle = styled.div`
  justify-content: center;
  display: flex;
`;

export const BrowseMarketsButton = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
`;

export const CartButton = styled(BaseButton)`
  margin: 10px 0;
  margin-bottom: 20px;
  margin-inline: auto;
  width: 250px;
`;

export const PurchaseButton = styled(BaseButton)`
  margin: 10px 0;
  margin-bottom: 20px;
  margin-inline: auto;
  width: 250px;
`;

export const ServiceCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ServiceName = styled.h5`
  font-size: 14px;
  margin: 5px;
`;

export const ServiceCardContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const RemoveButton = styled.button`
  background-color: #ff6347;
  color: #fff;
  border: none;
  font-size: 10px;
  padding: 2px 5px;
  cursor: pointer;
`;

export const CollapseSub = styled(Collapse)`
  width: 250px;
`;

export const SelectCategory = styled(BaseSelect)`
  width: 250px;
  margin-bottom: 10px;
`;
export const LoadingCard = styled(Card)`
  margin: 15px 0px;
  width: 100%;
  .ant-card-head-title {
    textwrap: wrap !important;
    textoverflow: unset !important;
    overflow: unset !important;
  }
`;
export const LoaderSpin = styled(BaseSpin)`
  top: 70% !important;
`;
