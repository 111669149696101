import { httpApi } from '@app/api/http.api';
interface response {
  status: boolean;
  message: string;
  data: Subscription[];
}

interface Subscription {
  _id: string;
  category_id: any;
  market_id: any;
  cat_market_sabcat_id: any;
  matching_subcategory: any;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export const GetSubscriptionModules = (): Promise<response> => {
  const userDataFromLocalStorage = localStorage.getItem('user');
  const userId = userDataFromLocalStorage ? JSON.parse(userDataFromLocalStorage)?.id : null;
  return httpApi.get<response>(`/subscription/getsubscription/${userId}`).then(({ data }) => data);
};
// This API is fetching all the Resource Modules(Execute,Examine, etc) and all the Resources in that folder
export const GetAllSubscription = (): Promise<response> => {
  return httpApi.get<response>(`/subscription/allSubscription`).then(({ data }) => data);
};
