/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import Model from '@app/components/Model';
import * as S from './MarketsPage.styles';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { DeleteMarket } from '@app/api/markets/DeleteMarket.api';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../assets/common.css';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
interface markets {
  name: string;
  _id: string;
}
interface props {
  items: markets[];
  loadMarkets: () => void;
  permissions: any;
}
interface ModalState {
  isModalOpen: boolean;
  name?: string;
  _id?: string;
}
interface Error {
  message: string;
}
const MarketsData = ({ items, loadMarkets, permissions }: props): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [delLoading, setDelLoading] = useState(false);
  const { isModalOpen } = modalState;
  useEffect(() => {
    if (state?.update && items?.length > 0) {
      const details = items.find((e) => e?._id === state.update);
      navigate(`${pathname}/${details?._id}`, { state: details });
    }
  }, [items]);
  const showModal = ({ _id, name }: markets) => {
    setModalState({ ...modalState, isModalOpen: true, _id, name });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };
  const deleteAlert = async ({ _id, name }: markets) => {
    showModal({ _id, name });
  };
  const handleDeleteMarket = async () => {
    setDelLoading(true);
    try {
      await DeleteMarket(modalState._id as string);
      notificationController.success({ message: t('common.success') });
      await loadMarkets();
      handleOk();
    } catch (error) {
      // console.error('Error downloading users:', error);
      notificationController.error(error as Error);
      console.log(error);
      // Handle errors appropriately
    } finally {
      setDelLoading(false);
    }
  };
  const DeleteMsg = () => (
    <div>
      <div>Are you sure you want to delete {modalState.name}?</div>
      <S.ButtonContainer>
        <S.Button disabled={delLoading} onClick={handleCancel}>
          Cancel
        </S.Button>
        <S.Button disabled={delLoading} type="primary" onClick={handleDeleteMarket} loading={delLoading}>
          Submit
        </S.Button>
      </S.ButtonContainer>
    </div>
  );
  const navigateToDifferentRoute = (e: markets) => {
    // Use the history.push method to navigate to a different route
    navigate(`${pathname}/${e._id}`, { state: e });
  };

  return (
    <>
      {items
        .sort((a, b) => a?.name?.localeCompare(b?.name))
        .map((e) => (
          <S.ListWrapper style={{ display: 'flex', boxShadow: '-3px -1px 9px 0px', margin: '10px 0px' }} key={e._id}>
            <div className="hoverDiv" onClick={() => navigateToDifferentRoute(e)}>
              <S.BaseRowBox gutter={[16, 16]}>{e.name}</S.BaseRowBox>
            </div>
            {permissions?.permissions?.delete_markets_icon && (
              <BaseButton style={{ margin: '7px 10px' }} onClick={() => deleteAlert({ _id: e._id, name: e.name })}>
                <DeleteOutlined />
              </BaseButton>
            )}
          </S.ListWrapper>
        ))}
      <Model title="Delete Market" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} content={<DeleteMsg />} />
    </>
  );
};
export default MarketsData;
