import { useEffect, useState } from 'react';
import * as S from '../ConsumerBehaviour/ConsumerBehaviourButton.styles';
import CustomModal from '@app/components/Model';
import { useParams } from 'react-router-dom';
import { GetECommerce } from '@app/api/markets/MarketDetails/ECommerce/GetECommerce.api';
import ECQuillEditor from './ECQuillEditor';
interface ECommerceData {
  key_trends: string;
  Key_e_tailers: string;
}
const ECommerceCD = (): JSX.Element => {
  const [formData, setFormData] = useState<ECommerceData>();
  const [ECLoading, setECLoading] = useState(false);
  const { id } = useParams();
  const [modalState, setModalState] = useState({ id: '', isModalOpen: false });
  const [etModalState, setEtModalState] = useState({ id: '', isModalOpen: false });
  const fetchData = async () => {
    setECLoading(true);
    try {
      const response = await GetECommerce(id as string);
      setFormData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setECLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Key Trends Modal
  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  //Key E-TAILERS Modal
  const showEtModal = () => {
    setEtModalState({ ...modalState, isModalOpen: true });
  };

  const handleEtOk = () => {
    setEtModalState({ ...modalState, isModalOpen: false });
  };

  const handleEtCancel = () => {
    setEtModalState({ ...modalState, isModalOpen: false });
  };
  return (
    <>
      <S.ButtonRowWrap>
        <S.BaseRowButton onClick={showModal}>Key Trends</S.BaseRowButton>
        <S.BaseRowButton onClick={showEtModal}>Key E-TAILERS</S.BaseRowButton>
      </S.ButtonRowWrap>
      <CustomModal
        title="Key Trends"
        open={modalState.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={
          <ECQuillEditor
            handleOk={handleCancel}
            data={formData?.key_trends as string}
            fetchData={fetchData}
            type="key-trends"
          />
        }
      />
      <CustomModal
        title="Key E-TAILERS"
        open={etModalState.isModalOpen}
        onOk={handleEtOk}
        onCancel={handleEtCancel}
        content={
          <ECQuillEditor
            handleOk={handleEtCancel}
            data={formData?.Key_e_tailers as string}
            fetchData={fetchData}
            type="Key_e_tailers"
          />
        }
      />
    </>
  );
};
export default ECommerceCD;
