import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/lib/mentions';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { ViewMarkets } from '@app/api/markets/ViewMarkets.api';
import { AddCategoryMarkets } from '@app/api/categories/CategoryMarkets/AddCategoryMarkets.api';
import { Form } from 'antd';
import { CustomSpin } from '../CategoriesPage.styles';

interface props {
  fetchCatMarkData: () => void;
  handleOk: () => void;
  handleCancel: () => void;
  catID: string;
  existingData: MyType[];
}
interface categoryType {
  _id: string;
  name: string;
}
interface addCategory {
  market_id: string;
}
type MyType = {
  category_id: string;
  createdAt: Date;
  market_id: string;
  market_name: string;
  updatedAt: Date;
  _id: string;
};
type MyObjectType = {
  status: boolean;
  _id: string;
  name: string;
  country_id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
const AddCategoryMarketForm = ({ fetchCatMarkData, handleOk, catID, existingData }: props): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [categoryMarketOptions, setCategoryMarketOptions] = useState<categoryType[]>([]);
  const [loading, setLoading] = useState(false);
  const [dropdwonLoading, setDropdownLoading] = useState(false);
  console.log('existingData', existingData);

  useEffect(() => {
    const fetchData = async () => {
      setDropdownLoading(true);
      try {
        const response = await ViewMarkets();
        const markets: MyObjectType[] = response?.data || [];
        // console.log('markets', markets);
        // console.log('existingData', existingData);
        let filteredData; // Define filteredData outside the condition

        if (existingData?.length > 0) {
          filteredData = markets.filter((item) => !existingData?.map((bItem) => bItem.market_id).includes(item._id));
        } else {
          filteredData = markets;
        }

        setCategoryMarketOptions(filteredData);
      } catch (error) {
        console.error('Error fetching market data:', error);
      } finally {
        setDropdownLoading(false);
      }
    };

    fetchData();
  }, [existingData]);
  // console.log('data------->', categoryMarketOptions);
  const handleSubmit = async (values: addCategory) => {
    console.log(values);
    setLoading(true);
    try {
      await AddCategoryMarkets(catID, values);
      notificationController.success({ message: t('common.success') });
      await fetchCatMarkData();
      handleOk();
      form.resetFields();
    } catch (error) {
      console.error('Error while adding category.', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {!dropdwonLoading ? (
        <BaseForm form={form} onFinish={handleSubmit} style={{ display: 'flex', justifyContent: 'center' }}>
          <Auth.FormItem name="market_id" rules={[{ required: true, message: t('common.requiredField') }]}>
            {
              <Auth.FormBaseSelect placeholder="Category market">
                {categoryMarketOptions?.map((e) => (
                  <Option value={e._id} key={e._id}>
                    {e.name}
                  </Option>
                ))}
              </Auth.FormBaseSelect>
            }
          </Auth.FormItem>

          <BaseForm.Item style={{ margin: '0px 5px' }}>
            <Auth.SubmitButton type="primary" disabled={loading} htmlType="submit">
              ADD
            </Auth.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      ) : (
        <CustomSpin />
      )}
    </div>
  );
};
export default AddCategoryMarketForm;
