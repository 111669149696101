/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/lib/mentions';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { UploadOutlined } from '@ant-design/icons';
import { GetFolders } from '@app/api/Videos/Folders/GetFolders.api';
import { EditVideoDetails } from '@app/api/Videos/Modules/EditVideoDetails.api';
import TextArea from 'antd/lib/input/TextArea';
// Put APi here

interface props {
  handleEditOk: () => void;
  fetchData: () => void;
  data: { state: boolean; countryID: string; videoData: videoData };
}
interface videoData {
  name: string;
  _id: string;
  description: string;
  free: boolean;
  position_in_folder: number;
}
interface foldersData {
  _id: string;
  name: string;
}
interface formData {
  folder_id: string;
  free: Blob;
  position_in_folder: string;
  name: string;
  description: string;
  media: {
    file: {
      originFileObj: File;
      size: number;
      type: string;
    };
  };
  thumbnail: {
    file: {
      originFileObj: File;
      size: number;
      type: string;
    };
  };
  // media_file_url: string;
  // thumbnail_url: string;
}
interface fileFormat {
  originFileObj: File;
  size: number;
  type: string;
}
interface Error {
  message: string;
}

const EditVideo = ({ handleEditOk, data, fetchData }: props): JSX.Element => {
  const initValues = {
    folder_id: data.countryID,
    free: data.videoData.free,
    // media_file_url: data.videoData.media_file_url,
    name: data.videoData.name,
    position_in_folder: data.videoData.position_in_folder,
    // thumbnail_url: data.videoData.thumbnail_url,
    description: data.videoData.description,
  };

  const { t } = useTranslation();
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const videoPerms =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Videos');

  const [folders, setFolders] = useState<foldersData[]>([]);
  const [loading, setLoading] = useState(false);
  const [form] = BaseForm.useForm();

  const mediaUploadProps = {
    name: 'media',
    multiple: false,
    accept: '.3gp,.avi,.flv,.gvi,.m2v,.m4v,.mkv,.mov,.mp4,.mpg,.ogv,.webm,.wmv,.mpeg', // Specify accepted video file types
  };
  const thumbnailUploadProps = {
    name: 'thumbnail',
    multiple: false,
    accept: '.jpg,.jpeg,.png,.gif,.bmp,.apng,.avif,.jfif,.pjpeg,.pjp,.svg,.webp', // Specify accepted image file types
  };
  useEffect(() => {
    form.resetFields();
  }, [data]);
  useEffect(() => {
    const fetchDataAndUpdateFolders = async () => {
      try {
        const response = await GetFolders();
        setFolders(response?.data as foldersData[]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataAndUpdateFolders();
  }, []);

  const handleSubmit = async (values: formData) => {
    const formdata = new FormData();
    formdata.append('position_in_folder', values?.position_in_folder);
    formdata.append('name', values?.name);
    formdata.append('free', values?.free);
    formdata.append('description', values?.description);

    // formdata.append('media_file_url', values?.media_file_url);

    // formdata.append('thumbnail_url', values?.thumbnail_url);

    //   Run Put Api here
    // if (values.media && values.media.file && values.thumbnail && values.thumbnail.file) {
    //   // Run validation for media file
    //   if (!validateFile(values?.media?.file, 'video')) {
    //     return; // Stop execution if media file validation fails
    //   }

    //   // Run validation for thumbnail file
    //   if (!validateFile(values?.thumbnail?.file, 'image')) {
    //     return; // Stop execution if thumbnail file validation fails
    //   }

    //   // Append media file and thumbnail file to formdata if validations pass
    //   formdata.append('media_file', values?.media?.file?.originFileObj);
    //   formdata.append('thumbnail', values?.thumbnail?.file?.originFileObj);
    // }

    // Append media file if present
    if (values.media && values.media.file) {
      if (!validateFile(values.media.file, 'pdf')) {
        return; // Stop execution if media file validation fails
      }
      formdata.append('media_file', values?.media?.file?.originFileObj);
    }

    // Append thumbnail file if present
    if (values.thumbnail && values.thumbnail.file) {
      if (!validateFile(values.thumbnail.file, 'image')) {
        return; // Stop execution if thumbnail file validation fails
      }
      formdata.append('thumbnail', values?.thumbnail?.file?.originFileObj);
    }

    try {
      setLoading(true);
      await EditVideoDetails(values.folder_id, data.videoData._id, formdata);
      notificationController.success({ message: t('common.success') });
      fetchData();
      handleEditOk();
    } catch (error) {
      notificationController.error(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const validateFile = (file: fileFormat, fileType: 'video' | 'image' | 'pdf') => {
    // Validate file type
    const isVideo = fileType === 'video' ? file.type.startsWith('video/') : false;
    const isImage = fileType === 'image' ? file.type.startsWith('image/') : false;

    if (fileType === 'video' && !isVideo) {
      notificationController.error({ message: 'Invalid file type. Please upload a Video file In Media.' });
      return false;
    }

    if (fileType === 'image' && !isImage) {
      notificationController.error({ message: 'Invalid file type. Please upload an Image file in Thumbnail.' });
      return false;
    }

    // Validate file size
    let maxSize;
    if (fileType === 'video') {
      // 60MB limit for video files
      maxSize = 60 * 1024 * 1024; // 50 MB in bytes
    } else {
      // 20MB limit for image files
      maxSize = 20 * 1024 * 1024; // 10 MB in bytes
    }

    if (file.size > maxSize) {
      if (fileType === 'video') {
        notificationController.error({
          message: 'Video file size exceeds the limit. Please upload a file up to 60 MB.',
        });
      } else {
        notificationController.error({
          message: 'Image file size exceeds the limit. Please upload a file up to 20 MB.',
        });
      }
      return false;
    }

    return true;
  };

  return (
    <Auth.FormWrapper>
      <BaseForm
        form={form}
        onFinish={handleSubmit}
        initialValues={initValues}
        disabled={!videoPerms.permissions?.add_video_button}
      >
        <Auth.FormItem name="folder_id" label="Folder" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Auth.FormBaseSelect placeholder="Folder">
            {folders?.map((element) => {
              return (
                <>
                  <Option value={element._id}>{element.name}</Option>
                </>
              );
            })}
          </Auth.FormBaseSelect>
        </Auth.FormItem>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Auth.FormItem name="free" valuePropName="checked" noStyle>
            <Auth.FormCheckbox style={{ marginLeft: '10px' }}>
              <Auth.Text>Free</Auth.Text>
            </Auth.FormCheckbox>
          </Auth.FormItem>

          <Auth.FormItem name="position_in_folder" label="">
            <Auth.FormInput placeholder="Position" />
          </Auth.FormItem>
        </div>

        <Auth.FormItem name="name" label="" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Auth.FormInput placeholder="Name" />
        </Auth.FormItem>

        <Auth.FormItem name="description" label="">
          <TextArea placeholder="Description" autoSize={{ minRows: 2, maxRows: 5 }} />
        </Auth.FormItem>

        <Auth.FormItem name="media" label="Media">
          <BaseUpload {...mediaUploadProps} maxCount={1}>
            <BaseButton icon={<UploadOutlined />}>{t('uploads.clickToUpload')}</BaseButton>
          </BaseUpload>
        </Auth.FormItem>

        {/* <Auth.FormItem
          name="media_file_url"
          label="Media File URl"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder="Media File URL"></Auth.FormInput>
        </Auth.FormItem> */}

        <Auth.FormItem name="thumbnail" label="Thumbnail">
          <BaseUpload {...thumbnailUploadProps} maxCount={1}>
            <BaseButton icon={<UploadOutlined />}>{t('uploads.clickToUpload')}</BaseButton>
          </BaseUpload>
        </Auth.FormItem>

        {/* <Auth.FormItem
          name="thumbnail_url"
          label="Thumbnail URL"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput></Auth.FormInput>
        </Auth.FormItem> */}

        <BaseForm.Item noStyle>
          <Auth.SubmitButton disabled={loading} type="primary" htmlType="submit" loading={loading}>
            {!loading ? 'Save' : ''}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
export default EditVideo;
