import { httpApi } from '@app/api/http.api';

export const DeleteCategoryTableFields = (
  category_id: string,
  category_market_id: string,
  cat_market_sabcat_id: string,
  tableFieldId: string,
) => {
  return httpApi
    .delete(
      `categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/categoryTableFields/${tableFieldId}/categoryTableFieldDelete`,
    )
    .then(({ data }) => data);
};

// this api is to call the category contents in category data table
