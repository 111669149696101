/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { DeltaStatic } from 'quill';
import { TextAreaQuill } from '../../MarketsPage.styles';
import { PutECommerce } from '@app/api/markets/MarketDetails/ECommerce/PutECommerce.api';

interface Props {
  handleOk: () => void;
  data: string;
  fetchData: () => void;
  type: string;
}

const QuillEditor = ({ handleOk, data, fetchData, type }: Props): JSX.Element => {
  const modules = {
    toolbar: [['bold', 'italic', 'underline'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]],
  };
  const [value, setValue] = useState(data); // State for editor content
  const [html, setHtml] = useState(data);
  const quillRef = useRef(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const handleChange = (content: string, _delta: DeltaStatic, source: string) => {
    setValue(content); // Update state with the latest content (text or HTML)
    if (source === 'user') setHtml(content);
  };
  // Function to handle HTML input and update both editor and state
  const handleHtmlChange = (e: string) => {
    console.log('html content', e);
    setHtml(e);
    setValue(e);
  };
  // useEffect(() => {
  //   if (quill) {
  //     quill.clipboard.dangerouslyPasteHTML(data ?? '');
  //     quill.on('text-change', handleEditorChange);
  //     return () => {
  //       quill.off('text-change', handleEditorChange);
  //     };
  //   }
  // }, [quill]);
  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (type === 'key-trends') await PutECommerce(id as string, { key_trends: value });
      else await PutECommerce(id as string, { Key_e_tailers: value });
      fetchData();
      notificationController.success({
        message: 'Data Saved Successfully',
      });
      handleOk();
    } catch (error) {
      console.error('Error while submitting data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setLoading(false);
    }
  };
  const handleReset = () => {
    setValue(data ?? '');
    setHtml(data ?? '');
  };
  const disabledButton = loading || value === data || value === '<p><br></p>';
  return (
    <div>
      <div style={{ display: 'flex', padding: '5px', justifyContent: 'space-between', marginBottom: '15px' }}>
        <BaseButton disabled={disabledButton} severity="warning" onClick={() => handleReset()}>
          Reset
        </BaseButton>
        <BaseButton type="default">
          <a target="" href="https://www.prepostseo.com/tool/online-text-editor">
            Editor
          </a>
        </BaseButton>
        <BaseButton severity="success" disabled={disabledButton} loading={loading} onClick={handleSubmit}>
          Apply
        </BaseButton>
      </div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(value, delta, source) => handleChange(value, delta, source)}
        modules={modules}
        ref={quillRef} // Assign reference to the editor instance
      />
      <div>
        <TextAreaQuill
          value={html}
          onChange={(e) => handleHtmlChange(e.target.value)}
          autoSize={{ minRows: 2, maxRows: 5 }}
          placeholder="Enter your HTML here"
        />
      </div>
    </div>
  );
};
export default QuillEditor;
