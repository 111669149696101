import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarDashboard, SidebarNavigationItem } from '../sidebarNavigation';
import { getAssignedPermissionsAPI } from '../../../../../api/permissions/GetAssignPermission.api';
import { GlobalSpinner } from '@app/components/common/GlobalSpinner/GlobalSpinner';
import { themeObject } from '@app/styles/themes/themeVariables';
import SkeletonsPage from '@app/pages/uiComponentsPages/feedback/SkeletonsPage';
import { Skeleton } from 'antd';
import { Menu } from 'antd';
import { StarOutlined, GlobalOutlined, PlayCircleOutlined, DatabaseOutlined, UserOutlined } from '@ant-design/icons';
import { readUser } from '@app/services/localStorage.service';
interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}
interface AssignPermissionsResponse {
  status: boolean;
  message: string;
  data?: {
    _id: string;
    UserID: string;
    AccessPermissions: {
      _id: string;
      permissionId: string;
      value: string;
    }[];
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
}
const SidebarSkeleton = () => {
  const skeletonItems = Array.from({ length: 5 }).map((_, index) => (
    <Menu.Item key={index}>
      <Skeleton avatar paragraph={{ rows: 1 }} active />
    </Menu.Item>
  ));

  return (
    <Menu style={{ background: 'inherit' }} mode="inline">
      {skeletonItems}
    </Menu>
  );
};
const sidebarNavFlat = sidebarDashboard.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);
const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const user = readUser();
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState<AssignPermissionsResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];
  const filteredSideBar = sidebarDashboard?.filter(
    (e) => userPermissions?.pagePermissions?.find((event) => event?.key === e?.key)?.permissions?.show_page,
  );
  const userDashboard = user?.role === 'SUPERADMIN' ? sidebarDashboard : filteredSideBar;
  const openedSubmenu = userDashboard.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];
  const userDataFromLocalStorage = localStorage.getItem('user');
  const userId = userDataFromLocalStorage ? JSON.parse(userDataFromLocalStorage).id : null;
  useLayoutEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (userId) {
          const response: AssignPermissionsResponse = await getAssignedPermissionsAPI(userId);
          setData(response);
        } else {
          console.error('UserID is undefined');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const filteredSidebarNavFlat = userDashboard.filter((item) => {
    if (!data || !data.data || !data.data.AccessPermissions) {
      return true;
    } else {
      return !data.data.AccessPermissions.some((permission) => t(item.key) === permission.value);
    }
  });
  return (
    <>
      {loading && <SidebarSkeleton />}
      {data && (
        <S.Menu
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          onClick={() => setCollapsed(true)}
          items={filteredSidebarNavFlat.map((nav) => {
            const isSubMenu = nav.children?.length;

            return {
              key: nav.key,
              title: t(nav.title),
              label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
              icon: nav.icon,
              children:
                isSubMenu &&
                nav.children &&
                nav.children.map((childNav) => ({
                  key: childNav.key,
                  label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
                  title: t(childNav.title),
                })),
            };
          })}
        />
      )}
    </>
  );
};

export default SiderMenu;
