import React, { useState } from 'react';
// import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
// import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
// import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import Model from '@app/components/Model';
import ContactUs from '@app/pages/ContactUs/ContactUs';
import { Badge } from 'antd';
import { IoCartOutline } from 'react-icons/io5';
import { useAppSelector } from '@app/hooks/reduxHooks';
import ProductCard from '@app/components/landingPage/Addtocart/addToCart';
import { selectCartItems } from '@app/store/slices/addToCartSlice';
interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}
interface ModalState {
  isModalOpen: boolean;
  isModalOpenCart: boolean;
}
export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  const cartItems = useAppSelector(selectCartItems);
  const navigate = useNavigate();
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, isModalOpenCart: false });
  const { isModalOpen, isModalOpenCart } = modalState;
  const user = useAppSelector((state: any) => state.user.user);
  const cart = localStorage.getItem('subscribeplan');
  const subscribeplan = cart ? JSON.parse(cart) : null;
  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true, isModalOpenCart: false });
  };
  const showModalCart = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: true });
  };
  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };
  const product = { id: cartItems.length + 1, name: 'dsdf', price: 99 };
  return (
    <BaseRow justify="space-between" align="middle">
      {/* <BaseCol>
        <ProfileDropdown />
      </BaseCol> */}
      <BaseCol>
        <BasePopover>
          <BaseButton onClick={() => navigate('/')}>
            <S.HeaderActionWrapper>Home</S.HeaderActionWrapper>
          </BaseButton>
        </BasePopover>
      </BaseCol>
      <BaseCol>
        <BasePopover>
          <BaseButton onClick={showModal}>
            <S.HeaderActionWrapper>Contact Us</S.HeaderActionWrapper>
          </BaseButton>
        </BasePopover>
      </BaseCol>

      <BaseCol>
        <BaseRow align="middle">
          {/* <BaseCol>
            <NotificationsDropdown />
          </BaseCol> */}

          {/* <BaseCol>
            <HeaderSearch />
          </BaseCol> */}

          <BaseCol>
            <SettingsDropdown />
          </BaseCol>
        </BaseRow>
      </BaseCol>
      {user && (
        <BaseCol>
          <Badge style={{ marginLeft: '30px' }} color="#00B4A0" count={subscribeplan?.length} showZero>
            <IoCartOutline onClick={showModalCart} fontSize="30px" />
          </Badge>
        </BaseCol>
      )}
      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
      <Model
        title="Contact"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<ContactUs handleCancel={handleCancel} />}
      />
      <Model
        title="Cart"
        open={isModalOpenCart}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<ProductCard product={product} handleClose={handleCancel} handleOpen={showModalCart} />}
      />
    </BaseRow>
  );
};
