import React, { useEffect, useMemo, useState } from 'react';
import Model from '@app/components/Model';
import styled from 'styled-components';
import { CoverContainer, CoverBg, VideoBg, CoverContent, CoverH1, CoverP, BannerImg } from './CoverElements';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { PageGet } from '@app/api/pages/pages.api';
import { pageDetail } from '@app/api/pages/page.Details.api';
import { useLocation } from 'react-router-dom';
interface ModalState {
  isModalOpen: boolean;
}

const ImgBox = styled.img`
  max-width: auto;
`;
const CoverSection: React.FC = () => {
  // const [hover, setHover] = useState(false);

  // const onHover = () => {
  //   setHover(!hover);
  // };
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const user = useAppSelector((state) => state.user.user);
  const { isModalOpen } = modalState;
  const site = useLocation();

  const url = site.pathname;
  const urlName = url.split('/');
  const [pagename, setPageName] = useState<any>('');
  const [pagedetails, setPagedetails] = useState<any>('');
  const PagenameId = pagename && pagename.find((page: any) => page.slug === 'landing-page');
  const memoPagenameId = useMemo(() => PagenameId, [PagenameId]);

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  useEffect(() => {
    PageGet()
      .then((data: any) => setPageName(data.data))
      .catch((err: any) => console.log('err', err));
  }, []);

  useEffect(() => {
    if (urlName && memoPagenameId?._id) {
      pageDetail(memoPagenameId?._id)
        .then((data: any) => setPagedetails(data.data))

        .catch((err: any) => console.log('err', err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoPagenameId?._id]);

  console.log('page detailssssss', pagedetails);
  console.log(pagename);
  return (
    <CoverContainer id="about">
      <CoverBg>
        <VideoBg
          autoPlay
          loop
          muted
          src={
            'https://mazid1.github.io/react-landing-page-with-styled-component-and-smooth-scroll/static/media/video.ed44dc5d.mp4'
          }
        />
      </CoverBg>
      <CoverContent>
        <CoverH1>
          Your export journey is about <br /> to be transformed.
        </CoverH1>
        <CoverP>
          Welcome to the Export Connect Portal, where actionable data, valuable market insights, and practical resources
          and tools are at your fingertips.
        </CoverP>
        {user && pagedetails && pagedetails[0]?.status && (
          <BannerImg>
            <ImgBox
              style={{
                cursor: 'pointer',
                border: '1px solid #6a6a6a',
                borderRadius: '10px',
                maxWidth: '500px',
                height: 'auto',
              }}
              onClick={showModal}
              src={pagedetails[0]?.image}
              // alt="pagedetails"
              width="100%"
            />
          </BannerImg>
        )}

        <Model
          title={''}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          content={
            <video
              style={{ margin: '15px 0px' }}
              id="myVideo"
              width="100%"
              height="280"
              autoPlay
              controls
              poster="https://firebasestorage.googleapis.com/v0/b/export-connect-portal.appspot.com/o/pages%2Fimages%2Fsubscriptions_1660525789439.png?alt=media&token=cf1e103e-dbc6-47e6-8ab4-35c1fe85d3c5"
            >
              <source src={pagedetails[0]?.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          }
        />
        {/* <CoverH1>
          <video
            id="myVideo"
            width="100%"
            height="280"
            autoPlay
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/export-connect-portal.appspot.com/o/pages%2Fimages%2Fsubscriptions_1660525789439.png?alt=media&token=cf1e103e-dbc6-47e6-8ab4-35c1fe85d3c5"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/export-connect-portal.appspot.com/o/pages%2Fvideos%2Flanding_1664845900104.mp4?alt=media&token=a45f99d1-2390-4711-92fd-b758e6214f0a"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </CoverH1> */}
      </CoverContent>
    </CoverContainer>
  );
};

export default CoverSection;
