import React from 'react';
import { useTranslation } from 'react-i18next';
// import { ThemePicker } from '../ThemePicker/ThemePicker';
import * as S from './SettingsOverlay.styles';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const SettingsOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  return (
    <S.SettingsOverlayMenu {...props}>
      {/* <S.Text>
        <Link to="/contactus">Contact Us</Link>
      </S.Text>
      <S.Text>
        <Link to="/admin">Admin</Link>
      </S.Text>
      <S.Text>
        <Link to="/shop">
          <ShoppingCartOutlined style={{ fontSize: '2rem' }} />
        </Link>
      </S.Text> */}
      <S.Text>
        Hello, {user?.firstName} {user?.lastName}
      </S.Text>
      <S.Text>
        <Link to="/logout">{t('header.logout')}</Link>
      </S.Text>
      {/* <DropdownCollapse bordered={false} expandIconPosition="end" ghost defaultActiveKey="themePicker">
        <DropdownCollapse.Panel header={t('header.changeLanguage')} key="languagePicker">
          <LanguagePicker />
        </DropdownCollapse.Panel> */}
      {/* <DropdownCollapse.Panel header={t('header.changeTheme')} key="themePicker">
          <ThemePicker />
        </DropdownCollapse.Panel> */}
      {/* <DropdownCollapse.Panel header={t('header.nightMode.title')} key="nightMode">
          <NightModeSettings />
        </DropdownCollapse.Panel>
      </DropdownCollapse> */}
      {/* {isPWASupported && (
        <S.PwaInstallWrapper>
          <BaseButton block type="primary" onClick={() => event && (event as BeforeInstallPromptEvent).prompt()}>
            {t('common.pwa')}
          </BaseButton>
        </S.PwaInstallWrapper>
      )} */}
    </S.SettingsOverlayMenu>
  );
};
