import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/lib/mentions';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { UploadOutlined } from '@ant-design/icons';
import { AddResourceModule } from '@app/api/Resources/ResourceModules/AddResourceModule.api';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as S from './AdminResourcesPage.styles';

interface props {
  folders: foldersData[];
  handleOk: () => void;
  GetResource: () => void;
}
interface foldersData {
  _id?: string;
  name?: string;
}
interface formData {
  folder_id: string;
  free: Blob;
  position_in_folder: string;
  name: string;
  description: string;
  media: {
    file: {
      originFileObj: File;
      size: number;
      type: string;
    };
  };
  thumbnail: {
    file: {
      originFileObj: File;
      size: number;
      type: string;
    };
  };
  // media_file_url: string;
  // thumbnail_url: string;
}
interface fileFormat {
  originFileObj: File;
  size: number;
  type: string;
}
interface Error {
  message: string;
}

const ResourceUploadFormModel = ({ folders, handleOk, GetResource }: props): JSX.Element => {
  const initValues = {
    free: false,
  };
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.pdf', // Specify accepted file types
  };
  const thumbnailUploadProps = {
    name: 'thumbnail',
    multiple: false,
    accept: '.jpg,.jpeg,.png,.gif,.bmp,.apng,.avif,.jfif,.pjpeg,.pjp,.svg,.webp', // Specify accepted image file types
  };

  const handleSubmit = async (values: formData) => {
    const formdata = new FormData();
    // console.log('values?.Image?.file?.originFileObj', values?.Image?.file?.originFileObj);
    formdata.append('free', values?.free);
    formdata.append('position_in_folder', values?.position_in_folder);
    formdata.append('name', values?.name);
    formdata.append('description', values?.description);
    formdata.append('media_file', values?.media?.file?.originFileObj);
    // formdata.append('media_file_url', values?.media_file_url);
    formdata.append('thumbnail', values?.thumbnail?.file?.originFileObj);
    // formdata.append('thumbnail_url', values?.thumbnail_url);
    if (validateFile(values.media.file, 'pdf') && validateFile(values.thumbnail.file, 'image')) {
      try {
        setLoading(true);
        await AddResourceModule(formdata, values?.folder_id);
        notificationController.success({ message: 'Resource Added Successfully!' });
        form.resetFields();
        GetResource();
        setLoading(false);
        handleOk();
      } catch (error) {
        notificationController.error(error as Error);
      } finally {
        setLoading(false);
      }
    }
  };
  const validateFile = (file: fileFormat, fileType: 'pdf' | 'image') => {
    // Validate file type
    const isPDF = fileType === 'pdf' ? file.type === 'application/pdf' : false;
    const isImage = fileType === 'image' ? file.type.startsWith('image/') : false;

    if (fileType === 'pdf' && !isPDF) {
      notificationController.error({ message: 'Invalid file type. Please upload a PDF file in Media.' });
      return false;
    }

    if (fileType === 'image' && !isImage) {
      notificationController.error({ message: 'Invalid file type. Please upload an Image file in Thumbnail.' });
      return false;
    }

    // Validate file size
    let maxSize;
    if (fileType === 'pdf') {
      // 20MB limit for PDF files
      maxSize = 20 * 1024 * 1024; // 20 MB in bytes
    } else {
      // 10MB limit for image files
      maxSize = 10 * 1024 * 1024; // 10 MB in bytes
    }

    if (file.size > maxSize) {
      if (fileType === 'pdf') {
        notificationController.error({
          message: 'PDF file size exceeds the limit. Please upload a file up to 20 MB.',
        });
      } else {
        notificationController.error({
          message: 'Image file size exceeds the limit. Please upload a file up to 10 MB.',
        });
      }
      return false;
    }

    return true;
  };
  return (
    <S.ResourceuploadFormWrapper>
      <BaseForm form={form} onFinish={handleSubmit} initialValues={initValues}>
        <Auth.FormItem name="folder_id" label="Folder" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Auth.FormBaseSelect placeholder="Folder">
            {folders?.map((element) => {
              return (
                <>
                  <Option value={element._id}>{element.name}</Option>
                </>
              );
            })}
          </Auth.FormBaseSelect>
        </Auth.FormItem>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <BaseForm.Item name="free" noStyle>
            <Auth.FormCheckbox style={{ marginLeft: '10px' }}>
              <Auth.Text style={{ marginLeft: '10px' }}>Free</Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>

          <Auth.FormItem
            name="position_in_folder"
            label=""
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder="Position In Folder" />
          </Auth.FormItem>
        </div>
        <Auth.FormItem name="name" label="" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Auth.FormInput placeholder="Name" />
        </Auth.FormItem>

        <Auth.FormItem name="description" label="">
          <TextArea placeholder="Description" autoSize={{ minRows: 2, maxRows: 5 }} />
        </Auth.FormItem>

        <Auth.FormItem name="media" label="Media" rules={[{ required: true, message: t('common.requiredField') }]}>
          <BaseUpload {...uploadProps} maxCount={1}>
            <BaseButton icon={<UploadOutlined />}>{t('uploads.clickToUpload')}</BaseButton>
          </BaseUpload>
        </Auth.FormItem>

        {/* <Auth.FormItem
          name="media_file_url"
          label="Media File URl"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder="Media File URL"></Auth.FormInput>
        </Auth.FormItem> */}

        <Auth.FormItem
          name="thumbnail"
          label="Thumbnail"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <BaseUpload {...thumbnailUploadProps} maxCount={1}>
            <BaseButton icon={<UploadOutlined />}>{t('uploads.clickToUpload')}</BaseButton>
          </BaseUpload>
        </Auth.FormItem>

        {/* <Auth.FormItem
          name="thumbnail_url"
          label="Thumbnail URL"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput></Auth.FormInput>
        </Auth.FormItem> */}

        <BaseForm.Item noStyle>
          <Auth.SubmitButton disabled={loading} loading={loading} type="primary" htmlType="submit">
            {!loading ? 'Save' : ''}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </S.ResourceuploadFormWrapper>
  );
};
export default ResourceUploadFormModel;
