import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const InfoContainer = styled.div`
  color: #fff;
  background: inherit;

  // @media screen and (max-width: 768px) {
  //   padding: 100px 0;
  // }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  margin: 90px 0 10px 0;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 30px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  -webkit-box-align: center;
  align-items: center;
  grid-template-areas: 'col1 col2';
  margin-top: 25px;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 10px;
`;
export const NewSpan = styled.span`
  background-color: #c33c32;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 14pt;
  display: inline-block;
  padding: 9px 14px;
  margin-right: 15px;
  color: #fff;
`;
export const TopLine = styled.p`
  color: #fff;
  background: #00b4a0;
  font-size: 15px;
  line-height: 26px;
  border-radius: 0px;
  padding: 15px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
  color: black;
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 500px;
  margin-bottom: 35px;
  font-size: 19px;
  line-height: 30px;
  color: black;
  text-align: justify;
  font-weight: 400;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 755px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
export const NavBtnLink = styled(Link)`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
`;
