import React from 'react';
import CatMarkSubCatData from './CatMarkSubCatData';

const CatMarkSubCatDetails: React.FC = () => {
  return (
    <>
      <CatMarkSubCatData />
    </>
  );
};

export default CatMarkSubCatDetails;
