/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import * as S from './AdminLayout.styles';
import { Outlet, useLocation, Link, useParams } from 'react-router-dom';
import { DASHBOARD } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
// import { References } from '@app/components/common/References/References';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import AdminContent from '../AdminContent/AdminContent';
import AdminSider from '../AdminSider/AdminSider';
import { useNavigate } from 'react-router-dom';
import { AdminsidebarDashboard, AdminsidebarSettingsDashboard } from '../AdminSider/adminSidebarNavigation';
import { BaseBreadcrumb } from '@app/components/common/BaseBreadcrumb/BaseBreadcrumb';
import { ViewMarkets } from '@app/api/markets/ViewMarkets.api';
import { readUser } from '@app/services/localStorage.service';
const AdminLayout: React.FC = () => {
  const user = readUser();
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [item, setitem] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const { state } = useLocation();
  const toggleSider = () => setSiderCollapsed(!siderCollapsed);
  useEffect(() => {
    setIsTwoColumnsLayout([DASHBOARD].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);
  const loadMarkets = async () => {
    try {
      const response: any = await ViewMarkets();
      setitem(() => response?.data as []);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    loadMarkets();
  }, []);
  // console.log('item', item);
  // Combine all navigation items into one array
  // console.log('adminsidebardashboard', AdminsidebarDashboard);
  // console.log('AdminsidebarSettingsDashboard', AdminsidebarSettingsDashboard);
  // console.log('userpermission', userPermissions);
  const filteredAdminSideBar = AdminsidebarDashboard.filter(
    (e) => userPermissions?.pagePermissions?.find((event) => event?.key === e?.key)?.permissions?.show_page,
  );
  const filteredAdminsidebarSettingsDashboard = AdminsidebarSettingsDashboard.filter(
    (e) => userPermissions?.pagePermissions?.find((event) => event?.key === e?.key)?.permissions?.show_page,
  );
  const userDashboard = user?.role === 'SUPERADMIN' ? AdminsidebarDashboard : filteredAdminSideBar;
  const userSettingsDashboard =
    user?.role === 'SUPERADMIN' ? AdminsidebarSettingsDashboard : filteredAdminsidebarSettingsDashboard;
  // console.log('filteredAdminSideBar', filteredAdminSideBar);
  const allNavigationItems: any[] = [...userDashboard, ...userSettingsDashboard];

  // Find the matching navigation item for the current location
  const getCurrentNavItem = (): any | undefined => {
    return allNavigationItems.find((item) => location.pathname.startsWith(item.url || ''));
  };
  // Generate breadcrumb items dynamically
  const generateBreadcrumbItems = (currentNavItem?: any): React.ReactNode => {
    if (!currentNavItem) return null;
    const breadcrumbs: React.ReactNode[] = [];

    // Add parent breadcrumb item
    breadcrumbs.push(
      <BaseBreadcrumb.Item key={currentNavItem.key}>
        <Link to={currentNavItem.url || '#'}>
          {currentNavItem.key.charAt(0).toUpperCase() + currentNavItem.key.slice(1)}
        </Link>
      </BaseBreadcrumb.Item>,
    );

    const keyToStatePropertyMap: any = location.pathname.includes('/admin/categories')
      ? {
          id: 'category_name',
          marketId: 'market_name',
          subcatId: 'sub_category_name',
        }
      : location.pathname.includes('/admin/markets')
      ? {
          id: 'name',
          insights: 'market_name',
        }
      : {
          id: 'name',
          insights: 'market_name',
        };

    const paramsKeys = Object.keys(params);
    const matchResult = location?.pathname?.match('tradeData');
    const matchResult1 = location?.pathname?.match('categorydata');
    const matchResult2 = location?.pathname?.match('competitordata');

    // Check if any of the match results is an array
    const tradeDataPath =
      (Array.isArray(matchResult) && matchResult[0]) ||
      (Array.isArray(matchResult1) && matchResult1[0]) ||
      (Array.isArray(matchResult2) && matchResult2[0]);
    if (paramsKeys.length > 0) {
      paramsKeys.forEach((key) => {
        const stateProperty = keyToStatePropertyMap[key];
        // console.log('state[stateProperty]', state[stateProperty]);
        // console.log('stateProperty', stateProperty);
        if (stateProperty && state[stateProperty]) {
          breadcrumbs.push(
            <BaseBreadcrumb.Item key={key} onClick={() => navigate(-1)}>
              {state[stateProperty]}
            </BaseBreadcrumb.Item>,
          );
        }
      });
    }
    tradeDataPath &&
      breadcrumbs.push(
        <BaseBreadcrumb.Item key={currentNavItem.key}>
          {tradeDataPath === 'tradeData'
            ? ' Trade Data'
            : tradeDataPath === 'categorydata'
            ? 'Category Data'
            : tradeDataPath === 'competitordata'
            ? 'Competitor Data'
            : 'No data'}
        </BaseBreadcrumb.Item>,
      );
    return breadcrumbs;
  };
  // console.log('locations', location);
  // console.log('state', state);

  // console.log('params', Object.keys(params));

  // console.log('params', Object.keys(params).length > 0 && Object.keys(params));
  return (
    <S.LayoutMaster>
      <AdminSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <AdminHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
          <BaseBreadcrumb>{generateBreadcrumbItems(getCurrentNavItem())}</BaseBreadcrumb>
        </AdminHeader>
        <AdminContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
          {/* {!isTwoColumnsLayout && <References />} */}
        </AdminContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default AdminLayout;
