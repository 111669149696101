import { AddCategoryTableFields } from '@app/api/categories/categoryTableData/AddCategoryTableField.api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormWrapper, SubmitButton } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Checkbox, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Option } from 'antd/lib/mentions';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { initValues } from '@app/components/auth/LoginForm/LoginForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

interface valuesData {
  field_id: string;
  lable: string;
  info: string;
  type: string;
  align: string;
  is_group: boolean;
  is_percent: boolean;
}

// interface formData {
//   field_id: string;
//   lable: string;
//   info: string;
//   type: string;
//   align: string;
//   is_group: boolean;
//   is_percent: boolean;
//   _id: string;
// }

interface Props {
  handleOk: () => void;
  fetchData: () => void;
  ediTableData: [];
  type: string;
}

const TableFieldsModal = ({ handleOk, ediTableData, type, fetchData }: Props): JSX.Element => {
  const { id, marketId, subcatId } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  console.log('editableData', ediTableData);

  const handleSubmit = async (values: valuesData) => {
    setLoading(true);
    let formData = {};

    type === 'Add'
      ? (formData = {
          field_id: values.field_id,
          lable: values.lable,
          info: values.info ?? '',
          type: values.type ?? '',
          align: values.align,
          is_group: values.is_group ?? false,
          is_percent: values.is_percent ?? false,
        })
      : (formData = {
          field_id: values.field_id,
          lable: values.lable,
          info: values.info,
          type: values.type,
          align: values.align,
          is_group: values.is_group ?? false,
          is_percent: values.is_percent ?? false,
          _id: ediTableData._id,
        });

    console.log('fromData', formData);

    try {
      await AddCategoryTableFields(id, marketId, subcatId, formData);
      notificationController.success({ message: t('common.success') });
      handleOk();
      fetchData();
    } catch (error) {
      notificationController.error(error);
    } finally {
      setLoading(false);
    }

    console.log('values', values);
  };

  useEffect(() => {
    form.resetFields();
  }, [ediTableData, handleOk]);

  const handleReset = () => {
    form.resetFields();
  };

  return (
    <>
      <FormWrapper style={{ padding: '10px' }}>
        <BaseForm onFinish={handleSubmit} initialValues={ediTableData} form={form}>
          <div style={{ display: 'flex', padding: '5px', justifyContent: 'space-between', marginBottom: '15px' }}>
            <BaseButton severity="warning" onClick={handleReset}>
              Reset
            </BaseButton>

            <BaseButton type="default">
              <a target="blank" href="https://www.prepostseo.com/tool/online-text-editor">
                Editor
              </a>
            </BaseButton>
            {/* <BaseButton severity="success" htmlType="submit">
            Apply
          </BaseButton> */}

            <Form.Item>
              <Auth.SubmitButton severity="success" htmlType="submit" disabled={loading}>
                Apply
              </Auth.SubmitButton>
            </Form.Item>
          </div>
          <Form.Item label="Field ID" name="field_id" rules={[{ required: true, message: 'Field ID is required' }]}>
            <Input placeholder="Field ID" disabled={type === 'Edit'} />
          </Form.Item>

          <Form.Item label="Label" name="lable" rules={[{ required: true, message: 'Label is required' }]}>
            <Input placeholder="Label" />
          </Form.Item>

          <Form.Item label="Info" name="info">
            <Input placeholder="Info" />
          </Form.Item>

          <Form.Item label="Type" name="type">
            <Auth.FormBaseSelect placeholder=" Type">
              <Option value="text">Text</Option>
              <Option value="number">Number</Option>
            </Auth.FormBaseSelect>
          </Form.Item>

          <Form.Item label="Align" name="align">
            <Select>
              <Option value="center">Center</Option>
              <Option value="left">Left</Option>
              <Option value="right">Right</Option>
            </Select>
          </Form.Item>

          <div style={{ display: 'flex' }}>
            <Form.Item name="is_group" valuePropName="checked" noStyle>
              <Auth.FormCheckbox>
                <Auth.Text>Is Group Field?</Auth.Text>
              </Auth.FormCheckbox>
            </Form.Item>

            <Form.Item name="is_percent" valuePropName="checked" noStyle>
              <Auth.FormCheckbox>
                <Auth.Text>Is Percentage?</Auth.Text>
              </Auth.FormCheckbox>
            </Form.Item>
          </div>

          {/* <Form.Item>
            <Auth.SubmitButton type="primary" htmlType="submit">
              Submit
            </Auth.SubmitButton>
          </Form.Item> */}
        </BaseForm>
      </FormWrapper>
    </>
  );
};

export default TableFieldsModal;
