import React, { useEffect, useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import * as S from './CompetitorReviews.styles';
import { ShopOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import styled from 'styled-components';
import { LAYOUT, media } from '@app/styles/themes/constants';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button, Card, Modal, Select, Table } from 'antd';
import MarketBrowserModal from './MarketBrowserModal';
import Model from '@app/components/Model';

import { buySubscription } from '@app/api/Subscriptions/SubscriptionModules/PostSubscriptionModulus.api';
import { loadStripe } from '@stripe/stripe-js';
import { StatisticsCards } from '@app/components/medical-dashboard/statisticsCards/StatisticsCards';
import { PageGet } from '@app/api/pages/pages.api';
import { pageDetail } from '@app/api/pages/page.Details.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { ViewCategories } from '@app/api/categories/ViewCategories.api';
import { Option } from 'antd/lib/mentions';
import { GetCategoryMarkets } from '@app/api/categories/CategoryMarkets/GetCategoryMarkets.api';
import { GetCatMarketSubCategory } from '@app/api/categories/CategoryMarkets/CategoryMarketSubCategory/GetCatMarketSubCategory.api';
import {
  GetAllSubscription,
  GetSubscriptionModules,
} from '@app/api/Subscriptions/SubscriptionModules/GetSubscriptionModules.api';
export const MainRow = styled(BaseRow)`
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  @media only screen and ${media.xl} {
    jusjustify-content: space-between;
  }
`;
export const RightSideCol = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--sider-background-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(BaseCol)`
  @media only screen and ${media.xl} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    overflow: auto;
  }
`;
interface response {
  sessionId?: string;
}
interface SelectedItem {
  categoryId: string;
  category_name: string;
  marketId: string;
  market_name: string;
  subcategoryId: string;
  sub_category_name: string;
}
interface Service {
  category: { id: string; name: string };
  market: { marketId: string; marketName: string };
  subCategory: { subcategoryId: string; subcategoryName: string };
  price: number;
}

interface ModalState {
  isModalOpen: boolean;
}
interface CatResponse {
  data?: [];
}
const CompetitorReviewsPage: React.FC = () => {
  // const { t } = useTranslation();
  // const { Option } = Select;
  // function handleChange(value: string) {
  //   console.log(`selected ${value}`);
  // }
  const navigate = useNavigate();
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const competitorPerms =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Competeitor Reviews');

  const [prePurchaseModal, setPrePurchaseModal] = useState(false);
  const [cartModal, setCartModal] = useState(false);
  const [marketModal, setMarketModal] = useState(false);
  const [serviceCart, setServiceCart] = useState<Service[]>([]);
  const [totalCartPrice, setTotalCartPrice] = useState<number>();
  const [defaultService, setDefaultService] = useState<{ serviceName: any; price: number; status: boolean }>();
  const [item, setitem] = useState([]);
  const [catMarkets, setCatMarkets] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [loading, setLoading] = useState(false);
  const site = useLocation();

  const url = site.pathname;
  const urlName = url.split('/');
  const [pagename, setPageName] = useState<any>('');
  const [pagedetails, setPagedetails] = useState<any>('');

  const PagenameId = pagename && pagename.find((page: any) => page.slug === urlName[2]);
  const memoPagenameId = useMemo(() => PagenameId, [PagenameId]);

  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedMarket, setSelectedMarket] = useState<string>();
  const [selectedSubCat, setSelectedSubCat] = useState<string>();
  const [modLoading, setModLoading] = useState(false);
  const [marketDropdownLoading, setMarketDropdownLoading] = useState(false);
  const [subCatLoading, setSubCatLoading] = useState(false);
  const [subscriptionModules, setSubscriptionModules] = useState<response>();
  const { isModalOpen } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  useEffect(() => {
    const totalPrice = serviceCart.reduce((total, service) => total + service.price, 0);
    setTotalCartPrice(totalPrice);
    setDefaultService(undefined);
  }, [serviceCart]);

  useEffect(() => {
    // setDefaultService({
    //   serviceName: "Competitor review Service",
    //   price: 150,
    //   status: true
    // });
  }, []);

  useEffect(() => {
    if (defaultService) {
      setTotalCartPrice(defaultService.price);
    }
  }, [defaultService]);

  const toggleCompetitorReviewModal = () => {
    setPrePurchaseModal(true);
  };
  const closeModal = () => {
    setPrePurchaseModal(false);
  };
  const toggleCartModal = () => {
    setCartModal(true);
    setPrePurchaseModal(false);
  };

  const browseMarketModal = () => {
    setMarketModal(true);
    setCartModal(false);
  };

  const handleMarketBrowserCloseModal = () => {
    setMarketModal(false);
  };

  const handleModalOk = () => {
    // Handle Ok action here
    handleMarketBrowserCloseModal();
  };

  const handleMarketSelection = (selectedItems: SelectedItem[]) => {
    const output = selectedItems.map((item) => ({
      category: { id: item.categoryId, name: item.category_name },
      market: { marketId: item.marketId, marketName: item.market_name },
      subCategory: { subcategoryId: item.subcategoryId, subcategoryName: item.sub_category_name },
      price: 150,
    }));

    const uniqueOutput = output.filter(
      (item) =>
        !serviceCart.some(
          (existingItem) =>
            existingItem.category.id === item.category.id &&
            existingItem.market.marketId === item.market.marketId &&
            existingItem.subCategory.subcategoryId === item.subCategory.subcategoryId,
        ),
    );

    // Add only unique items to serviceCart
    setServiceCart((prevServiceCart) => [...prevServiceCart, ...uniqueOutput]);
  };
  const removeSubscription = (index: number) => {
    const updatedServiceCart = [...serviceCart];
    updatedServiceCart.splice(index, 1);
    setServiceCart(updatedServiceCart);
  };

  const removeService = () => {
    setDefaultService(undefined);
  };
  const handlePayment = async () => {
    setLoading(true);
    const stripePromise = loadStripe(
      'pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3',
    );
    try {
      const response: response = await buySubscription('/subscription/buySubscription', { cart: serviceCart });
      const sessionId: string = response?.sessionId ?? '';
      const stripe = await stripePromise;
      localStorage.setItem('cart', JSON.stringify(serviceCart));
      await stripe?.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: '#',
      key: '#',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
    },
  ];
  const loadCategories = async () => {
    try {
      setLoading(true);
      const response: CatResponse = await ViewCategories();
      setitem(response?.data as []);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchCatMarkData = async (e: string) => {
    setMarketDropdownLoading(true);
    try {
      const response = await GetCategoryMarkets(e);
      response?.data?.length >= 1 && setCatMarkets(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setMarketDropdownLoading(false);
    }
  };
  const fetchCatmarketSubCategoryData = async (e) => {
    setSubCatLoading(true);
    try {
      const response = await GetCatMarketSubCategory(selectedCategory, e);
      response?.data?.length >= 1 && setSubCat(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setSubCatLoading(false);
    }
  };
  useEffect(() => {
    loadCategories();
  }, []);
  useEffect(() => {
    PageGet()
      .then((data: any) => setPageName(data.data))
      .catch((err: any) => console.log('err', err));
  }, []);
  useEffect(() => {
    if (urlName[2] && memoPagenameId?._id) {
      pageDetail(memoPagenameId?._id)
        .then((data: any) => setPagedetails(data.data))

        .catch((err: any) => console.log('err', err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoPagenameId?._id]);
  const handleCatChange = (e) => {
    setSelectedCategory(e);
    fetchCatMarkData(e);
    setSelectedMarket(undefined);
    setSelectedSubCat(undefined);
  };
  const handleMarketChange = (e) => {
    setSelectedMarket(e);
    fetchCatmarketSubCategoryData(e);
    setSelectedSubCat(undefined);
  };
  const handleSubCatChange = (e) => {
    setSelectedSubCat(e);
  };
  const GetSubscription = async () => {
    setModLoading(true);
    try {
      const response = await GetAllSubscription();
      await setSubscriptionModules(response);
    } catch (err) {
      console.log(err);
    } finally {
      setModLoading(false);
    }
  };
  const subsNavigation = async () => {
    await GetSubscription();
    // Check if all necessary selections are made
    if (selectedCategory && selectedMarket && selectedSubCat) {
      // Filter subscription modules based on market id, category id, and subcategory id
      const filteredSubscription = subscriptionModules?.data?.filter((module) => {
        return (
          module?.SubscriptionName !== 'Membershipsubscription' &&
          module?.category_id?._id === selectedCategory &&
          module?.market_id?._id === selectedMarket &&
          module?.cat_market_sabcat_id === selectedSubCat
        );
      });
      // Navigate to the desired route with the filtered object as state
      filteredSubscription?.length > 0 &&
        navigate('/dashboard/your-subscriptions/TradeDetailsPage', { state: filteredSubscription[0] });
    } else {
      // Handle the case where necessary selections are not made
      console.error('Please select category, market, and subcategory.');
    }
  };
  return (
    <>
      <Modal
        title="Pre-Purchase Here"
        open={prePurchaseModal}
        onOk={toggleCartModal}
        onCancel={() => setPrePurchaseModal(false)}
      >
        <p>An item will be added to your cart. Once paid, a form will appear for you to complete.</p>
      </Modal>
      <Modal footer={false} width={300} title="Cart" open={cartModal} onCancel={() => setCartModal(false)}>
        {serviceCart.length > 0 && <S.SubcriptionTitle>Subscription</S.SubcriptionTitle>}

        {defaultService && (
          <div>
            <S.SubcriptionTitle>Services</S.SubcriptionTitle>
            <>
              <S.ServiceCardContainer style={{ border: '1px solid black', borderRadius: '0px' }}>
                <S.ServiceCard>
                  <div>
                    <S.ServiceName>{defaultService?.serviceName}</S.ServiceName>
                  </div>
                  <div>
                    <S.ServiceName>${defaultService?.price}</S.ServiceName>
                    <S.RemoveButton onClick={() => removeService()}>Remove</S.RemoveButton>
                  </div>
                </S.ServiceCard>
              </S.ServiceCardContainer>
            </>
          </div>
        )}
        {serviceCart.length > 0 &&
          serviceCart.map((service, index) => (
            <React.Fragment key={index}>
              <S.ServiceCardContainer style={{ border: '1px solid black', borderRadius: '0px' }}>
                <S.ServiceCard>
                  <div>
                    <S.ServiceName>{service.category.name}</S.ServiceName>
                    <S.ServiceName>{service.market.marketName}</S.ServiceName>
                    <S.ServiceName>{service.subCategory.subcategoryName}</S.ServiceName>
                  </div>
                  <div>
                    <S.ServiceName>{service.price} $</S.ServiceName>
                    <S.RemoveButton onClick={() => removeSubscription(index)}>Remove</S.RemoveButton>
                  </div>
                </S.ServiceCard>
              </S.ServiceCardContainer>
            </React.Fragment>
          ))}
        {!defaultService && serviceCart.length == 0 && <h4>There are no items in your cart</h4>}
        {(defaultService || serviceCart.length > 0) && <S.Total>Total: {totalCartPrice}.00 $</S.Total>}
        {loading && (
          <S.LoadingCard title="Taking you to checkout page">
            <S.LoaderSpin tip="Taking you to checkout page" />
          </S.LoadingCard>
        )}
        <S.CartButton shape="round" type="primary" onClick={() => browseMarketModal()}>
          <ShoppingCartOutlined />
          Browse Markets
        </S.CartButton>
        {(defaultService || serviceCart.length > 0) && (
          <S.PurchaseButton onClick={handlePayment} type="ghost" shape="round" loading={loading} disabled={loading}>
            <ShopOutlined />
            Complete Purchase
          </S.PurchaseButton>
        )}
      </Modal>
      <MarketBrowserModal
        open={marketModal}
        onCancel={handleMarketBrowserCloseModal}
        onOk={handleModalOk}
        onSelect={handleMarketSelection}
        toggleCompetitorReviewModal={toggleCartModal}
      />

      {/* <S.TablesWrapper>
        <S.Card id="basic-table" title={t('tables.basicTable')} padding="1.25rem 1.25rem 0">
          <BasicTable />
        </S.Card>
        <S.Card id="tree-table" title={t('tables.treeTable')} padding="1.25rem 1.25rem 0">
          <TreeTable />
        </S.Card>
        <S.Card id="editable-table" title={t('tables.editableTable')} padding="1.25rem 1.25rem 0">
          <EditableTable />
        </S.Card>
      </S.TablesWrapper> */}
      <S.Title>Competitor Reviews</S.Title>
      <S.SubTitle>Determine your most lucrative market opportunities & develop your evidence-based USP.</S.SubTitle>
      <S.BlockWrapper>
        <MainRow>
          <RightSideCol xl={13} xxl={14}>
            Assess your full market potential by analysing detailed competitor reviews for your product category in
            international markets including competitor product claims, pricing, product images, country of manufacture,
            and more. <br />
            <br />
            Tip: Compare these competitor insights with your existing product range and story to develop a valuable
            unique selling proposition (USP) specific to your target markets.
          </RightSideCol>
          {pagedetails && pagedetails[0]?.status && (
            <LeftSideCol xl={11} xxl={10}>
              {/* <video controls width="100%" height="100%">
              <source
                src="https://firebasestorage.googleapis.com/v0/b/export-connect-portal.appspot.com/o/pages%2Fvideos%2Fcompetitor_review_1664928463240.mp4?alt=media&token=acb107c4-cc43-4889-8ca4-613e4281db70"
                type="video/mp4"
              />
            </video> */}
              <div onClick={showModal}>
                <img src={pagedetails[0]?.image} alt="pagedetails" width="100%" />
              </div>
            </LeftSideCol>
          )}
        </MainRow>
      </S.BlockWrapper>
      <div style={{ textAlign: 'center' }} className="AdminMarketBrowser">
        {competitorPerms?.permissions?.browse_markets_button && (
          <>
            <S.SubTitle>Select your category and country of choice to view a detailed competitor review.</S.SubTitle>
            <S.BrowseMarketsButton onClick={() => browseMarketModal()}>
              <ShopOutlined />
              Browse Markets
            </S.BrowseMarketsButton>
          </>
        )}
        {competitorPerms?.permissions?.admin_market_browser_dropdown_box && (
          <S.Card>
            Admin Market Browser
            <Select style={{ marginBottom: '10px' }} onChange={(e) => handleCatChange(e)} placeholder="Categories">
              {item
                .filter((e) => e?.status)
                .map((e) => (
                  <Option value={e?._id} key={e?._id}>
                    {e?.name}
                  </Option>
                ))}
            </Select>
            {catMarkets?.length >= 1 && !marketDropdownLoading && (
              <Select
                style={{ marginBottom: '10px' }}
                onChange={(e) => handleMarketChange(e)}
                placeholder="Select Market"
              >
                {catMarkets
                  .filter((e) => e?.status)
                  .map((e) => (
                    <Option value={e?.market_id} key={e?.market_id}>
                      {e?.market_name}
                    </Option>
                  ))}
              </Select>
            )}
            {subCat?.length >= 1 && !marketDropdownLoading && !subCatLoading && (
              <Select
                style={{ marginBottom: '10px' }}
                onChange={(e) => handleSubCatChange(e)}
                placeholder="Select Subcategory"
              >
                {subCat
                  .filter((e) => e?.status)
                  .map((e) => (
                    <Option value={e?.sub_cat_id} key={e?.sub_cat_id}>
                      {e?.sub_category_name}
                    </Option>
                  ))}
              </Select>
            )}
            {selectedCategory && selectedMarket && selectedSubCat && (
              <Button type="primary" style={{ width: 250, margin: '10px auto' }} onClick={subsNavigation}>
                Go
              </Button>
            )}
          </S.Card>
        )}
        {competitorPerms?.permissions?.request_competitor_reviews_button && (
          <>
            <h2 style={{ color: 'red', marginTop: '10px' }}>Please note:</h2>
            <S.SubTitle>
              If you are unable to source a relevant competitor review for a country or category, please click the
              “Request Competitor Review” button below and fill out the details.
            </S.SubTitle>
            <S.BrowseMarketsButton onClick={toggleCompetitorReviewModal}>
              Request Competitor Review
            </S.BrowseMarketsButton>
            <S.SubTitle>
              We will notify you within a fortnight when your tailored competitor review is ready for you to analyse.
            </S.SubTitle>
            <MainRow>
              <Card>
                <h2>Competitor Reviews Services</h2>
                <Card>
                  <Table columns={columns} />
                </Card>
              </Card>
            </MainRow>
          </>
        )}
      </div>
      {/* <S.LeftSideCol xl={16} xxl={17}>
        <BaseRow gutter={[30, 30]}>
          <BaseCol span={24}>
            <BaseRow gutter={[30, 30]}>
              <StatisticsCards />
            </BaseRow>
          </BaseCol>

          <BaseCol id="map" span={24}>
            <MapCard />
          </BaseCol>

          <BaseCol id="latest-screenings" span={24}>
            <ScreeningsCard />
          </BaseCol>

          <BaseCol id="treatment-plan" xl={24}>
            <TreatmentCard />
          </BaseCol>

          <BaseCol id="covid" xl={24}>
            <CovidCard />
          </BaseCol>

          <BaseCol id="activity" xl={24} xxl={12}>
            <ActivityCard />
          </BaseCol>

          <BaseCol id="health" xl={24} xxl={12}>
            <HealthCard />
          </BaseCol>

          <BaseCol id="favorite-doctors" xl={24}>
            <FavoritesDoctorsCard />
          </BaseCol>

          <BaseCol id="news" span={24}>
            <NewsCard />
          </BaseCol>
        </BaseRow>
        <References />
      </S.LeftSideCol>

      <S.RightSideCol xl={8} xxl={7}>
        <div id="blood-screening">
          <BloodScreeningCard />
        </div>
        <S.Space />
        <S.ScrollWrapper id="patient-timeline">
          <PatientResultsCard />
        </S.ScrollWrapper>
      </S.RightSideCol> */}
      {pagedetails[0]?.video && (
        <Model
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          content={
            <>
              <video width="100%" controls autoPlay>
                <source src={pagedetails[0]?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          }
        />
      )}
    </>
  );
};
export default CompetitorReviewsPage;
