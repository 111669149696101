import React from 'react';
import * as S from './Insights.styles';
import { useLocation } from 'react-router-dom';
import EIForm from '../InsightForms/EconomicIndicator/EIForm';
import ConsumerBehaviourForm from '../InsightForms/ConsumerBehaviour/ConsumerBehaviourForm';
import GroceryRetail from '../InsightForms/GroceryRetail/GroceryRetail';
import FoodService from '../InsightForms/FoodService/FoodService';
import MarketAccess from '../InsightForms/MarketAccess/MarketAccess';
import ECommerceCD from '../InsightForms/ECommerceCD/ECommerceCD';
import DemographicFactorsForm from '../InsightForms/DemographicFactors/DemographicFactorsForm';

// import BreadcrumbsPage from './BreadcrumbsPage';

const Insights: React.FC = () => {
  const location = useLocation();
  const desktopLayout = (
    <S.Container>
      <S.title>{location.state.market_name}</S.title>
      {location.state.market_name === 'Economic Indicator' && <EIForm />}
      {location.state.market_name === 'Demographic Factors' && <DemographicFactorsForm />}
      {location.state.market_name === 'Consumer Behaviour and Societal Trends' && <ConsumerBehaviourForm />}
      {location.state.market_name === 'Grocery Retail Channel Developments' && <GroceryRetail />}
      {location.state.market_name === 'Foodservice Channel Developments' && <FoodService />}
      {location.state.market_name === 'e-Commerce Channel Developments' && <ECommerceCD />}
      {location.state.market_name === 'Market Access Requirements' && <MarketAccess />}
    </S.Container>
  );

  return (
    <>
      {/* <BreadcrumbsPage /> */}
      {desktopLayout}
      {/* {isDesktop ? desktopLayout : mobileAndTabletLayout} */}
    </>
  );
};

export default Insights;
