/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { GetFolders } from '@app/api/Videos/Folders/GetFolders.api';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Model from '@app/components/Model';
import * as S from './VideosPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { DeleteFolder } from '@app/api/Videos/Folders/DeleteFolders.api';
import { useTranslation } from 'react-i18next';
import VideoModules from './VideoModules/VideoModules';
import VideoUploadFormModel from './VideoUploadFormModel';
import { GetVideoModules } from '@app/api/Videos/Modules/VideoModules.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { Input } from 'antd';
import { BasePopconfirm } from '@app/components/common/BasePopconfirm/BasePopconfirm';
import { createfolders } from '@app/api/Videos/Folders/CreateFolders.api';

interface folderData {
  name?: string;
  _id?: string;
}
interface ModalState {
  _id: string;
  isModalOpen: boolean;
}
interface DelModalState {
  isDelModalOpen: boolean;
  name: string;
  _id: string;
}
interface showModal {
  name: string;
  _id: string;
}
type videoModules = [
  {
    name: string;
    id: string;
    data: [
      {
        name: string;
        _id: string;
        description: string;
        free: boolean;
        media_file: string;
        position_in_folder: number;
      },
    ];
  },
];
const VideosPage: React.FC = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [folders, setFolders] = useState<folderData[]>([]);
  const [backup, setBackup] = useState<folderData[]>([]);
  const [videoModules, setVideoModules] = useState<videoModules>();
  const [loading, setLoading] = useState(false);
  const [modLoading, setModLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const videosPermissions =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Videos');

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await GetFolders();
      setFolders(res?.data as folderData[]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const GetVideos = async () => {
    setModLoading(true);
    try {
      const response = await GetVideoModules();
      setVideoModules(response?.data as videoModules);
    } catch (err) {
      console.log(err);
    } finally {
      setModLoading(false);
    }
  };

  useEffect(() => {
    setBackup([...folders]);
  }, [folders]);

  useEffect(() => {
    fetchData();
    GetVideos();
  }, []);
  const handleReset = () => {
    setBackup([...folders]);
  };
  const handleUploadModel = () => {
    setIsVideoModalOpen(true);
  };
  const handleUploadCancel = () => {
    setIsVideoModalOpen(false);
  };
  const handleUploadOk = () => {
    setIsVideoModalOpen(false);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInputs = JSON.parse(JSON.stringify([...backup])); // Deep clone the backup array
    newInputs[index].name = event.target.value;
    setBackup(newInputs);
  };
  const addItem = () => {
    setBackup([...backup, { name: '' }]);
  };
  const deleteItem = (i: number) => {
    setBackup((prevArray) => {
      // Create a copy of the array without the element at the specified index
      const newArray = [...prevArray.slice(0, i), ...prevArray.slice(i + 1)];
      return newArray;
    });
  };

  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, _id: '' });
  const [delModalState, setDelModalState] = useState<DelModalState>({
    isDelModalOpen: false,
    name: '',
    _id: '',
  });

  const { isModalOpen } = modalState;
  const { isDelModalOpen } = delModalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  const showDelModal = ({ _id, name }: showModal) => {
    setDelModalState({ ...modalState, isDelModalOpen: true, _id, name });
  };

  const handleDelOk = () => {
    setDelModalState({ ...modalState, isDelModalOpen: false, _id: '', name: '' });
  };

  const handleDelCancel = () => {
    setDelModalState({ ...modalState, isDelModalOpen: false, _id: '', name: '' });
  };
  const deleteAlert = async ({ _id, name }: showModal) => {
    showDelModal({ _id, name });
  };
  const handleDelete = async () => {
    setDelLoading(true);
    try {
      await DeleteFolder({ id: delModalState?._id });
      notificationController.success({ message: t('common.success') });
      await fetchData();
      await GetVideos();
      handleDelOk();
    } catch (error) {
      console.error('Error downloading users:', error);
      // Handle errors appropriately
    } finally {
      setDelLoading(false);
    }
  };
  async function closeAllModal() {
    await setOpen(false);
    handleCancel();
  }
  const handleSubmit = async () => {
    try {
      await createfolders(backup);
      fetchData();
      setOpen(false);
    } catch (err) {
      notificationController.error({ message: 'Error adding videos' });
      setOpen(false);
    }
  };

  const DeleteMsg = () => (
    <div>
      <div>Are you sure you want to delete {delModalState.name}?</div>
      <S.ButtonContainer>
        <S.Button disabled={delLoading} onClick={handleDelCancel}>
          Cancel
        </S.Button>
        <S.Button disabled={delLoading} type="primary" onClick={handleDelete} loading={delLoading}>
          Submit
        </S.Button>
      </S.ButtonContainer>
    </div>
  );
  const disableButton = JSON.stringify(backup) === JSON.stringify(folders);
  return (
    <>
      {/* {isDesktop ? desktopLayout : mobileAndTabletLayout} */}
      <S.FormWrapper>
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ margin: '0' }}>Folder Options</p>
          {videosPermissions?.permissions?.add_video_button && <EditOutlined onClick={showModal} />}
        </div>

        {videosPermissions?.permissions?.list_of_folder_videos && (
          <>
            {!loading ? (
              folders?.map((element: folderData) => {
                return (
                  <div
                    style={{ display: 'flex', boxShadow: '-3px -1px 9px 0px', margin: '10px 0px' }}
                    key={element?._id}
                  >
                    <div className="hoverDiv">
                      <S.BaseRowBox gutter={[16, 16]}>{element?.name}</S.BaseRowBox>
                    </div>
                    {videosPermissions?.permissions?.delete_icon_folders && (
                      <BaseButton
                        style={{ margin: '7px 10px' }}
                        onClick={() => deleteAlert({ _id: element?._id as string, name: element?.name as string })}
                      >
                        <DeleteOutlined />
                      </BaseButton>
                    )}
                  </div>
                );
              })
            ) : (
              <BaseSpin />
            )}
          </>
        )}
        {folders.length === 0 && <div>Please Add Folders</div>}
      </S.FormWrapper>
      <Model
        title="
        Folder Options"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={closeAllModal}
        content={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <S.Button severity="warning" onClick={handleReset} disabled={disableButton}>
                Reset
              </S.Button>
              <BasePopconfirm
                placement="bottom"
                title={'Are you sure you want to make these changes?'}
                onConfirm={handleSubmit}
                okText={t('popconfirm.yes')}
                cancelText={t('popconfirm.no')}
                onCancel={() => setOpen(false)}
                open={open}
              >
                <BaseButton onClick={() => setOpen(true)} disabled={disableButton}>
                  Apply
                </BaseButton>
              </BasePopconfirm>
            </div>

            <S.Scroll_box>
              {backup.map((element, index) => (
                <div
                  key={element._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Input type="text" value={backup[index].name} onChange={(event) => handleInputChange(event, index)} />
                  <BaseButton
                    disabled={'_id' in element}
                    onClick={() => deleteItem(index)}
                    style={{ marginLeft: '10px' }}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </div>
              ))}
            </S.Scroll_box>
            <S.Button type="primary" onClick={addItem}>
              Add item
            </S.Button>
          </>
        }
      />
      <Model
        title="Delete "
        open={isDelModalOpen}
        onOk={handleDelOk}
        onCancel={handleDelCancel}
        content={<DeleteMsg />}
      />

      <Model
        title="Add Video"
        open={isVideoModalOpen}
        onOk={handleUploadOk}
        onCancel={handleUploadCancel}
        content={<VideoUploadFormModel folders={folders} handleOk={handleUploadCancel} fetchVideoModules={GetVideos} />}
      />

      {videosPermissions?.permissions?.add_video_button && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <S.Button onClick={handleUploadModel} disabled={folders?.length === 0}>
            Add Video
          </S.Button>
        </div>
      )}
      {!modLoading ? <VideoModules videoModules={videoModules} GetVideos={GetVideos} /> : <BaseSpin />}
    </>
  );
};

export default VideosPage;
