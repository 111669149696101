// PrivacyPolicy.tsx
import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

const { Title, Text } = Typography;

const Container = styled.div`
  padding: 20px;
`;
const Signature = styled.h4`
  padding: 5px;
`;

const Heading = styled.h3`
  margin-top: 20px;
`;

const Footer = styled.footer`
  margin-top: 50px;
  font-size: 14px;
`;
const StyledListItem = styled.li`
  margin-bottom: 16px;
`;
const SubText = styled(Text)`
  font-size: 14px;
`;
const TermsOfUse: React.FC = () => {
  return (
    <Container>
      <Title level={3} style={{ textAlign: 'center' }}>
        WEBSITE TERMS AND CONDITIONS OF USE
      </Title>
      {/* <Title level={5}>Export Connect Portal Pty Ltd</Title> */}

      <Heading>1.About the Website</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            Welcome to https://exportconnectportal.com/landing the Website. The Website advisory services, online
            reports, competitor data, online training videos, documents and trade data the Services.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            The Website is operated by Export Connect Portal Pty Ltd(ABN 73 649 861 576). Access to and use of the
            Website, or any of its associated Products or Services, is provided by Export Connect Portal Pty Ltd. Please
            read these terms and conditions the Terms carefully. By using, browsing and/or reading the Website, this
            signifies that you have read, understood and agree to be bound by the Terms. If you do not agree with the
            Terms, you must cease usage of the Website, or any of Services, immediately.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Export Connect Portal Pty Ltd reserves the right to review and change any of the Terms by updating this page
            at its sole discretion. When Export Connect Portal Pty Ltd updates the Terms, it will use reasonable
            endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate
            effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms
            for your records.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>2. Acceptance of the Terms</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            You accept the Terms by remaining on the Website. You may also accept the Terms by clicking to accept or
            agree to the Terms where this option is made available to you by Export Connect Portal Pty Ltd in the user
            interface.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>3. Subscription to use the Services</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            In order to access the Services, you must first purchase a subscription through the Website (the{' '}
            <b>&apos;Subscription&apos;</b>) and pay the applicable fee for the selected Subscription (the{' '}
            <b>&apos;Subscription Fee&apos;</b>).
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            In purchasing the Subscription, you acknowledge and agree that it is your responsibility to ensure that the
            Subscription you elect to purchase is suitable for your use.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Once you have purchased the Subscription, you will then be required to register for an account through the
            Website before you can access the Services (the <b>&apos;Account&apos;</b>).
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            As part of the registration process, or as part of your continued use of the Services, you may be required
            to provide personal information about yourself (such as identification or contact details), including:
            <br /> (i)Email address <br /> (ii)Preferred username <br /> (iii) Mailing address <br /> (iv) Telephone
            number <br /> (v) Password <br /> (vi) Company name and position
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            You warrant that any information you give to Export Connect Portal Pty Ltd in the course of completing the
            registration process will always be accurate, correct and up to date.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Once you have completed the registration process, you will be a registered member of the Website (
            <b>&apos;Member&apos;</b>) and agree to be bound by the Terms. As a Member you will be granted immediate
            access to the Services from the time you have completed the registration process until the subscription
            period expires (the <b>&apos;Subscription Period&apos;</b>).
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Login details for each registration account are for use by a single user only. Please do not share your
            login details, or allow those of any authorised users to be shared, with any other person as you will be
            held responsible for all activities that occur under these passwords and/or accounts with or without your
            knowledge.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            If you become aware of any misuse or unauthorised use of your login details, including those of any
            authorised users, then you must inform Export Connect Portal immediately
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            If you wish to use our content in any way, you must attribute the content to Export Connect Portal in the
            following form source: © and database right Export Connect Portal. All rights reserved.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            When you buy any of our content in digital form, what you are buying is the right to use it for extracts for
            your own reference in connection with your business in the following ways only <br />
            <br /> (i)to view it online <br /> (ii)to download one copy of that digital content in an available
            electronic format <br />
            (iii) to store the content in electronic format on the drive of one hardware device; to print out one copy
            of that digital content <br />
            (iv)provided you keep intact all and any copyright and proprietary notices
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            You may not use the content in any way not permitted in in cause 3(j), in particular: <br />
            (i)you may not offer the content for resale
            <br /> (ii)you may not scan, photocopy, fax, email or in any other way reproduce or copy the content <br />
            (iii) you may not share the content with anybody else
            <br /> (iv)you may not distribute the content whether by electronic mail, over any network or otherwise
            <br /> (v)you may not upload the content to any database or server
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            When you pay for a subscription/membership, what you are buying is the right for an authorised user to
            access and use the digital content available through the Export Connect Portal in connection with you
            business and for the duration of the subscription, subject to these terms
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>4. Your obligations as a Member</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            As a Member, you agree to comply with the followin <br />
            (i)you will use the Services only for purposes that are permitted by: <br /> (A) the Terms; and <br />
            (B) any applicable law, regulation or generally accepted practices or guidelines in the relevant
            jurisdictions; <br />
            (ii) you have the sole responsibility for protecting the confidentiality of your password and/or email
            address. Use of your password by any other person may result in the immediate cancellation of the Services;
            <br /> (iii) any use of your registration information by any other person, or third parties, is strictly
            prohibited. You agree to immediately notify Export Connect Portal Pty Ltd of any unauthorised use of your
            password or email address or any breach of security of which you have become aware; <br />
            (iv) access and use of the Website is limited, non-transferable and allows for the sole use of the Website
            by you for the purposes of Export Connect Portal Pty Ltd providing the Services; <br /> (v) you will not use
            the Services or the Website in connection with any commercial endeavours except those that are specifically
            endorsed or approved by the management of Export Connect Portal Pty Ltd; <br /> (vi) you will not use the
            Services or Website for any illegal and/or unauthorised use which includes collecting email addresses of
            Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of
            or linking to the Website; <br />
            (vii) you agree that commercial advertisements, affiliate links, and other forms of solicitation may be
            removed from the Website without notice and may result in termination of the Services. Appropriate legal
            action will be taken by Export Connect Portal Pty Ltd for any illegal or unauthorised use of the Website;
            and <br />
            (viii) you acknowledge and agree that any automated use of the Website or its Services is prohibited.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>5. Payment</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            Where the option is given to you, you may make payment of the Subscription Fee by way of: <br />
            (i) Credit Card Payment <b>(&apos;Credit Card&apos;)</b>
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            All payments made in the course of your use of the Services are made using Stripe Payments Australia Pty
            Ltd. In using the Website, the Services or when making any payment in relation to your use of the Services,
            you warrant that you have read, understood and agree to be bound by the Stripe Payments Australia Pty Ltd
            terms and conditions which are available on their website.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            You acknowledge and agree that where a request for the payment of the Subscription Fee is returned or
            denied, for whatever reason, by your financial institution or is unpaid by you for any other reason, then
            you are liable for any costs, including banking fees and charges, associated with the Subscription Fee .
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            You agree and acknowledge that Export Connect Portal Pty Ltd can vary the Subscription Fee at any time and
            that the varied Subscription Fee will come into effect following the conclusion of the existing Subscription
            Period.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>6. Refund Policy</Heading>

      <SubText>
        Export Connect Portal Pty Ltd will only provide you with a refund of the Subscription Fee in the event they are
        unable to continue to provide the Services or if the manager of Export Connect Portal Pty Ltd makes a decision,
        at its absolute discretion, that it is reasonable to do so under the circumstances. Where this occurs, the
        refund will be in the proportional amount of the Subscription Fee that remains unused by the Member{' '}
        <b>(the &apos;Refund&apos;)</b>.
      </SubText>
      <Heading>7. Copyright and Intellectual Property</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            The Website, the Services and all of the related products of Export Connect Portal Pty Ltd are subject to
            copyright. The material on the Website is protected by copyright under the laws of Australia and through
            international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and
            compilation of the Website (including but not limited to text, graphics, logos, button icons, video images,
            audio clips, Website, code, scripts, design elements, data and interactive features) or the Services are
            owned or controlled for these purposes, and are reserved by Export Connect Portal Pty Ltd or its
            contributors.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            All trademarks, service marks and trade names are owned, registered and/or licensed by Export Connect Portal
            Pty Ltd, who grants to you a worldwide, nonexclusive, royalty-free, revocable license whilst you are a
            Member to:
            <br /> (i) use the Website pursuant to the Terms; <br /> (ii) copy and store the Website and the material
            contained in the Website in your devices cache memory; and <br /> (iii)print pages from the Website for your
            own personal and non-commercial use. <br /> <br /> Export Connect Portal Pty Ltd does not grant you any
            other rights whatsoever in relation to the Website or the Services. All other rights are expressly reserved
            by Export Connect Portal Pty Ltd.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Export Connect Portal Pty Ltd retains all rights, title and interest in and to the Website and all related
            Services. Nothing you do on or in relation to the Website will transfer any:
            <br /> (i)business name, trading name, domain name, trade mark, industrial design, patent, registered design
            or copyright, or <br />
            (ii) a right to use or exploit a business name, trading name, domain name, trade mark or industrial design,
            or <br />
            (iii) a thing, system or process that is the subject of a patent, registered design or copyright (or an
            adaptation or modification of such a thing, system or process),to you.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            You may not, without the prior written permission of Export Connect Portal Pty Ltd and the permission of any
            other relevant rights owners: broadcast, republish, upload to a third party, transmit, post, share,
            distribute, show or play in public, adapt or change in any way the Services (which include all the content
            provided on the website) or third party Services for any purpose, unless otherwise provided by these Terms.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>8. Privacy</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            Export Connect Portal Pty Ltd takes your privacy seriously and any information provided through your use of
            the Website and/or Services are subject to Export Connect Portal Pty Ltds Privacy Policy, which is available
            on the Website.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>9. General Disclaimer</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied or
            imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be
            limited or excluded.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Subject to this clause, and to the extent permitted by law:
            <br /> (i)all terms, guarantees, warranties, representations or conditions which are not expressly stated in
            the Terms are excluded; and <br /> (ii) Export Connect Portal Pty Ltd will not be liable for any special,
            indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting
            from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to
            goodwill arising out of or in connection with the Services or these Terms (including as a result of not
            being able to use the Services or the late supply of the Services), whether at common law, under contract,
            tort (including negligence), in equity, pursuant to statute or otherwise.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Use of the Website and the Services is at your own risk. Everything on the Website and the Services is
            provided to you as is and as available without warranty or condition of any kind. None of the affiliates,
            directors, officers, employees, agents, contributors and licensors of Export Connect Portal Pty Ltd make any
            express or implied representation or warranty about the Services or any products or Services (including the
            products or Services of Export Connect Portal Pty Ltd) referred to on the Website, includes (but is not
            restricted to) loss or damage you might suffer as a result of any of the following: <br /> (i) failure of
            performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation
            or transmission, computer virus or other harmful component, loss of data, communication line failure,
            unlawful third party conduct, or theft, destruction, alteration or unauthorised access to records; <br />
            (ii) the accuracy, suitability or currency of any information on the Website, the Services, or any of its
            Services related products (including third party material and advertisements on the Website); <br /> (iii)
            costs incurred as a result of you using the Website, the Services or any of the products of Export Connect
            Portal Pty Ltd; and <br />
            (iv) the Services or operation in respect to links which are provided for your convenience.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Export Connect Portal may change these terms at any time by sending you an email with the modified terms or
            by posting a copy of them on the site. Any changes will take into effect seven (7) days after the date or
            the e-mail or the date on which Export Connect Portal posts the modified terms on the site, whichever is
            earlier. Your continued use of the site after that period expires means that you agree to be bound by the
            modified terms
          </SubText>
        </StyledListItem>
      </ul>

      <Heading>10. Limitation of liability</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            Export Connect Portal Pty Ltds total liability arising out of or in connection with the Services or these
            Terms, however arising, including under contract, tort (including negligence), in equity, under statute or
            otherwise, will not exceed the resupply of the Services to you.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            You expressly understand and agree that Export Connect Portal Pty Ltd, its affiliates, employees, agents,
            contributors and licensors shall not be liable to you for any direct, indirect, incidental, special
            consequential or exemplary damages which may be incurred by you, however caused and under any theory of
            liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or
            indirectly), any loss of goodwill or business reputation and any other intangible loss.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>11. Termination of Contract</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            The Terms will continue to apply until terminated by either you or by Export Connect Portal Pty Ltd as set
            out below.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            If you want to terminate the Terms, you may do so by:
            <br /> (i) providing Export Connect Portal Pty Ltd with 7 business days notice of your intention to
            terminate; and <br />
            (ii) closing your accounts for all of the services which you use, where Export Connect Portal Pty Ltd has
            made this option available to you. Your notice should be sent, in writing, to Export Connect Portal Pty Ltd
            via the Contact Us link on our homepage.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Export Connect Portal Pty Ltd may at any time, terminate the Terms with you if:
            <br /> (i) you have breached any provision of the Terms or intend to breach any provision; <br />
            (ii) Export Connect Portal Pty Ltd is required to do so by law; the provision of the Services to you by
            Export Connect Portal Pty Ltd is, in the opinion of Export Connect Portal Pty Ltd, no longer commercially
            viable
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Subject to local applicable laws, Export Connect Portal Pty Ltd reserves the right to discontinue or cancel
            your membership at any time and may suspend or deny, in its sole discretion, your access to all or any
            portion of the Website or the Services without notice if you breach any provision of the Terms or any
            applicable law or if your conduct impacts Export Connect Portal Pty Ltds name or reputation or violates the
            rights of those of another party.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            Export Connect Portal reserves the right to terminate any contract with you if your payment is not processed
            for any reason, but you have nevertheless received the content or access to services you have ordered
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>12. Indemnity</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            You agree to indemnify Export Connect Portal Pty Ltd, its affiliates, employees, agents, contributors, third
            party content providers and licensors from and against: <br />
            (i) all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees
            on a full indemnity basis) incurred, suffered or arising out of or in connection with Your Content;
            <br /> (ii) any direct or indirect consequences of you accessing, using or transacting on the Website or
            attempts to do so; and/or
            <br /> any breach of the Terms.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>13. Dispute Resolution</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            <b>Compulsory</b>: If a dispute arises out of or relates to the Terms, either party may not commence any
            Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied
            with (except where urgent interlocutory relief is sought).
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            <b>Notice</b>: A party to the Terms claiming a dispute Dispute has arisen under the Terms, must give written
            notice to the other party detailing the nature of the dispute, the desired outcome and the action required
            to settle the Dispute
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            <b>Resolution</b>: On receipt of that notice Notice by that other party, the parties to the Terms (i) Within
            15 business days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation
            or such other means upon which they may mutually agree.
          </SubText>
        </StyledListItem>
        <StyledListItem>
          <SubText>
            <b>Confidential</b>: All communications concerning negotiations made by the Parties arising out of and in
            connection with this dispute resolution clause are confidential and to the extent possible, must be treated
            as without prejudice negotiations for the purpose of applicable laws of evidence.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>14. Venue and Jurisdiction</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            The Services offered by Export Connect Portal Pty Ltd is intended to be viewed by residents globally. In the
            event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for
            resolving any dispute shall be in the courts of New South Wales, Australia.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>15. Governing Law</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            The Terms are governed by the laws of New South Wales, Australia. Any dispute, controversy, proceeding or
            claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby
            shall be governed, interpreted and construed by, under and pursuant to the laws of New South Wales,
            Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of
            this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto
            and their successors and assigns.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>16. Independent Legal Advice</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties
            having taken the opportunity to obtain independent legal advice and declare the Terms are not against public
            policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.
          </SubText>
        </StyledListItem>
      </ul>
      <Heading>17. Severance</Heading>
      <ul style={{ listStyleType: 'lower-alpha', paddingLeft: '30px' }}>
        <StyledListItem>
          <SubText>
            If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that
            part shall be severed and the rest of the Terms shall remain in force.
          </SubText>
        </StyledListItem>
      </ul>
      <Footer>
        Approved by
        <Signature> Signature : Najib Lawand</Signature>
        <Signature> Full Name : Najib Lawand</Signature>
        <Signature> Date : 1st September 2021</Signature>
      </Footer>
    </Container>
  );
};
export default TermsOfUse;
