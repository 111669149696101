/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import Model from '@app/components/Model';
import * as S from './CategoriesPage.styles';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { DeleteCategory } from '@app/api/categories/DeleteCategory.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

interface categories {
  name: string;
  _id: string;
  permissions: any;
}
interface props {
  items: categories[];
  loadCategories: () => void;
}
interface ModalState {
  isModalOpen: boolean;
  name?: string;
  _id?: string;
}
const CategoriesData = ({ items, loadCategories, permissions }: props): JSX.Element => {
  const BaseRowBox = styled(BaseRow)`
    // box-shadow: -3px -1px 9px 0px rgba(5, 5, 5, 0.48);
    padding: 20px;
    // margin-bottom: 10px;
    justify-content: space-between;
  `;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [delLoading, setDelLoading] = useState(false);
  const { pathname, state } = useLocation();

  // console.log(pathname);

  const { isModalOpen } = modalState;
  useEffect(() => {
    if (state?.update && items?.length > 0) {
      const details = items.find((e) => e?._id === state.update);
      navigate(`${pathname}/${details?._id}`, { state: details });
    }
  }, [items]);
  const showModal = ({ _id, name }: categories) => {
    setModalState({ ...modalState, isModalOpen: true, _id, name });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, _id: '', name: '' });
  };
  const deleteAlert = async ({ _id, name }: categories) => {
    showModal({ _id, name });
  };
  const handleDeleteCategory = async () => {
    setDelLoading(true);
    try {
      await DeleteCategory(modalState._id as string);
      notificationController.success({ message: t('common.success') });
      await loadCategories();
      handleCancel();
    } catch (error) {
      notificationController.error(error);
      // Handle errors appropriately
    } finally {
      setDelLoading(false);
    }
  };
  const navigateToDifferentRoute = (e: categories) => {
    // Use the history.push method to navigate to a different route
    navigate(`${pathname}/${e._id}`, { state: e });
    console.log(e);
  };
  const DeleteMsg = () => (
    <div>
      <div>Are you sure you want to delete {modalState.name}?</div>
      <S.ButtonContainer>
        <S.Button disabled={delLoading} onClick={handleCancel}>
          Cancel
        </S.Button>
        <S.Button disabled={delLoading} type="primary" onClick={handleDeleteCategory} loading={delLoading}>
          Submit
        </S.Button>
      </S.ButtonContainer>
    </div>
  );
  return (
    <>
      {items
        .sort((a, b) => a?.name?.localeCompare(b?.name))
        .map((e) => (
          <div style={{ display: 'flex', boxShadow: '-3px -1px 9px 0px', margin: '10px 0px' }} key={e._id}>
            <div className="hoverDiv" onClick={() => navigateToDifferentRoute(e)}>
              <BaseRowBox gutter={[16, 16]}>{e.name} </BaseRowBox>
            </div>
            {permissions?.permissions?.delete_categories_icon && (
              <BaseButton style={{ margin: '7px 10px' }} onClick={() => deleteAlert({ _id: e._id, name: e.name })}>
                <DeleteOutlined />
              </BaseButton>
            )}
          </div>
        ))}
      <Model
        title="Delete Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<DeleteMsg />}
      />
    </>
  );
};
export default CategoriesData;
