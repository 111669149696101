import { GetCountry } from '@app/api/markets/GetCountry.api';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import React, { useEffect, useState } from 'react';
import { Option } from 'antd/lib/mentions';
import { useTranslation } from 'react-i18next';
import * as S from './MarketDetails.styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CrownOutlined,
  DesktopOutlined,
  DollarOutlined,
  FileDoneOutlined,
  PieChartOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { BaseRowBox } from '../MarketsPage.styles';
import { Form } from 'antd';
import { MarketUpdate } from '@app/api/markets/MarketDetails/MarketUpdate.api';
import { notificationController } from '@app/controllers/notificationController';
import styled from 'styled-components';

interface marketType {
  _id: string;
  name: string;
  country_id: string;
  status: boolean;
}
interface response {
  data?: [];
}
const InsightsRowBox = styled.div`
  margin: 10px 0px;
  box-shadow: rgba(35, 78, 84, 0.61) 0px 0px 15px 1px;
  border-radius: 3px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
`;
const MarketDetails: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const [countryData, setCountryData] = useState<marketType[]>([]);
  const { t } = useTranslation();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const defaultCountry = countryData?.find((e) => e?._id === state?.country_id);
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const marketPermissions =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Markets');
  const initValue = {
    name: state.name,
    status: state.status,
  };
  useEffect(() => {
    const getCountry = async () => {
      const response: response = await GetCountry();
      setCountryData(response?.data as []);
    };
    getCountry();
  }, []);
  const marketInsightsData = [
    { name: 'Economic Indicator', path: 'economic', icon: <DollarOutlined /> },
    { name: 'Demographic Factors', path: 'demographic', icon: <UsergroupAddOutlined /> },
    { name: 'Consumer Behaviour and Societal Trends', path: 'consumer-behaviour', icon: <CrownOutlined /> },
    { name: 'Grocery Retail Channel Developments', path: 'grocery-retailing-channel', icon: <ShoppingCartOutlined /> },
    { name: 'Foodservice Channel Developments', path: 'food-service-channel', icon: <PieChartOutlined /> },
    { name: 'e-Commerce Channel Developments', path: 'ecommerce-channel', icon: <DesktopOutlined /> },
    { name: 'Market Access Requirements', path: 'market-access-requirements', icon: <FileDoneOutlined /> },
  ];
  const navigateToDifferentRoute = (path: string, name: string) => {
    // Use the history.push method to navigate to a different route
    navigate(`${pathname}/${path}`, { state: { name: state.name, market_name: name } });
  };
  const handleSubmit = async (values: marketType) => {
    setLoading(true);
    try {
      // Simulate a delay, replace this with your actual API call
      const EditMarketData = {
        country_id: values.country_id,
        name: values.name,
        status: values.status,
      };
      // Assuming ChangePassword is an asynchronous function, use await here
      await MarketUpdate(id as string, EditMarketData);
      notificationController.success({ message: t('common.success') });
      navigate('/admin/markets', { state: { update: id } });
    } catch (error) {
      console.error('Error while adding market.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setLoading(false);
    }
  };
  const country_id = Form.useWatch('country_id', form);
  const name = Form.useWatch('name', form);
  const status = Form.useWatch('status', form);
  const checkDefaultvalue =
    country_id === defaultCountry?._id && name === state.name && status === state.status ? true : false;
  return (
    <S.MarketDetailsWrap>
      <div style={{ padding: '20px', boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 15px 1px', borderRadius: '5px' }}>
        <BaseForm
          form={form}
          initialValues={initValue}
          onFinish={handleSubmit}
          disabled={!marketPermissions?.permissions?.edit_update_markets}
        >
          {defaultCountry && (
            <Auth.FormItem
              name="country_id"
              label={t('common.country')}
              rules={[{ required: true, message: t('common.requiredField') }]}
              initialValue={defaultCountry._id}
            >
              <Auth.FormBaseSelect placeholder="Country" defaultValue={defaultCountry?.name}>
                {countryData?.map((e) => (
                  <Option value={e._id} key={e._id}>
                    {e.name}
                  </Option>
                ))}
              </Auth.FormBaseSelect>
            </Auth.FormItem>
          )}
          <Auth.FormItem
            name="name"
            label="Market Name"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder="Market Name" />
          </Auth.FormItem>
          <div style={{ display: 'flex', padding: '12px 0px' }}>
            <BaseForm.Item name="status" valuePropName="checked" noStyle>
              <Auth.FormCheckbox onChange={(e) => setCheckboxChecked(e.target.checked)}>
                <Auth.Text>Active</Auth.Text>
              </Auth.FormCheckbox>
            </BaseForm.Item>
          </div>

          {!status && (
            <p>
              Market must be activated before it is visible in Market Insights. Complete market insights before
              activating.
            </p>
          )}
          <BaseForm.Item noStyle>
            <Auth.SubmitButton
              disabled={checkDefaultvalue || loading}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              {t('common.submit')}
            </Auth.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      </div>
      <S.MarketInsights>
        <S.MarketInsightsText>Market Insights</S.MarketInsightsText>
        {marketInsightsData.map((e) => (
          <InsightsRowBox
            className="hoverDiv"
            gutter={[16, 16]}
            key={e.name}
            onClick={() => navigateToDifferentRoute(e.path, e.name)}
          >
            <div>{e.name}</div>
            <div>{e.icon}</div>
          </InsightsRowBox>
        ))}
      </S.MarketInsights>
    </S.MarketDetailsWrap>
  );
};

export default MarketDetails;
