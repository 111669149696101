import React, { useEffect, useState } from 'react';
import { Checkbox, Collapse, List } from 'antd';
import { GetCatMarketSubCategoryDasboard } from '@app/api/categories/CategoryMarkets/CategoryMarketSubCategory/GetCatMarketSubCategory.api';
import * as S from './CompetitorReviews.styles';
import { GetSubscriptionModules } from '@app/api/Subscriptions/SubscriptionModules/GetSubscriptionModules.api';

const { Panel } = Collapse;

interface Market {
  category_markets_name: string;
  category_id: string;
  market_id: string;
  market_name: string;
  _id: string;
}

interface SubCategory {
  status: boolean;
  sub_category_name: string;
  sub_cat_id: string;
  category_id: string;
  category_name: string;
  market_id: string;
  market_name: string;
  _id: string;
}

interface SelectedItem {
  categoryId: string;
  categoryName: string;
  marketId: string;
  marketName: string;
  subcategoryId: string;
  subcategoryName: string;
}

interface Category {
  id: string;
  name: string;
}

interface Props {
  markets: Market[];
  selectedCategory: Category | null;
  onSelectedItemsChange: (items: SelectedItem[]) => void;
}

interface SelectedItem {
  categoryId: string;
  category_name: string;
  marketId: string;
  market_name: string;
  subcategoryId: string;
  sub_category_name: string;
}

interface subsscription {
  status: boolean;
  message: string;
  data: Subscription[];
}

interface Subscription {
  _id: string;
  category_id: any;
  market_id: any;
  cat_market_sabcat_id: any;
  matching_subcategory: any;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const SubCategoriesComponent: React.FC<Props> = ({ markets, selectedCategory, onSelectedItemsChange }) => {
  const [subcategories, setSubcategories] = useState<SubCategory[][]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [allSubscription, setAllSubscription] = useState<Subscription[]>();
  useEffect(() => {
    const subscategoreies = async () => {
      const subsscription = await GetSubscriptionModules();
      console.log('subsscription.data', subsscription.data);
      setAllSubscription(subsscription.data);
      const promises = markets.map(async (market, index) => {
        if (selectedCategory) {
          try {
            const response = await GetCatMarketSubCategoryDasboard(market.market_id, selectedCategory.id);
            return response.data;
          } catch (error) {
            console.error('Error fetching sub-categories:', error);
            return [];
          }
        } else {
          return Promise.resolve([]);
        }
      });

      Promise.all(promises)
        .then((subcategories) => {
          setSubcategories(subcategories);
        })
        .catch((error) => {
          console.error('Error fetching sub-categories:', error);
        });
    };

    subscategoreies();
  }, [markets, selectedCategory]);

  const handleCheckboxChange = async (
    categoryId: string,
    marketId: string,
    subcategoryId: string,
    subcategoryName: string,
    marketName: string,
    categoryName: string,
  ) => {
    const selectedIndex = selectedItems.findIndex(
      (item) => item.categoryId === categoryId && item.marketId === marketId && item.subcategoryId === subcategoryId,
    );
    console.log(categoryId, categoryName, marketId, marketName, subcategoryId, subcategoryName, selectedIndex);

    if (selectedIndex === -1) {
      setSelectedItems(
        (prevItems) =>
          [
            ...prevItems,
            {
              categoryId: categoryId,
              category_name: categoryName,
              marketId: marketId,
              market_name: marketName,
              subcategoryId: subcategoryId,
              sub_category_name: subcategoryName,
            },
          ] as SelectedItem[],
      );
    } else {
      setSelectedItems((prevItems) => prevItems.filter((_, index) => index !== selectedIndex));
    }
  };

  useEffect(() => {
    onSelectedItemsChange(selectedItems);
  }, [selectedItems, onSelectedItemsChange]);

  return (
    <S.CollapseSub expandIconPosition="end" accordion>
      {markets.map((market, marketIndex) => (
        <Panel header={`${market.market_name}`} key={marketIndex}>
          {subcategories[marketIndex]
            ?.filter((e) => e?.status)
            .map((subcategory, subIndex) => (
              <List key={subIndex}>
                <Checkbox
                  onChange={() =>
                    handleCheckboxChange(
                      selectedCategory!.id,
                      market.market_id,
                      subcategory.sub_cat_id,
                      subcategory.sub_category_name,
                      market.market_name,
                      selectedCategory!.name,
                    )
                  }
                  checked={selectedItems.some(
                    (item) =>
                      item.categoryId === selectedCategory?.id &&
                      item.marketId === market.market_id &&
                      item.subcategoryId === subcategory.sub_cat_id,
                  )}
                  disabled={allSubscription?.some(
                    (sub) =>
                      sub.cat_market_sabcat_id === subcategory.sub_cat_id &&
                      sub.market_id._id === subcategory.market_id,
                  )}
                >
                  {subcategory.sub_category_name}
                  {allSubscription?.some(
                    (sub) =>
                      sub.cat_market_sabcat_id == subcategory.sub_cat_id && sub.market_id._id === subcategory.market_id,
                  ) && <small> (Subscribed)</small>}
                </Checkbox>
              </List>
            ))}
        </Panel>
      ))}
    </S.CollapseSub>
  );
};

export default SubCategoriesComponent;
