import { httpApi } from '@app/api/http.api';
interface response {
  status: boolean;
  message: string;
  data?: EIData;
}
interface EIData {
  ease_ranking: string;
  gdb_billions: string;
  gdb_per_capita: string;
  human_ranking: string;
  logistics_ranking: string;
  market_id: string;
  quality_ranking: string;
  source_date: Date;
  trade_agreement: string;
}
export const EIGet = (id: string): Promise<response> => {
  return httpApi.get<response>(`/marketInsightsEI/${id}`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
