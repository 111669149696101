import { httpApi } from '@app/api/http.api';
interface response {
  data: categoryMarkets[];
}
interface categoryMarkets {
  category_markets_name: string;
  category_id: string;
  market_id: string;
  market_name: string;
  _id: string;
}
export const DeleteCategoryMarkets = (id: string): Promise<response> => {
  return httpApi.delete<response>(`categories/categoryMarket/${id}/categoryMarketDelete`).then(({ data }) => data);
};
