import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: formData;
}

interface formData {
  key_trends?: string;
  Key_e_tailers?: string;
}
export const PutECommerce = (id: string, formData: formData): Promise<response> => {
  return httpApi.put<response>(`marketInsightsEccd/${id}/marketEccdUpdate`, formData).then(({ data }) => data);

  // This API is fetching Video details(name,descprtion etc) with help of folder > clicked video id
};
