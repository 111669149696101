import { DatabaseOutlined, GlobalOutlined, PlayCircleOutlined, StarOutlined, UserOutlined } from '@ant-design/icons';
import { RiAdminFill } from 'react-icons/ri';
export interface AdminSidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: AdminSidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const AdminsidebarDashboard: AdminSidebarNavigationItem[] = [
  {
    title: 'Users',
    key: 'users',
    // TODO use path variable
    url: '/admin/users',
    icon: <StarOutlined />,
  },
  {
    title: 'Markets',
    key: 'markets',
    url: '/admin/markets',
    icon: <GlobalOutlined />,
  },
  {
    title: 'Categories',
    key: 'categories',
    url: '/admin/categories',
    icon: <PlayCircleOutlined />,
  },
  {
    title: 'Videos',
    key: 'videos',
    url: '/admin/videos',
    icon: <DatabaseOutlined />,
  },
  {
    title: 'Resources',
    key: 'resource',
    url: '/admin/resources',
    icon: <UserOutlined />,
  },
  {
    title: 'Super Admin Panel',
    key: 'super-admin-panel',
    url: '/admin/superadminpanel',
    icon: <RiAdminFill />,
  },
];
export const AdminsidebarSettingsDashboard: AdminSidebarNavigationItem[] = [
  {
    title: 'common.pages',
    key: 'pages',
    // TODO use path variable
    url: '/admin/pages',
    icon: <StarOutlined />,
  },
  {
    title: 'common.coupons',
    key: 'coupons',
    url: '/admin/coupons',
    icon: <GlobalOutlined />,
  },
];
