import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { InputRef } from 'antd';
import { Button, Form, Input, Table } from 'antd';
import '../../../../../../../assets/common.css';
import { DeleteOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { AddTradeImportTable } from '@app/api/categories/TradeImportTableData/AddTradeImportTable.api';
import { useParams } from 'react-router-dom';
import { GetTradeImportTable } from '@app/api/categories/TradeImportTableData/GetTradeImportTable.api';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { DeleteTradeImportData } from '@app/api/categories/TradeImportTableData/DeleteTradeImportData.api';
import Model from '@app/components/Model';
import AddFromCsvModel from '../AddFromCsvModel/AddFromCsvModel';

type FormInstance<T> = typeof Form<T>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleBlur = () => {
    save();
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: false,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={handleBlur} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          padding: '6px',
          height: '100%',
          // minWidth: '300px',
          fontSize: '13px',
          // width: 'auto',
          // background: '#e0eaf5',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  _id: string; // Assuming _id is a unique identifier for each row
  exporter: string;
  rank: string;
  imported_value: string;
  imported_quantity: string;
  annual_growth_short_term: string;
  annual_growth_long_term: string;
  annual_growth_quantity: string;
}
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
interface formData {
  exporter: string;
  rank: string;
  imported_value: string;
  imported_quantity: string;
  annual_growth_short_term: string;
  annual_growth_long_term: string;
  annual_growth_quantity: string;
  year_range_short_term?: {
    from: number;
    to: number;
  };
  year_range_long_term?: {
    from: number;
    to: number;
  };
}
interface props {
  tradeFormData: formData;
  fetchFormData: () => void;
}
interface ModalState {
  isModalOpen: boolean;
}

const TradeImportData = ({ tradeFormData, fetchFormData }: props) => {
  const { t } = useTranslation();
  const { id, marketId, subcatId } = useParams();

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [DeletebtnLoading, setDeleteBtnLoading] = useState(false);

  const { isModalOpen } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  // console.log(tradeFormData);

  const [newselectedRows, setSelectedRows] = useState<DataType[]>([]);
  const [count, setCount] = useState(2);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Rank ',
      dataIndex: 'rank',
      width: 10,
      editable: true,
    },
    {
      title: 'Exporter ',
      dataIndex: 'exporter',
      width: 100,
      editable: true,
    },
    {
      title: 'Value imported (USD thousands)',
      dataIndex: 'imported_value',
      width: 10,
      editable: true,
    },
    {
      title: 'Quality imported (tons)',
      dataIndex: 'imported_quantity',
      width: 10,
      editable: true,
    },
    {
      title:
        tradeFormData?.year_range_short_term?.from && tradeFormData?.year_range_short_term?.to ? (
          <>
            Annual growth in imported value %<br />
            {tradeFormData?.year_range_short_term?.from} - {tradeFormData?.year_range_short_term?.to}
          </>
        ) : (
          'Annual growth in imported value %'
        ),
      dataIndex: 'annual_growth_short_term',
      width: 10,
      editable: true,
      render: (params) => <p style={{ color: params > 0 ? 'green' : 'red' }}>{params}</p>,
    },
    {
      title:
        tradeFormData?.year_range_long_term?.from && tradeFormData?.year_range_long_term?.to ? (
          <>
            Annual growth in imported value %<br />
            {tradeFormData?.year_range_long_term?.from} - {tradeFormData?.year_range_long_term?.to}
          </>
        ) : (
          'Annual growth in imported value %'
        ),
      dataIndex: 'annual_growth_long_term',
      width: 10,
      editable: true,
      render: (params) => <p style={{ color: params > 0 ? 'green' : 'red' }}>{params}</p>,
    },
    {
      title:
        tradeFormData?.year_range_long_term?.from && tradeFormData?.year_range_long_term?.to ? (
          <>
            Annual growth in imported quantity %<br />
            {tradeFormData?.year_range_long_term?.from} - {tradeFormData?.year_range_long_term?.to}
          </>
        ) : (
          'Annual growth in imported quantity %'
        ),
      dataIndex: 'annual_growth_quantity',
      width: 10,
      editable: true,
      render: (params) => <p style={{ color: params > 0 ? 'green' : 'red' }}>{params}</p>,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[] | undefined) => {
      if (selectedRows && Array.isArray(selectedRows)) {
        setSelectedRows(selectedRows);
      } else {
        console.error('selectedRows is not an array:', selectedRows);
      }
    },
  };

  console.log('selectedRoooooooows', newselectedRows);

  const fetchTableData = async () => {
    setLoading(true);
    try {
      const response = await GetTradeImportTable(id as string, marketId as string, subcatId as string);
      console.log(response);
      // Assign _id as key for each item in response.data
      const dataWithKeys = response.data.map((item: DataType) => ({ ...item, key: item._id }));
      // Sort the data based on the "rank" parameter
      const sortedData = dataWithKeys.sort((a: DataType, b: DataType) => parseFloat(a.rank) - parseFloat(b.rank));
      setDataSource(sortedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    fetchTableData();
  }, []);

  const handleDelete = async (selectedRows: DataType[]) => {
    setDeleteBtnLoading(true);
    if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
      console.log('No rows selected for deletion');
      return;
    }

    // Separate saved rows (with _id) and unsaved rows
    const savedRows: DataType[] = [];
    const unsavedRows: DataType[] = [];
    selectedRows.forEach((row) => {
      if (row._id) {
        savedRows.push(row);
      } else {
        unsavedRows.push(row);
      }
    });

    // Delete saved rows via API
    if (savedRows.length > 0) {
      const DeletedIds = savedRows.map((item) => ({ _id: item._id }));
      try {
        await DeleteTradeImportData(id as string, marketId as string, subcatId as string, DeletedIds);
      } catch (error) {
        notificationController.error(error);
      } finally {
        setDeleteBtnLoading(false);
      }
    }

    // Remove selected rows from the data source array
    const updatedDataSource = dataSource.filter(
      (item) => !selectedRows.some((selectedItem) => selectedItem.key === item.key),
    );

    // Update state
    setDataSource(updatedDataSource);

    setSelectedRows([]);
    setDeleteBtnLoading(false);

    // Handle unsaved changes (if any)
    if (unsavedRows.length > 0) {
      console.log('Handling unsaved changes:', unsavedRows);
      // Handle unsaved changes (e.g., show a message to the user)
    }
  };

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      exporter: '',
      rank: '',
      imported_value: '',
      imported_quantity: '',
      annual_growth_short_term: '',
      annual_growth_long_term: '',
      annual_growth_quantity: '',
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const handleSaveTest = async () => {
    setBtnLoading(true);
    try {
      const newData: formData[] = [...dataSource];
      await AddTradeImportTable(id as string, marketId as string, subcatId as string, newData);
      notificationController.success({ message: t('common.success') });
      fetchTableData();
    } catch (error) {
      notificationController.error(error);
    } finally {
      setBtnLoading(false);
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div style={{ padding: '20px 10px', background: '#F5F5F5' }}>
      <h3 style={{ color: 'black' }}>Top 10 Countries your Market Imports from.</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#f5f5f5',
          padding: '18px',
          borderBottom: '1px solid #b3cbe1',
          borderTop: '1px solid #b3cbe1',
        }}
      >
        {' '}
        <p>Exporters</p>
        <BaseButton
          onClick={() => {
            showModal();
          }}
          type="text"
        >
          ADD FROM CSV
        </BaseButton>
      </div>
      <Table
        loading={loading}
        // rowKey={(params) => console.log(params._id)}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        pagination={false}
        columns={columns as ColumnTypes}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#f5f5f5',
          padding: '18px',
          borderBottom: '1px solid #b3cbe1',
          borderRight: '1px solid #b3cbe1',
          borderLeft: '1px solid #b3cbe1',
        }}
      >
        <BaseButton
          onClick={() => handleDelete(newselectedRows)}
          loading={DeletebtnLoading}
          disabled={DeletebtnLoading || btnLoading || newselectedRows.length === 0}
        >
          <DeleteOutlined style={{ fontSize: '22px' }} />
        </BaseButton>
        <Button onClick={handleAdd} className="addrowBtnTable">
          Add a row
        </Button>

        <Button
          onClick={handleSaveTest}
          disabled={DeletebtnLoading || btnLoading || dataSource.length === 0}
          loading={btnLoading}
        >
          Save
        </Button>
      </div>
      <Model
        title="Input CSV"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<AddFromCsvModel type="import" />}
      />
    </div>
  );
};

export default TradeImportData;
