import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: foldersData[];
}

interface foldersData {
  sub_category_name?: string;
  _id?: string;
}
export const createfolders = (data: foldersData[]): Promise<response> => {
  return httpApi.post<response>(`videoFolders/folderAdd`, data).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
