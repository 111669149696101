import { httpApi } from '@app/api/http.api';
interface addCategory {
  market_id: string;
}

export const AddCategoryMarkets = (id: string, market_id: addCategory): Promise<undefined> => {
  return httpApi
    .post<undefined>(`/categories/categoryMarket/${id}/categoryMarketUpdate`, market_id)
    .then(({ data }) => data);
};
