import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LogoChange from './LogoChange';
import AvocadoLogoChange from './AvocadoLogoChange'; // Assuming you have this component
import { Tabs as BaseTabs } from 'antd'; // Assuming you are using Ant Design Tabs

const StyledTabs = styled(BaseTabs)`
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
`;

function LogoChangeSelectionTab() {
  const { t } = useTranslation();

  const commonTabs = useMemo(
    () => [
      {
        key: '1',
        label: t('Ec Portal'), // Use translation for label
        children: (
          <div>
            <LogoChange />
          </div>
        ),
      },
      {
        key: '2',
        label: t('Avocado'), // Use translation for label
        children: (
          <div>
            <AvocadoLogoChange />
          </div>
        ),
      },
    ],
    [t],
  );

  return <StyledTabs defaultActiveKey="1" items={commonTabs} />;
}

export default LogoChangeSelectionTab;
