import styled from 'styled-components';
import { LAYOUT, media } from '@app/styles/themes/constants';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

export const RightSideCol = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  background-color: var(--sider-background-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(BaseCol)`
  @media only screen and ${media.xl} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    height: calc(100vh - ${LAYOUT.desktop.headerHeight});
    overflow: auto;
  }
`;

export const Space = styled.div`
  margin: 1rem 0;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;
  margin: 0 auto;
  // background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;

export const ItemFlex = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
  font-size: 20px;
`;
export const Title = styled.h1`
  font-size: 2rem;
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin: 0;
`;
export const SubTitle = styled.p`
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
`;
export const CollapseWrapper = styled(BaseCollapse)`
  width: 40rem;
`;
export const ListDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #000;
  // margin-bottom: 5px;
`;

export const DescDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 20px;
  color: #305e8b;
`;
export const ModalButton = styled(BaseButton)`
  width: 400px;
  margin: 10px auto;
`;
export const ListHeader = styled.div`
  text-align: center;
`;
export const ListSubheader = styled.div`
  text-align: center;
  font-size: small;
`;
export const ListData = styled.div`
  border: 1px solid #000;
  border-top: 0px;
  width: 55%;
  margin: 1rem auto;
`;
export const EditorData = styled.div`
  padding: 10px;
`;
