import React, { FC } from 'react';

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  NavBtnLink,
  Subtitle,
  Img,
  ImgWrap,
  NewSpan,
} from './InfoElements';
interface InfoSectionProps {
  id: string;
  headline: string;
  description: string;
  img: string;
  topLine: string; // Assuming 'img' is a string representing the image source
}
const dataArray = [
  {
    number: 1,
    description: 'New Exporters',
    details:
      'Have you been on the hunt for potential export markets? Or perhaps you want to gain an insight into the current competitor landscape? This Portal is the perfect place to begin your export journey and will support you in every aspect of building your export capability.',
  },
  {
    number: 2,
    description: 'Experienced Exporters',
    details:
      'Access up-to-date market intelligence including comprehensive market summaries and key trade, category-specific, and competitor information. This Portal will enhance your capabilities as an experienced exporter and help you develop and grow your business.',
  },
  {
    number: 3,
    description: 'Stakeholders supporting Food and Agri-Businesses',
    details:
      'Stay informed with practical and global cross-category trends and insights that will aid in the design and development of programs that your industry/government bodies want to deliver. This Portal will equip food and agri-businesses with comprehensive material and resources to encourage export and enhance expertise overall within the sector.',
  },
];
const InfoSection: FC = () => {
  return (
    <>
      <InfoContainer id="InfoSection">
        <InfoWrapper>
          <TopLine>Who is the Export Connect Portal for?</TopLine>
          <InfoRow>
            <Column1>
              {dataArray.map((data) => (
                <TextWrapper key={data.number}>
                  <Heading>
                    {' '}
                    <NewSpan>{data.number}</NewSpan>
                    {data.description}
                  </Heading>
                  <Subtitle>{data.details}</Subtitle>
                  {/* <NavBtnLink to="/">Hello</NavBtnLink> */}
                </TextWrapper>
              ))}
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src="https://exportconnectportal.com/img/tablet-banner-dashboard-1200.7e7f4266.jpg" />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
