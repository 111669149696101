import React from 'react';

const Removeicon: React.FC = () => (
  <span>
    <svg
      aria-hidden="true"
      role="img"
      focusable="false"
      viewBox="0 0 24 24"
      className="q-icon notranslate"
      style={{ marginLeft: '25px', width: '25px', height: '25px', cursor: 'pointer' }}
    >
      <path d="M0 0h24v24H0z" style={{ fill: 'none' }} />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
    {/* Add any additional content here */}
  </span>
);

export default Removeicon;
