import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

export const FoodServiceStyle = styled.div`
  margintop: 2rem;
  margin: 15px 0px;
  padding: 20px;
  box-shadow: rgba(35, 78, 84, 0.61) 0px 0px 15px 1px;
  border-radius: 5px;
`;
export const FSButton = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
`;
export const ButtonRowWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
export const BaseRowButton = styled(Button)`
  box-shadow: -3px -1px 9px 0px rgba(5, 5, 5, 0.48);
  justify-content: space-between;
  margin: 10px;
`;
export const Scroll_box = styled.div`
  height: 200px;
  overflow-y: auto;
`;
export const TextAreaQuill = styled(TextArea)`
  margin: 10px 0px;
`;
