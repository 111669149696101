import { httpApi } from '@app/api/http.api';
interface DataType {
  outlet: string;
  product_name: string;
  type: string;
  weight: string;
  price: string;
  country_manufacture: string;
  product_claim: string;
  date: string;
  _id: string;
}
interface formData {
  data: {
    map(
      arg0: (item: DataType) => {
        key: string;
        outlet: string;
        product_name: string;
        type: string;
        weight: string;
        price: string;
        country_manufacture: string;
        product_claim: string;
        date: string;
        _id: string;
      },
    ): DataType;
    outlet: string;
    product_name: string;
    type: string;
    weight: string;
    price: string;
    country_manufacture: string;
    product_claim: string;
  };
}

type category_id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;

export const GetCompetitorData = (
  category_id: category_id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
): Promise<formData> => {
  return httpApi
    .get<formData>(
      `categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/competitorData`,
    )
    .then(({ data }) => data);
};

//this api is to fetch the Trade Export Table data

// http://localhost:3000/localhost/categories/categoryMarket/65e01492f9cc4d34a85a1894/65e01670f9cc4d34a85a1971/65e0178ff9cc4d34a85a1b2d/competitorData?productName=new demo     (GET)

// category_id/category_market_id/cat_market_sabcat_id
