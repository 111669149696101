import { httpApi } from '@app/api/http.api';

type category_id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;
interface formData {
  data: {
    source: string;
    importer_ranking: number;
    imported_value: number;
    year_range_short_term: {
      from: number;
      to: number;
    };
    year_range_long_term: {
      from: number;
      to: number;
    };
    date: Date;
    import_description: string;
  };
}

export const GetTradeFixedData = (
  category_id: category_id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
): Promise<formData> => {
  return httpApi
    .get<formData>(`/categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/tradeData`)
    .then(({ data }) => data);
};
