import { httpApi } from '@app/api/http.api';

interface formData {
  lable: string;
  info: string;
  type: string;
  align: string;
  is_group: string;
  is_percent: string;
}

export const AddCategoryTableFields = (
  category_id: string,
  market_id: string,
  subcatId: string,
  formData: formData,
): Promise<undefined> => {
  return httpApi
    .post<undefined>(
      `categories/categoryMarket/${category_id}/${market_id}/${subcatId}/categoryTableFields/categoryTableFieldAdd`,
      formData,
    )
    .then(({ data }) => data);
};
