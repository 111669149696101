import { httpApi } from '@app/api/http.api';

// POST: Add a new retail price audit report

export const pageMediaDelete = (type: string, pageId: string): Promise<undefined> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return httpApi.delete<undefined>(`/pages/${pageId}/pageDetail/media/${type}`, config).then(({ data }) => data);
};
