import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: formData[];
}

interface formData {
  dominant_religious_groups?: { type: inputData[] };
  expatriate_population?: number;
  market_id?: number;
  median_age?: number;
  population_ethnicity?: { type: inputData[] };
  population_growth?: number;
  total_population?: number;
  urban_population?: number;
  population_source?: string;
  dominant_source?: string;
}
interface inputData {
  name?: string;
  percentage: number;
  _id?: string;
}
export const DFPut = (id: string, formData: formData): Promise<response> => {
  return httpApi.put<response>(`marketInsightsDf/${id}/marketDfUpdate`, formData).then(({ data }) => data);

  // This API is fetching Video details(name,descprtion etc) with help of folder > clicked video id
};
