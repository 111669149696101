import React, { useState, useEffect } from 'react';
import { Modal, message, Input, Button } from 'antd';
import { ViewCoupons } from '@app/api/coupons/ViewCoupons.api';
import { CopyOutlined } from '@ant-design/icons';
import { GetAllCoupan } from '@app/api/coupons/GetAllCoupan.api';
interface response {
  status: boolean;
  message: string;
  data?: [];
}
interface Props {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
}
const { Group: InputGroup } = Input;
const CoupanCodeModel: React.FC<Props> = ({ open, onCancel, onOk }) => {
  const [item, setitem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState();

  const handleCopy = (code: string) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        message.success('Coupon code copied to clipboard!');
      })
      .catch(() => {
        message.error('Failed to copy coupon code.');
      });
  };
  const getallcode: () => void = async () => {
    setLoading(true);
    const response: response = await GetAllCoupan();
    setitem(() => response?.data as []);
    setLoading(false);
  };
  useEffect(() => {
    getallcode();
  }, []);

  return (
    <Modal title="Coupon List" open={open} onOk={onOk} onCancel={onCancel} okText="Go to Cart" footer={false}>
      {item && (
        <>
          {item.map((item: any) => (
            <div key={item?.code} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <InputGroup compact>
                <Input style={{ width: '40%' }} value={`Discount ${item?.percent}%`} readOnly />
                <Input
                  style={{ width: '50%', marginRight: 8 }}
                  value={item?.code}
                  placeholder="Enter your coupon code"
                />
              </InputGroup>
              <Button type="primary" icon={<CopyOutlined />} onClick={() => handleCopy(item?.code)}>
                Copy
              </Button>
            </div>
          ))}
        </>
      )}
    </Modal>
  );
};

export default CoupanCodeModel;
