import { httpApi } from '@app/api/http.api';

// This Api is for Adding(POST) the Table Data for --> Trade Export Data in category module

interface formData {
  exporter: string;
  rank: string;
  imported_value: string;
  imported_quantity: string;
  annual_growth_short_term: string;
  annual_growth_long_term: string;
  annual_growth_quantity: string;
}

type _id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;

export const AddTradeExportTable = (
  _id: _id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
  formData: formData[],
): Promise<undefined> => {
  return httpApi
    .post<undefined>(
      `categories/categoryMarket/${_id}/${category_market_id}/${cat_market_sabcat_id}/tradeExport/tradeExportAdd `,
      formData,
    )
    .then(({ data }) => data);
};
