import React, { useEffect, useMemo, useState } from 'react';
import * as S from './TradePage.styles';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { FileImageOutlined } from '@ant-design/icons';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { Image, Input, Select } from 'antd';
import moment from 'moment';
import currenciesList from './currencies.json';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

interface Props {
  competitorData?: {
    status: boolean;
    message: string;
    data?: any[];
  };
  onFilterChange: (value: string) => void;
}

const CompetitorData: React.FC<Props> = ({ competitorData, onFilterChange }) => {
  const [filterValue, setFilterValue] = useState('');
  const [showImage, setShowImage] = useState(true);
  const site = useLocation();

  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'product_name',
    'weight',
    'country_manufacture',
    'type',
    'outlet',
    'date',
    'price',
    'product_claim',
  ]);

  const [exchangeRate, setExchangeRate] = useState<number | null>(null);

  useEffect(() => {
    const countrycurrency = async () => {
      const currencies = currenciesList.find((item) => item?.country === site?.state?.market_id?.name)?.currency_code;
      if (currencies) {
        try {
          const response = await axios.get(
            `https://free.currconv.com/api/v7/convert?q=${currencies}_AUD&compact=ultra&apiKey=${process.env.REACT_APP_CURRENCY_KEY}`,
          );
          const exchangeRate = response.data[`${currencies}_AUD`];
          setExchangeRate(exchangeRate);
        } catch (error) {
          console.error('Error fetching exchange rates:', error);
        }
      }
    };
    countrycurrency();
  }, [site?.state?.market_id?.name]);

  const getfilterName = (filter: string) => {
    setFilterValue(filter);
    onFilterChange(filter);
  };

  const priceByWeight = (AudPrice: number, weight: number) => {
    return AudPrice / weight.toFixed(2);
  };

  const countrycurrency = () => {
    const currencies = currenciesList.find((item) => item?.country === site?.state?.market_id?.name)?.currency_code;

    return currencies;
    // setCurrency(currencies)
  };
  useEffect(() => {
    countrycurrency();
  }, []);

  const keyLabelMap: { [key: string]: string } = {
    product_name: '',
    outlet: 'Outlet:',
    type: 'Type:',
    price: `Price ${countrycurrency()}(Retail):`,
    priceConverted: `Price (${exchangeRate ? `AUD` : 'Local'}):`,
    country_manufacture: 'Country Manufacturing:',
    pricePerGmMl: 'Price per g/ml:',
    product_claim: 'Product Claims:',
    date: 'Date:',
    weight: 'Weight:',
  };

  return (
    <>
      <S.FilterRow>
        <BaseCheckbox checked={showImage} onChange={(e) => setShowImage(e.target.checked)}>
          Thumbnail
        </BaseCheckbox>
        <S.Input placeholder="Name Filter" value={filterValue} onChange={(e) => getfilterName(e.target.value)} />
        <S.Select
          mode="multiple"
          placeholder="Visible Fields"
          onChange={(e) => setSelectedColumns(e as string[])}
          value={selectedColumns}
        >
          {Object.keys(keyLabelMap).map((key) => (
            <S.CustomOption value={key} key={key}>
              {keyLabelMap[key].replace(':', '')}
            </S.CustomOption>
          ))}
        </S.Select>
      </S.FilterRow>
      <S.Row gutter={[10, 10]}>
        {competitorData?.data?.map((item, index) => {
          const priceInAud = exchangeRate ? (item?.price * exchangeRate).toFixed(2) : null;

          const pricePerGmMl = priceByWeight(priceInAud, item?.weight);

          return (
            <BaseCol key={index} xs={24} sm={12} md={12} lg={12} xl={12}>
              <BaseCard padding="1rem" style={{ background: '#CECECF' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {selectedColumns.includes('product_name') && (
                    <div style={{ padding: '0px 10px', fontWeight: 'bold' }}>
                      <p style={{ textAlign: 'center', textAlign: 'center', fontSize: '20px' }}>
                        <strong>{keyLabelMap['product_name']}</strong> {item['product_name']}
                      </p>
                    </div>
                  )}
                  {selectedColumns.includes('weight') && (
                    <S.CustomDiv>
                      <p>
                        <strong>{keyLabelMap['weight']}</strong>
                      </p>

                      {item['weight']}
                    </S.CustomDiv>
                  )}
                  {selectedColumns.includes('country_manufacture') && (
                    <S.CustomDiv>
                      <p>
                        <strong>{keyLabelMap['country_manufacture']}</strong>
                      </p>

                      {item['country_manufacture']}
                    </S.CustomDiv>
                  )}
                  {selectedColumns.includes('type') && (
                    <S.CustomDiv>
                      <p>
                        <strong>{keyLabelMap['type']}</strong>
                      </p>

                      {item['type']}
                    </S.CustomDiv>
                  )}
                  {selectedColumns.includes('outlet') && (
                    <S.CustomDiv>
                      <p>
                        <strong>{keyLabelMap['outlet']}</strong>
                      </p>
                      {item['outlet']}
                    </S.CustomDiv>
                  )}
                  {selectedColumns.includes('date') && (
                    <S.CustomDiv>
                      <p>
                        <strong>{keyLabelMap['date']}</strong>
                      </p>

                      {moment(item['date']).format('DD/MM/YYYY')}
                    </S.CustomDiv>
                  )}
                  {selectedColumns.includes('price') && (
                    <S.CustomDiv>
                      <p>
                        <strong>{keyLabelMap['price']}</strong>
                      </p>

                      {item['price']}
                    </S.CustomDiv>
                  )}

                  {priceInAud && (
                    <S.CustomDiv>
                      <p>
                        <strong>Price AUD (Retail):</strong>
                      </p>
                      {priceInAud}
                    </S.CustomDiv>
                  )}
                  <S.CustomDiv>
                    <p>
                      <strong>Price Per g/ml:</strong>
                    </p>

                    {pricePerGmMl.toFixed(4)}
                  </S.CustomDiv>

                  {selectedColumns.includes('product_claim') && (
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 10px' }}>
                      <p>
                        <strong>{keyLabelMap['product_claim']}</strong>
                      </p>
                      <br />

                      <p style={{ marginTop: '-2rem' }}>{item['product_claim']}</p>
                    </div>
                  )}
                  <div style={{ width: '50%', marginInline: 'auto' }}>
                    {showImage && (
                      <Image src={item?.product_image?.product_image} preview={true} className="alpha-image" />
                    )}
                  </div>
                </div>
              </BaseCard>
            </BaseCol>
          );
        })}
      </S.Row>
    </>
  );
};

export default CompetitorData;
