import React from 'react';
// import { useTranslation } from 'react-i18next';
import { activityStatuses } from '@app/constants/config/activityStatuses';
import * as S from './ActivityStoryItem.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

interface ActivityStoryItemProps {
  item: data;
  setshow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelecteditem: (item: data) => void;
}

interface data {
  country_name: string;
  password: any;
  firstName: string;
  lastName: string;
  country: string;
  company: string;
  position: string;
  phoneNumber: number;
  lastActive: string;
  email: string;
  _id: string;
  created: Date;
}

export const ActivityStoryItem = ({ item, setshow, setSelecteditem }: ActivityStoryItemProps): JSX.Element => {
  // const { t } = useTranslation();
  const currentStatus = activityStatuses.find((configStatus) => configStatus.name === status);
  const BaseRowBox = styled(BaseRow)`
    box-shadow: -3px -1px 9px 0px rgba(5, 5, 5, 0.48);
    padding: 20px;
    background: #e5f4e7;
  `;
  const navigate = useNavigate();
  const navigateToDifferentRoute = (id: string) => {
    navigate(`/admin/allPermission/${id}`);
  };
  return (
    <BaseRowBox wrap={false} align="middle">
      <BaseCol>{/* <img width={80} height={80} src={image} alt={title} /> */}</BaseCol>
      <BaseCol flex={1}>
        <BaseRow justify="space-between" wrap={true}>
          <BaseCol>
            <BaseRow gutter={[6, 6]}>
              <>
                <BaseCol span={24}>
                  <S.Title>
                    <S.StyledSpan>Name:</S.StyledSpan> {item?.firstName}
                    {item?.lastName}
                  </S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>
                    <S.StyledSpan>Email: </S.StyledSpan> {item?.email}
                  </S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>
                    <S.StyledSpan>Country:</S.StyledSpan> {item?.country_name}
                  </S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>
                    <S.StyledSpan>Company:</S.StyledSpan> {item?.company}
                  </S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>
                    <S.StyledSpan>Position: </S.StyledSpan>
                    {item?.position}
                  </S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>
                    <S.StyledSpan>Phone Number: </S.StyledSpan>
                    {item?.phoneNumber}
                  </S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>
                    <S.StyledSpan>Role: </S.StyledSpan>
                    {item?.role}
                  </S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Status $color={currentStatus?.color || 'primary'}>
                    Created: {moment(item?.created).format('ddd MMM DD YYYY HH:mm:ss')}
                  </S.Status>
                </BaseCol>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
                  {/* <BaseCol span={24}>
                    <Button
                      type="primary"
                      style={{ fontSize: '15px' }}
                      onClick={() => navigateToDifferentRoute(item._id)}
                    >
                      Access Permission
                    </Button>
                  </BaseCol> */}
                  <div>
                    <Button
                      type="primary"
                      style={{ fontSize: '15px' }}
                      onClick={() => (setshow(false), setSelecteditem(item))}
                    >
                      Manage User
                    </Button>
                  </div>
                </div>
              </>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRowBox>
  );
};
