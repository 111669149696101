import { httpApi } from '@app/api/http.api';

export const GetCategoryTableFields = (
  category_id: string,
  category_market_id: string,
  cat_market_sabcat_id: string,
) => {
  return httpApi
    .get(`categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/categoryTableFields`)
    .then(({ data }) => data);
};

// this api is to call the category contents in category data table

// categories/categoryMarket/65f294e4a85b1949f49ce9ed/65f295eea85b1949f49cebae/65f296a9a85b1949f49cec8d/categoryTableFields
