import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import styled from 'styled-components';
import ViewUserEcPortal from './Ecportaluser/ViewUsers';
import ViewUserAvocado from './AvocadoUser/ViewUsers';

const UsersTabsPage: React.FC = () => {
  const { t } = useTranslation();
  const Tabs = styled(BaseTabs)`
    .ant-tabs-nav-wrap {
      justify-content: center;
    }
  `;
  const commonTabs = useMemo(
    () => [
      {
        key: '1',
        label: <h1>Ec Portal</h1>,
        children: (
          <div>
            <ViewUserEcPortal />
          </div>
        ),
      },
      // {
      //   key: '2',
      //   label: `${t('tabs.view')} User`,
      //   children: <ViewUser type="user" />,
      // },
      {
        key: '2',
        label: <h1>Avocado</h1>,
        children: (
          <div>
            <ViewUserAvocado />
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <Tabs style={{ justifyContent: 'center' }} defaultActiveKey="1" items={commonTabs} />
    </>
  );
};

export default UsersTabsPage;
