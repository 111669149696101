import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: formData[];
}

interface formData {
  key_trends?: string;
  digital_adoption?: string;
}

export const PutConsumerBehaviour = (id: string, formData: formData): Promise<response> => {
  return httpApi.put<response>(`marketInsightsCbst/${id}/marketCbstUpdate`, formData).then(({ data }) => data);

  // This API is fetching Video details(name,descprtion etc) with help of folder > clicked video id
};
