import { httpApi } from '@app/api/http.api';
interface response {
  message?: string;
  sessionId?: string;
}

interface Service {
  category: { id: string; name: string };
  market: { marketId: string; marketName: string };
  subCategory: { subcategoryId: string; subcategoryName: string };
  price: number;
}
interface Cart {
  cart: Service[];
}

export const buySubscription = (path: string, serviceCart: Cart): Promise<response> => {
  return httpApi.post<response>(`${path}`, serviceCart).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
