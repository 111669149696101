import React, { useEffect, useState } from 'react';
import CategoryContents from './CategoryContents';
import CategoryTableFields from './CategoryTableFields';
import CategoryDataTable from './CategoryDataTable';
import { GetCategoryTableFields } from '@app/api/categories/CategoryTableFields/GetCategoryTableFields.api';
import { useParams } from 'react-router-dom';
import { GetCategoryTableData } from '@app/api/categories/CategoryDataTableNEW/GetCategoryTableData.api';

const CategoryDataDetails = () => {
  const { id, marketId, subcatId } = useParams();
  const [tableFieldsData, setTableFieldsData] = useState([]);
  const [tableDataSource, setCatTableDataSource] = useState();
  const [loading, setLoading] = useState(false);

  const fetchTableFieldsData = async () => {
    try {
      const response = await GetCategoryTableFields(id, marketId, subcatId);
      setTableFieldsData(response.data); // Reverse the columns here
      console.log('tableFieldsData', response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTableFieldsData();
  }, []);

  // APi to get table data
  const fetchTableData = async () => {
    setLoading(true);
    try {
      const response = await GetCategoryTableData(id as string, marketId as string, subcatId as string);
      const dataWithKeys = response?.data?.rowsData?.map((item: DataType) => ({ ...item, key: item._id }));
      setCatTableDataSource(dataWithKeys); // Reverse the data here
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);
  return (
    <div>
      <CategoryContents />
      <CategoryTableFields
        columnsData={tableFieldsData}
        fetchData={fetchTableFieldsData}
        fetchCatTableData={fetchTableData}
      />
      <CategoryDataTable
        columnsData={tableFieldsData}
        fetchCatTableData={fetchTableData}
        setCatTableDataSource={setCatTableDataSource}
        tableDataSource={tableDataSource}
      />
    </div>
  );
};

export default CategoryDataDetails;
