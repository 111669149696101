import React, { useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import styled from 'styled-components';
import { notificationController } from '@app/controllers/notificationController';
import { ViewUsers } from '@app/api/users/ViewUsers.api';
import { ActivityStory } from './activityStory/ActivityStory';
import { GetAUser, ViewEcportalUsers } from '@app/api/alluser/GetAllUser';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput.styles';
const YourSubscriptionbutton = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
`;
interface response {
  status?: boolean;
  message?: string;
  data?: [];
}
const YourSearchbutton = styled(BaseButton)`
  margin-left: 10px;
`;

const WrapperBox = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 550px;
`;

const SearchInputNew = styled(SearchInput)`
  .ant-input-search-button {
    display: none;
  }
  .ant-input-group-addon {
    display: none;
  }
`;
const ViewUserEcPortal = (): JSX.Element => {
  const [loading, setloading] = useState<boolean>(false);
  const [value, setValue] = useState('');
  const [filteredUser, setFilteredUser] = useState();
  const [show, setshow] = useState<boolean>(false);
  const [showFilteredData, setShowFilteredData] = useState(false);
  const [item, setitem] = useState<response[]>([]);
  const handleDownloadClick = async () => {
    setloading(true);
    try {
      const response: response = await ViewEcportalUsers();
      setitem(response.data as []);
      notificationController.success({ message: 'Success Fetching Users' });
      setShowFilteredData(false);
      setshow(true);
      setValue('');
    } catch (error) {
      console.error('Error·downloading·users:', error);
    } finally {
      setloading(false);
    }
  };
  const GetUser = async () => {
    setloading(true);
    try {
      const response: response = await GetAUser('ecportal', value);
      setFilteredUser(response?.data as []);
      notificationController.success({ message: 'Success Fetching User' });
      setShowFilteredData(true);
      setshow(false);
    } catch (error) {
      console.error('Error·downloading·users:', error);
    } finally {
      setloading(false);
    }
  };
  return (
    <>
      <WrapperBox>
        <SearchInputNew
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value={value}
          onChange={(e) => setValue(e.target.value as any)}
          placeholder="Enter Email or Name"
          allowClear
        />
        <YourSearchbutton disabled={loading || value.trim() === ''} onClick={() => GetUser()}>
          search
        </YourSearchbutton>
      </WrapperBox>
      <YourSubscriptionbutton disabled={loading} onClick={handleDownloadClick}>
        Load Users
      </YourSubscriptionbutton>
      {showFilteredData ? <ActivityStory item={filteredUser as []} /> : ''}
      {loading && <BaseSpin />}
      {show ? <ActivityStory item={item as []} /> : ''}
    </>
  );
};

export default ViewUserEcPortal;
