import { httpApi } from '@app/api/http.api';

export const GetCategoryTableData = (
  category_id: string,
  category_market_id: string,
  cat_market_sabcat_id: string,
): Promise<undefined> => {
  return httpApi
    .get(
      `categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/categoryData/tableData`,
    )
    .then(({ data }) => data);
};
