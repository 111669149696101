import React from 'react';

import { ServicesContainer, ServicesH1, ServicesWrapper } from './PriceAndFeatures.styles';
import { LandingNav } from '../Navbar/NavbarElements';

const PriceAndFeatures: React.FC = () => {
  return (
    <ServicesContainer id="features">
      <ServicesWrapper>
        <ServicesH1>First time Member Special: $99 for the first 6 months</ServicesH1>
        <LandingNav
          style={{
            borderRadius: '50px',
            width: '196px',
            padding: '14px 18px',
            fontSize: '17px',
            background: 'white',
            color: '#00b4a0',
          }}
          to="NewMemberOffer"
          smooth={true}
          duration={500}
          spy={true}
          offset={-20}
        >
          Pricing & Features
        </LandingNav>
        {/* <BaseButton style={{ backgroundColor: 'white' }} type="ghost" size="middle">
          Pricing & Features
        </BaseButton> */}
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default PriceAndFeatures;
