import { httpApi } from '@app/api/http.api';

type data = string;
interface response {
  status?: boolean;
  message?: string;
  data?: [];
}
export const SearchUser = (data: data): Promise<response> => {
  if (data) {
    return httpApi.get<response>(`/user?search=${data}`).then(({ data }) => data);
  } else {
    // Handle the case when readUser is null, perhaps throw an error or handle it appropriately.
    throw new Error('User data not found in local storage');
  }
};

export const SearchUserSub = (data: data): Promise<response> => {
  if (data) {
    return httpApi.get<response>(`/user/subscribed?search=${data}`).then(({ data }) => data);
  } else {
    // Handle the case when readUser is null, perhaps throw an error or handle it appropriately.
    throw new Error('User data not found in local storage');
  }
};
