import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { GetLogoChange } from './api/logoChange/GetLogoChange.api';
import { Helmet } from 'react-helmet-async';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);
  const [img, setImage] = useState();

  usePWA();

  useAutoNightMode();

  useThemeWatcher();

  const fetchImage = async () => {
    try {
      const response = await GetLogoChange();
      console.log('response ', response);
      setImage(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <Helmet>
          <link rel="icon" href={img?.favicon_value} />
          {/* You can also use a .png or .svg file */}
          <link rel="icon" type="image/png" sizes="32x32" href={img?.favicon_value} />
        </Helmet>
        <ConfigProvider locale={language === 'en' ? enUS : deDe}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
