import styled from 'styled-components';
import { FormTitle } from '@app/components/layouts/AuthLayout/AuthLayout.styles';

export const Title = styled(FormTitle)`
  margin-bottom: 1.875rem;
`;
export const cusDiv = styled.div`
  width: 33.5rem;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
