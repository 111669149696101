import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
  margin: auto;
  padding: 20px;
  box-shadow: rgba(35, 78, 84, 0.61) 0px 0px 15px 1px; 
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 100%;
   }s
`;
export const Title = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 30px;
`;
export const ListWrapper = styled.div`
  display: flex;
  box-shadow: rgba(35, 78, 84, 0.61) 0px 0px 15px 1px;
  border-radius: 5px;
  margin: 10px 0px;
`;
