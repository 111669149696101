import React from 'react';

import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesH2, ServicesP } from './Features.styles';

const Features: React.FC = () => {
  return (
    <ServicesContainer id="features">
      <ServicesH1>Explore Our Features</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <img
            width="50px"
            aria-hidden="true"
            src="https://exportconnectportal.com/custom-icons/market-reports-07.svg"
          />
          <ServicesH2>Market Insights</ServicesH2>
          <ServicesP>
            Determine the most appropriate markets for your products by analysing key insights and data, including
            economic indicators, demographic and consumer trends, channel developments, and market access requirements.
          </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <img width="50px" aria-hidden="true" src="https://exportconnectportal.com/custom-icons/trade-data.svg" />
          <ServicesH2>Trade & Category Data</ServicesH2>
          <ServicesP>
            Develop your knowledge and understanding of the competitive landscape by identifying the top ten source
            countries for your products for each market that you are assessing.
          </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <img
            width="50px"
            aria-hidden="true"
            src="https://exportconnectportal.com/custom-icons/competitor-insights.svg"
          />
          <ServicesH2>Competitor Insights</ServicesH2>
          <ServicesP>
            Analyse the competitive landscape and pitch your USP to global buyers by reviewing crucial aspects such as
            competitor brands, product claims, pricing, country of manufacture, product packaging, and product images.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <img width="50px" aria-hidden="true" src="https://exportconnectportal.com/custom-icons/workshops-10.svg" />
          <ServicesH2>Export Video Library</ServicesH2>
          <ServicesP>
            Strengthen your export market capability and knowledge by viewing content designed exclusively for food &
            agribusinesses, covering the most relevant topics and buyer interviews for new and experienced exporters
            alike.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <img width="50px" aria-hidden="true" src="https://exportconnectportal.com/custom-icons/tools.svg" />
          <ServicesH2>Resources & Tools</ServicesH2>
          <ServicesP>
            Plan for your export success through spreadsheets, templates, and checklists that include topics of
            importance such as the market selection table, export pricing model, and distributor performance audit.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <img
            width="50px"
            aria-hidden="true"
            src="https://exportconnectportal.com/custom-icons/advisory-service.svg"
          />
          <ServicesH2>Advisory Service</ServicesH2>
          <ServicesP>
            Save time and money in developing your export market goals and strategies by accessing support from industry
            leaders with decades of export market development experience.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Features;
