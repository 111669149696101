import * as S from './ConsumerBehaviourButton.styles';
import CustomModal from '@app/components/Model';
import { useEffect, useState } from 'react';
import QuillEditor from '../EconomicIndicator/QuillEditor';
import { GetConsumerBehaviour } from '@app/api/markets/MarketDetails/ConsumerBehaviour/GetConsumerBehaviour.api';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import KeyTrendsQuillEditor from './KeyTrendsQuillEditor';
import DigitalAdoptionQuillEditor from './DigitalAdoptionQuillEditor';

interface ConsumerBehaviourData {
  key_trends: string;
  digital_adoption: string;
}

const ConsumerBehaviourForm = (): JSX.Element => {
  const [formData, setFormData] = useState<ConsumerBehaviourData>();
  const [CBloading, setCBLoading] = useState(false);
  const { id } = useParams();
  const [modalState, setModalState] = useState({ id: '', isModalOpen: false });
  const [daModalState, setDaModalState] = useState({ id: '', isModalOpen: false });
  //Key trends Modal
  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  //Digital Adoption Modal
  const showDaModal = () => {
    setDaModalState({ ...modalState, isModalOpen: true });
  };

  const handleDaOk = () => {
    setDaModalState({ ...modalState, isModalOpen: false });
  };

  const handleDaCancel = () => {
    setDaModalState({ ...modalState, isModalOpen: false });
  };
  const fetchData = async () => {
    setCBLoading(true);
    try {
      const response = await GetConsumerBehaviour(id as string);
      setFormData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setCBLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <S.ButtonRowWrap>
        <S.BaseRowButton onClick={showModal}>Key Trends</S.BaseRowButton>
        <S.BaseRowButton onClick={showDaModal}>Digital Adoption</S.BaseRowButton>
      </S.ButtonRowWrap>
      <CustomModal
        title="Key Trends"
        open={modalState.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={
          <KeyTrendsQuillEditor handleOk={handleCancel} data={formData?.key_trends as string} fetchData={fetchData} />
        }
      />
      <CustomModal
        title="Digital Adoption"
        open={daModalState.isModalOpen}
        onOk={handleDaOk}
        onCancel={handleDaCancel}
        content={
          <DigitalAdoptionQuillEditor
            handleOk={handleCancel}
            data={formData?.digital_adoption as string}
            fetchData={fetchData}
          />
        }
      />
    </>
  );
};
export default ConsumerBehaviourForm;
