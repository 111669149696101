import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/lib/mentions';
import { GetCategoryType } from '@app/api/categories/GetCategoryType.api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { AddCategory } from '@app/api/categories/AddCategory.api';
import { notificationController } from '@app/controllers/notificationController';
import { Form } from 'antd';

interface props {
  loadCategories: () => void;
  handleOk: () => void;
}

interface categoryType {
  _id: string;
  name: string;
}

interface response {
  status: boolean;
  message: string;
  data?: [];
}

interface addCategory {
  category_type_id: string;
  name: string;
  status: boolean;
}

const AddCategoryForm = ({ loadCategories, handleOk }: props): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [categoryTypeData, setCategoryTypeData] = useState<categoryType[]>([]);
  const [loading, setLoading] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  useEffect(() => {
    const getCategoryType = async () => {
      const response: response = await GetCategoryType();
      setCategoryTypeData(response?.data as []);
    };
    getCategoryType();
  }, []);

  const handleSubmit = async (values: addCategory) => {
    setLoading(true);
    try {
      // Simulate a delay, replace this with your actual API call
      const AddCategoryData = {
        category_type_id: values.category_type_id,
        name: values.name,
        status: values.status,
      };
      // Assuming ChangePassword is an asynchronous function, use await here
      await AddCategory(AddCategoryData);
      notificationController.success({ message: t('common.success') });
      await loadCategories();
      handleOk();
      form.resetFields();
    } catch (error) {
      console.error('Error while adding category.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseForm onFinish={handleSubmit} form={form}>
      <Auth.FormItem name="name" label="Category Name" rules={[{ required: true, message: t('common.requiredField') }]}>
        <Auth.FormInput placeholder="Category Name" />
      </Auth.FormItem>
      <Auth.FormItem
        name="category_type_id"
        label={t('common.category-type')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <Auth.FormBaseSelect placeholder="Category Type">
          {categoryTypeData?.map((e) => (
            <Option value={e._id} key={e._id}>
              {e.name}
            </Option>
          ))}
        </Auth.FormBaseSelect>
      </Auth.FormItem>

      <div
        style={{
          border: '1px solid rgba(67, 115, 109, 0.82)',
          borderRadius: '8px',
          padding: '10px',
          marginBottom: '10px',
        }}
      >
        <div style={{ display: 'flex', padding: '12px 0px' }}>
          <BaseForm.Item name="status" valuePropName="checked" noStyle>
            <Auth.FormCheckbox onChange={(e) => setCheckboxChecked(e.target.checked)}>
              <Auth.Text>Active</Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </div>

        {!checkboxChecked && (
          <p style={{ background: '#dbdbdb5c', padding: '10px', borderRadius: '4px' }}>
            Category must be activated before it is visible user-side. Add at least one category market with one
            subcategory before activating.
          </p>
        )}
      </div>
      <BaseForm.Item noStyle>
        <Auth.SubmitButton type="primary" disabled={loading} htmlType="submit" loading={loading}>
          {!loading ? `${t('common.submit')}` : ''}
        </Auth.SubmitButton>
      </BaseForm.Item>
    </BaseForm>
  );
};
export default AddCategoryForm;
