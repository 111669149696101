import { httpApi } from '@app/api/http.api';

// interface FormDatas {
//   Image: {
//     file: {
//       originFileObj: File;
//     };
//   };
//   Video: {
//     file: {
//       originFileObj: File;
//     };
//   };
// }

export const AddResourceModule = (pageDetail: FormData, Id: string): Promise<undefined> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return httpApi
    .post<undefined>(`/resourceFolders/${Id}/resources/addResource`, pageDetail, config)
    .then(({ data }) => data);
};
