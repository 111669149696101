import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
  margin: auto;
`;
export const Button = styled(BaseButton)`
  display: unset;
  margin: 10px 0 10px 10px;
`;

export const CategoryDetailsWrap = styled.div`
  width: 50%;
  margin: 20px auto;

  @media (max-width: 1260px) {
    width: 100%;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  box-shadow: rgba(35, 78, 84, 0.61) 0px 0px 15px 1px;
  border-radius: 5px;
  margin: 10px 0px;
`;

export const BaseRowBox = styled(BaseRow)`
  // box-shadow: -3px -1px 9px 0px rgba(5, 5, 5, 0.48);
  padding: 20px;
  // margin-bottom: 10px;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;
export const CustomSpin = styled(BaseSpin)`
  position: relative;
  left: 50%;
  margin-top: 30px;
`;
