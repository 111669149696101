import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import Model from '@app/components/Model';
import TermsOfUse from './TermsOfUse';
import { GetCountry } from '@app/api/markets/GetCountry.api';

interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  termOfUse: boolean;
}
interface country {
  _id: string;
  name: string;
}
const initValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  termOfUse: false,
};
interface ModalState {
  isModalOpen: boolean;
}

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [countries, setCountries] = useState<country[]>([]);
  const fetchCountries = async () => {
    const response = await GetCountry();
    setCountries(response?.data?.reverse() as country[]);
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });

  const { isModalOpen } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);
    if (values?.termOfUse) {
      dispatch(doSignUp(values))
        .unwrap()
        .then(() => {
          notificationController.success({
            message: t('auth.signUpSuccessMessage'),
            description: t('auth.signUpSuccessDescription'),
          });
          navigate('/auth/login');
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    } else {
      notificationController.error({ message: 'You must agree with the Terms and Conditions' });
      setLoading(false);
    }
  };

  return (
    <Auth.SignUpFormWrapper>
      <S.cusDiv>
        <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
          <S.Title>{t('common.signUp')}</S.Title>
          <Auth.FormItem
            name="firstName"
            label={t('common.firstName')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.firstName')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="lastName"
            label={t('common.lastName')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.lastName')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="company"
            label={t('common.company')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.company')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="position"
            label={t('common.position')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.position')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="country"
            label={t('common.country')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormBaseSelect placeholder={t('forms.validationFormLabels.selectCountry')}>
              {countries?.map((e) => (
                <Option value={e?._id} key={e?._id}>
                  {e?.name}
                </Option>
              ))}
            </Auth.FormBaseSelect>
          </Auth.FormItem>
          <Auth.FormItem
            name="phoneNumber"
            label={t('common.phone')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.phone')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="email"
            label={t('common.email')}
            rules={[
              { required: true, message: t('common.requiredField') },
              {
                type: 'email',
                message: t('common.notValidEmail'),
              },
            ]}
          >
            <Auth.FormInput placeholder={t('common.email')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="confirmEmail"
            label={t('common.confirmEmail')}
            rules={[
              { required: true, message: t('common.requiredField') },
              {
                type: 'email',
                message: t('common.notValidEmail'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('common.notValidEmail')));
                },
              }),
            ]}
          >
            <Auth.FormInput placeholder={t('common.confirmEmail')} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t('common.password')}
            name="password"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInputPassword placeholder={t('common.password')} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t('common.confirmPassword')}
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: t('common.requiredField') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('common.confirmPasswordError')));
                },
              }),
            ]}
          >
            <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
          </Auth.FormItem>
          <Auth.ActionsWrapper>
            <Auth.FormItem valuePropName="checked" name="termOfUse" noStyle>
              <Auth.FormCheckbox>
                <Auth.Text>
                  {t('signup.agree')} {''}
                  <Auth.LinkText onClick={showModal}>{t('signup.termOfUse')}</Auth.LinkText> {''}
                  {/* and <Auth.LinkText onClick={showModal}>{t('signup.privacyOPolicy')}</Auth.LinkText> */}
                </Auth.Text>
              </Auth.FormCheckbox>
            </Auth.FormItem>
          </Auth.ActionsWrapper>
          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.signUp')}
            </Auth.SubmitButton>
          </BaseForm.Item>
          <Auth.FooterWrapper>
            <Auth.Text>
              {t('signup.alreadyHaveAccount')}{' '}
              <Link to="/auth/login">
                <Auth.LinkText>{t('common.here')}</Auth.LinkText>
              </Link>
            </Auth.Text>
          </Auth.FooterWrapper>
        </BaseForm>
      </S.cusDiv>
      <Model
        title="Terms and Conditions"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<TermsOfUse />}
      />
    </Auth.SignUpFormWrapper>
  );
};
