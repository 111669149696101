/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { DeltaStatic } from 'quill';
import { TextAreaQuill } from '@app/pages/Admin/Categories/CategoriesPage.styles';
import { AddTradeFixedData } from '@app/api/categories/TradeDataFixed/AddTradeFixedData.api';

interface Props {
  handleOk: () => void;
  catID: string;
  marketID: string;
  subCatID: string;
  data: string;
  type: string;
}

const QuillEditor = ({ handleOk, catID, marketID, subCatID, data, type }: Props): JSX.Element => {
  const modules = {
    toolbar: [['bold', 'italic', 'underline'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]],
  };
  const [value, setValue] = useState(data); // State for editor content
  const [html, setHtml] = useState(data);
  const quillRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const handleChange = (content: string, _delta: DeltaStatic, source: string) => {
    setValue(content); // Update state with the latest content (text or HTML)
    if (source === 'user') setHtml(content);
  };
  // Function to handle HTML input and update both editor and state
  const handleHtmlChange = (e: string) => {
    console.log('html content', e);
    setHtml(e);
    setValue(e);
  };
  // useEffect(() => {
  //   if (quill) {
  //     quill.clipboard.dangerouslyPasteHTML(data ?? '');
  //     quill.on('text-change', handleEditorChange);
  //     return () => {
  //       quill.off('text-change', handleEditorChange);
  //     };
  //   }
  // }, [quill]);
  const handleAddDesc = async () => {
    setLoading(true);
    if (type === 'import') {
      const formData = {
        import_description: value,
      };

      try {
        await AddTradeFixedData(catID, marketID, subCatID, formData);
        handleOk();
        notificationController.success({ message: 'success' });
      } catch (error) {
        console.error('error', error);
      } finally {
        setLoading(false);
      }
    } else {
      const formData = {
        export_description: value,
      };
      try {
        await AddTradeFixedData(catID, marketID, subCatID, formData);
        handleOk();
        notificationController.success({ message: 'success' });
      } catch (error) {
        console.error('error', error);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleReset = () => {
    setValue(data ?? '');
    setHtml(data ?? '');
  };
  return (
    <div>
      <div style={{ display: 'flex', padding: '5px', justifyContent: 'space-between', marginBottom: '15px' }}>
        <BaseButton disabled={value === data} severity="warning" onClick={() => handleReset()}>
          Reset
        </BaseButton>
        <BaseButton type="default">
          <a target="" href="https://www.prepostseo.com/tool/online-text-editor">
            Editor
          </a>
        </BaseButton>
        <BaseButton severity="success" disabled={loading || value === data} loading={loading} onClick={handleAddDesc}>
          Apply
        </BaseButton>
      </div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(value, delta, source) => handleChange(value, delta, source)}
        modules={modules}
        ref={quillRef} // Assign reference to the editor instance
      />
      <div>
        <TextAreaQuill
          value={html}
          onChange={(e) => handleHtmlChange(e.target.value)}
          autoSize={{ minRows: 2, maxRows: 5 }}
          placeholder="Enter your HTML here"
        />
      </div>
    </div>
  );
};
export default QuillEditor;
