import React, { useCallback, useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

// Import the downloadUsers function
import { downloadUsers } from '@app/api/downloaduser.api'; // Replace with the actual path
import styled from 'styled-components';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { ImportOutlined } from '@ant-design/icons';
const YourSubscriptionbutton = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
`;
const DownloadCsv = (): JSX.Element => {
  const [loading, setloading] = useState<boolean>();
  const { t } = useTranslation();
  const saveFile = useCallback((filename: string, type: string, data: string) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (a as any).style = 'display: none';
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }, []);
  const handleDownloadClick = async () => {
    setloading(true);
    try {
      // Call the downloadUsers function when the button is clicked
      const response: string = await downloadUsers();
      //   const blob = await response.blob()
      const filename = 'users.csv';
      const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      saveFile(filename, contentType, response);
      setloading(false);
      notificationController.success({ message: t('common.success') });
      // You can handle success here
    } catch (error) {
      console.error('Error downloading users:', error); // Handle errors appropriately
    }
    setloading(false);
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <YourSubscriptionbutton
        disabled={loading}
        loading={loading}
        onClick={handleDownloadClick}
        icon={<ImportOutlined />}
      >
        Download Users-Subscriptions CSV
      </YourSubscriptionbutton>
    </div>
  );
};

export default DownloadCsv;
