import React from 'react';
import {
  // CompassOutlined,
  // DashboardOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  // FormOutlined,
  // HomeOutlined,
  // LayoutOutlined,
  // LineChartOutlined,
  PlayCircleOutlined,
  StarOutlined,
  // TableOutlined,
  UserOutlined,
  // UserOutlined,
  // BlockOutlined,
} from '@ant-design/icons';
// import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarDashboard: SidebarNavigationItem[] = [
  {
    title: 'Your Subscriptions',
    key: 'your-subscriptions',
    // TODO use path variable
    url: '/dashboard/your-subscriptions',
    icon: <StarOutlined />,
  },
  {
    title: 'Market Insights',
    key: 'market-insights',
    url: '/dashboard/market-insights',
    icon: <GlobalOutlined />,
  },
  {
    title: 'Export Video Library',
    key: 'export-video',
    url: '/dashboard/export-video',
    icon: <PlayCircleOutlined />,
  },
  {
    title: 'Resources',
    key: 'resources-dashboard',
    url: '/dashboard/resources',
    icon: <DatabaseOutlined />,
  },
  {
    title: 'Your Profile',
    key: 'your-profile',
    url: '/dashboard/profile',
    icon: <UserOutlined />,
  },
];
export const sidebarPremiumFeatures: SidebarNavigationItem[] = [
  {
    title: 'Competitor Reviews',
    key: 'competitor-reviews',
    url: '/dashboard/competitor-reviews',
    icon: <StarOutlined />,
  },
  {
    title: 'Advisory Service',
    key: 'advisory-service',
    url: '/dashboard/advisory-service',
    icon: <StarOutlined />,
  },
];
