import { httpApi } from '@app/api/http.api';

interface formData {
  source?: string;
  importer_ranking?: number;
  imported_value?: number;
  year_range_short_term?: {
    from: number;
    to: number;
  };
  year_range_long_term?: {
    from: number;
    to: number;
  };
  date?: Date;
  import_description?: string;
  export_description?: string;
}

// Define types for the parameters
type category_id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;

export const AddTradeFixedData = (
  category_id: category_id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
  formData: formData,
): Promise<undefined> => {
  return httpApi
    .post<undefined>(
      `/categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/tradeData/tradeDataAdd`,
      formData,
    )
    .then(({ data }) => data);
};

// This API is to POST the data for Trade Data Form in category -> cat market-> sub categrory ->trade Data

// http://localhost:3000/localhost/categories/categoryMarket/65e01492f9cc4d34a85a1894/65e01670f9cc4d34a85a1971/65e0178ff9cc4d34a85a1b2d/tradeData/tradeDataAdd  (POST)

// category_id/category_market_id/cat_market_sabcat_id
