import styled from 'styled-components';
import { LAYOUT, media } from '@app/styles/themes/constants';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
export const Head = styled.h1`
  font-size: 30px;
  text-align: center;
  margin: 30px 10px;
  font-weight: bold;
`;
export const SubHead = styled.h2`
  font-size: 25px;
  font-weight: bold;
`;
export const Content = styled.p`
  font-size: 20px;
`;
export const Button = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
`;
export const MainRow = styled(BaseRow)`
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  @media only screen and ${media.xl} {
    jusjustify-content: space-between;
  }
`;
export const RightSideCol = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--sider-background-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(BaseCol)`

  @media only screen and ${media.xl} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    overflow: auto;
    jus
  }
`;

export const Space = styled.div`
  margin: 1rem 0;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;

export const Container = styled.div`
  width: 50%;
  margin: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;
export const Scroll_box = styled.div`
  height: 200px;
  overflow-y: auto;
`;
export const CollapseWrapper = styled(BaseCollapse)`
  width: 40rem;
`;
export const BaseRowBox = styled(BaseRow)`
  // box-shadow: -3px -1px 9px 0px rgba(5, 5, 5, 0.48);
  padding: 20px;
  // margin-bottom: 10px;
  justify-content: space-between;
`;

export const fixedModel = styled.div`
  position: fixed;
  background-color: var(--background-color);
  background-clip: padding-box;
  border: 0;
  border-radius: 7px;
  pointer-events: auto;
  top: 8px;
`;

export const DemographicFactors = styled.div`
  margintop: 2rem;
  margin: 15px 0px;
  padding: 20px;
  box-shadow: rgba(35, 78, 84, 0.61) 0px 0px 15px 1px;
  border-radius: 5px;
`;
