import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: foldersData[];
}

interface foldersData {
  ease_ranking?: string;
  gdb_billions?: string;
  gdb_per_capita?: string;
  human_ranking?: string;
  logistics_ranking?: string;
  market_id?: string;
  quality_ranking?: string;
  source_date?: Date;
  trade_agreement?: string;
}
export const EIPut = (id: string, formData: foldersData): Promise<response> => {
  return httpApi.put<response>(`/marketInsightsEI/${id}/marketEiUpdate `, formData).then(({ data }) => data);

  // This API is fetching Video details(name,descprtion etc) with help of folder > clicked video id
};
