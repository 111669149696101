import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
// import { useAppDispatch } from '@app/hooks/reduxHooks';
// import { doSignUp } from '@app/store/slices/authSlice';
// import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { notificationController } from '@app/controllers/notificationController';
import { ContactUsForm } from '@app/api/contactUsForm.api';

interface ContactUsData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  enquiry: string;
}
interface ModalState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleCancel: any; // Define handleCancel as a function
  // Add other properties of modalState if any
}

const ContactUs: React.FC<ModalState> = ({ handleCancel }) => {
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [Loading, setLoading] = useState(false);
  const handleSubmit = async (values: ContactUsData) => {
    setLoading(true);
    try {
      // Simulate a delay, replace this with your actual API call
      const contactFormData = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        companyName: values.companyName,
        enquiry: values.enquiry,
      };
      // Assuming ContactUsForm is an asynchronous function, use await here
      await ContactUsForm(contactFormData);
      handleCancel();
      notificationController.success({ message: t('common.success') });
    } catch (error) {
      console.error('Error while submitting data.', error);
      // Handle the error, show a notification, etc.
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <Auth.FormItem
          name="firstName"
          label={t('common.firstName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.firstName')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="lastName"
          label={t('common.lastName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.lastName')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="phoneNumber"
          label={t('common.phone')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.phone')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="companyName"
          label={t('common.company')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.company')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="enquiry"
          label={t('common.enquiry')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.enquiry')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={Loading}>
            {t('common.submit')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </>
  );
};
export default ContactUs;
