import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: foldersData[];
}

interface foldersData {
  name: string;
}
export const EditResourceDetails = (folderID: string, ResourceID: string, formData: FormData): Promise<response> => {
  return httpApi
    .put<response>(`/resourceFolders/${folderID}/resources/${ResourceID}/resourceUpdate`, formData)
    .then(({ data }) => data);

  // This API is fetching Video details(name,descprtion etc) with help of folder > clicked video id
};
