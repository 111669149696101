import React, { useState, useEffect } from 'react';
import { AllPermissions } from '@app/api/permissions/AllPermission.api';
import { assignPermissionsAPI } from '@app/api/permissions/AssignPermission.api';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getAssignedPermissionsAPI } from '@app/api/permissions/GetAssignPermission.api';
import { Container, Title, ListWrapper } from './AssignPermission.styles';
import { Button } from 'antd';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';

interface Permission {
  _id: string;
  accessPermissions: string;
  fieldName: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
interface AssignPermissionsResponse {
  status: boolean;
  message: string;
  data?: {
    _id: string;
    UserID: string;
    AccessPermissions: {
      _id: string;
      permissionId: string;
      value: string;
    }[];
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
}
interface SelectedPermission {
  permissionId: string;
  value: string;
  checked: boolean;
  _id: string;
  accessPermissions: string;
}

interface Response {
  status?: boolean;
  message?: string;
  data?: Permission[];
}

const AssignPermission: React.FC = () => {
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<SelectedPermission[]>([]);
  const { id = '' } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allPermissionsResponse: Response = await AllPermissions();
        if (allPermissionsResponse.status) {
          const allPermissions = allPermissionsResponse.data || [];
          const assignedPermissionsResponse: AssignPermissionsResponse = await getAssignedPermissionsAPI(id);
          if (assignedPermissionsResponse.status) {
            const assignedPermissions =
              assignedPermissionsResponse?.data?.AccessPermissions?.map((perm) => perm.permissionId) || [];
            const updatedSelectedPermissions = allPermissions.map((permission) => ({
              permissionId: permission._id,
              value: permission.accessPermissions,
              checked: assignedPermissions.includes(permission._id),
              _id: permission._id,
              accessPermissions: permission.accessPermissions,
            }));
            setPermissions(allPermissions);
            setSelectedPermissions(updatedSelectedPermissions); // Ensure passing updatedSelectedPermissions
            notificationController.success({ message: t('common.success') });
          } else {
            notificationController.error({ message: assignedPermissionsResponse.message });
          }
        } else {
          notificationController.error({ message: allPermissionsResponse.message });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        notificationController.error({ message: t('common.error') });
      }
    };
    fetchData();
  }, []);

  const handlePermissionToggle = (permissionId: string, value: string) => {
    setSelectedPermissions((prevSelected: SelectedPermission[]) => {
      const existingPermissionIndex = prevSelected.findIndex((perm) => perm._id === permissionId);
      if (existingPermissionIndex !== -1) {
        const updatedSelectedPermissions = [...prevSelected];
        updatedSelectedPermissions[existingPermissionIndex] = {
          ...prevSelected[existingPermissionIndex],
          checked: !prevSelected[existingPermissionIndex].checked,
        };
        return updatedSelectedPermissions;
      } else {
        return [...prevSelected, { permissionId, value, checked: true, _id: '', accessPermissions: '' }];
      }
    });
  };

  const handleSubmit = async () => {
    if (!id) {
      console.error('UserID is undefined');
      return;
    }
    const checkedPermissions = selectedPermissions.filter((permission) => permission.checked);
    const requestData = {
      UserID: id,
      AccessPermissions: checkedPermissions.map((permission) => ({
        permissionId: permission._id,
        value: permission.accessPermissions,
      })),
    };

    try {
      const response = await assignPermissionsAPI(requestData);
      if (response.status) {
        notificationController.success({ message: t('common.success') });
      } else {
        notificationController.error({ message: response.message });
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      notificationController.error({ message: t('common.error') });
    }
  };

  return (
    <>
      <Title>Assign Permission</Title>
      <Container>
        <ul>
          {permissions.map(({ _id, accessPermissions, fieldName }) => {
            const isChecked = selectedPermissions.some((permission) => permission._id === _id && permission.checked);
            return (
              <ListWrapper
                key={_id}
                style={{
                  display: 'flex',
                  boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 15px 1px',
                  margin: '10px 0px',
                  borderRadius: '3px',
                  padding: '10px',
                }}
              >
                <BaseCheckbox
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => handlePermissionToggle(_id, accessPermissions)}
                />
                <div style={{ margin: '0px 0px 0px 10px' }}>{fieldName}</div>
              </ListWrapper>
            );
          })}
        </ul>
        <Button onClick={handleSubmit} type="primary">
          {t('common.submit')}
        </Button>
      </Container>
    </>
  );
};

export default AssignPermission;
