import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonRowWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: rgba(35, 78, 84, 0.61) 0px 0px 15px 1px;
  border-radius: 5px;
`;
export const BaseRowButton = styled(Button)`
  justify-content: space-between;
  margin: 10px;
  box-shadow: rgba(35, 78, 84, 0.61) 0px 0px 15px 1px;
  border-radius: '5px';
`;
