import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: GRData;
}

interface GRData {
  key_trends?: string;
  grocery_retailing_source?: string;
  petro_convenience_source?: string;
  grocery_retailing?: { type: inputData[] };
  petro_convenience?: { type: inputData[] };
}
interface inputData {
  name?: string;
  percentage: number;
  _id?: string;
}
export const PutGroceryRetail = (id: string, formData: GRData): Promise<response> => {
  return httpApi.put<response>(`/marketInsightsGrcd/${id}/marketGrcdUpdate `, formData).then(({ data }) => data);

  // This API is fetching Video details(name,descprtion etc) with help of folder > clicked video id
};
