import { httpApi } from '@app/api/http.api';
interface response {
  status: string;
  message: string;
  data?: foldersData[];
}
interface foldersData {
  ID: string;
  name: string;
}

export const Delete = (folderID: string, videoID: string): Promise<response> => {
  return httpApi.delete<response>(`videoFolders/${folderID}/videos/${videoID}/videoDelete`).then(({ data }) => data);
};
