import React, { useEffect, useState } from 'react';
import * as S from './TradePage.styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { ViewCompetitor } from '@app/api/tradedata/ViewCompetitor.api';
import { ViewTradedata } from '@app/api/tradedata/ViewTradedata.api';
import { ViewCategorydata } from '@app/api/tradedata/ViewCategorydata';
import { Link } from 'react-router-dom';
import CompetitorData from './CompetitorData';
import TradeData from './TradeData';
import CategoryData from './CategoryData';
import { Loading } from '@app/components/common/Loading/Loading';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import CustomTabBar from '@app/components/common/CustomTabBar/CustomTabBar';
import { AndroidFilled } from '@ant-design/icons';
import CompetitorIcon from '@app/components/common/icons/Competitoricon';
import TradeDataIcon from '@app/components/common/icons/Tradedataicon';
import CategoryDataIcon from '@app/components/common/icons/Categorydataicon';
import { Breadcrumb } from 'antd';

interface response {
  status: boolean;
  message: string;
  data?: any[];
}

const TradePage: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const [activeButton, setActiveButton] = useState('1');
  const [competitorData, setCompetitorData] = useState<response>();
  const [tradeData, setTradeData] = useState<response>();
  const [exportData, setExportData] = useState<response>();
  const [categoryTableFields, setCategoryTableFields] = useState<response>();
  const [categoryContents, setCategoryContents] = useState<response>();
  const [loading, setLoading] = useState(false);
  const [currentLink, setCurrentLink] = useState<string>('-');

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        setCurrentLink(
          `${state.category_id?.name ?? ''} — ${state.market_id?.name ?? ''} — ${
            state.matching_subcategory[0]?.sub_category_name ?? ''
          }` || ' ',
        );
        const endpoint = getEndpoint(activeButton);
        const response: response = await fetchData(endpoint);
        switch (activeButton) {
          case '1':
            setCompetitorData(response);
            break;
          case '2':
            if (response.data && response.data.length >= 2) {
              setTradeData(response.data[0]);
              setExportData(response.data[1]);
            } else {
              console.error('Trade and Export data not available in response');
            }
            break;
          case '3':
            if (response.data && response.data.length >= 2) {
              setCategoryContents(response.data[0]);
              setCategoryTableFields(response.data[1]);
            }
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [activeButton]);

  const fetchData = async (endpoint: string): Promise<response> => {
    try {
      if (!endpoint) throw new Error('Invalid endpoint');
      switch (activeButton) {
        case '1':
          return await ViewCompetitor(endpoint);
        case '2':
          const [tradeData, exportData] = await Promise.all([
            ViewTradedata(`${endpoint}/tradeImport`),
            ViewTradedata(`${endpoint}/tradeExport`),
          ]);
          return { status: true, message: 'Trade and Export data fetched successfully', data: [tradeData, exportData] };
        case '3':
          const [categoryContents, categoryTableFields] = await Promise.all([
            ViewCategorydata(`${endpoint}/categoryContents`),
            ViewCategorydata(`${endpoint}/categoryTableFields`),
          ]);
          return {
            status: true,
            message: 'Category data fetched successfully',
            data: [categoryContents, categoryTableFields],
          };
        default:
          return { status: false, message: 'Invalid tab' };
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return { status: false, message: 'Error fetching data' };
    }
  };

  const getEndpoint = (button: string) => {
    const { category_id, market_id, cat_market_sabcat_id } = state || {};
    console.log(state);
    switch (button) {
      case '1':
        return `categories/categoryMarket/${category_id?._id}/${market_id?._id}/${cat_market_sabcat_id}/competitorData`;
      case '2':
      case '3':
        return `categories/categoryMarket/${category_id?._id}/${market_id?._id}/${cat_market_sabcat_id}`;
      default:
        return '';
    }
  };

  const handleTabChange = (key: string) => {
    setActiveButton(key);
  };

  const handleFilterChange = async (value: string) => {
    try {
      const endpoint = getEndpoint(activeButton);
      const response: response = await ViewCompetitor(`${endpoint}?productName=${value}`);
      setCompetitorData(response);
    } catch (error) {
      console.error('Error fetching filtered competitor data:', error);
    }
  };

  return (
    <>
      <S.MainRow>
        <S.Breadcrumb>
          <Breadcrumb style={{ margin: '8px 0' }}>
            <Breadcrumb.Item>
              <Link to={'/dashboard/your-subscriptions'}>Subscriptions</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={'/dashboard/your-subscriptions/TradeDetailsPage'}>{currentLink}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </S.Breadcrumb>
      </S.MainRow>
      <S.MainRow>
        <BaseCol span={24}>
          <S.TabRow>
            <BaseCard padding={[10, 20]}>
              <CustomTabBar
                activeKey={activeButton}
                onChange={handleTabChange}
                tabs={[
                  {
                    key: '1',
                    label: `${t('Competitor')} Data`,
                    icon: <CompetitorIcon style={activeButton === '1' ? { fill: '#294d4a' } : { fill: 'black' }} />,
                  },
                  {
                    key: '2',
                    label: `${t('Trade')} Data`,
                    icon: <TradeDataIcon style={activeButton === '2' ? { fill: '#294d4a' } : { fill: 'black' }} />,
                  },
                  {
                    key: '3',
                    label: `${t('Category')} Data`,
                    icon: <CategoryDataIcon style={activeButton === '3' ? { fill: '#294d4a' } : { fill: 'black' }} />,
                  },
                ]}
              />
            </BaseCard>
          </S.TabRow>

          {activeButton === '1' && (
            <S.CompetitorTable padding={[20, 25]}>
              {loading ? (
                <Loading />
              ) : (
                <CompetitorData onFilterChange={handleFilterChange} competitorData={competitorData} />
              )}
            </S.CompetitorTable>
          )}

          {activeButton === '2' && (
            <>{loading ? <Loading /> : <TradeData tradeData={tradeData} exportData={exportData} />}</>
          )}

          {activeButton === '3' && (
            <S.TradeDataTable padding={[10, 10]}>
              {loading ? (
                <Loading />
              ) : (
                <CategoryData categoryTableFields={categoryTableFields} categoryContents={categoryContents} />
              )}
            </S.TradeDataTable>
          )}
        </BaseCol>
      </S.MainRow>
    </>
  );
};

export default TradePage;
