import React, { useEffect, useState } from 'react';
import * as S from './CategoriesPage.styles';
import { ViewCategories } from '@app/api/categories/ViewCategories.api';
import CategoriesData from './CategoriesData';
import Model from '@app/components/Model';
import AddCategoryForm from './AddCategoryForm';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';

interface response {
  data?: [];
}
interface ModalState {
  isModalOpen: boolean;
}
const CategoriesPage: React.FC = () => {
  const [item, setitem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const categoryPermissions =
    permissionFromLocalStorage && userPermissions.pagePermissions.find((e) => e.page_name === 'Categories');
  const { isModalOpen } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const AddCategory = async () => {
    showModal();
  };
  const loadCategories = async () => {
    try {
      setLoading(true);
      const response: response = await ViewCategories();
      setitem(response?.data as []);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const desktopLayout = (
    <S.Container>
      {!loading ? (
        <>
          {categoryPermissions?.permissions?.add_category_button && (
            <>
              <S.Button onClick={AddCategory}>Add Category</S.Button>
              <Model
                title="Add Category"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                content={<AddCategoryForm loadCategories={loadCategories} handleOk={handleOk} />}
              />
            </>
          )}
          {categoryPermissions?.permissions?.list_of_categories && (
            <CategoriesData items={item} loadCategories={loadCategories} permissions={categoryPermissions} />
          )}
        </>
      ) : (
        <div>
          <BaseSpin size="large" />
        </div>
      )}
    </S.Container>
  );

  return (
    <>
      {desktopLayout}
      {/* {isDesktop ? desktopLayout : mobileAndTabletLayout} */}
    </>
  );
};

export default CategoriesPage;
