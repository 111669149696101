import { httpApi } from '@app/api/http.api';

// // This Api is for Adding(POST) the Table Data for --> Competitor table data in category module

// interface formData {
//   outlet: string;
//   product_name: string;
//   type: string;
//   weight: string;
//   price: string;
//   country_manufacture: string;
//   product_claim: string;
//   date: string;
// }

//  interface formData {

//  }
type _id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;

export const AddImage = (
  _id: _id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
  formData: any,
): Promise<undefined> => {
  return httpApi
    .post<undefined>(
      `categories/categoryMarket/${_id}/${category_market_id}/${cat_market_sabcat_id}/competitorData/competitorImageAdd`,
      formData,
    )
    .then(({ data }) => data);
};
export const deleteImage = (
  _id: _id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
  deleteid: any,
): Promise<undefined> => {
  return httpApi
    .delete<undefined>(
      `categories/categoryMarket/${_id}/${category_market_id}/${cat_market_sabcat_id}/competitorData/${deleteid}/imageDelete`,
    )
    .then(({ data }) => data);
};
