import { httpApi } from '@app/api/http.api';
interface values {
  sub_cat_id: string;
}
export const AddCatMarketSubcategory = (category_id: string, market_id: string, values: values): Promise<undefined> => {
  return httpApi
    .post<undefined>(`categories/categoryMarket/${category_id}/${market_id}/marketSubCatUpdate`, values)
    .then(({ data }) => data);
};

// http://localhost:3000/localhost/categories/market/65e01492f9cc4d34a85a1894/65dec75d7e603737e04eb0f9/marketSubCatUpdate   (POST)  category_id/market_id
// {
//     "sub_cat_id":"65e016aaf9cc4d34a85a1a50"
// }
