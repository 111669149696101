import { httpApi } from '@app/api/http.api';

interface AccessPermission {
  permissionId: string;
  value: string;
}

interface AssignPermissionsRequest {
  UserID: string;
  AccessPermissions: AccessPermission[];
}

interface AssignPermissionsResponse {
  status: boolean;
  message: string;
}

export const assignPermissionsAPI = (requestData: AssignPermissionsRequest): Promise<AssignPermissionsResponse> => {
  return httpApi.post<AssignPermissionsResponse>('/assign-permission/user', requestData).then(({ data }) => data);
};
