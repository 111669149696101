import React from 'react';
import LogoChange from './LogoChange';

function AvocadoLogoChange() {
  return (
    <>
      <div>
        <LogoChange site_name={'avocado'} />
      </div>
    </>
  );
}

export default AvocadoLogoChange;
