import { httpApi } from '@app/api/http.api';

interface AssignPermissionsResponse {
  status: boolean;
  message: string;
  data: {
    _id: string;
    UserID: string;
    AccessPermissions: {
      _id: string;
      permissionId: string;
      value: string;
    }[];
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
}
export const getAssignedPermissionsAPI = (UserID: string): Promise<AssignPermissionsResponse> => {
  return httpApi.get<AssignPermissionsResponse>(`/assign-permission/${UserID}/`).then(({ data }) => data);
};
