import { httpApi } from '@app/api/http.api';

interface formData {
  data: {
    source: string;
    importer_ranking: number;
    imported_value: number;
    year_range_short_term: {
      from: number;
      to: number;
    };
    year_range_long_term: {
      from: number;
      to: number;
    };
    date: Date;
  };
}

type category_id = string;
type category_market_id = string;
type cat_market_sabcat_id = string;

export const GetTradeImportTable = (
  category_id: category_id,
  category_market_id: category_market_id,
  cat_market_sabcat_id: cat_market_sabcat_id,
): Promise<formData> => {
  return httpApi
    .get<formData>(
      `/categories/categoryMarket/${category_id}/${category_market_id}/${cat_market_sabcat_id}/tradeImport`,
    )
    .then(({ data }) => data);
};

//this api is to fetch the Trade import Table data

// categories/categoryMarket/65f29536a85b1949f49cea5c/65f295eea85b1949f49cebae/65f296a9a85b1949f49cec8d/tradeImport
