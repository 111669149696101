import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './AdminSiderMenu.styles';
import {
  AdminSidebarNavigationItem,
  AdminsidebarDashboard,
} from '@app/components/layouts/admin/AdminSider/adminSidebarNavigation';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = AdminsidebarDashboard.reduce(
  (result: AdminSidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const AdminSiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  // const user = readUser();
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const { t } = useTranslation();
  const location = useLocation();
  const user = useAppSelector((state: any) => state.user.user);
  //   const role = useAppSelector((state) => state?.user?.user?.role);
  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];
  const filteredAdminSideBar = AdminsidebarDashboard?.filter(
    (e) => userPermissions?.pagePermissions?.find((event) => event?.key === e?.key)?.permissions?.show_page,
  );
  const userDashboard = user?.role === 'SUPERADMIN' ? AdminsidebarDashboard : filteredAdminSideBar;
  const sidebarDashboardFilter = userDashboard.filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (nav: any) => !((user?.role === 'CLIENT' || !(user?.role === 'SUPERADMIN')) && nav.key === 'super-admin-panel'),
  );

  const openedSubmenu = userDashboard.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];
  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
      items={sidebarDashboardFilter.map((nav) => {
        const isSubMenu = nav.children?.length;

        return {
          key: nav.key,
          title: t(nav.title),
          label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
          icon: nav.icon,
          children:
            isSubMenu &&
            nav.children &&
            nav.children.map((childNav) => ({
              key: childNav.key,
              label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
              title: t(childNav.title),
            })),
        };
      })}
    />
  );
};

export default AdminSiderMenu;
