import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import React, { useEffect, useLayoutEffect } from 'react';
import {
  MenuIcon,
  Nav,
  NavbarContainer,
  NavLink,
  NavLogo,
  NavBtn,
  NavBtnLink,
  NavModal,
  NavBarBtn,
} from './NavbarElements';
import { useState } from 'react';
import Model from '@app/components/Model';
import ContactUs from '@app/pages/ContactUs/ContactUs';
import { readUser } from '@app/services/localStorage.service';
import { IoCartOutline } from 'react-icons/io5';
import ProductCard from '../Addtocart/addToCart';
import { Badge } from 'antd';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { selectCartItems } from '@app/store/slices/addToCartSlice';
import { GetLogoChange } from '@app/api/logoChange/GetLogoChange.api';
interface Log {
  toggle: () => void;
  userLoggedIn: boolean;
}
interface ModalState {
  isModalOpen: boolean;
  isModalOpenCart: boolean;
}
const Navbar: React.FC<Log> = ({ toggle, userLoggedIn }: Log) => {
  const user = readUser();
  const [img, setImage] = useState();
  const cartItems = useAppSelector(selectCartItems);
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, isModalOpenCart: false });
  const cart = localStorage.getItem('subscribeplan');
  const subscribeplan = cart ? JSON.parse(cart) : null;
  // const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;

  const { isModalOpen, isModalOpenCart } = modalState;

  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true, isModalOpenCart: false });
  };

  const showModalCart = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: true });
  };
  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false, isModalOpenCart: false });
  };
  const [scrollNav, setScrollNav] = useState<boolean>(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);
  const product = { id: cartItems.length + 1, name: 'dsdf', price: 99 };

  const fetchImage = async () => {
    try {
      const response = await GetLogoChange();
      console.log('image response', response);
      setImage(response?.data[0]);
      localStorage.setItem('logo', response?.data[0]?.logo);
    } catch (error) {
      console.log(error);
    }
  };
  const finalImage = localStorage.getItem('logo');
  useLayoutEffect(() => {
    fetchImage();
  }, []);
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/">
              <img src={finalImage} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </NavLogo>
            <MenuIcon onClick={toggle}>
              <FaBars />
            </MenuIcon>
            <NavBarBtn to="about" smooth={true} duration={500} spy={true} offset={-140}>
              About
            </NavBarBtn>
            <NavBarBtn to="features" smooth={true} duration={500} spy={true} offset={-140}>
              Features
            </NavBarBtn>
            <NavBarBtn to="services" smooth={true} duration={500} spy={true} offset={-100}>
              Testimonials
            </NavBarBtn>
            {/* <NavItem>
                <NavLink to="#about">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="#discover">Discover</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="#services">Services</NavLink>
              </NavItem> */}
            {!userLoggedIn && (
              <NavBtn>
                <NavLink to="/auth/sign-up">Sign Up</NavLink>
              </NavBtn>
            )}
            {userLoggedIn && (
              <NavBtn>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </NavBtn>
            )}
            {(user?.role === 'ADMIN' || user?.role === 'SUPERADMIN') && (
              <NavBtn>
                <NavLink to="/admin/users">{user?.role === 'SUPERADMIN' ? 'Super Admin' : 'Admin'}</NavLink>
              </NavBtn>
            )}
            <NavBtn>
              <NavModal onClick={showModal}>Contact Us</NavModal>
            </NavBtn>

            <NavBtn>
              {!userLoggedIn ? (
                <NavBtnLink to="/auth/login">Sign In</NavBtnLink>
              ) : (
                <NavBtnLink to="/logout">Logout</NavBtnLink>
              )}
            </NavBtn>
            {userLoggedIn && (
              <NavBtn>
                <Badge color="#00B4A0" count={subscribeplan?.length} showZero>
                  <IoCartOutline onClick={showModalCart} fontSize="30px" />
                </Badge>
              </NavBtn>
            )}

            {/* {!userLoggedIn && (
              <NavBtn>
                <NavLink to="/auth/sign-up">Sign Up</NavLink>
              </NavBtn>
            )} */}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
      <Model
        title="Contact"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<ContactUs handleCancel={handleCancel} />}
      />
      <Model
        title="Cart"
        open={isModalOpenCart}
        onOk={handleOk}
        onCancel={handleCancel}
        content={<ProductCard product={product} handleClose={handleCancel} handleOpen={showModalCart} />}
      />
    </>
  );
};

export default Navbar;
