import { httpApi } from '@app/api/http.api';
interface response {
  status?: boolean;
  message?: string;
  data?: [];
}
export const assignPagePermission = (userid: string, type: number): Promise<response> => {
  return httpApi.get<response>(`/assignPagePermission/${userid}/${type}  `).then(({ data }) => data);
};

export const updatePagePermission = (data: any): Promise<response> => {
  return httpApi.post<response>(`assignPagePermission/addPermission`, data).then(({ data }) => data);
};
